import { get } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import {
  EditButton,
  List,
  NumberField,
  TextField,
  Responsive,
  SelectField,
  ShowButton,
  SimpleList,
  FunctionField,
} from 'react-admin';
import { companies } from 'reactAdmin/users/constants';

import Datagrid from '../Datagrid';
import EmbeddedEventTypeField from '../event-types/EmbeddedEventTypeField';
import CustomDateField from '../fields/CustomDateField';
import DateField from '../fields/DateField';
import TruthyBooleanField from '../fields/TruthyBooleanField';
import BooleanField from '../fields/VerbalBooleanField';
import { districts, cities, countries } from '../locations/constants';
import EmbeddedLocationField from '../locations/EmbeddedLocationField';
import EmbeddedTrainerField from '../trainers/EmbeddedTrainerField';
import formattedTrainerFullName from '../trainers/utils/formattedFullName';
import CopyIdButton from '../../components/CopyIdButton';
import EventFilter from './EventFilter';
import EventListActions from './EventListActions';
import EventStatusIcon from './EventStatusIcon';
import LinkToRelatedResults from './LinkToRelatedResults';
import LinkToRelatedTickets from './LinkToRelatedTickets';
import dateBeginFormattedString from './utils/dateBeginFormattedString';

const eventRowStyle = (record) => ({
  backgroundColor: get(record, 'flags.needs_coach_replacement') === true ? '#FFB9B5' : 'inherit',
});

const renderAllChargeableBookings = (record) => {
  return record.illicit_cancellations_count + record.participants_count;
};

const defaultFilterValues = {
  hide_created_by_mistake: true,
  with_company: '_all',
  is_cancelled: false,
  date_begin_gte: moment().clone().startOf('day').format(),
  date_begin_lte: moment().clone().endOf('day').format(),
};

const EventList = (props) => (
  <List
    {...props}
    sort={{ field: 'date_begin', order: 'desc' }}
    perPage={25}
    filters={<EventFilter />}
    filterDefaultValues={defaultFilterValues}
    actions={<EventListActions />}
    bulkActionButtons={false}
  >
    <Responsive
      small={
        <SimpleList
          primaryText={(record) => dateBeginFormattedString(get(record, 'date_begin', ''))}
          secondaryText={(record) =>
            `${formattedTrainerFullName(get(record, 'coach'))} | ${get(record, 'location.name', '')}`
          }
          rightAvatar={(record) => (
            <small>
              {get(record, 'participants_count', 0)}/{record.max_participants || '∞'}
            </small>
          )}
          leftIcon={(record) => <EventStatusIcon record={record} />}
        />
      }
      medium={
        <Datagrid
          defaultColumns={[
            'date_begin',
            'coach_id',
            'location_id',
            'current_status.status_name',
            'attendees_count',
            'participants_count',
            '_all_chargeable_bookings',
            'is_published',
            'offer_earliest_valid_from',
            'pending_offers',
            'open_offers',
            'seen_offers',
            'offer_applications_count',
          ]}
          rowStyle={eventRowStyle}
        >
          <ShowButton />
          <CopyIdButton />

          <CustomDateField source='date_begin' timezoneSource='location.timezone' label='Starts At' />

          <BooleanField source='is_cancelled' label='Cancelled' colored={false} />
          <TruthyBooleanField source='recurrent_id' label='Is Recurrent' />

          <EmbeddedTrainerField source='coach_id' label='Coach' showAvatar={false} />
          <EmbeddedLocationField source='location_id' label='Location' />
          <EmbeddedEventTypeField source='event_type_id' label='Event Type' />

          <SelectField source='company' label='Company' choices={companies} allowEmpty />

          <NumberField source='attendees_count' label='Attended' sortable />
          <NumberField source='no_shows_count' label='NoShows' sortable />
          <NumberField source='participants_count' label='Bookings (Not canceled)' sortable />
          <FunctionField
            source='_all_chargeable_bookings'
            sortable={false}
            label='Bookings (Chargeable)'
            render={renderAllChargeableBookings}
          />

          <NumberField source='max_participants' />
          <NumberField source='duration' />

          <TextField source='current_status.status_name' label='Current status' sortable={false} />
          <TextField source='current_status.meta.reason' label='Cancellation reason' sortable={false} />
          <SelectField source='location.country_code' label='Country' choices={countries} sortable={false} />
          <SelectField source='location.city_code' label='City' choices={cities} sortable={false} />
          <SelectField source='location.district_code' label='District' choices={districts} asortable={false} />
          <BooleanField source='location.flags.automatic_cancellation' sortable={false} label='Autom. Cancellation' />

          <NumberField source='results_count' label='# Results' sortable={false} />
          <NumberField
            label='Average Gritpoints'
            options={{ maximumFractionDigits: 1 }}
            sortable={false}
            source='gritpoints_avg'
          />
          <NumberField source='gritpoints_sum' label='Sum of Gritpoints' sortable={false} />
          <NumberField
            label='Average Calories'
            options={{ maximumFractionDigits: 1 }}
            sortable={false}
            source='calories_burned_avg'
          />
          <NumberField source='calories_burned_sum' label='Sum of Calories' sortable={false} />
          <DateField source='created_at' />
          <BooleanField source='is_published' label='Published' />
          <CustomDateField
            source='offer_earliest_valid_from'
            timezoneSource='location.timezone'
            label='Earliest Offer Valid From'
            fallback={() => <>No Open Offers</>}
          />

          {/* The following are not real sources, but they're necessary to allow to select/deselect them from the UI :facepalm: */}
          <NumberField source='pending_offers' sortable={false} label='Pending Offers' />
          <NumberField source='open_offers' sortable={false} label='Open Offers' />
          <NumberField source='seen_offers' sortable={false} label='Seens Offers' />
          <NumberField label='Applications' sortable source='offer_applications_count' />

          <LinkToRelatedTickets />
          <LinkToRelatedResults />
          <EditButton />
        </Datagrid>
      }
    />
  </List>
);

export default EventList;
