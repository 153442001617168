import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  itemButtonWrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  iconSuccess: {
    color: green[500],
  },
  fabProgress: {
    position: 'absolute',
    top: -8,
    left: -8,
    zIndex: 1,
  },
}));

const CreateList = ({ timezone, occurrences, isLoading, isSuccess, isError }) => {
  const classes = useStyles();
  return (
    <div>
      <List>
        {occurrences &&
          occurrences.map((date) => {
            const dateBegin = moment.tz(date, timezone);
            return (
              <ListItem key={date}>
                <div className={classes.itemButtonWrapper}>
                  {!isError && !isSuccess && !isLoading && <SaveIcon />}
                  {isSuccess && <CheckIcon className={classes.iconSuccess} />}
                  {isError && <CloseIcon />}
                  {isLoading && (
                    <>
                      <SaveIcon />
                      <CircularProgress className={classes.fabProgress} />
                    </>
                  )}
                </div>
                <ListItemText primary={dateBegin.format('ddd, DD.MM.YY')} secondary={dateBegin.format('h:mm a')} />
              </ListItem>
            );
          })}
      </List>
    </div>
  );
};

CreateList.propTypes = {
  occurrences: PropTypes.array,
  timezone: PropTypes.string,
};

export default CreateList;
