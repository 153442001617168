import React from 'react';
import { Show, SimpleShowLayout, BooleanField, DateField, TextField } from 'react-admin';

import SensorPoolReferenceField from '../sensor_pools/SensorPoolReferenceField';


const SensorShow = (props) => (
  <Show title='Sensor Detail View' {...props}>
    <SimpleShowLayout>
      <TextField source='slug' label='Sensor ID' />
      <TextField source='hw_uid' label='Hardware ID' />
      <BooleanField source='is_allocatable' label='Allocatable' />
      {/* <BooleanField source='is_customer_property' label='Customer Owned' /> */}

      <TextField source='vendor_name' />
      <TextField source='model_name' />
      {/* <TextField source='device_uuid' label='iOS Hardware UUID' />
      <TextField source='device_mac' label='Android Hardware MAC' /> */}
      <TextField source='serial_no' label='Vendor Serial No.' />

      {/* <DateField source='commissioned_at' label='Commissioned' />
      <DateField source='decommissioned_at' label='Decommissioned' /> */}

      <TextField source='current_battery_level' />
      <DateField
        source='current_battery_level_updated_at'
        label='Last Battery Updated'
      />
      <SensorPoolReferenceField />
      <TextField source='replacement_reason' label='Last Replacement Reason' />
      <TextField source='notes' />

      <DateField source='created_at' />
    </SimpleShowLayout>
  </Show>
);

export default SensorShow;
