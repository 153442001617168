import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import _filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import PQueue from 'p-queue';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Toolbar, Button, useCreate } from 'react-admin';

import Row from './Row';

const useStyles = makeStyles((theme) => ({
  disabled: {
    opacity: '0.3',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonGroup: {
    justifyContent: 'flex-end',
  },
  deleteButton: {
    color: theme.palette.error.main,
    text: {
      color: theme.palette.error.main,
    },
  },
}));

const queue = new PQueue({ concurrency: 2 });

const UploadedAudioFilesTable = ({ files, adminProps, removeFiles, clearSavedFile }) => {
  const classes = useStyles();

  const [progressStatus, setProgressStatus] = useState({});
  const [formState, setFormState] = useState({});

  const [create] = useCreate();
  const createAsset = async (values) => {
    const postData = {
      ...values,
    };
    try {
      await create('assets', postData);
      updateStatus(postData.file.file_path, 'success');
    } catch (error) {
      updateStatus(postData.file.file_path, 'error');
    }
  };

  const updateStatus = (id, status) => {
    const newStatus = { [id]: { id, status } };
    setProgressStatus((prevStatus) => ({ ...prevStatus, ...newStatus }));
  };

  const getFormState = (id, state) => {
    const newFormState = { [id]: { ...state } };
    setFormState((prevState) => ({ ...prevState, ...newFormState }));
  };

  const formIds = files.map((file) => file.path);

  const savedFiles =
    !!Object.keys(progressStatus).length && _filter(progressStatus, (status) => status.status === 'success');

  const unsavedFiles = files.filter((file) => !Object.keys(progressStatus).includes(file.path));

  const queueUpload = (id) => {
    updateStatus(id, 'loading');

    return queue.add(async () => {
      const button = document.getElementById(id);
      button.click();
    });
  };

  const onSave = () => {
    formIds.forEach((id) => {
      if (!isEmpty(formState[id].errors)) return;
      if (
        (!!Object.keys(progressStatus).length && progressStatus[id] && progressStatus[id].status === 'success') ||
        formState[id].hasValidationErrors
      )
        return;
      queueUpload(id);
    });
  };

  const deleteAllUnsaved = () => {
    const fileIDsToDelete = unsavedFiles && unsavedFiles.map((file) => file.path);
    removeFiles(fileIDsToDelete);
  };

  const clearAllSaved = () => {
    const savedFilesPaths = savedFiles && savedFiles.map((file) => file.id);
    const fileIdsToDelete = files.reduce((accumulator, file) => {
      if (savedFilesPaths.includes(file.path)) {
        accumulator.push(file.path);
      }
      return accumulator;
    }, []);
    removeFiles(fileIdsToDelete);
  };

  const FormToolbar = ({ classes }) => (
    <Toolbar className={classes.toolbar}>
      <Button label='save' onClick={() => onSave()}>
        <SaveIcon />
      </Button>
      <div>
        <Button color='primary' label='clear saved' onClick={() => clearAllSaved()}>
          <ClearIcon />
        </Button>
        <Button className={classes.deleteButton} label='delete all unsaved' onClick={() => deleteAllUnsaved()}>
          <DeleteIcon />
        </Button>
      </div>
    </Toolbar>
  );

  return (
    <>
      {files.map((row) => {
        const { path } = row;
        const status = !!Object.keys(progressStatus).length && progressStatus[path] && progressStatus[path].status;
        const disableForm = status === 'success';
        return (
          <div key={path} className={classes.container}>
            <Row
              disableForm={disableForm}
              row={row}
              createAsset={createAsset}
              removeFiles={removeFiles}
              status={status}
              clearSavedFile={clearSavedFile}
              adminProps={adminProps}
              updateStatus={updateStatus}
              getFormState={getFormState}
              formState={formState}
            />
          </div>
        );
      })}
      {!!files.length && <FormToolbar classes={classes} />}
    </>
  );
};
UploadedAudioFilesTable.propTypes = {
  files: PropTypes.array,
  adminProps: PropTypes.object,
  removeFiles: PropTypes.func,
};

export default UploadedAudioFilesTable;
