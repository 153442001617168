import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import {
  BooleanInput,
  FormDataConsumer,
  NumberInput,
  SelectInput,
  TextInput,
  SimpleForm,
  DateTimeInput,
  FunctionField
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { companies } from 'reactAdmin/users/constants';
import emptyToNull from 'utils/emptyToNull';

import EventTypeReferenceInput from '../event-types/EventTypeReferenceInput';
import LocationReferenceInput from '../locations/LocationReferenceInput';
import TrainerReferenceInput from '../trainers/TrainerReferenceInput';
import { languages, entityDefaultValues } from './constants';
import { EventFormWarning } from './EventFormWarning';

/**
 * @deprecated
 * Now we use RecurringEventForm to create events
 */
const EventForm = ({ isExisting, ...props }) => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const queryValues = {};
  const params = ['event_type_id', 'location_id', 'coach_id', 'date_begin', 'duration', 'max_participants'];

  params.forEach((p) => {
    if (queryParams[p]) queryValues[p] = queryParams[p];
  });

  const defaultValues = { ...entityDefaultValues, ...queryValues };

  return (
    <SimpleForm {...props} initialValues={defaultValues} redirect={isExisting ? 'show' : 'list'}>
      {isExisting && <TextInput disabled source='id' />}
      <BooleanInput label='Find coach' source='flags.needs_coach_replacement' />
      <BooleanInput source='is_published' />
      <BooleanInput source='is_chargeable' label='Chargeable' />
      <LocationReferenceInput defaultValue={defaultValues.location_id} />
      <TrainerReferenceInput defaultValue={defaultValues.trainer_id} />
      <EventTypeReferenceInput defaultValue={defaultValues.event_type_id} />
      <SelectInput source='language' label='Event Language' choices={languages} defaultValue='en' />

      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return <DateTimeInput source='date_begin' label='Starts At' location_id={formData.location_id} {...rest} />;
        }}
      </FormDataConsumer>
      {
        isExisting && <FunctionField addLabel={false} render={(record) => <EventFormWarning startTime={record.date_begin} />}></FunctionField>
      }
      <NumberInput label='Duration (in minutes)' source='duration' step={1} />
      <SelectInput label='Company' source='company' choices={companies} allowEmpty resettable />
      <NumberInput source='max_participants' step={1} parse={emptyToNull} />

      {/* Special Fields */}
      <BooleanInput source='special' />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.special && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TextInput source='special_event_name' label='Special Event Name' {...rest} />
              <TextInput multiline source='special_event_description' label='Special Event Description' {...rest} />
            </div>
          )
        }
      </FormDataConsumer>
    </SimpleForm>
  );
};

EventForm.propTypes = {
  isExisting: PropTypes.bool,
  location: PropTypes.object,
};

EventForm.defaultProps = {
  isExisting: false,
  location: {},
};

export default EventForm;
