import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const authToken = localStorage.getItem('token');

const AudioPlayer = ({ file = {}, style }) => {
  const generateFilePath = () => {
    return `${file.filePath}?token=${authToken}&_=${Math.random().toString(12)}`;
  };

  const [loadedFile, setLoadedFile] = useState({
    filePath: generateFilePath(),
    currentTime: 0,
  });
  const player = useRef();

  useEffect(() => {
    setLoadedFile((prevState) => {
      return {
        ...prevState,
        filePath: generateFilePath(),
        currentTime: 0,
      };
    });
  }, [file]);

  const updateCurrentTime = () => {
    if (!loadedFile.currentTime) return;
    player.current.currentTime = loadedFile.currentTime;
    setLoadedFile((prevState) => {
      return {
        ...prevState,
        currentTime: 0,
      };
    });
  };

  const updateLink = (event) => {
    const { currentTime } = player.current;
    if (player.current.error.code === 2) {
      setLoadedFile((prevState) => {
        return {
          ...prevState,
          filePath: generateFilePath(),
          currentTime,
        };
      });
    }
  };

  return (
    <audio
      key={loadedFile.filePath}
      controls
      autoPlay
      style={style}
      onCanPlayThrough={updateCurrentTime}
      onError={updateLink}
      ref={player}>
      <source
        src={loadedFile.filePath}
        type={loadedFile.contentType} />
      Your browser does not support the audio element.
    </audio>
  );
};

AudioPlayer.propTypes = {
  file: PropTypes.object,
  style: PropTypes.object,
};

export default AudioPlayer;
