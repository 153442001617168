import React from 'react';
import { Create } from 'react-admin';

import EventTypeForm from './EventTypeForm';

const EventTypeCreate = (props) => (
  <Create title='Create Event Type' {...props} undoable={false}>
    <EventTypeForm />
  </Create>
);

export default EventTypeCreate;
