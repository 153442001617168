import React from 'react';
import { withRouter } from 'react-router';
import { GET_LIST, GET_ONE } from 'react-admin';
import qs from 'query-string';
import get from 'lodash/get';
import has from 'lodash/has';
import moment from 'moment-timezone';
import domToImage from 'dom-to-image-chrome-fix';
import download from 'downloadjs';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

import { restClient } from 'client';
import DailyStats from 'components/DailyStats/DailyStats';
import generatePreview from 'utils/generatePreview';

const styles = {
  note: {
    fontSize: '0.7rem',
    color: 'rgba(0, 0, 0, 0.4)',
  },

  exampleImageInput: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    opacity: 0,
  },
};

class DailyStatsPage extends React.Component {
  constructor (props) {
    super(props);

    const params = qs.parse(this.props.history.location.search);
    let result_id = params.result_id || null;

    // TODO: React >= 16 !
    // this.dailyStatsRef = React.createRef();

    this.state = {
      result_id,
      isLoading: false,
      error: null,
      report: null,
      user: null,
      hrGraphImageUrl: null,
      modalOpen: false,
    };
  }

  componentDidMount () {
    this.fetchStats({ result_id: this.state.result_id });
  }

  fetchStats = async ({ result_id: resultId }) => {
    let report = null;
    let user = null;

    this.setState({ isLoading: true, error: null });

    try {
      report = await restClient(GET_LIST, `reports/results/${resultId}/daily_stats`).then((res) => res.data || null);
      user = await restClient(GET_ONE, 'users', { id: get(report, 'result.user_id') }).then((res) => res.data || null);
    } catch (error) {
      this.setState({ isLoading: false, error });
    }

    this.setState({ isLoading: false, error: null, report, user });
  }

  handleFileInputChange = (event) => {
    if (!event.target.files || !event.target.files[0]) return;

    const reader = new FileReader();

    reader.onloadend = (ev) => {
      this.setState({
        hrGraphImageUrl: ev.target.result,
      });
    };

    const file = event.target.files[0];

    reader.readAsDataURL(file);

    this.setState({ hrGraphFile: file.name });
  }

  handleExport = async (username, date) => {
    this.setState({ error: null });

    const name = username.toLowerCase();
    const dateFormatted = moment(date).format('DDMMYYYY');
    const filename = `dailystats-${name}-${dateFormatted}.png`;
    const input = document.getElementById('dailystats');
    // const input = this.dailyStatsRef;

    try {
      const dataUrl = await domToImage.toPng(input);
      download(dataUrl, filename);
    } catch (error) {
      generatePreview('smallpreview', input);
      this.setState({
        error: 'The report could not be automatically downloaded. Please use the preview bellow.',
        modalOpen: true,
      });
    }
  }

  handleClose = () => {
    this.setState({ modalOpen: false });
  }

  renderLoading = () => (
    <div>LOADING...</div>
  );

  render () {
    const { isLoading, report, user, hrGraphImageUrl } = this.state;

    if (isLoading || !report) {
      return this.renderLoading();
    }

    const eventDateBegin = moment(get(report, 'result.event.date_begin'));
    const eventDateBeginFormatted = moment(get(report, 'result.event.date_begin')).format('LLL');
    const userName = user.forename;
    const { result } = report;
    const showImageUpload = has(result, 'readings');
    const workoutStats = {
      meanPoints: get(report, 'event_stats.gritpoints'),
    };
    const weekStats = {
      totalPoints: get(report, 'week_stats.gritpoints'),
      totalCalories: get(report, 'week_stats.calories_burned', 0) + get(report, 'week_stats.calories_epoc', 0),
    };
    const monthStats = {
      totalPoints: get(report, 'month_stats.gritpoints'),
      totalCalories: get(report, 'month_stats.calories_burned', 0) + get(report, 'month_stats.calories_epoc', 0),
    };

    return (
      <div>
        <div style={{ marginBottom: '1rem' }}>
          Daily Stats
        </div>
        <div style={{ marginBottom: '1rem' }}>
          Result: {userName}, {eventDateBeginFormatted}<br />
        </div>

        {!showImageUpload && (
          <div style={{ marginBottom: '1rem' }}>
            {/* <RaisedButton id="upload-btn" label="Select HR Graph Image" containerElement="label"> */}
            {/* </RaisedButton> */}
            <Button id='upload-btn' label='Select HR Graph Image' containerElement='label' variant='contained'>
              <input type='file' onChange={this.handleFileInputChange} style={styles.exampleImageInput} />
            </Button>
            {/* <span>{hrGraphImageUrl || '...'}</span> */}
            <div style={styles.note}>
              * The uploaded image will be lost if you leave the Result Detail View page (notice that you can still switch tabs in this page).
            </div>
          </div>
        )}

        <Dialog
          open={this.state.modalOpen}
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth='xs'
          onClose={this.handleClose}
          aria-labelledby='confirmation-dialog-title'
        >
          <DialogTitle id='confirmation-dialog-title'>Right Click and Save</DialogTitle>
          <DialogContent>
            <div>
              <div id='smallpreview' />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color='primary'>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <div style={{ marginBottom: '1rem' }}>
          <Button variant='contained' onClick={() => this.handleExport(userName, eventDateBegin)}>
            Download as Image *
          </Button>
          <p>
            * Google Chrome required to download the image
          </p>
        </div>

        <div>
          <DailyStats
            result={result}
            user={user}
            hrGraphImageUrl={hrGraphImageUrl}
            workoutStats={workoutStats}
            weekStats={weekStats}
            monthStats={monthStats}
            ref={this.dailyStatsRef}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(DailyStatsPage);
