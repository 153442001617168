export default (gritpoints, calories) => {
  let afterBurn;
  const afterBurnFactors = {
    lvl1: { min: 0, max: 8, factor: 2.68 },
    lvl2: { min: 9, max: 16, factor: 2.43 },
    lvl3: { min: 17, max: 30, factor: 2.21 },
  };

  if (gritpoints >= 16.5) {
    afterBurn = afterBurnFactors.lvl3;
  } else if (gritpoints >= 8.5 && gritpoints < 16.5) {
    afterBurn = afterBurnFactors.lvl2;
  } else {
    afterBurn = afterBurnFactors.lvl1;
  }
  return Math.round((calories / afterBurn.factor));
};
