import React, { useState, useEffect } from 'react';
import { CheckboxGroupInput } from 'react-admin';
import httpClient from '../../../client/httpClient';

const AmenitiesInput = () => {
  const [amenities, setAmenitiesJson] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;
  const url = `${apiUrl}/locations/amenities`;

  const getAmenities = async () => {
    return httpClient(url).then(({ json }) => {
      setAmenitiesJson(json.data);
    });
  };

  useEffect(() => {
    getAmenities();
  }, []);

  const formatValues = (values) => {
    const formattedAmenities = [];
    values?.map((value) => {
      if (value.selected === true) formattedAmenities.push(value.id);
    });
    return formattedAmenities;
  };

  const parseValues = (values) => {
    const parsedAmenities = amenities.map((amenity) => {
      if (values?.includes(amenity.id)) {
        return { ...amenity, selected: true };
      }
      return { ...amenity, selected: false };
    });
    return parsedAmenities;
  };

  return (
    <>
      {amenities && (
        <CheckboxGroupInput
          source='amenities'
          choices={amenities}
          optionText='label'
          optionValue='id'
          translateChoice={false}
          format={formatValues}
          parse={parseValues}
        />
      )}
    </>
  );
};

export default AmenitiesInput;
