import React from 'react';
import { BooleanInput } from 'react-admin';
import { useFormState } from 'react-final-form';

const ApplyToFollowingInput = () => {
  const { values } = useFormState();

  return (
    <BooleanInput
      label='Apply to following events'
      source='apply_to_following_events'
      helperText={
        values.apply_to_following_events &&
        'Attention! Once the offer is accepted, the coach will be assigned to this and all the following events in the series, and this can override previously assigned coaches! Click save to proceed.'
      }
    />
  );
};

export default ApplyToFollowingInput;
