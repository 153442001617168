import domToImage from 'dom-to-image-chrome-fix';

export default (el, input) => {
  domToImage.toCanvas(input)
    .then((canvas) => {
      const preview = document.getElementById(el);
      preview.innerHTML = '';
      canvas.setAttribute('style', 'max-width: 100%; height: auto;');
      preview.appendChild(canvas);
    });
};
