import decodeJwt from 'jwt-decode';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import moment from 'moment-timezone';

import httpClient from './httpClient';

const apiUrl = process.env.REACT_APP_API_URL;

const fetchAuthenticate = (credentials) => {
  const url = `${apiUrl}/authentication`;
  const request = new Request(url, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify({ ...credentials, strategy: 'local' }),
  });

  return fetch(request)
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }

      return response.json();
    })
    .then((payload) => payload.data)
    .then((data) => ({ accessToken: data.accessToken }));
};

const fetchUser = (userId) => {
  const url = `${apiUrl}/users/${userId}`;

  return httpClient(url)
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }

      return response.json;
    })
    .then((payload) => payload.data)
    .then((data) => ({ user: data }));
};

const validateJWT = (decodedToken = null) => {
  let token = decodedToken;
  if (!token) {
    const t = localStorage.getItem('token');
    try {
      token = decodeJwt(t);
    } catch (e) {
      return false;
    }
  }

  const expirationDate = moment.unix(token.exp);
  if (moment().diff(expirationDate) > 0) {
    return false;
  }

  const acceptedRoles = intersection(
    ['admin', 'coach', 'head_coach', 'customer_support_manager', 'customer_support', 'tech_ops'],
    get(token, 'roles', []),
  );
  if (acceptedRoles.length === 0) {
    return false;
  }

  return true;
};

export default {
  login: ({ username, password }) => {
    return fetchAuthenticate({ email: username, password })
      .then(({ accessToken }) => {
        const decodedToken = decodeJwt(accessToken);

        const isJWTValid = validateJWT(decodedToken);
        if (isJWTValid) {
          localStorage.setItem('token', accessToken);
          localStorage.setItem('userId', decodedToken.userId);

          return fetchUser(decodedToken.userId);
        }
        return Promise.reject();
      })
      .then(({ user }) => localStorage.setItem('user', JSON.stringify(user)))
      .catch(() => {
        const msg = 'Authentication failed...';
        return Promise.reject(msg);
      });
  },
  checkAuth: () => {
    const isJWTValid = validateJWT();
    if (!isJWTValid) {
      localStorage.removeItem('token');
      return Promise.reject({ redirectTo: '/login' });
    }
    return Promise.resolve();
  },
  getPermissions: () => {
    return Promise.resolve();
  },
  logout: () => {
    localStorage.clear();
    return Promise.resolve();
  },
  checkError: (error) => Promise.resolve(),
};
