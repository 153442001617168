import Avatar from '@material-ui/core/Avatar';
import get from 'lodash/get';
import React from 'react';
import { EditButton, List, Responsive, SelectField, ShowButton, SimpleList, DateField, TextField } from 'react-admin';

import CopyIdButton from 'components/CopyIdButton';
import Datagrid from '../Datagrid';
import UserAvatarField from './AvatarField';
import { companies } from './constants';
import UserEmailField from './EmailField';
import FlagsField from './FlagsField';
import UserFilter from './UserFilter';
import UserListActions from './UserListActions';
import profilePictureUrl from './utils/profilePictureUrl';
import rowStyle from './utils/rowStyle';

const UserList = (props) => (
  <List
    {...props}
    sort={{ field: 'created_at', order: 'desc' }}
    perPage={40}
    filters={<UserFilter />}
    actions={<UserListActions />}
    bulkActions={false}
    bulkActionButtons={false}
  >
    <Responsive
      small={
        <SimpleList
          leftAvatar={(record) => record && <Avatar src={`${profilePictureUrl(record)}`} />}
          primaryText={(record) => `${get(record, 'forename', '')} ${get(record, 'surname', '')}`}
          secondaryText={(record) => (record && record.email) || <UserEmailField record={record} />}
          tertiaryText={(record) => record && new Date(record.created_at).toLocaleDateString('de-DE')}
        />
      }
      medium={
        <Datagrid rowStyle={rowStyle} defaultColumns={['forename', 'surname', 'provider', 'promo_code']}>
          <ShowButton />
          <CopyIdButton />
          <UserAvatarField />
          <TextField source='forename' label='First Name' />
          <TextField source='surname' label='Last Name' />
          <UserEmailField />

          <FlagsField />
          <TextField source='provider_id' label='Provider ID' />
          <TextField source='provider' label='Provider' />
          <TextField source='usc_membership_type' label='USC Membership Type' />
          <TextField source='promo_code' label='Promo Code' />
          <SelectField source='company' label='Company' choices={companies} allowEmpty />

          <TextField source='homezone_city_code' />
          <TextField source='homezone_country_code' />
          <TextField source='homezone_district_code' />

          <TextField source='calculated_max_heart_rate' />
          <TextField source='consent_update_requested_at' />

          <TextField source='fitness_goal' />
          <TextField source='fitness_goal_level' />

          <TextField source='last_logged_in_at' />
          <TextField source='locale' />
          <TextField source='payment_situation' />

          <DateField source='created_at' showTime locales='de-DE' />

          <EditButton />
        </Datagrid>
      }
    />
  </List>
);

export default UserList;
