import React from 'react';
import { Create } from 'react-admin';

import UserForm from './UserForm';

const UserCreate = (props) => (
  <Create {...props} undoable={false}>
    <UserForm />
  </Create>
);

export default UserCreate;
