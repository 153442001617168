import React from 'react';

import { get } from 'lodash';

import { Grid, Link, Typography } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';

function AccountInfo({ record }) {
  return (
    <Grid container direction='row' justifyContent='space-between'>
      <Grid direction='column'>
        <Typography component='h1' color='textPrimary'>
          {get(record, 'forename')}
        </Typography>
        <Typography component='h1' color='textSecondary'>
          {get(record, 'email')}
        </Typography>
      </Grid>
      <Grid item flex={4} justifyContent='flex-end'>
        <Link color='textSecondary' href={`#/users/${record.id}/show`} target='_blank' rel='noopener noreferrer'>
          <LaunchIcon fontSize='small' />
        </Link>
      </Grid>
    </Grid>
  );
}

export default AccountInfo;
