import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput, required } from 'react-admin';
import { languages } from '../../../reactAdmin/audio-files/constants';

function AssetLanguageField ({inputProps, defaultValue}) {
  return (
    <SelectInput
      inputProps={inputProps}
      source='metadata.language'
      label='Language'
      defaultValue={defaultValue}
      choices={languages}
      validate={required()} />
  );
}

AssetLanguageField.propTypes = {
  inputProps: PropTypes.object,
  defaultValue: PropTypes.string,
};

export default AssetLanguageField;


