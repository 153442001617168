import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import {
  BooleanField,
  Button,
  Datagrid,
  EditButton,
  ReferenceManyField,
  Show,
  ShowButton,
  Tab,
  TabbedShowLayout,
  TextField,
  Pagination,
} from 'react-admin';
import { Link } from 'react-router-dom';

import { IdField } from 'components/IdField';
import DateField from '../fields/DateField';
import LocationReferenceField from '../locations/LocationReferenceField';
import { SensorIcon } from '../sensors';
import SensorModelField from '../sensors/SensorModelField';

const styles = {
  button: {
    marginTop: '1em',
  },
};

const CreateNewSensorButton = withStyles(styles)(({ classes, record }) => {
  if (!record) return null;
  return (
    <Button
      className={classes.button}
      component={Link}
      to={{
        pathname: '/sensors/create',
        state: { sensor_pool_id: record.id },
      }}
      label='Add a sensor'
    >
      <SensorIcon />
    </Button>
  );
});

const SensorPoolTitle = ({ record }) => (record ? `"${record.name}" Sensor Pool Detail View` : null);

const SensorPoolShow = (props) => (
  <Show title={<SensorPoolTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label='Base'>
        <IdField id='id' />
        <TextField source='name' />
        <LocationReferenceField source='place_id' />
        <DateField source='created_at' />
      </Tab>
      <Tab label='Sensors'>
        <ReferenceManyField
          addLabel={false}
          reference='sensors'
          target='sensor_pool_id'
          sort={{ field: 'created_at', order: 'ASC' }}
          pagination={<Pagination perPage={25} />}
        >
          <Datagrid>
            <ShowButton />
            <TextField source='slug' label='Sensor ID' />
            <TextField source='hw_uid' label='Hardware ID' />
            <BooleanField source='is_allocatable' label='Allocatable' />
            <SensorModelField label='Model' />
            <TextField source='serial_no' label='Vendor Serial No.' />
            <TextField source='current_battery_level' label='Battery' />
            <DateField source='current_battery_level_updated_at' label='Battery Updated At' showTime locales='de-DE' />
            <DateField source='created_at' showTime locales='de-DE' />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
        <CreateNewSensorButton />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default SensorPoolShow;
