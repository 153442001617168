import React from 'react';
import { AppBar, UserMenu, MenuItemLink, translate } from 'react-admin';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { withStyles } from '@material-ui/core/styles';

import AvatarField from './users/AvatarField';
import AudioPlayer from './audio-player';

const CustomIconStyle = {
  avatar: {
    height: 30,
    width: 30,
  },
};

const styles = {
  player: {
    maxWidth: '100%',
    minWidth: '70%',
    display: 'block',
    margin: '0 auto',
  },
  playerContainer: {
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const CustomIcon = withStyles(CustomIconStyle)(({ user, classes }) => (
  <AvatarField className={classes.avatar} record={user} />
));

const CustomUserMenu = translate(({ translate, ...props }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const userDisplay = user && user.email;

  return (
    <UserMenu
      {...props}
      label='User Menu'
      icon={<CustomIcon user={user} />}
    >
      <MenuItemLink
        to='/account'
        primaryText={userDisplay}
        leftIcon={<AccountCircle />}
      />
    </UserMenu>
  );
});

const CustomAppBar = (props) => (
  <AppBar {...props} userMenu={<CustomUserMenu />}>
    <div id='react-admin-title' />
    <div style={styles.playerContainer}>
      <AudioPlayer style={styles.player} />
    </div>
  </AppBar>
);

export default CustomAppBar;
