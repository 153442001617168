/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _times from 'lodash/times';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import ListItem from './ListItem';


const styles = {
  table: {},
  cell: {
    textAlign: 'center',
  },
};

const List = ({ items, cycles, interval, newCustomers }) => (
  <Table selectable={false} style={styles.table}>
    <TableHead adjustForCheckbox={false} displaySelectAll={false} enableSelectAll={false}>
      <TableRow>
        <TableCell>Interval</TableCell>
        <TableCell style={styles.cell}>{newCustomers ? 'New Customers' : 'Customers'}</TableCell>
        {
          _map(_times(cycles - 1), (cycle) => (
            <TableCell style={styles.cell}>{cycle + 1}</TableCell>
          ))
        }
      </TableRow>
    </TableHead>
    <TableBody stripedRows>
      {items.map((item, idx) => <ListItem key={idx} item={item} index={idx} interval={interval} />)}
    </TableBody>
  </Table>
);

List.propTypes = {
  items: PropTypes.array.isRequired,
  cycles: PropTypes.number.isRequired,
  interval: PropTypes.string.isRequired,
  newCustomers: PropTypes.bool.isRequired,
};


export default List;
