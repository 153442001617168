import React from 'react';
import PropTypes from 'prop-types';
import OngoingIcon from '@material-ui/icons/Schedule';
import CancelledIcon from '@material-ui/icons/Cancel';
import CompletedIcon from '@material-ui/icons/CheckCircle';
import get from 'lodash/get';

const EventStatusIcon = ({ record }) => {
  const status = get(record, 'current_status.status_name');

  if (status === 'completed') {
    return <CompletedIcon />;
  } else if (status === 'cancelled') {
    return <CancelledIcon />;
  } else if (status) {
    return <OngoingIcon />;
  }

  return null;
};

EventStatusIcon.propTypes = { record: PropTypes.object };
EventStatusIcon.defaultProps = { record: {} };

export default EventStatusIcon;
