import React from 'react';
import { AutocompleteInput, ReferenceInput } from 'react-admin';


const SensorPoolReferenceInput = props => (
  <ReferenceInput
    reference='sensor_pools'
    perPage={100}
    {...props}
  >
    <AutocompleteInput optionText={(resource) => resource.name} />
  </ReferenceInput>
);

SensorPoolReferenceInput.defaultProps = {
  source: 'sensor_pool_id',
  label: 'Sensor Pool',
  allowEmpty: true,
  // filter: { is_published: true },
  sort: { field: 'name', order: 'ASC' },
};

export default SensorPoolReferenceInput;
