import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput, required } from 'react-admin';
import { categories } from '../../../reactAdmin/audio-files/constants';

function AssetCategoryField ({inputProps, defaultValue}) {
  return (
    <SelectInput
      inputProps={inputProps}
      source='metadata.category'
      label='Category'
      defaultValue={defaultValue}
      choices={categories}
      validate={required()} />
  );
}

AssetCategoryField.propTypes = {
  inputProps: PropTypes.object,
  defaultValue: PropTypes.string,
};

export default AssetCategoryField;

