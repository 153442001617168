import React from 'react';
import { ReferenceField } from 'react-admin';

import FlagsField from './FlagsField';


const UserReferenceField = (props) => (
  <ReferenceField reference='users' {...props}>
    <FlagsField />
  </ReferenceField>
);

UserReferenceField.defaultProps = {
  source: 'user_id',
  addLabel: true,
  label: 'User Flags',
  link: 'show',
  allowEmpty: true,
};

export default UserReferenceField;
