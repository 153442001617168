import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';

import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';

import {
  cancelTicket as cancelTicketAction,
} from './statusActions';

const styles = {
  text: {
    color: '#f44336',
  },
};

class CancelButton extends Component {
  constructor () {
    super();

    this.state = {
      showDialog: false,
    };
  }

  handleClick = () => {
    this.setState({ showDialog: true });
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };

  handleAction = () => {
    const { cancelTicket, record } = this.props;
    cancelTicket(record.id, record);
    this.setState({ showDialog: false });
  }

  render () {
    const { record, classes } = this.props;
    const { showDialog } = this.state;

    return (
      <Fragment>
        <Button
          onClick={this.handleClick}
          classes={classes}
          color='primary'
          disabled={![ 'booked', 'waitlisted' ].includes(get(record, 'current_status.status_name'))}
        >
          <CancelIcon /> Cancel ticket
        </Button>
        <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label='Are you sure?'>
          <DialogTitle>Are you sure you want to <strong>cancel</strong> this ticket?</DialogTitle>
          <DialogActions>
            <Button onClick={this.handleCloseClick}>
              No
            </Button>
            <Button onClick={this.handleAction} classes={classes} key='button'>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

CancelButton.propTypes = {
  record: PropTypes.object,
  cancelTicket: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

CancelButton.defaultProps = {
  record: {},
};

export default connect(null, {
  cancelTicket: cancelTicketAction,
})(withStyles(styles)(CancelButton));
