import { restClient } from 'client';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import moment from 'moment';
import { GET_LIST } from 'react-admin';
import formatDateTime from 'utils/formatDateTime';

export function getGradient(index) {
  const gradientScale = ['#E0F6D6', '#B7ECB1', '#8CE193', '#68D685', '#45C980', '#23BC84'];

  return gradientScale[index];
}
export async function fetchOneWeekAgo(filters) {
  const now = moment(filters.date_begin_gte).subtract(7, 'days');
  const currentWeekStart = now.startOf('week').toISOString();
  const currentWeekEnd = now.endOf('week').toISOString();

  const fetchOptions = {
    filter: { ...filters, date_begin_gte: currentWeekStart, date_begin_lte: currentWeekEnd },
    sort: { field: 'location_id', order: 'asc' },
    pagination: { page: 1, perPage: 200 },
  };

  const { data } = await restClient(GET_LIST, 'events', fetchOptions);

  return convertCalendarEvents(data);
}

export async function fetchTwoWeeksAgo(filters) {
  const now = moment(filters.date_begin_gte).subtract(14, 'days');
  const currentWeekStart = now.startOf('week').toISOString();
  const currentWeekEnd = now.endOf('week').toISOString();

  const fetchOptions = {
    filter: { ...filters, date_begin_gte: currentWeekStart, date_begin_lte: currentWeekEnd },
    sort: { field: 'location_id', order: 'asc' },
    pagination: { page: 1, perPage: 200 },
  };

  const { data } = await restClient(GET_LIST, 'events', fetchOptions);

  return convertCalendarEvents(data);
}

export function convertCalendarEvents(records) {
  moment().locale('de');
  return map(records, (record) => ({
    id: get(record, 'id'),
    series: get(record, 'recurrent_id'),
    location: get(record, 'location.name'),
    time: formatDateTime(get(record, 'date_begin'), {
      timezone: get(record, 'location.timezone'),
      format: 'LT',
    }),
    iso: get(record, 'date_begin'),
    coach: get(record, 'coach.forename'),
    bookings: get(record, 'participants_count'),
    day: moment(get(record, 'date_begin')).startOf('day').toISOString(),
    max: get(record, 'max_participants_count', 24),
    status: get(record, 'current_status.status_name', ''),
  }));
}

export function groupEventsByLocation(events) {
  if (isEmpty(events)) return null;

  const items = map(events, (item) => {
    return {
      ...item,
    };
  });

  const results = reduce(
    items,
    (r, a) => {
      (r[a.location] || (r[a.location] = [])).push(a);
      return r;
    },
    {},
  );

  return results;
}

export function groupEventsByTime(events) {
  if (isEmpty(events)) return null;

  const items = map(events, (item) => ({
    ...item,
  }));

  const results = reduce(
    items,
    (r, a) => {
      (r[a.time] || (r[a.time] = [])).push(a);
      return r;
    },
    {},
  );

  return results;
}
