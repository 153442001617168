import React from 'react';
import PropTypes from 'prop-types';

import { Button, withStyles } from '@material-ui/core';
import { PlayArrow, Stop } from '@material-ui/icons';

const styles = (theme) => ({
  stopColor: {
    color: theme.palette.error.main,
    text: {
      color: theme.palette.error.main,
    },
  },
});

const PlayableButton = ({ isPlaying, playLabel, stopLabel, classes, onPlay, onStop }) => {
  const callback = !isPlaying ? onPlay : onStop;

  const Icon = () => {
    if (isPlaying) return <Stop className={classes.stopColor} />;
    return <PlayArrow />;
  };

  const Label = () => {
    if (!(playLabel || stopLabel)) return '';
    if (isPlaying && stopLabel) return ` ${stopLabel}`;
    return ` ${playLabel}`;
  };

  return (
    <Button color='primary' className={isPlaying ? classes.stopColor : ''} onClick={callback}>
      <Icon />
      <Label />
    </Button>
  );
};

PlayableButton.propTypes = {
  isPlaying: PropTypes.bool,
  playLabel: PropTypes.string,
  stopLabel: PropTypes.string,
  classes: PropTypes.object,
  onPlay: PropTypes.func,
  onStop: PropTypes.func,
};

PlayableButton.defaultProps = {
  isPlaying: false,
  playLabel: 'PLAY',
  stopLabel: 'STOP',
  classes: {},
  onPlay: () => {},
  onStop: () => {},
};

export default withStyles(styles)(PlayableButton);
