import React from 'react';
import { ReferenceArrayInput, required, ReferenceInput, AutocompleteInput } from 'react-admin';
import { Field } from 'react-final-form';
import AutocompleteArrayInput from 'reactAdmin/inputs/AutocompleteArrayInput';

import UserQuickPreviewButton from './UserQuickPreviewButton';
import UserQuickSelectButton from './UserQuickSelectButton';
import formattedFullNameEmail from './utils/formattedFullNameEmail';

const styles = {
  refInp: {
    display: 'inline-grid',
  },
};

const validate = required();
const matchSuggestion = () => true;

const UserReferenceInput = (props) => (
  <>
    {props.multiple ? (
      <ReferenceArrayInput reference='users' {...props} validate={validate}>
        <AutocompleteArrayInput optionText={formattedFullNameEmail} styles={styles.refInp} resettable />
      </ReferenceArrayInput>
    ) : (
      <ReferenceInput reference='users' {...props} validate={validate}>
        <AutocompleteInput
          optionText={formattedFullNameEmail}
          styles={styles.refInp}
          resettable
          matchSuggestion={matchSuggestion}
        />
      </ReferenceInput>
    )}
    <UserQuickSelectButton formType={props.formType} />
    <Field name={props.source} component={({ input }) => input.value && <UserQuickPreviewButton id={input.value} />} />
  </>
);

UserReferenceInput.defaultProps = {
  multiple: false,
  source: 'user_id',
  label: 'User',
  sort: { field: 'created_at', order: 'DESC' },
  perPage: 10,
};

export default UserReferenceInput;
