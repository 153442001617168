import PropTypes from 'prop-types';
import React from 'react';
import { ReferenceArrayInput, ReferenceInput, required } from 'react-admin';
import SortedLocationInput from './SortedLocationInput';

const LocationReferenceInput = (props) => {
  const filter = { is_published: true, city_code: props.city };

  const Reference = props.multiple ? ReferenceArrayInput : ReferenceInput;

  // when a city code is provided to the component only show location name
  function getOptionText(item) {
    if (!item) return;

    if (props.city) {
      return item.name;
    }

    if (!item.city_code) {
      return '';
    }

    return `[${item.city_code}] ${item.name}`;
  }

  return (
    <Reference reference='locations' perPage={100} filter={filter} validate={props.required && [required()]} {...props}>
      <SortedLocationInput optionText={getOptionText} multiple={props.multiple} />
    </Reference>
  );
};

LocationReferenceInput.defaultProps = {
  multiple: false,
  resource: 'locations',
  source: 'location_id',
  city: undefined,
  label: 'Location',
  allowEmpty: true,
  required: true,
};

LocationReferenceInput.propTypes = {
  multiple: PropTypes.bool,
  resource: PropTypes.string,
  allowEmpty: PropTypes.bool,
  label: PropTypes.string,
  city: PropTypes.string,
  required: PropTypes.bool,
  source: PropTypes.string,
};

export default LocationReferenceInput;
