import React, { useEffect, useState, Fragment } from 'react';
import { array, object } from 'prop-types';
import get from 'lodash/get';
import set from 'lodash/set';
import flatten from 'lodash/flatten';
import keys from 'lodash/keys';
import find from 'lodash/find';
import map from 'lodash/map';
import times from 'lodash/times';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { Pagination } from 'react-admin';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import EventStatusIcon from 'reactAdmin/events/EventStatusIcon';
import {
  convertCalendarEvents,
  fetchOneWeekAgo,
  fetchTwoWeeksAgo,
  getGradient,
  groupEventsByLocation,
  groupEventsByTime,
} from 'reactAdmin/events/utils/eventCalendarHelpers';

const styles = {
  bold: {
    fontWeight: 'bold',
  },
  red: {
    color: '#A84B58',
  },
  morningfat: {
    borderTop: '2px solid lightgray',
    height: '100%',
    backgroundColor: '#F2FFFF',
  },
  afternoonfat: {
    borderTop: '2px solid lightgray',
    height: '100%',
    backgroundColor: '#FFFFF2',
  },
  eveningfat: {
    borderTop: '2px solid lightgray',
    height: '100%',
    backgroundColor: '#FFF2FF',
  },
  morning: {
    height: '100%',
    backgroundColor: '#F2FFFF',
  },
  afternoon: {
    height: '100%',
    backgroundColor: '#FFFFF2',
  },
  evening: {
    height: '100%',
    backgroundColor: '#FFF2FF',
  },
  blue: {
    color: '#8FBCE0',
  },
  green: {
    color: '#23BC84',
  },
  tableRow: {
    height: '100%',
  },
  tableRoot: {
    borderTop: '1px solid lightgray',
    marginTop: '2rem',
  },
  tableCell: {
    fontSize: '0.7em',
    padding: '0.5em',
    maxWidth: '100px',
    minWidth: '1.4rem',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  borderRight: {
    borderRight: '1px solid lightgray',
  },
};

export function EventCalendarPagination (props) {
  return <Pagination rowsPerPageOptions={[]} {...props} />;
}

const TableHeader = withStyles(styles)(({ classes, dateBegin }) => {
  const weekStart = moment(dateBegin).startOf('week');
  const weekNumber = moment(weekStart).week();
  const weekdates = [];
  times(7, (idx) => weekdates.push(weekStart.day(idx + 1).format('dddd, LL')));

  return (
    <TableHead padding='dense'>
      <TableRow classes={{ head: classes.tableRow }}>
        <TableCell classes={{ paddingDense: classes.tableCell }}>Week</TableCell>
        <TableCell classes={{ paddingDense: classes.tableCell }}>{weekNumber}</TableCell>
        {map(weekdates, (weekdate, idx) => (
          <TableCell
            style={{ borderLeft: '1px solid lightgray', borderRight: '1px solid lightgray' }}
            colSpan={6}
            align='center'
            classes={{ paddingDense: classes.tableCell }}
            key={idx}
          >
            {weekdate}
          </TableCell>
        ))}
      </TableRow>
      <TableRow classes={{ head: classes.tableRow }}>
        <TableCell classes={{ paddingDense: classes.tableCell }}>Location</TableCell>
        <TableCell classes={{ paddingDense: classes.tableCell }} style={{ borderRight: '1px solid lightgray' }}>
          Slots
        </TableCell>
        {times(7, (idx) => (
          <Fragment key={idx}>
            <TableCell classes={{ paddingDense: classes.tableCell }}>Time</TableCell>
            <TableCell classes={{ paddingDense: classes.tableCell }}>Coach</TableCell>
            <TableCell align='center' classes={{ paddingDense: classes.tableCell }}>
              Status
            </TableCell>
            <TableCell align='center' classes={{ paddingDense: classes.tableCell }}>
              B
            </TableCell>
            <TableCell align='center' classes={{ paddingDense: classes.tableCell }}>
              -1W
            </TableCell>
            <TableCell align='center' classes={{ paddingDense: classes.tableCell, root: classes.borderRight }}>
              -2W
            </TableCell>
          </Fragment>
        ))}
      </TableRow>
    </TableHead>
  );
});

function CalendarTable ({ classes, data, ids, ...rest }) {
  const [events, setEvents] = useState(prepare());
  const [calendarEvents1, setCalendarEvents1] = useState();
  const [calendarEvents2, setCalendarEvents2] = useState();
  let location;
  let skipCol = false;

  useEffect(() => {
    getAuxData();
    setEvents(prepare());
  }, [data]);

  useEffect(() => {
    setEvents(prepare());
  }, [calendarEvents1, calendarEvents2]);

  async function getAuxData () {
    setCalendarEvents1(await fetchOneWeekAgo(rest.filterValues));
    setCalendarEvents2(await fetchTwoWeeksAgo(rest.filterValues));
  }

  function prepare () {
    const events = convertCalendarEvents(map(ids, (id) => data[id]));
    const eventsByLocation = groupEventsByLocation(events);
    const eventsByTimeAndLocation = map(keys(eventsByLocation), (locationName) => {
      return { [`${locationName}`]: groupEventsByTime(eventsByLocation[locationName]) };
    });
    const customEvents = [];
    map(flatten(eventsByTimeAndLocation), (byLocation) =>
      map(keys(byLocation), (location) =>
        map(byLocation[location], (slots) => {
          const obj = {};
          set(obj, 'location', location);
          map(slots, (slot) => {
            const num = moment(slot.day).isoWeekday();
            set(obj, `iso${num}`, slot.iso);
            set(obj, `max${num}`, slot.max);
            set(obj, `id${num}`, slot.id);
            set(obj, `time${num}`, slot.time);
            set(obj, `coach${num}`, slot.coach);
            set(obj, `bookings${num}`, slot.bookings);
            set(
              obj,
              `bookings-1${num}`,
              get(find(calendarEvents1, (evt) => !!evt.series && evt.series === slot.series), 'bookings')
            );
            set(
              obj,
              `bookings-2${num}`,
              get(find(calendarEvents2, (evt) => !!evt.series && evt.series === slot.series), 'bookings')
            );
            set(obj, `series${num}`, slot.series);
            set(obj, `status${num}`, slot.status);
          });
          customEvents.push(obj);
        })
      )
    );

    return sortBy(customEvents, (row) => [
      row.location,
      row.time1 || row.time2 || row.time3 || row.time4 || row.time5 || row.time6 || row.time7,
    ]);
  }

  return (
    <Table padding='dense' classes={{ root: classes.tableRoot }}>
      <TableHeader dateBegin={rest.filterValues.date_begin_gte} />
      <TableBody>
        {map(events, (slot, idx) => {
          let category;
          const time = moment(
            slot.iso1 || slot.iso2 || slot.iso3 || slot.iso4 || slot.iso5 || slot.iso6 || slot.iso7
          ).hour();
          if (time >= 0 && time < 10) {
            category = 'morning';
          } else if (time >= 10 && time < 17) {
            category = 'afternoon';
          } else if (time >= 17 && time < 24) {
            category = 'evening';
          }

          if (location === slot.location) {
            skipCol = true;
          } else {
            location = slot.location;
            skipCol = false;
          }

          return (
            <TableRow
              key={`${slot.location}${idx}`}
              className={!skipCol ? classes[`${category}fat`] : classes[category]}
            >
              {skipCol ? (
                <TableCell classes={{ paddingDense: classes.tableCell }} />
              ) : (
                <TableCell classes={{ paddingDense: classes.tableCell }}>{slot.location}</TableCell>
              )}
              <TableCell classes={{ paddingDense: classes.tableCell, root: classes.borderRight }}>
                {slot.time1 || slot.time2 || slot.time3 || slot.time4 || slot.time5 || slot.time6 || slot.time7}
              </TableCell>
              {times(7, (index) => (
                <Fragment key={index}>
                  <TableCell classes={{ paddingDense: classes.tableCell }}>
                    <Link to={{ pathname: `/events/${slot[`id${index + 1}`]}/show` }}>{slot[`time${index + 1}`]}</Link>
                  </TableCell>
                  <TableCell classes={{ paddingDense: classes.tableCell }}>{slot[`coach${index + 1}`]}</TableCell>
                  <TableCell
                    align='center'
                    classes={{
                      paddingDense: classes.tableCell,
                      root:
                        slot[`status${index + 1}`] === 'cancelled'
                          ? classes.red
                          : slot[`status${index + 1}`] === 'completed'
                            ? classes.green
                            : slot[`status${index + 1}`] === 'scheduled'
                              ? classes.blue
                              : undefined,
                    }}
                  >
                    <EventStatusIcon record={{ current_status: { status_name: slot[`status${index + 1}`] } }} />
                  </TableCell>
                  <TableCell
                    align='center'
                    classes={{ paddingDense: classes.tableCell }}
                    style={{
                      backgroundColor:
                        slot[`bookings${index + 1}`] >= 0 &&
                        `${getGradient(Math.floor((slot[`bookings${index + 1}`] / slot[`max${index + 1}`]) * 5) / 1)}`,
                    }}
                  >
                    {slot[`bookings${index + 1}`]}
                  </TableCell>
                  <TableCell
                    align='center'
                    classes={{ paddingDense: classes.tableCell }}
                    style={{
                      backgroundColor:
                        slot[`bookings-1${index + 1}`] >= 0 &&
                        `${getGradient(
                          Math.floor((slot[`bookings-1${index + 1}`] / slot[`max${index + 1}`]) * 5) / 1
                        )}`,
                    }}
                  >
                    {slot[`bookings-1${index + 1}`]}
                  </TableCell>
                  <TableCell
                    align='center'
                    classes={{ paddingDense: classes.tableCell, root: classes.borderRight }}
                    style={{
                      backgroundColor:
                        slot[`bookings-2${index + 1}`] >= 0 &&
                        `${getGradient(
                          Math.floor((slot[`bookings-2${index + 1}`] / slot[`max${index + 1}`]) * 5) / 1
                        )}`,
                    }}
                  >
                    {slot[`bookings-2${index + 1}`]}
                  </TableCell>
                </Fragment>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
CalendarTable.propTypes = { classes: object, data: object, ids: array };

export default withStyles(styles)(CalendarTable);
