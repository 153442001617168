import React from 'react';
import { Card, Typography, Grid, ButtonBase, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { beat81Brand } from 'theme/colors';

const useStyles = makeStyles({
  error: {
    boxShadow: 'unset',
    padding: '8px 16px',
    backgroundColor: `${beat81Brand}`,
    color: 'white',
  },
});

const Error = ({ handleReset, errorMessage = '' }) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  return (
    <Card className={styles.error}>
      <Grid container direction='row' alignItems='center' justifyContent='space-between'>
        <Typography variant='body1'>{errorMessage || '😳 There was an error trying to merge.'}</Typography>
        <ButtonBase onClick={handleReset}>
          <Typography variant='button'>Try Again</Typography>
        </ButtonBase>
      </Grid>
    </Card>
  );
};

export default Error;
