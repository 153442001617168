import React from 'react';
import { Card, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles({
  success: {
    boxShadow: 'unset',
    padding: '8px 16px',
    color: 'white',
    backgroundColor: (props) => `${props.palette.success.main}`,
  },
});

const Success = () => {
  const theme = useTheme();
  const styles = useStyles(theme);
  return (
    <Card className={styles.success}>
      <Typography component='h3'>💪 Merge Was Successful!</Typography>
    </Card>
  );
};

export default Success;
