import React from 'react';
import PropTypes from 'prop-types';
import { SaveButton, Toolbar } from 'react-admin';

const ResultCreateToolbar = ({ eventId, ...props }) => {
  const link = eventId ? `/events/${eventId}/show` : 'list';

  return (
    <Toolbar {...props}>
      <SaveButton label='Save' redirect={link} />
    </Toolbar>
  );
};


ResultCreateToolbar.propTypes = {
  eventId: PropTypes.string,
};

ResultCreateToolbar.defaultProps = {
  eventId: null,
};

export default ResultCreateToolbar;
