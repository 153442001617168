import React from 'react';
import moment from 'moment-timezone';

const styles = {
  subtitle: {
    padding: '10px',
  },
};

const formatCycleInterval = (startFrom, interval) => {
  const startAt = moment.tz(startFrom, 'UTC');

  if (interval === 'day') {
    return startAt.format('DD.MM.YYYY');
  }

  if (interval === 'month') {
    return startAt.format('MMMM YYYY');
  }

  if (interval === 'year') {
    return startAt.format('YYYY');
  }

  return `the week at ${startAt.format('DD.MM.YYYY')}`;
};

const getIntervalName = (interval) => {
  if (interval === 'isoWeek') {
    return 'week';
  }

  return interval;
};

const RetentionSubtitle = ({ interval, startFrom }) => (
  <div style={styles.subtitle}>
    Customers are users that booked <strong>or attended (depending on the state of "By attendance" switch)</strong> at least once in that interval of time.
    <br />
    Bookings are tickets for workouts that were not cancelled.
    <br />
    Bookings are accounted based on workout date, not day that the booking was created.
    <br />
    Each number column represents number of retained customers after <strong>n</strong> amount of {getIntervalName(interval)}s. So in the first row, the column 2 means how many of the customers of {formatCycleInterval(startFrom, interval)} returned after 2 {getIntervalName(interval)}s
    <br />
    <strong>Exclude trial</strong> when checked means that bookings that were created on the first two weeks are not taken into account
    <br />
    <strong>Only new customers</strong> when checked means that only customers that neved booked before that period are taken into account
    <br />
    <strong>By attendance</strong> when checked means that only bookings where the customer attended the workout are taken into account
    <br />
    <strong>Provider</strong> when set filters by user current provider, not at time of booking
    <br />
    <strong>Total bookings</strong> means absolute number of bookings instead of number of unique customers. <strong>For retention calculation, this should always be unchecked</strong>
  </div>
);

export default RetentionSubtitle;
