import React, { useState, useEffect } from 'react';
import { useNotify, SaveButton, useRefresh, useCreate } from 'react-admin';
import SaveIcon from '@material-ui/icons/Save';

const SaveOffersButton = ({ handleSubmitWithRedirect, createOfferConfig, event, formData, ...props }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [create] = useCreate();

  const [saving, setSaving] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const [isSuccess, setIsSuccess] = useState(false);

  const countdownText = `00:${countdown < 10 ? `0${countdown}` : countdown}`;

  const postData = {
    event_id: event.id,
    bonus_currency: 'EUR',
    ...formData,
  };

  const config = createOfferConfig(postData);

  useEffect(() => {
    let countdownTimer;
    if (saving) {
      countdownTimer = setInterval(() => {
        if (countdown > 1) {
          setCountdown(countdown - 1);
        } else {
          isSuccess && handleSuccess();
          clearInterval(countdownTimer);
          setSaving(false);
          setCountdown(10);
        }
      }, 1000);
    }
    return () => {
      !!countdownTimer && clearInterval(countdownTimer);
    };
  }, [saving, countdown, isSuccess]);

  const handleSuccess = () => {
    refresh();
    const { body, level } = config.meta.onSuccess.notification;
    notify(body, level);
  };

  const onHandleSubmit = async () => {
    if (props.invalid) return;
    setSaving(true);

    create(config.meta.resource, config.payload.data, {
      onSuccess: () => {
        setIsSuccess(true);
      },
      onError: () => {
        setIsSuccess(false);
        setSaving(false);
        setCountdown(10);
        const { body, level } = config.meta.onFailure.notification;
        notify(body, level);
      },
    });
  };

  return (
    <SaveButton
      disabled={saving}
      label={saving ? `${countdownText}` : 'Save'}
      icon={saving ? <></> : <SaveIcon />}
      handleSubmitWithRedirect={onHandleSubmit}
      {...props}
    />
  );
};

export default SaveOffersButton;
