import { brand, gsBlack, gsYellow } from 'theme/colors';

export default {
  section: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    textAlign: 'center',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  h1: {
    fontSize: '2rem',
    color: gsBlack,
    marginBottom: '1rem',
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
    marginBottom: 0,
  },
  statsIcon: {
    width: '22px',
  },
  statsText: {
    fontSize: '1rem',
    fontWeight: 'bold',
    marginLeft: '0.5rem',
    color: gsBlack,
    overflow: 'visible',
    whiteSpace: 'nowrap',
  },
  gritLogo: {
    marginTop: '0.5rem',
  },
  banner: {
    backgroundColor: brand,
    display: 'flex',
    color: 'white',
    alignItems: 'center',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
  avatar: {
    marginRight: '1rem',
  },
  username: {
    fontSize: '2rem',
  },
  date: {
    overflow: 'visible',
    whiteSpace: 'nowrap',
  },
  chart: {
    margin: '0 auto',
    padding: '0 5%',
  },
  chartLegend: {
    textAlign: 'right',
    color: '#919191',
    maxWidth: '90%',
    marginBottom: 0,
  },
  chartLegendKey: {
    width: '25px',
    height: '13px',
    backgroundColor: gsYellow,
    display: 'inline-block',
    marginRight: '10px',
  },
  noWorkouts: {
    section: {
      padding: '1rem 2rem',
    },
    header: {
      fontWeight: 'normal',
    },
  },
  preview: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
};
