import * as React from 'react';
import { primary } from 'theme/colors';

function Copy({ color = primary, ...props }) {
  return (
    <svg width='24px' height='24px' viewBox='0 0 24 24' fill='none' {...props}>
      <path d='M14 8H6c-.6 0-1 .4-1 1v10c0 .6.4 1 1 1h8c.6 0 1-.4 1-1V9c0-.6-.4-1-1-1z' fill={color} />
      <path d='M18 4H8v2h9v11h2V5c0-.6-.4-1-1-1z' fill={color} />
    </svg>
  );
}

const CopyIcon = React.memo(Copy);
export default CopyIcon;
