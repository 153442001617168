import React from 'react';
import { Show, SimpleShowLayout, DateField, TextField } from 'react-admin';

import { IdField } from 'components/IdField';
import EventReferenceField from '../events/EventReferenceField';
import CustomDateField from '../fields/CustomDateField';
import BooleanField from '../fields/VerbalBooleanField';
import UserReferenceField from '../users/UserReferenceField';
import TicketShowActions from './TicketShowActions';

const TicketShow = (props) => (
  <Show title='Ticket Detail View' actions={<TicketShowActions />} {...props}>
    <SimpleShowLayout>
      <IdField id='id' />

      <UserReferenceField />
      <EventReferenceField />

      <BooleanField label='On waiting list' colored={false} source='is_waitinglist' />
      <BooleanField label='Has Attended' source='has_attended' />
      <BooleanField label='Cancelled' inverted source='is_cancelled' />
      <TextField label='Fulfilled' inverted source='payment.charge.fulfillment' />

      <TextField source='current_status.status_name' label='Current status' />
      <CustomDateField source='current_status.transitioned_at' label='Status transitioned At' />

      <BooleanField label='Is Chargeable' colored={false} source='is_chargeable' />
      <UserReferenceField source='created_by' label='Created By' />

      <DateField source='created_at' showTime locales='de-DE' />
    </SimpleShowLayout>
  </Show>
);

export default TicketShow;
