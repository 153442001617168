import React from 'react';
import { Create } from 'react-admin';

import Form from './SensorPoolForm';


const SensorPoolCreate = (props) => (
  <Create title='Create Sensor Pool' {...props} undoable={false} >
    <Form />
  </Create>
);


export default SensorPoolCreate;
