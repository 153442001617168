import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import { ReferenceInput, AutocompleteInput } from 'react-admin';
import moment from 'moment-timezone';

import EventQuickSelectButton from './EventQuickSelectButton';
import EventQuickPreviewButton from './EventQuickPreviewButton';
import formattedNameString from './utils/formattedNameString';


const EventReferenceInput = (props) => (
  <Fragment>
    <ReferenceInput reference='events' {...props}>
      <AutocompleteInput optionText={formattedNameString} />
    </ReferenceInput>
    <EventQuickSelectButton formType={props.formType} />
    <Field
      name={props.source}
      component={({ input }) => (
        input.value && <EventQuickPreviewButton id={input.value} />
      )}
    />
  </Fragment>
);

EventReferenceInput.defaultProps = {
  source: 'event_id',
  label: 'Event',
  allowEmpty: true,
  filter: {
    is_published: true,
    date_begin_gte: moment().clone().startOf('day').format(),
    date_begin_lte: moment().clone().endOf('day').format(),
  },
  sort: { field: 'date_begin', order: 'DESC' },
};

export default EventReferenceInput;
