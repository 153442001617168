import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';

import Button from '@material-ui/core/Button';
import RedeemIcon from '@material-ui/icons/AssignmentTurnedIn';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import {
  redeemWaitinglist as redeemWaitinglistAction,
} from './statusActions';

class RedeemButton extends Component {
  constructor () {
    super();

    this.state = {
      showDialog: false,
    };
  }

  handleClick = () => {
    this.setState({ showDialog: true });
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };

  handleAction = () => {
    const { redeemWaitinglist, record } = this.props;
    redeemWaitinglist(record.id, record);
    this.setState({ showDialog: false });
  }

  render () {
    const { record, classes } = this.props;
    const { showDialog } = this.state;

    return (
      <Fragment>
        <Button
          onClick={this.handleClick}
          classes={classes}
          color='primary'
          disabled={get(record, 'current_status.status_name') !== 'waitlisted'}
        >
          <RedeemIcon /> Redeem ticket
        </Button>
        <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label='Are you sure?'>
          <DialogTitle>Are you sure you want to <strong>redeem</strong> this ticket?</DialogTitle>
          <DialogContent><DialogContentText>This will remove the ticket from the waiting list and grant a booking to the event</DialogContentText></DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseClick}>
              No
            </Button>
            <Button onClick={this.handleAction} classes={classes} key='button'>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

RedeemButton.propTypes = {
  record: PropTypes.object,
  redeemWaitinglist: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

RedeemButton.defaultProps = {
  record: {},
};

export default connect(null, {
  redeemWaitinglist: redeemWaitinglistAction,
})(RedeemButton);
