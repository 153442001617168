import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import { IdField } from 'components/IdField';
import AudioSetsFileField from './AudioSetsFileField';
import AudioSetShowActions from './AudioSetShowActions';

const AudioSetShow = (props) => (
  <Show title='Audio Set Details' actions={<AudioSetShowActions />} {...props}>
    <SimpleShowLayout>
      <IdField id='id' />
      <TextField source='name' label='Name' />
      <TextField source='metadata.category' label='Category' />
      <TextField source='metadata.genre' label='Category' />
      <TextField source='metadata.language' label='Language' />
      <TextField source='event_type_id' label='Event Type' />
      <AudioSetsFileField />
    </SimpleShowLayout>
  </Show>
);

export default AudioSetShow;
