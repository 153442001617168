import React from 'react';
import { object } from 'prop-types';
import { withRouter } from 'react-router';

import EventCalendar from 'containers/EventCalendar';

function EventCalendarPage ({ location }) {
  return <EventCalendar resource='events' title='Event' basePath='/' location={location} />;
}
EventCalendarPage.propTypes = { location: object };

export default withRouter(EventCalendarPage);
