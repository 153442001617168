import SensorPoolIcon from '@material-ui/icons/Work';
import SensorPoolCreate from './SensorPoolCreate';
import SensorPoolEdit from './SensorPoolEdit';
import SensorPoolList from './SensorPoolList';
import SensorPoolShow from './SensorPoolShow';

export default {
  icon: SensorPoolIcon,
  list: SensorPoolList,
  show: SensorPoolShow,
  create: SensorPoolCreate,
  edit: SensorPoolEdit,
};

export {
  SensorPoolIcon,
};
