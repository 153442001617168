import PropTypes from 'prop-types';
import React from 'react';

const NameField = ({ record }) => <span>{record.name_en || record.name}</span>;

NameField.propTypes = {
  record: PropTypes.object,
};

NameField.defaultProps = {
  record: {},
};

export default NameField;
