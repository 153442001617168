import { get, has, keys, set, toLower } from 'lodash';
import { stringify } from 'qs';
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
  fetchUtils,
} from 'react-admin';

import HttpError from './httpError';
import { resourceMap } from './resourceMap';

const { flattenObject } = fetchUtils;

const sanitizeListQuery = (query) => {
  const parsed = {};
  keys(query).forEach((key) => {
    if (key.includes('.')) {
      set(parsed, key, query[key]);
    } else {
      parsed[key] = query[key];
    }
  });
  return parsed;
};

/**
 * Maps admin-on-rest queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 * @example
 * GET_LIST     => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The REST request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertRESTRequestToHTTP = (type, resource, params) => {
    let url = '';
    const options = {};

    if (get(resourceMap, `${resource}.targetResource`)) {
      resource = resourceMap[resource].targetResource;
    }

    switch (type) {
      case GET_LIST: {
        const page = get(params, 'pagination.page', 1);
        const perPage = get(params, 'pagination.perPage', 25);

        let query = {
          ...flattenObject(params.filter),
          $limit: perPage,
          $skip: (page - 1) * perPage,
        };

        if (has(params, 'sort.field')) {
          const field = get(params, 'sort.field');
          const order = get(params, 'sort.order', 1);

          query = {
            ...query,
            [`$sort[${field}]`]: toLower(order) === 'desc' ? -1 : 1,
          };
        }

        if (get(resourceMap, `${resource}.defaultQuery`)) {
          query = { ...resourceMap[resource].defaultQuery, ...query };
        }

        const sanitizedQuery = sanitizeListQuery(query);
        url = `${apiUrl}/${resource}?${stringify(sanitizedQuery)}`;

        break;
      }

      case GET_ONE: {
        const query = flattenObject(params.query);
        url = `${apiUrl}/${resource}/${params.id}?${stringify(query)}`;
        break;
      }

      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
          ...flattenObject(params.filter),
          [params.target]: params.id,
          [`$sort[${field}]`]: toLower(order) === 'desc' ? -1 : 1,
          $limit: perPage,
          $skip: (page - 1) * perPage,
        };
        url = `${apiUrl}/${resource}?${stringify(query)}`;
        break;
      }

      case UPDATE: {
        if (resource === 'audio-files') {
          delete params.data.file;
          if (!params.data.metadata.genre) {
            delete params.data.metadata.genre;
          }
        }
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'PATCH';
        options.body = JSON.stringify(params.data);
        break;
      }

      case CREATE: {
        url = `${apiUrl}/${resource}`;
        options.method = 'POST';
        options.body = JSON.stringify(params.data);
        break;
      }

      case DELETE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'DELETE';
        break;
      }

      case 'GET_RAW':
      case 'GET_JSON':
        url = `${apiUrl}/${resource}?${stringify(params)}`;
        options.method = 'GET';
        break;

      default:
      // throw new Error(`Unsupported fetch action type ${type}`);
    }

    return { url, options };
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The REST request params, depending on the type
   * @returns {Object} REST response
   */
  const convertHTTPResponseToREST = (response, type, resource, params) => {
    const { json } = response;

    if (get(resourceMap, `${resource}.targetResource`)) {
      resource = resourceMap[resource].targetResource;
    }

    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        return {
          data: json.data,
          total: parseInt(json.total, 10),
        };

      case CREATE:
        if (resource === 'asset-sets') {
          return { data: { ...params.data, id: json.id } };
        }
        return { data: { ...params.data, id: json.data.id } };

      case DELETE:
        return { data: { ...params } };

      case DELETE_MANY:
        return { data: params.ids };

      case UPDATE_MANY:
        return { data: params.ids };

      case 'GET_RAW':
        return response.body;

      case 'GET_JSON':
        return json;

      default:
        return { data: json.data };
    }
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a REST response
   */
  return (type, resource, params) => {
    if (get(resourceMap, `${resource}.targetResource`)) {
      resource = resourceMap[resource].targetResource;
    }

    // json-server doesn't handle WHERE IN requests, so we fallback to calling GET_ONE n times instead
    if (type === GET_MANY) {
      return Promise.all(params.ids.map((id) => httpClient(`${apiUrl}/${resource}/${id}`)))
        .then((responses) => ({
          data: responses.map((response) => response.json.data),
        }))
        .catch((err) => {
          console.log(`Error caught in ${type} ${resource}:`, err);
          console.log('Proceed without throwing an error');

          const promises = params.ids.map(
            (id) =>
              new Promise((resolve) => {
                httpClient(`${apiUrl}/${resource}/${id}`)
                  .then((response) => resolve(response))
                  .catch(() => resolve({ json: { data: {} } }));
              }),
          );

          return Promise.all(promises).then((responses) => ({
            data: responses.map((response) => response.json.data),
          }));
        });
    }

    if (type === UPDATE_MANY) {
      const errors = [];
      return Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'PATCH',
            body: JSON.stringify(params.data),
          }).catch((e) => {
            errors.push({
              id,
              error: e,
            });
          }),
        ),
      ).then((responses) => {
        if (errors.length) {
          throw new HttpError('Some items could not be updated', null, {
            code: 'asset_is_referenced_by_asset_sets',
            details: errors,
          });
        }
        return {
          data: responses.map((response) => response.json.data),
        };
      });
    }

    if (type === DELETE_MANY) {
      const errors = [];
      return Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'DELETE',
          }).catch((e) => {
            errors.push({
              id,
              error: e,
            });
          }),
        ),
      ).then((responses) => {
        if (errors.length) {
          throw new HttpError('Some items could not be deleted', null, {
            code: 'asset_is_referenced_by_asset_sets',
            details: errors,
          });
        }
        return {
          data: responses.map((response) => response.json.data),
        };
      });
    }

    const { url, options } = convertRESTRequestToHTTP(type, resource, params);

    return httpClient(url, options).then((response) => convertHTTPResponseToREST(response, type, resource, params));
  };
};
