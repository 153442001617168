import React from 'react';
import { Create } from 'react-admin';

import LocationForm from './LocationForm';

const LocationCreate = (props) => (
  <Create title='Create Location' {...props} undoable={false}>
    <LocationForm />
  </Create>
);

export default LocationCreate;
