import React, { Component } from 'react';
import moment from 'moment-timezone';

import { GET_LIST } from 'react-admin';
import { restClient } from 'client';

import Board from 'components/Dashboard';


class Dashboard extends Component {
  state = {};

  componentDidMount() {
    this.fetchPastEvents();
    this.fetchUpcomingEvents();
    this.fetchNewUsers();
  }

  fetchPastEvents = async () => {
    const now = moment().format();

    const events = await restClient(GET_LIST, 'events', {
      filter: {
        date_begin_lte: now,
      },
      sort: { field: 'date_begin', order: 'desc' },
      pagination: { page: 1, perPage: 4 },
    })
    .then((response) => response.data);

    this.setState({ pastEvents: events });
  }

  fetchUpcomingEvents = async () => {
    const now = moment().format();

    const events = await restClient(GET_LIST, 'events', {
      filter: {
        date_begin_gte: now,
      },
      sort: { field: 'date_begin', order: 'ASC' },
      pagination: { page: 1, perPage: 4 },
    })
    .then((response) => response.data);

    this.setState({ upcomingEvents: events });
  }

  fetchNewUsers = async () => {
    const users = await restClient(GET_LIST, 'users', {
      sort: { field: 'created_at', order: 'desc' },
      pagination: { page: 1, perPage: 10 },
    })
    .then((response) => response.data);

    this.setState({ newUsers: users });
  }

  render() {
    const { pastEvents, upcomingEvents, newUsers } = this.state;

    return (
      <Board
        pastEvents={pastEvents}
        upcomingEvents={upcomingEvents}
        newUsers={newUsers}
      />
    );
  }
}

export default Dashboard;
