export default {
  wrapper: {
    display: 'flex',
    marginTop: '1rem',
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    fontFamily: 'Arial, sans-serif',
    fontSize: '16px',
    width: '950px',
  },
  form: {
    width: '30%',
    padding: '1rem',
  },
  image: {
    width: '65%',
    backgroundColor: '#fff',
    paddingTop: '0.5rem',
    paddingBottom: '2rem',
  },
};
