import React from 'react';
import { Create } from 'react-admin';

import TrainerForm from './TrainerForm';


const TrainerCreate = (props) => (
  <Create title='Create Trainer' {...props} undoable={false} >
    <TrainerForm />
  </Create>
);


export default TrainerCreate;
