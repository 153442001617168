import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Toolbar from '@material-ui/core/Toolbar';


const styles = {
  toolbar: {
    backgroundColor: '#fff',
    justifyContent: 'space-between',
  },
};

class LeaderboardToolbar extends React.Component {
  static propTypes = {
    interval: PropTypes.oneOf(['monthly', 'weekly']),
    year: PropTypes.number,
    month: PropTypes.number,
    week: PropTypes.number,
    onChangeInterval: PropTypes.func,
    onChangePeriod: PropTypes.func,
  }

  static defaultProps = {
    interval: null,
    year: null,
    month: null,
    week: null,
    onChangeInterval: () => null,
    onChangePeriod: () => null,
  }

  // handleChange = (event, index, value) => this.setState({ value });

  handleChangeInterval = (event) => {
    this.props.onChangeInterval(event.target.value);
  }

  handleChangeYear = (event) => {
    const { month, week } = this.props;

    this.props.onChangePeriod({ year: event.target.value, month, week });
  }

  handleChangeMonth = (event) => {
    this.props.onChangePeriod({
      interval: 'monthly',
      year: this.props.year,
      month: parseInt(event.target.value, 10),
    });
  }

  handleChangeWeek = (event) => {
    this.props.onChangePeriod({
      interval: 'weekly',
      year: this.props.year,
      week: event.target.value ? parseInt(event.target.value, 10) : null,
    });
  }

  handleSelectCurrentMonth = () => {
    this.props.onChangePeriod({
      interval: 'monthly',
      year: this.props.year,
      month: (moment().month() + 1),
    });
  }

  handleSelectCurrentWeek = () => {
    this.props.onChangePeriod({
      interval: 'weekly',
      year: this.props.year,
      week: moment().week(),
    });
  }

  render () {
    const { interval, year, month, week } = this.props;

    return (
      <Toolbar style={styles.toolbar}>
        <div firstChild>
          <Select value={interval} onChange={this.handleChangeInterval}>
            <MenuItem value='monthly'>Monthly Leaderboard</MenuItem>
            <MenuItem value='weekly'>Weekly Leaderboard</MenuItem>
          </Select>
        </div>
        <div>
          {interval === 'monthly' ? (
            <Select value={month} onChange={this.handleChangeMonth}>
              <MenuItem value={1}>January</MenuItem>
              <MenuItem value={2}>Februar</MenuItem>
              <MenuItem value={3}>March</MenuItem>
              <MenuItem value={4}>April</MenuItem>
              <MenuItem value={5}>May</MenuItem>
              <MenuItem value={6}>June</MenuItem>
              <MenuItem value={7}>July</MenuItem>
              <MenuItem value={8}>August</MenuItem>
              <MenuItem value={9}>September</MenuItem>
              <MenuItem value={10}>October</MenuItem>
              <MenuItem value={11}>November</MenuItem>
              <MenuItem value={12}>December</MenuItem>
            </Select>
          ) : (
            <TextField
              id='weekly_week'
              value={week || ''}
              onChange={this.handleChangeWeek}
            />
          )}
          <Select value={year} onChange={this.handleChangeYear}>
            <MenuItem value={2017}>2017</MenuItem>
            <MenuItem value={2018}>2018</MenuItem>
            <MenuItem value={2019}>2019</MenuItem>
          </Select>
        </div>
        <div>
          <Button onClick={this.handleSelectCurrentMonth}>Current Month</Button>
          <Button onClick={this.handleSelectCurrentWeek}>Current Week</Button>

          {/*
          <IconMenu
            iconButtonElement={
              <IconButton touch={true}>
                <NavigationExpandMoreIcon />
              </IconButton>
            }
          >
            <MenuItem primaryText='Export as Excel' />
            <MenuItem primaryText='More Info' />
          </IconMenu>
          */}
        </div>
      </Toolbar>
    );
  }
}

export default LeaderboardToolbar;
