import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import calculateAge from 'utils/calculateAge';

const BirthdayAgeField = ({ record = {}, srcDay, srcMonth, srcYear, showAge }) => {
  const use2Digits = (x) => `0${x}`.slice(-2);

  const day = get(record, srcDay);
  const month = get(record, srcMonth);
  const year = get(record, srcYear);

  const age = <span> ({calculateAge(day, month, year)} years old)</span>;

  return (day && month && year) ? (
    <span>{use2Digits(day)}.{use2Digits(month)}.{year}{showAge && age}</span>
  ) : (
    <span />
  );
};

BirthdayAgeField.propTypes = {
  label: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  addLabel: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  record: PropTypes.object,
  srcDay: PropTypes.string.isRequired,
  srcMonth: PropTypes.string.isRequired,
  srcYear: PropTypes.string.isRequired,
  showAge: PropTypes.bool,
};

BirthdayAgeField.defaultProps = {
  label: 'Birthday (Age)',
  addLabel: true,
  record: {},
  showAge: false,
};

export default BirthdayAgeField;
