import { restClient } from 'client';
import PropTypes from 'prop-types';
import React from 'react';

class HeartRateGraphField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { svg: null };
  }

  componentDidMount() {
    this.props.record &&
      restClient('GET_RAW', `results/${this.props.record.id}/graphs/time_in_zones.svg`, { width: 250 }).then((svg) => {
        this.setState({ svg });
      });
  }

  render() {
    const { svg } = this.state;

    return svg ? (
      <span dangerouslySetInnerHTML={{ __html: svg }} style={{ maxWidth: '100%' }} />
    ) : (
      <div>Loading...</div>
    );
  }
}

HeartRateGraphField.propTypes = {
  record: PropTypes.object,
};

export default HeartRateGraphField;
