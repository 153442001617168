import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import onlyUpdateForKeys from 'recompose/onlyUpdateForKeys';
import moment from 'moment-timezone';
import CardActions from '@material-ui/core/CardActions';
import { sanitizeListRestProps } from 'ra-core';
import { CreateButton, Button, RefreshButton } from 'react-admin';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import DateRangeIcon from '@material-ui/icons/DateRange';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import ResetIcon from '@material-ui/icons/Remove';


const style = (theme) => ({
  verticalActions: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
  },
  advancedFilters: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    paddingTop: '0.5em',
  },
});

class Actions extends React.Component {
  setDateRangeFilter = (range) => () => {
    const { filterValues } = this.props;

    let from, to;
    const today = moment();
    const tomorrow = moment().add(1, 'day');
    const yesterday = moment().subtract(1, 'day');

    if (!range) {
      this.props.setFilters({
        ...filterValues,
        date_begin_gte: undefined,
        date_begin_lte: undefined,
      });
    }

    switch (range) {
      case 'yesterday': {
        from = yesterday.clone().startOf('day').format();
        to = yesterday.clone().endOf('day').format();
        break;
      }
      case 'today': {
        from = today.clone().startOf('day').format();
        to = today.clone().endOf('day').format();
        break;
      }
      case 'tomorrow': {
        from = tomorrow.clone().startOf('day').format();
        to = tomorrow.clone().endOf('day').format();
        break;
      }
      case 'current_week': {
        from = today.clone().startOf('week').format();
        to = today.clone().endOf('week').format();
        break;
      }
      case 'next_week': {
        from = today.clone().add(1, 'week').startOf('week').format();
        to = today.clone().add(1, 'week').endOf('week').format();
        break;
      }
      default:
        break;
    }

    this.props.setFilters({
      ...filterValues,
      event_date_begin_gte: from,
      event_date_begin_lte: to,
    });
  }

  setGritpointsFilter = ({ min, max, equal }) => () => {
    const { filterValues } = this.props;

    if (equal) {
      return this.props.setFilters({
        ...filterValues,
        gritpoints: equal,
      });
    }

    const query = {};

    if (min) {
      query.gritpoints_gte = min;
    }

    if (max) {
      query.gritpoints_lte = max;
    }

    this.props.setFilters({
      ...filterValues,
      ...query,
    });
  }

  resetFilters = () => {
    this.props.setFilters({});
  }

  render () {
    const {
      bulkActions,
      currentSort,
      className,
      resource,
      filters,
      displayedFilters,
      exporter,
      filterValues,
      hasCreate,
      basePath,
      selectedIds,
      onUnselectItems,
      showFilter,
      classes,
      ...rest
    } = this.props;

    return (
      <CardActions
        className={classnames(className, classes.verticalActions)}
        disableActionSpacing
        {...sanitizeListRestProps(rest)}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
          {bulkActions &&
            cloneElement(bulkActions, {
              basePath,
              filterValues,
              resource,
              selectedIds,
              onUnselectItems,
            })}
          {filters &&
            cloneElement(filters, {
              resource,
              showFilter,
              displayedFilters,
              filterValues,
              context: 'button',
            })}
          {hasCreate && <CreateButton basePath={basePath} />}
          <RefreshButton />
        </div>

        <div className={classes.advancedFilters}>
          <Button onClick={this.setDateRangeFilter('yesterday')} label='Yesterday'><DateRangeIcon /></Button>
          <Button onClick={this.setDateRangeFilter('today')} label='Today'><DateRangeIcon /></Button>
          <Button onClick={this.setDateRangeFilter('current_week')} label='Current Week'><DateRangeIcon /></Button>
          <Button onClick={this.setGritpointsFilter({ max: 10 })} label='Low gritpoints'><TrendingDownIcon /></Button>
          <Button onClick={this.resetFilters} label='Reset'><ResetIcon /></Button>
        </div>

      </CardActions>
    );
  }
}

Actions.propTypes = {
  bulkActions: PropTypes.node,
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  currentSort: PropTypes.object,
  displayedFilters: PropTypes.object,
  exporter: PropTypes.func,
  filters: PropTypes.element,
  filterValues: PropTypes.object,
  hasCreate: PropTypes.bool,
  resource: PropTypes.string,
  onUnselectItems: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  showFilter: PropTypes.func,
};

Actions.defaultProps = {
  selectedIds: [],
};

export default onlyUpdateForKeys([
  'resource',
  'filters',
  'displayedFilters',
  'filterValues',
  'selectedIds',
])(withStyles(style)(Actions));
