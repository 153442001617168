import React from 'react';
import { Create } from 'react-admin';

import ResultForm from './ResultForm';


const ResultCreate = (props) => (
  <Create title='Create Result' {...props} undoable={false} >
    <ResultForm />
  </Create>
);

export default ResultCreate;
