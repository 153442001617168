export const PLAY_AUDIO_FILE = 'PLAY_AUDIO_FILE';
export const STOP_AUDIO_FILE = 'STOP_AUDIO_FILE';

export const playAudioFile = (data) => {
  return {
    type: PLAY_AUDIO_FILE,
    payload: { data },
  };
};

export const stopAudioFile = (data) => {
  return {
    type: STOP_AUDIO_FILE,
    payload: { data },
  };
};
