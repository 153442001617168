import React from 'react';
import PropTypes from 'prop-types';
import UserFullNameField from './FullNameField';

const UserTitle = ({ record }) => (record ? <UserFullNameField record={record} size={32} /> : null);

UserTitle.propTypes = {
  record: PropTypes.object,
};

UserTitle.defaultProps = {
  record: {},
};

export default UserTitle;
