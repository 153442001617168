import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import get from 'lodash/get';

import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';

import { restClient } from 'client';

import GritText from './GritText';
import NumberField from './NumberField';
import GritpointsMeter from './GritpointsMeter';
// import TimeInZoneChart from './TimeInZoneChart';
// import HeartRateGraph from './HeartRateGraph/HeartRateGraph';

import styles from './DailyStats.styles';

const logo = 'images/gritlogo.png';
const statsIcon = 'images/results/daily_stats/stats.png';
const enduranceIcon = 'images/results/daily_stats/endurance.png';
const afterBurnIcon = 'images/results/daily_stats/36hours.png';
const trophyIcon = 'images/results/daily_stats/trophy.png';
const fireIcon = 'images/results/daily_stats/fire.png';


class HeartRateGraph extends React.Component {
  constructor (props) {
    super(props);
    this.state = { svg: null };
  }

  componentDidMount () {
    restClient('GET_RAW', `results/${this.props.resultId}/graphs/heart_rate.svg`).then((svg) => {
      this.setState({ svg });
    });
  }

  render () {
    const { svg } = this.state;

    return svg ? <span dangerouslySetInnerHTML={{ __html: svg }} style={{ maxWidth: '100%' }} /> : <div>Loading...</div>;
  }
}

HeartRateGraph.propTypes = {
  resultId: PropTypes.string.isRequired,
};

class TimeInZoneGraph extends React.Component {
  constructor (props) {
    super(props);
    this.state = { svg: null };
  }

  componentDidMount () {
    restClient('GET_RAW', `results/${this.props.resultId}/graphs/time_in_zones.svg`, { width: 600 }).then((svg) => {
      this.setState({ svg });
    });
  }

  render () {
    const { svg } = this.state;

    return svg ? <span dangerouslySetInnerHTML={{ __html: svg }} style={{ maxWidth: '100%' }} /> : <div>Loading...</div>;
  }
}

TimeInZoneGraph.propTypes = {
  resultId: PropTypes.string.isRequired,
};


class DailyStats extends React.Component {
  render () {
    const { result, user, hrGraphImageUrl, weekStats, monthStats } = this.props;

    const eventDateBegin = get(result, 'event_date_begin');
    const eventDateBeginFormatted = moment(eventDateBegin).format('MMM, D');

    const username = user.forename;
    const profilePictureUrl = get(user, 'profile_picture.small.url');

    const hrGraphData = get(result, 'readings');
    const maxHeartRate = get(result, 'user_profile.max_heart_rate', get(user, 'max_heart_rate'));

    if (!maxHeartRate) {
      console.warn('No max heart rate set');
    }

    const previousPoints = 0;
    console.warn('No previousPoints set');

    return (
      <div>
        <div /* ref={forwardedRef} */ id='dailystats' style={styles.container}>
          <div style={{ ...styles.section, ...styles.dailyStats.section }}>
            <div style={styles.dailyStats.textWrapper}>
              <div>
                <img src={statsIcon} alt='dailystats' style={styles.dailyStats.icon} />
              </div>
              <div style={styles.dailyStats.text}>Daily Stats</div>
            </div>
            <div style={styles.dailyStats.logo}>
              <img src={logo} alt='logo' width='60' height='60' style={styles.logo} />
            </div>
          </div>

          <div style={styles.banner}>
            {profilePictureUrl && (
              <div style={styles.avatar}>
                <Avatar src={profilePictureUrl} size={60} />
              </div>
            )}
            <div style={styles.column}>
              <div style={styles.name}>Hi {username}!</div>
              <div style={styles.date}>Here are your stats for {eventDateBeginFormatted}!</div>
            </div>
          </div>

          <div style={styles.section}>
            <div style={styles.header}>Heart Rate Chart</div>
            {hrGraphImageUrl && (
              <div>
                <img src={hrGraphImageUrl} alt='hrchart' style={styles.hrchart} />
              </div>
            )}
            {!hrGraphImageUrl && !!hrGraphData && (
              // <HeartRateGraph
              //   data={hrGraphData}
              //   maxHeartRate={maxHeartRate}
              // />
              <HeartRateGraph resultId={result.id} />
            )}
            <div style={{ maxWidth: '100%' }} />
          </div>

          <div style={styles.section}>
            <div style={styles.header}>Time in Zone</div>
            {/* <TimeInZoneChart record={result} /> */}
            <div style={{ marginLeft: '100px' }}>
              <TimeInZoneGraph resultId={result.id} />
            </div>
          </div>

          <div style={styles.section}>
            <GritText text='gritpoints' size='header' />
            <GritpointsMeter record={result} prevPoints={previousPoints} avgPoints={result.meanPoints} />
          </div>

          <Divider style={styles.divider} />

          <div style={styles.section}>
            <GritText text='calories' size='header' />
            <div style={{ ...styles.column, ...styles.center }}>
              <NumberField icon={enduranceIcon} value={result.calories_burned} label='Calories burned during the workout' w50 mb1 />
              <NumberField icon={afterBurnIcon} value={result.calories_epoc} label='Extra calories burned over the next 36 hours' w50 />
            </div>
          </div>

          <Divider style={styles.divider} />

          <div style={{ ...styles.section, ...styles.totals.section }}>

            <div style={{ ...styles.column, ...styles.center }}>
              <div style={styles.totals.label}>Weekly Totals</div>
              <NumberField icon={trophyIcon} value={weekStats.totalPoints} gritLabel='points' mb1 />
              <NumberField icon={fireIcon} value={weekStats.totalCalories} gritLabel='calories' />
            </div>

            <div style={styles.verticalDivider} />

            <div style={{ ...styles.column, ...styles.center }}>
              <div style={styles.totals.label}>Monthly Totals</div>
              <NumberField icon={trophyIcon} value={monthStats.totalPoints} gritLabel='points' mb1 />
              <NumberField icon={fireIcon} value={monthStats.totalCalories} gritLabel='calories' />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DailyStats.propTypes = {
  result: PropTypes.object,
  user: PropTypes.object,

  hrGraphImageUrl: PropTypes.string,

  workoutStats: PropTypes.shape({
    meanPoints: PropTypes.number,
  }),

  weekStats: PropTypes.shape({
    totalPoints: PropTypes.number,
    totalCalories: PropTypes.number,
  }),

  monthStats: PropTypes.shape({
    totalPoints: PropTypes.number,
    totalCalories: PropTypes.number,
  }),
};

DailyStats.defaultProps = {
  hrGraphImageUrl: null,
};

export default DailyStats;
// TODO: React >= 16 !
// export default React.forwardRef((props, ref) => <DailyStats {...props} forwardedRef={ref} />);
