import get from 'lodash/get';
import React from 'react';
import { TextInput, SelectInput, SimpleForm, ReferenceInput, FormDataConsumer, required } from 'react-admin';

import AudioFileAssignment from './AudioFileAssignment';
import { categories, genres, languages } from './constants';

const AudioSetForm = (props) => (
  <SimpleForm resource='asset-sets' {...props} initialValues={{ type: 'audio' }}>
    <TextInput label='Name' source='name' validate={required()} />
    <SelectInput label='Category' source='metadata.category' choices={categories} validate={required()} />
    <SelectInput label='Genre' source='metadata.genre' choices={genres} resettable />
    <SelectInput label='Language' source='metadata.language' choices={languages} resettable />
    <ReferenceInput source='event_type_id' reference='event-types' label='Event type'>
      <SelectInput optionText='name' resettable />
    </ReferenceInput>
    <FormDataConsumer>
      {({ formData }) => {
        const eventTypeId = get(formData, 'event_type_id');
        const category = get(formData, 'metadata.category');
        const genre = get(formData, 'metadata.genre');
        const language = get(formData, 'metadata.language');
        return <AudioFileAssignment eventTypeId={eventTypeId} category={category} genre={genre} language={language} />;
      }}
    </FormDataConsumer>
  </SimpleForm>
);

export default AudioSetForm;
