const addTranslationCapabilities = (requestHandler) => (type, resource, parameters) => {
  if (type === 'GET_ONE' && ['locations', 'event-types'].includes(resource)) {
    const params = { ...parameters, query: { $translate: false } };
    return requestHandler(type, resource, params);
  }

  return requestHandler(type, resource, parameters);
};

export default addTranslationCapabilities;
