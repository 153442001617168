import { Button } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { cancelOffer as cancelOfferAction } from '../actions';

const CancelButton = (props) => {
  const handleClick = () => {
    props.cancelOffer(record.id, record);
  };
  const { record } = props;

  return (
    <Button variant='text' size='small' color='primary' onClick={handleClick}>
      <CancelIcon />
      Cancel
    </Button>
  );
};

CancelButton.propTypes = {
  record: PropTypes.object,
  cancelOffer: PropTypes.func.isRequired,
};

CancelButton.defaultProps = {
  record: {},
};

export default connect(null, {
  cancelOffer: cancelOfferAction,
})(CancelButton);
