import * as React from 'react';
import { useRecordContext } from 'react-admin';

const AmenitiesField = (props) => {
  const record = useRecordContext(props);

  const selectedAmenities = record.amenities?.filter((obj) => !!obj.selected);

  if (!selectedAmenities) return null;

  return selectedAmenities.map((obj, index) => (
    <span>
      {obj.label}
      {index !== selectedAmenities.length - 1 ? ', ' : ''}
    </span>
  ));
};

export default AmenitiesField;
