import { makeStyles } from '@material-ui/core/styles';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React from 'react';
import { BooleanInput, FormTab, NumberInput, SelectInput, TabbedForm, TextInput, required } from 'react-admin';

import { difficultyLevels, languages } from './constants';

const useStyles = makeStyles(() => ({
  fullWidthInput: {
    width: '100%',
  },
}));

function EventTypeForm({ isExisting, ...props }) {
  const classes = useStyles();
  const validateEventTypeCreation = (values) => {
    let errors = {}
    if (!values.name_en) {
      errors.name_en = 'English Translation is Required'
    } else if (!values.name_de) {
      errors.name_de = 'German Translation is Required'
    }
    return errors
  }
  return (
    <TabbedForm {...props} redirect={isExisting ? 'show' : 'list'} validate={validateEventTypeCreation}  >
      <FormTab label='Basic'>
        {props.isExisting && <TextInput disabled source='id' />}
        <BooleanInput source='is_published' />
        <BooleanInput source='booking_options.requires_paying_customer' label='Is Chargeable' defaultValue />

        <TextInput source='slug' />
        <TextInput source='seo_slug' label='SEO Slug' />

        <TextInput validate={required('Workout Category is Required')} source='workout_category' />
        <SelectInput validate={required('Difficulty Level is Required')} source='difficulty_level' label='Difficulty' choices={difficultyLevels} />
        <NumberInput validate={required('Cardio Level is Required')} source='cardio_level' label='Cardio Level' min={1} max={5} />
        <NumberInput validate={required('Strength Level is Required')} source='strength_level' label='Strength Level' min={1} max={5} />
      </FormTab>
      {map(languages, (language) => (
        <FormTab path={`translation_${language.code}`} label={`${language.name} Translation`}>
          <TextInput label='Name' source={`name_${language.code}`} />
          <TextInput
            className={classes.fullWidthInput}
            multiline
            label='Description'
            source={`description_${language.code}`}
          />
          <TextInput label='SEO Title' source={`seo_title_${language.code}`} />
          <TextInput
            multiline
            label='SEO Description'
            source={`seo_description_${language.code}`}
            className={classes.fullWidthInput}
          />
        </FormTab>
      ))}
    </TabbedForm>
  );
}
EventTypeForm.propTypes = { isExisting: PropTypes.bool };
EventTypeForm.defaultProps = { isExisting: false };

export default EventTypeForm;
