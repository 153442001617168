import { httpClient } from 'client';

const apiUrl = process.env.REACT_APP_API_URL;

const getUser = async ({ userId }) => {
  try {
    const data = await httpClient(`${apiUrl}/users/${userId}`).then(({ json }) => json.data);
    return { data, success: true, error: false };
  } catch (error) {
    return { data: null, success: false, error: error?.message };
  }
};

export default getUser;
