/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { GET_LIST } from 'react-admin';
import _get from 'lodash/get';
import debounce from 'lodash/debounce';

import { restClient } from 'client';
// eslint-disable-next-line
import CohortChart from 'components/CohortChart';


class CRRCohortChart extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      startFrom: props.startFrom,
      interval: props.interval,
      cycles: props.cycles,
      coach: [],
      place: [],
      country: [],
      city: [],
      district: [],
      provider: null,
      coaches: [],
      places: [],
      attended: false,
      newCustomers: true,
      excludeTrial: false,
      uniqueUsers: true,
      isLoading: true,
      retention: null,
    };
  }

  componentDidMount () {
    this.fetchRetention();
    this.fetchAditionalData();
  }

  fetchAditionalData = async () => {
    try {
      const trainersResponse = await restClient(GET_LIST, 'trainers', {
        pagination: { page: 1, perPage: 100 },
      });
      const placesResponse = await restClient(GET_LIST, 'places', {
        pagination: { page: 1, perPage: 100 },
      });

      this.setState({
        coaches: _get(trainersResponse, 'data', []),
        places: _get(placesResponse, 'data', []),
      });
    } catch (error) {
      this.setState({ isLoading: true });
      throw error;
    }
  }

  changeRetentionFilters = async (params = {}) => {
    try {
      const startFrom = params.startFrom || this.state.startFrom;
      const interval = params.interval || this.state.interval;
      const cycles = params.cycles || this.state.cycles;
      const coach = _get(params, 'coach', this.state.coach);
      const place = _get(params, 'place', this.state.place);
      const country = _get(params, 'country', this.state.country);
      const city = _get(params, 'city', this.state.city);
      const district = _get(params, 'district', this.state.district);
      const provider = _get(params, 'provider', this.state.provider);
      const attended = _get(params, 'attended', this.state.attended);
      const newCustomers = _get(params, 'newCustomers', this.state.newCustomers);
      const uniqueUsers = _get(params, 'uniqueUsers', this.state.uniqueUsers);
      let excludeTrial = _get(params, 'excludeTrial', this.state.excludeTrial);

      if (provider && provider !== 'none') {
        excludeTrial = false;
      }

      this.setState({
        startFrom,
        interval,
        cycles,
        coach,
        attended,
        newCustomers,
        excludeTrial,
        uniqueUsers,
        place,
        country,
        city,
        district,
        provider,
      }, this.fetchRetention);
    } catch (error) {
      this.setState({ isLoading: true });
      throw error;
    }
  }

  fetchRetention = debounce(async () => {
    try {
      const { 
        startFrom,
        interval,
        cycles,
        coach,
        place,
        country,
        city,
        district,
        provider,
        attended,
        newCustomers,
        uniqueUsers,
      } = this.state;

      const excludeTrial =  provider && provider !== 'none'
        ? false
        : this.state.excludeTrial;

      this.setState({ isLoading: true });

      const response = await restClient('GET_JSON', 'reports/tickets/retention', {
        start_from: moment(startFrom).format('YYYY-MM-DD'),
        interval,
        cycles,
        coach_id: coach || [],
        location_id: place || [],
        country_code: country || [],
        city_code: city || [],
        district_code: district || [],
        provider: provider || undefined,
        has_attended: attended || undefined,
        new_customers: newCustomers || undefined,
        exclude_trial: excludeTrial || undefined,
        unique_users: uniqueUsers,
      });

      this.setState({
        retention: _get(response, 'data', []),
        isLoading: false,
      });
    } catch (error) {
      this.setState({ isLoading: true });
      throw error;
    }
  }, 1000);

  onChangeStartFrom = (date) => this.changeRetentionFilters({ startFrom: date });
  onChangeInterval = (interval) => this.changeRetentionFilters({ interval });
  onChangeCycles = (cycles) => this.changeRetentionFilters({ cycles });
  onChangeCoach = (coach) => this.changeRetentionFilters({ coach });
  onChangePlace = (place) => this.changeRetentionFilters({ place });
  onChangeCountry = (country) => this.changeRetentionFilters({ country });
  onChangeCity = (city) => this.changeRetentionFilters({ city });
  onChangeDistrict = (district) => this.changeRetentionFilters({ district });
  onChangeProvider = (provider) => this.changeRetentionFilters({ provider });
  onChangeAttended = (attended) => this.changeRetentionFilters({ attended });
  onChangeNewCustomers = (newCustomers) => this.changeRetentionFilters({ newCustomers });
  onChangeUniqueUsers = (uniqueUsers) => this.changeRetentionFilters({ uniqueUsers });
  onChangeExcludeTrial = (excludeTrial) => this.changeRetentionFilters({ excludeTrial });

  render () {
    const { isLoading, startFrom, interval, cycles, coach, place, country, city, district, provider, attended, newCustomers, excludeTrial, uniqueUsers, coaches, places, retention } = this.state;

    return (
      <CohortChart
        loading={isLoading}
        startFrom={startFrom}
        interval={interval}
        cycles={cycles}
        coach={coach}
        place={place}
        country={country}
        city={city}
        district={district}
        provider={provider}
        attended={attended}
        newCustomers={newCustomers}
        uniqueUsers={uniqueUsers}
        excludeTrial={excludeTrial}
        coaches={coaches}
        places={places}
        retention={retention}
        onChangeInterval={this.onChangeInterval}
        onChangeStartFrom={this.onChangeStartFrom}
        onChangeCycles={this.onChangeCycles}
        onChangeCoach={this.onChangeCoach}
        onChangePlace={this.onChangePlace}
        onChangeCountry={this.onChangeCountry}
        onChangeCity={this.onChangeCity}
        onChangeDistrict={this.onChangeDistrict}
        onChangeProvider={this.onChangeProvider}
        onChangeAttended={this.onChangeAttended}
        onChangeNewCustomers={this.onChangeNewCustomers}
        onChangeUniqueUsers={this.onChangeUniqueUsers}
        onChangeExcludeTrial={this.onChangeExcludeTrial}
      />
    );
  }
}

CRRCohortChart.propTypes = {
  startFrom: PropTypes.instanceOf(Date),
  interval: PropTypes.string,
  cycles: PropTypes.number,
};

CRRCohortChart.defaultProps = {
  startFrom: moment().subtract(5, 'month').toDate(),
  interval: 'month',
  cycles: 6,
};

export default CRRCohortChart;
