export default {
  normal: {
    text: {
      fontSize: '1rem',
      color: 'rgba(0, 0, 0 , .7)',
      overflow: 'visible',
      whiteSpace: 'nowrap',
    },
    img: {
      width: '9px',
    },
  },
  header: {
    text: {
      fontSize: '3rem',
      color: 'rgba(0, 0, 0 , .7)',
      marginBottom: '2rem',
    },
    img: {
      width: '27px',
    },
  },
};
