/* eslint-disable no-dupe-keys */
import { brand, gsBlack } from 'theme/colors';

export default {
  section: {
    width: '80%',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  points: {
    section: {
      display: 'flex',
    },
    leftText: {
      position: 'absolute',
      color: gsBlack,
      textAlign: 'right',
      top: '0.2rem',
      right: '100%',
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: '0.5rem',
      minWidth: '12rem',
    },
    rightText: {
      position: 'absolute',
      color: gsBlack,
      textAlign: 'left',
      top: '0.2rem',
      left: '100%',
      marginTop: 0,
      marginBottom: 0,
      marginLeft: '0.5rem',
      marginRight: 0,
      minWidth: '12rem',
    },
    balloon: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 0,
      marginBottom: '1px',
      marginLeft: 0,
      marginRight: 0,

      position: 'relative',
    },
    rectangle: {
      backgroundColor: brand,
      color: '#fff',
      fontSize: '2rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '0.6rem',
      paddingTop: '0.3rem',
      paddingBottom: '0.3rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      letterSpacing: '2px',
    },
    triangle: {
      width: '0',
      height: '0',
      borderLeft: '13px solid transparent',
      borderRight: '13px solid transparent',
      borderTop: `20px solid ${brand}`,
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  performance: {
    bar: {
      paddingTop: '1rem',
      paddingBottom: '1rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      marginTop: 0,
      marginBottom: '2rem',
      marginLeft: 0,
      marginRight: 0,
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      border: 'none',
      borderRadius: '2rem',
      background: '#000000', // Old browsers
      background: `-moz-linear-gradient(left, #000000 0%, ${brand} 100%)`, // FF3.6-15
      background: `-webkit-linear-gradient(left, #000000 0%, ${brand} 100%)`, // Chrome10-25,Safari5.1-6
      background: `linear-gradient(to right, #000000 0%, ${brand} 100%)`, // W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+
      color: '#fff',
    },
    divider: {
      borderLeft: '0.5px solid #fff',
      borderRight: '0.5px solid #fff',
      marginTop: 0,
      marginBottom: 0,
      marginLeft: '1rem',
      marginRight: '1rem',
      alignSelf: 'stretch',
    },
    text: {
      fontWeight: 'bold',
      overflow: 'visible',
      whiteSpace: 'nowrap',
    },
  },
  lead2: {
    fontSize: '1.5rem',
    color: gsBlack,
  },
  verticalCenter: {
    display: 'flex',
    alignItems: 'center',
  },
};
