import { cityDefaultLanguages } from 'reactAdmin/locations/constants';

const getDefaultLanguage = (city) => {
  if (cityDefaultLanguages.de.includes(city)) {
    return 'de';
  }
  return 'en';
};

export default getDefaultLanguage;
