import React from 'react';
import { Edit, SaveButton, Toolbar } from 'react-admin';

import UserForm from './UserForm';
import UserTitle from './UserTitle';

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const UserEdit = (props) => (
  <Edit title={<UserTitle />} {...props} undoable={false}>
    <UserForm isExisting toolbar={<EditToolbar />} />
  </Edit>
);

export default UserEdit;
