import get from 'lodash/get';
import dateBeginFormattedString from './dateBeginFormattedString';

export default (record, showDate) => {
  const formattedDate = dateBeginFormattedString(record.date_begin, { timezone: get(record, 'location.timezone') });
  const trainerName = get(record, 'coach.forename', '');
  const placeName = get(record, 'location.name', '');
  const typeName = get(record, 'type.name', '');

  return `${!showDate ? '' : `${formattedDate}, `}${trainerName}@${placeName}, ${typeName}`;
};
