import React from 'react';
import { Layout } from 'react-admin';

import AppBar from './AppBar';
import Menu from './Menu';
// import MyNotification from './MyNotification';


const MyLayout = (props) => (
  <Layout
    {...props}
    appBar={AppBar}
    menu={Menu}
    // notification={MyNotification}
  />
);

export default MyLayout;
