import Typography from '@material-ui/core/Typography';
import get from 'lodash/get';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import pure from 'recompose/pure';

import sanitizeRestProps from './sanitizeRestProps';
import { useRecordContext } from 'react-admin';

const DATETIME_FORMAT = 'ddd. DD.MM.YYYY, h:mm a';


const renderValidDate = (date, formatted, fallback) => {
  if (moment(date).isValid()) {
    return formatted
  } else if (fallback) {
    return fallback();
  }
  return null;
}
export const CustomDateField = ({ className, source, format, timezoneSource, fallback, record = {}, ...rest }) => {
  const dateFieldRecord = record && Object.keys(record).length > 0 ? record : useRecordContext();
  const formatted = moment
    .tz(get(dateFieldRecord, source), get(dateFieldRecord, timezoneSource, 'Europe/Berlin'))
    .format(format || DATETIME_FORMAT);
  return (
    <Typography component='span' className={className} {...sanitizeRestProps(rest)}>
      {renderValidDate(get(dateFieldRecord, source), formatted, fallback)}
    </Typography>
  );
};

CustomDateField.propTypes = {
  addLabel: PropTypes.bool,
  basePath: PropTypes.string,
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.object,
  sortBy: PropTypes.string,
  source: PropTypes.string.isRequired,
  format: PropTypes.string,
  fallback: PropTypes.func,
};

const PureCustomDateField = pure(CustomDateField);

PureCustomDateField.defaultProps = {
  addLabel: true,
  record: {},
};

export default PureCustomDateField;
