import React from 'react';
import PropTypes from 'prop-types';

import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ArrowUp from '@material-ui/icons/KeyboardArrowUp';

const styles = {
  header: {
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '0.5rem',
  },
};
class Collapsible extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: props.collapsed,
    };
  }

  toggleCollapsed = () => {
    this.setState((prevState) => ({ ...prevState, collapsed: !prevState.collapsed }));
  }

  render() {
    return (
      <div>
        <div role="button" aria-hidden onClick={this.toggleCollapsed} style={styles.header}>
          <div>{this.props.title}</div>

          {this.state.collapsed ? <ArrowDown color="white" /> : <ArrowUp color="white" />}
        </div>

        {!this.state.collapsed && this.props.children}
      </div>
    );
  }
}

Collapsible.propTypes = {
  collapsed: PropTypes.bool,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

Collapsible.defaultProps = {
  collapsed: true,
};

export default Collapsible;
