import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import onlyUpdateForKeys from 'recompose/onlyUpdateForKeys';
import moment from 'moment-timezone';
import CardActions from '@material-ui/core/CardActions';
import { sanitizeListRestProps } from 'ra-core';
import { Button } from 'react-admin';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import DateRangeIcon from '@material-ui/icons/DateRangeTwoTone';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import CalendarViewDay from '@material-ui/icons/CalendarTodayTwoTone';

// eslint-disable-next-line no-unused-vars
const style = (theme) => ({
  verticalActions: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingTop: '8px',
  },
  advancedFilters: {
    display: 'flex',
    flexDirection: 'column',
  },
  filterRow: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  mainFilterButton: {
    minWidth: '130px',
    padding: 0,
  },
  chevronSize: {
    minWidth: 0,
    margin: '0 4px',
    padding: 0,
  },
});

class Actions extends React.Component {
  setDateRangeFilter = (range) => () => {
    const { filterValues } = this.props;

    let from;
    let to;
    const today = moment();

    if (!range) {
      this.props.setFilters({
        ...filterValues,
        date_begin_gte: undefined,
        date_begin_lte: undefined,
      });
    }

    switch (range) {
      case 'today':
        from = today.clone().startOf('day').format();
        to = today.clone().endOf('day').format();
        break;
      case 'previous_day':
        from = moment(filterValues.date_begin_gte).clone().subtract(1, 'day').startOf('day').format();
        to = moment(filterValues.date_begin_lte).clone().subtract(1, 'day').endOf('day').format();
        break;
      case 'next_day':
        from = moment(filterValues.date_begin_gte).clone().add(1, 'day').startOf('day').format();
        to = moment(filterValues.date_begin_gte).clone().add(1, 'day').endOf('day').format();
        break;
      case 'current_week':
        from = today.clone().startOf('week').format();
        to = today.clone().endOf('week').format();
        break;
      case 'previous_week':
        from = moment(filterValues.date_begin_gte).clone().subtract(1, 'week').startOf('week').format();
        to = moment(filterValues.date_begin_lte).clone().subtract(1, 'week').endOf('week').format();
        break;
      case 'next_week':
        from = moment(filterValues.date_begin_gte).clone().add(1, 'week').startOf('week').format();
        to = moment(filterValues.date_begin_lte).clone().add(1, 'week').endOf('week').format();
        break;
      default:
        break;
    }

    this.props.setFilters({
      ...filterValues,
      date_begin_gte: from,
      date_begin_lte: to,
    });
  };

  render() {
    const {
      bulkActions,
      currentSort,
      className,
      resource,
      filters,
      displayedFilters,
      exporter,
      filterValues,
      hasCreate,
      basePath,
      selectedIds,
      onUnselectItems,
      showFilter,
      classes,
      ...rest
    } = this.props;

    return (
      <CardActions
        className={classnames(className, classes.verticalActions, 'event-filter-buttons')}
        disableActionSpacing
        {...sanitizeListRestProps(rest)}
      >
        <div style={{ display: 'flex' }}>
          {bulkActions &&
            cloneElement(bulkActions, {
              basePath,
              filterValues,
              resource,
              selectedIds,
              onUnselectItems,
            })}
          {filters &&
            cloneElement(filters, {
              resource,
              showFilter,
              displayedFilters,
              filterValues,
              context: 'button',
            })}
        </div>
        <div className={classes.advancedFilters}>
          {/* ============ DAYS ============ */}
          <div className={classes.filterRow} style={{ marginBottom: 8, marginTop: 8 }}>
            <Button
              onClick={this.setDateRangeFilter('previous_day')}
              className={classes.chevronSize}
              variant='outlined'
              title='Previous day'
            >
              <ChevronLeftRoundedIcon style={{ fontSize: '24px' }} />
            </Button>
            <Button
              onClick={this.setDateRangeFilter('today')}
              label='Today'
              className={classes.mainFilterButton}
              variant='outlined'
            >
              <CalendarViewDay />
            </Button>
            <Button
              onClick={this.setDateRangeFilter('next_day')}
              className={classes.chevronSize}
              variant='outlined'
              title='Next day'
            >
              <ChevronRightRoundedIcon style={{ fontSize: '24px' }} />
            </Button>
          </div>
          {/* ============== WEEKS ================== */}
          <div className={classes.filterRow}>
            <Button
              onClick={this.setDateRangeFilter('previous_week')}
              className={classes.chevronSize}
              variant='outlined'
              title='Previous week'
            >
              <ChevronLeftRoundedIcon style={{ fontSize: '24px' }} />
            </Button>
            <Button
              onClick={this.setDateRangeFilter('current_week')}
              label='This week'
              className={classes.mainFilterButton}
              variant='outlined'
            >
              <DateRangeIcon style={{ fontSize: '24px' }} />
            </Button>
            <Button
              onClick={this.setDateRangeFilter('next_week')}
              className={classes.chevronSize}
              variant='outlined'
              title='Next week'
            >
              <ChevronRightRoundedIcon style={{ fontSize: '24px' }} />
            </Button>
          </div>
        </div>
      </CardActions>
    );
  }
}

Actions.propTypes = {
  bulkActions: PropTypes.node,
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  currentSort: PropTypes.object,
  displayedFilters: PropTypes.object,
  exporter: PropTypes.func,
  filters: PropTypes.element,
  filterValues: PropTypes.object,
  hasCreate: PropTypes.bool,
  resource: PropTypes.string,
  onUnselectItems: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  showFilter: PropTypes.func,
  showNextPrevious: PropTypes.bool,
};

Actions.defaultProps = {
  selectedIds: [],
};

export default onlyUpdateForKeys(['resource', 'filters', 'displayedFilters', 'filterValues', 'selectedIds'])(
  withStyles(style)(Actions),
);
