import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import get from 'lodash/get';

const styles = {
  chip: { marginRight: '0.5rem' },
  list: { display: 'flex' },
};

const ChipArrayField = ({ record = {}, source }) => {
  const values = get(record, source);

  let list = [];
  if (values) {
    list = values.map((item) => {
      return <Chip key={item} label={item} />;
    });
  }

  return <div style={styles.list}>{list}</div>;
};

ChipArrayField.propTypes = {
  label: PropTypes.string, // eslint-disable-line
  addLabel: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

ChipArrayField.defaultProps = {
  addLabel: true,
  record: {},
};

export default ChipArrayField;
