import React from 'react';
import { Create } from 'react-admin';
import AudioSetForm from './AudioSetForm';

const AudioSetCreate = (props) => (
  <Create {...props}>
    <AudioSetForm {...props} />
  </Create>
);

export default AudioSetCreate;

