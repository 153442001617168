/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import download from 'downloadjs';
import get from 'lodash/get';
import domToImage from 'dom-to-image';
import moment from 'moment-timezone';

import { GET_ONE, GET_LIST } from 'react-admin';

import { restClient } from 'client';

import { profilePictureUrl } from 'reactAdmin/users';

import calculateAfterBurn from 'utils/calculateAfterBurn';
import generatePreview from 'utils/generatePreview';

import Form from './Form';
import Report from './Report';
import styles from './styles';


class WeeklyStats extends React.Component {
  constructor (props) {
    super(props);

    // TODO: Extract:
    moment.updateLocale('de', { week: { dow: 1 } }); // Monday is the first day of the week

    const date = moment().startOf('week');

    this.state = {
      avatar: null,
      username: null,
      date,
      weeks: [],
      error: null,
    };
  }

  componentDidMount () {
    this.fetchUserData();
    this.fetchWorkoutsData(this.state.date);
  }

  fetchUserData = async () => {
    const userId = get(this.props, 'match.params.user');
    const user = await restClient(GET_ONE, 'users', { id: userId }).then((response) => response.data);
    const username = user.forename;
    const avatar = profilePictureUrl(user, 'medium');

    this.setState({ username, avatar });
  }

  fetchTickets = async (date) => {
    const begin = moment(date).startOf('week').subtract(6, 'week');
    const end = moment(date).endOf('week');
    const requestOptions = {
      filter: {
        user_id: this.props.match.params.user,
        status_ne: 'cancelled',
        // has_attended: true,
        event_date_begin_gte: begin.toISOString(),
        event_date_begin_lte: end.toISOString(),
      },
      sort: { field: 'created_at', order: 'ASC' },
      pagination: { page: 1, perPage: 100 },
    };

    const tickets = await restClient(GET_LIST, 'tickets', requestOptions).then((response) => response.data);
    const filtered = [];

    tickets.forEach((t) => {
      const attended = typeof t.has_attended !== 'undefined' && (t.has_attended || t.has_attended === 'true');

      if (attended) {
        filtered.push(t);
      }
    });

    return filtered;
  }

  fetchResults = async (date) => {
    const begin = moment(date).startOf('week').subtract(6, 'week');
    const end = moment(date).endOf('week');
    const requestOptions = {
      filter: {
        user_id: this.props.match.params.user,
        event_date_begin_gte: begin.toISOString(),
        event_date_begin_lte: end.toISOString(),
      },
      sort: { field: 'created_at', order: 'ASC' },
      pagination: { page: 1, perPage: 100 },
    };

    return restClient(GET_LIST, 'results', requestOptions).then((response) => response.data);
  }

  filterByDate = (arr, begin, end) =>
    arr.filter((obj) => moment(obj.event.date_begin).isBetween(begin, end, null, '[]'));

  processData = (date, tickets, results) => {
    const datesBeginAux = [
      moment(date).startOf('week').subtract(6, 'week'),
      moment(date).startOf('week').subtract(5, 'week'),
      moment(date).startOf('week').subtract(4, 'week'),
      moment(date).startOf('week').subtract(3, 'week'),
      moment(date).startOf('week').subtract(2, 'week'),
      moment(date).startOf('week').subtract(1, 'week'),
      moment(date).startOf('week'),
    ];

    const weeks = datesBeginAux.map((begin) => {
      const end = moment(begin).endOf('week');

      const filteredTickets = this.filterByDate(tickets, begin, end);
      const filteredResults = this.filterByDate(results, begin, end);

      const workouts = Math.max(filteredTickets.length, filteredResults.length);
      const gritpoints = filteredResults.map((result) => result.gritpoints).reduce((acc, gritpoints) => acc + gritpoints, 0);
      const calories = filteredResults.map((result) => {
        const caloriesBurned = get(result, 'calories_burned', 0);
        const caloriesEpoc = calculateAfterBurn(get(result, 'gritpoints', 0), caloriesBurned);

        return caloriesBurned + caloriesEpoc;
      }).reduce((acc, cals) => (acc + cals), 0);

      const hasUntrackedWorkouts = filteredTickets.length !== filteredResults.length;
      const formattedDate = begin.format('DD/MM');

      return {
        begin,
        end,
        tickets: filteredTickets,
        results: filteredResults,
        workouts: { date: formattedDate, total: workouts, hasUntrackedWorkouts: false },
        gritpoints: { date: formattedDate, total: gritpoints, hasUntrackedWorkouts },
        calories: { date: formattedDate, total: calories, hasUntrackedWorkouts },
      };
    });

    return weeks;
  }

  fetchWorkoutsData = async (date) => {
    const tickets = await this.fetchTickets(date);
    const results = await this.fetchResults(date);
    const weeks = this.processData(date, tickets, results);

    this.setState({ date, weeks });
  }

  handleDateChange = (event) => {
    this.fetchWorkoutsData(event.target.value);
  };

  handleExport = async () => {
    this.setState({ error: null });

    const input = document.getElementById('weeklystats');
    const name = this.state.username.toLowerCase();
    const week = moment(this.state.date).isoWeek();
    const year = moment(this.state.date).year();
    const filename = `weeklystats-${name}-week_${week}_${year}.png`;

    try {
      const image = await domToImage.toPng(input);
      download(image, filename);
    } catch (error) {
      console.log('error', error);

      this.setState({
        error: 'The report could not be automatically downloaded. Please use the preview bellow.',
      });

      generatePreview('smallpreview', input);
    }
  }

  render () {
    const { avatar, date, error, username, weeks } = this.state;

    return (
      <div style={styles.wrapper}>
        <Form
          date={date}
          error={error}
          elStyles={styles.form}
          onDateChanged={this.handleDateChange}
          onExportClicked={this.handleExport}
        />
        <Report
          avatar={avatar}
          elStyles={styles.image}
          date={date}
          username={username}
          weeks={weeks}
        />
      </div>
    );
  }
}

WeeklyStats.propTypes = {
  match: PropTypes.object.isRequired,
};

export default WeeklyStats;
