import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Refresh from '@material-ui/icons/Autorenew';
import PropTypes from 'prop-types';
import React from 'react';

const style = (theme) => ({
  uploadcontainer: {
    padding: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(4),
    fontWeight: 500,
  },
  container: {
    display: 'flex',
    padding: theme.spacing(2),
    alignItems: 'flex-end',
  },
  filename: {
    marginRight: theme.spacing(6),
    marginLeft: theme.spacing(6),
    width: '250px',
  },
  progressbar: {
    width: '200px',
  },
  icon: {
    width: '0.8em',
    height: '0.8em',
  },
});

const UploadQueue = ({ files, classes }) => {
  return (
    <>
      <Divider />
      <div className={classes.uploadcontainer}>
        <Typography className={classes.title}>Upload Queue</Typography>
        {files.map((item) => (
          <div key={item.file.preview} className={classes.container}>
            <Refresh color='primary' className={classes.icon} />
            <Typography className={classes.filename}>{item.file.name}</Typography>
            {!!item.progress && (
              <LinearProgress className={classes.progressbar} variant='determinate' value={item.progress || 0} />
            )}
            {!item.progress && !item.uploadFailed && <Typography>In queue</Typography>}
            {item.uploadFailed && <Typography> Error while uploading, please re-upload file</Typography>}
          </div>
        ))}
      </div>
    </>
  );
};

export default withStyles(style)(UploadQueue);

UploadQueue.propTypes = {
  files: PropTypes.array,
  classes: PropTypes.object,
};
