import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import moment from 'moment-timezone';
import { withStyles } from '@material-ui/core/styles';
import LocationIcon from '@material-ui/icons/Place';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';

import { TrainerIcon } from 'reactAdmin/trainers';

import eventDateBeginFormattedString from 'reactAdmin/events/utils/dateBeginFormattedString';
import EventFlags from './EventFlags';


const style = (theme) => ({
  rightListItemText: {
    marginRight: '1em',
  },
  secondaryContainer: {
    display: 'block',
  },
  secondaryFirstIcon: {
    marginBottom: '-2px',
  },
  secondaryRow: {
    display: 'block',
  },
  radio: {
    padding: 0,
  },
});

class EventListItem extends React.Component {
  handleSelect = (event) => () => this.props.onSelected(event);

  render () {
    const { event, classes, selected, onSelected } = this.props;
    const isSelectable = !!onSelected;
    const dateBegin = moment(event.date_begin);
    const isPassed = moment().isAfter(dateBegin.add(1, 'hour'));
    const participants = get(event, 'participants_count') || 0;
    const published = get(event, 'is_published', false);
    const locationName = get(event, 'location.name');
    const coachName = get(event, 'coach.forename');

    const attendeesCount = get(event, 'attendees_count') || 0;
    const resultsCount = get(event, 'results_count') || 0;

    return (
      <ListItem
        button
        // dense
        component={isSelectable ? 'div' : 'a'}
        href={isSelectable ? null : `#/events/${event.id}/show`}
        onClick={isSelectable ? this.handleSelect(event) : null}
      >
        {isSelectable && (
          <Radio
            className={classes.radio}
            checked={selected}
            tabIndex={-1}
            disableRipple
          />
        )}
        <ListItemText
          primary={(
            <span>
              {eventDateBeginFormattedString(event.date_begin, {timezone: get(event, 'location.timezone')})}&nbsp;
            </span>
          )}
          secondary={(
            <span className={classes.secondaryContainer}>
              <span className={classes.secondaryRow}>
                {locationName && <span><LocationIcon className={classes.secondaryFirstIcon} fontSize='inherit' />{locationName}&nbsp;|&nbsp;</span>}
                {coachName && <span><TrainerIcon className={classes.secondaryFirstIcon} fontSize='inherit' />{coachName}</span>}
              </span>
              <span className={classes.secondaryRow}>
                {participants}/{event.max_participants || '∞'} Bookings
                {!published && <span> - Not published</span>}
                {isPassed && (
                  <span>
                    &nbsp;|&nbsp;Attended: {attendeesCount}
                    &nbsp;|&nbsp;Results: {resultsCount}
                  </span>
                )}
              </span>
              <span className={classes.secondaryRow}>
                <EventFlags event={event} />
              </span>
            </span>
          )}
        />
      </ListItem>
    );
  }
}

EventListItem.propTypes = {
  event: PropTypes.object.isRequired,
};
export default withStyles(style)(EventListItem);
