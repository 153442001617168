import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import React, { useCallback } from 'react';
import CopyIcon from 'reactAdmin/assets/custom-icons/Copy';
import { primary } from 'theme/colors';

const styles = {
  button: {
    marginLeft: '0.5rem',
    padding: 0,
    paddingLeft: 2,
    paddingRight: 2,
    minWidth: '32px',
  },
  link: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  icon: {
    width: '24px',
    height: '24px',
  },
};

const CopyIdButton = ({ classes, record, id, style = {} }) => {
  if (!record && !id) return null;

  const onClick = useCallback(() => navigator.clipboard.writeText(id || record.id), [record, id]);

  return (
    <Button
      style={{ ...styles.button, ...style }}
      className={classes.link}
      onClick={onClick}
      title='Copy ID to clipboard'
    >
      <CopyIcon className={classes.icon} color={primary} />
    </Button>
  );
};

export default withStyles(styles)(CopyIdButton);
