import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _get from 'lodash/get';
import moment from 'moment-timezone';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';


const styles = {
  cell: {
    textAlign: 'center',
  },
};

const formatCycleInterval = (item, interval) => {
  const startAt = moment.tz(item.start_at, 'UTC');
  const endAt = moment.tz(item.end_at, 'UTC');

  if (interval === 'day') {
    return startAt.format('DD.MM.YYYY');
  }

  if (interval === 'month') {
    return startAt.format('MMMM YYYY');
  }

  if (interval === 'year') {
    return startAt.format('YYYY');
  }

  return `${startAt.format('DD.MM.YYYY')} to ${endAt.format('DD.MM.YYYY')}`;
};

const ListItem = ({ item, index, interval }) => (
  <TableRow hoverable rowNumber={index}>
    <TableCell>
      {formatCycleInterval(item, interval)}
    </TableCell>
    <TableCell style={styles.cell}>
      {item.new_customers}
    </TableCell>
    {
      _map(item.retention, (cycle) => {
        if (_get(cycle, 'absolute', null) === null) {
          return (<TableCell style={styles.cell} />);
        }

        return (<TableCell style={styles.cell}>{cycle.absolute} ({cycle.percentage}%)</TableCell>);
      })
    }
  </TableRow>
);

ListItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  interval: PropTypes.string.isRequired,
};

export default ListItem;
