import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import ApproveIcon from '@material-ui/icons/ThumbUp';
import NegateIcon from '@material-ui/icons/ThumbDown';
import ResetIcon from '@material-ui/icons/Undo';

import {
  attendanceApprove as attendanceApproveAction,
  attendanceNegate as attendanceNegateAction,
  attendanceReset as attendanceResetAction,
} from '../statusActions';


const colors = {
  green: '#86b792',
  red: '#ff9b9b',
};

const styles = {
  icon: {
    padding: '12px',
  },
  positiveIcon: {
    padding: '12px',
    color: colors.green,
  },
  negativeIcon: {
    padding: '12px',
    color: colors.red,
  },
  actionIcon: {
    color: '#8E24AA', // cyan500,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
};

class AttendanceButton extends Component {
  handleApprove = () => {
    const { attendanceApprove, record } = this.props;
    attendanceApprove(record.id, record);
  }

  handleNegate = () => {
    const { attendanceNegate, record } = this.props;
    attendanceNegate(record.id, record);
  }

  handleReset = () => {
    const { attendanceReset, record } = this.props;
    attendanceReset(record.id, record, 'status');
  }

  render () {
    const { record } = this.props;

    if (record.is_cancelled) {
      return null;
    }

    switch (record.has_attended) {
      case true:
        return (
          <span style={styles.flex}>
            <ApproveIcon style={styles.positiveIcon} />
            <IconButton onClick={this.handleReset}>
              <ResetIcon />
            </IconButton>
          </span>
        );
      case false:
        return (
          <span style={styles.flex}>
            <NegateIcon style={styles.negativeIcon} />
            <IconButton onClick={this.handleReset}>
              <ResetIcon />
            </IconButton>
          </span>
        );
      default:
        return (
          <span>
            <IconButton onClick={this.handleApprove} disabled={record.has_attended === true}>
              <ApproveIcon style={styles.actionIcon} />
            </IconButton>
            <IconButton onClick={this.handleNegate} disabled={record.has_attended === false}>
              <NegateIcon style={styles.actionIcon} />
            </IconButton>
          </span>
        );
    }
  }
}

AttendanceButton.propTypes = {
  record: PropTypes.object,
  attendanceApprove: PropTypes.func.isRequired,
  attendanceNegate: PropTypes.func.isRequired,
  attendanceReset: PropTypes.func.isRequired,
};

AttendanceButton.defaultProps = {
  record: {},
};

export default connect(null, {
  attendanceApprove: attendanceApproveAction,
  attendanceNegate: attendanceNegateAction,
  attendanceReset: attendanceResetAction,
})(AttendanceButton);
