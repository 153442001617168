import SensorIcon from '@material-ui/icons/Bluetooth';
import SensorCreate from './SensorCreate';
import SensorEdit from './SensorEdit';
import SensorList from './SensorList';
import SensorShow from './SensorShow';

import SensorModelField from './SensorModelField';

export default {
  icon: SensorIcon,
  list: SensorList,
  show: SensorShow,
  create: SensorCreate,
  edit: SensorEdit,
};

export {
  SensorIcon,
  SensorModelField,
};
