import { httpClient } from 'client';


const apiUrl = process.env.REACT_APP_API_URL;

const addMapImageUploadCapabilities = (requestHandler) => (type, resource, parameters) => {
  const params = { ...parameters };
  if (type === 'UPDATE' && resource === 'locations') {
    if (params.data.map_image_virtual && params.data.map_image_virtual.rawFile) {
      const url = `${apiUrl}/locations/${params.id}/media/map_image`;
      const form = new FormData();
      const requestOptions = {
        method: 'POST',
        body: form,
      };

      form.append('image', params.data.map_image_virtual.rawFile);

      return httpClient(url, requestOptions).then(() => {
        delete (params.data.map_image);

        return requestHandler(type, resource, params);
      });
    }
  }

  return requestHandler(type, resource, params);
};

export default addMapImageUploadCapabilities;
