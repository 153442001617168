import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { SimpleForm, TextInput } from 'react-admin';

import EventReferenceInput from '../events/EventReferenceInput';
import UserReferenceInput from '../users/UserReferenceInput';
import ResultCreateToolbar from './ResultCreateToolbar';


const ResultForm = ({ isExisting, ...props }) => {
  const { location } = props;
  const queryParams = queryString.parse(location.search);
  const defaultEventId = queryParams.event_id ? queryParams.event_id : null;
  const defaultUserId = queryParams.user_id ? queryParams.user_id : null;

  return (
    <SimpleForm
      {...props}
      toolbar={<ResultCreateToolbar eventId={defaultEventId} />}
      redirect={isExisting ? 'show' : 'list'}
    >
      {isExisting && <TextInput disabled source='id' />}

      {defaultEventId
        ? <TextInput disabled source='event_id' defaultValue={defaultEventId} />
        : <EventReferenceInput />
      }

      {defaultUserId
        ? <TextInput disabled source='user_id' defaultValue={defaultUserId} />
        : <UserReferenceInput />
      }
    </SimpleForm>
  );
};


ResultForm.propTypes = {
  isExisting: PropTypes.bool,
  location: PropTypes.object,
};

ResultForm.defaultProps = {
  isExisting: false,
  location: {},
};

export default ResultForm;
