import React from 'react';
import get from 'lodash/get';
import {
  DateField,
  EditButton,
  List,
  NumberField,
  Responsive,
  SelectField,
  ShowButton,
  SimpleList,
  TextField,
} from 'react-admin';
import Datagrid from 'ra-customizable-datagrid';

import CopyIdButton from 'components/CopyIdButton';
import BooleanField from '../fields/VerbalBooleanField';
import LocationFilter from './LocationFilter';
import { districts, cities, countries, timezones } from './constants';
import LocationListActions from './LocationListActions';

const LocationList = (props) => (
  <List
    title='Locations List'
    sort={{ field: 'name', order: 'ASC' }}
    perPage={40}
    filters={<LocationFilter />}
    actions={<LocationListActions />}
    bulkActions={false}
    bulkActionButtons={false}
    filterDefaultValues={{ tier: 10 }}
    {...props}
  >
    <Responsive
      small={
        <SimpleList
          primaryText={(record) => get(record, 'name')}
          secondaryText={(record) => get(record, 'is_published') === false && <span>Not published</span>}
          tertiaryText={(record) => new Date(get(record, 'created_at')).toLocaleDateString('de-DE')}
        />
      }
      medium={
        <Datagrid
          defaultColumns={[
            'name',
            'city_code',
            'district_code',
            'flags.automatic_cancellation',
            'is_published',
            'tier',
          ]}
        >
          <ShowButton />
          <CopyIdButton />

          <TextField source='name' />
          <TextField source='analytics_name' />

          <SelectField source='country_code' label='Country' choices={countries} allowEmpty resettable />
          <SelectField source='city_code' label='City' choices={cities} allowEmpty resettable />
          <SelectField source='district_code' label='District' choices={districts} allowEmpty resettable />
          <SelectField source='timezone' label='Timezone' choices={timezones} allowEmpty resettable />
          <TextField source='lat' />
          <TextField source='lon' />
          <TextField source='address.address1' label='Street & No.' />
          <TextField source='address.zip' label='Postal Code' />

          <BooleanField source='flags.automatic_cancellation' label='Autom. Cancellation' />
          <BooleanField source='is_published' label='Published' />
          <NumberField source='tier' label='Tier' />
          <DateField source='created_at' locales='de-DE' />

          <EditButton />
        </Datagrid>
      }
    />
  </List>
);

export default LocationList;
