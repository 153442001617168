import Archive from '@material-ui/icons/Archive';
import get from 'lodash/get';
import React from 'react';
import {
  DateField,
  EditButton,
  List,
  Responsive,
  ShowButton,
  SimpleList,
  TextField,
  BooleanInput,
  TextInput,
  BulkUpdateButton,
} from 'react-admin';

import Datagrid from '../Datagrid';
import BooleanField from '../fields/VerbalBooleanField';
import EventTypeListActions from './EventTypeListActions';

const EventTypeList = (props) => (
  <List
    {...props}
    sort={{ field: 'name', order: 'ASC' }}
    perPage={40}
    bulkActionButtons={
      <BulkUpdateButton
        {...props}
        label='Archive Selected'
        data={{ is_published: false, is_archived: true }}
        icon={<Archive />}
        mutationMode='pessimistic'
      />
    }
    actions={<EventTypeListActions />}
    filters={[
      <TextInput label='Search' source='name' alwaysOn />,
      <BooleanInput source='is_published' />,
      <BooleanInput source='has_structure' />,
    ]}
    filterDefaultValues={{ is_archived: false }}
  >
    <Responsive
      small={
        <SimpleList
          primaryText={(record) => get(record, 'name')}
          secondaryText={(record) => get(record, 'is_published') === false && <span>Not published</span>}
          tertiaryText={(record) => get(record, 'workout_category')}
        />
      }
      medium={
        <Datagrid defaultColumns={['name', 'is_published', 'event_type_structure_id']}>
          <TextField source='name' />
          <BooleanField source='is_published' label='Published' />
          <BooleanField strict={false} source='event_type_structure_id' label='Structure' />
          <BooleanField source='booking_options.requires_paying_customer' label='Is Chargeable' />
          <TextField source='workout_category' />
          <DateField source='created_at' locales='de-DE' />
          <EditButton />
          <ShowButton />
        </Datagrid>
      }
    />
  </List>
);

export default EventTypeList;
