import PropTypes from 'prop-types';
import React from 'react';

const EventTypeTitle = ({ record }) => <span>EventType {record ? `'${record.name}'` : ''}</span>;

EventTypeTitle.propTypes = {
  record: PropTypes.object,
};

EventTypeTitle.defaultProps = {
  record: {},
};

export default EventTypeTitle;
