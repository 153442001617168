import { get } from 'lodash';
import React from 'react';
import { ReferenceField } from 'react-admin';

import CopyIdButton from 'components/CopyIdButton';

import FullNameField from './FullNameField';

const TrainerReferenceField = (props) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <ReferenceField reference='trainers' {...props}>
        <FullNameField />
      </ReferenceField>
      <CopyIdButton id={get(props, 'record.coach_id')} />
    </div>
  );
};

TrainerReferenceField.defaultProps = {
  source: 'coach_id',
  addLabel: true,
  label: 'Coach',
  link: 'show',
  allowEmpty: true,
};

export default TrainerReferenceField;
