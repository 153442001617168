import TicketIcon from '@material-ui/icons/ConfirmationNumber';

import TicketCreate from './TicketCreate';
import TicketEdit from './TicketEdit';
import TicketFilter from './TicketFilter';
import TicketShow from './TicketShow';
import { NotFound } from 'react-admin';

export default {
  icon: TicketIcon,
  create: TicketCreate,
  edit: TicketEdit,
  show: TicketShow,
  list: () => <NotFound />
};

export { TicketIcon, TicketFilter };
