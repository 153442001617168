import React from 'react';
import map from 'lodash/map';
import {
  DateField,
  SelectField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  UrlField,
  ArrayField,
  Datagrid,
  Labeled,
  NumberField,
} from 'react-admin';

import { IdField } from 'components/IdField';
import { districts, cities, countries, timezones, languages, locationTypes } from './constants';
import LocationMapImageField from './LocationMapImageField';
import TextArrayField from '../fields/TextArrayField';
import TrainerReferenceField from '../trainers/TrainerReferenceField';
import SimpleArrayField from '../../components/SimpleArrayField';
import AmenitiesField from './AmenitiesField';
import VerbalBooleanField from '../fields/VerbalBooleanField';

const LocationShow = (props) => (
  <Show title='Location Detail View' {...props}>
    <TabbedShowLayout>
      <Tab label='Base'>
        <IdField id='id' />
        <VerbalBooleanField source='is_published' label='Published' />
        <NumberField source='tier' label='Tier' />
        <TrainerReferenceField label='Location Coach' />
        <TextField source='analytics_name' />

        <SelectField source='country_code' label='Country' choices={countries} allowEmpty />
        <SelectField source='city_code' label='City' choices={cities} allowEmpty />
        <SelectField source='district_code' label='District' choices={districts} allowEmpty />
        <SelectField source='timezone' label='Timezone' choices={timezones} allowEmpty resettable />

        <TextArrayField label='Coordinates (lat, long)' sources={['lat', 'lon']} />
        <TextArrayField label='Address' sources={['address.address1', 'address.zip']} />

        <SelectField source='type' label='Location Type' choices={locationTypes} />
        <Labeled label='Amenities'>
          <AmenitiesField />
        </Labeled>

        <DateField source='created_at' />
      </Tab>

      <Tab label='Details'>
        <TextField source='news' label='News' />
        <ArrayField source='links' label='Links'>
          <Datagrid>
            <TextField source='name' label='Name' />
            <UrlField source='url' label='URL' />
          </Datagrid>
        </ArrayField>

        <ArrayField source='codes' label='Codes'>
          <Datagrid>
            <TextField source='code' label='Item' />
            <TextField source='value' label='Code' />
          </Datagrid>
        </ArrayField>
        <ArrayField source='public_data' label='Public Data'>
          <Datagrid>
            <TextField source='name' label='Name' />
            <UrlField source='url' label='URL' />
          </Datagrid>
        </ArrayField>

        <SimpleArrayField source='rules' emptyText='No rules specified for this location.' />
      </Tab>

      <Tab label='Features'>
        <VerbalBooleanField source='flags.automatic_cancellation' label='Automatic Cancellation' />
      </Tab>

      <Tab path='map_image' label='Map Image'>
        <LocationMapImageField />
      </Tab>

      {map(languages, (language) => (
        <Tab key={language.code} path={`translation_${language.code}`} label={`${language.name} Translation`}>
          <TextField label='Name' source={`name_${language.code}`} />
          <TextField label='Description' source={`description_${language.code}`} />
          <TextField label='Short Description' source={`description_short_${language.code}`} />
          <TextField label='Address Description' source={`description_address_${language.code}`} />
        </Tab>
      ))}
    </TabbedShowLayout>
  </Show>
);

export default LocationShow;
