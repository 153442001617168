import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Progress from '@material-ui/core/LinearProgress';

import List from './List';
import Toolbar from './Toolbar';


class ResultsLeaderboard extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    items: PropTypes.array,
    interval: PropTypes.oneOf(['monthly', 'weekly']),
    year: PropTypes.number,
    month: PropTypes.number,
    week: PropTypes.number,
    onChangeInterval: PropTypes.func,
    onChangePeriod: PropTypes.func,
  }

  static defaultProps = {
    isLoading: false,
    items: [],
    interval: null,
    year: null,
    month: null,
    week: null,
    onChangeInterval: () => null,
    onChangePeriod: () => null,
  }

  render () {
    const { isLoading, items, interval, year, month, week,
      onChangeInterval, onChangePeriod } = this.props;

    return (
      <Card>
        <CardHeader
          title='Leaderboard'
          subtitle='Top 30 ranking of users by GRITPoints'
        />
        <CardContent>
          <div style={{ marginBottom: '1rem' }}>
            <Toolbar
              interval={interval}
              year={year}
              month={month}
              week={week}
              onChangeInterval={onChangeInterval}
              onChangePeriod={onChangePeriod}
            />
          </div>
          {isLoading
            ? <Progress mode='indeterminate' />
            : <List items={items} />
          }
        </CardContent>
      </Card>
    );
  }
}

export default ResultsLeaderboard;
