import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import onlyUpdateForKeys from 'recompose/onlyUpdateForKeys';
import CardActions from '@material-ui/core/CardActions';
import { sanitizeListRestProps } from 'ra-core';
import { CreateButton, RefreshButton } from 'react-admin';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';


const style = (theme) => ({
  verticalActions: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
  },
  advancedFilters: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    paddingTop: '0.5em',
  },
});

const Actions = ({
  bulkActions,
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter,
  filterValues,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  classes,
  // onFiltersChanged,
  ...rest
}) => (
  <CardActions
    className={classnames(className, classes.verticalActions)}
    disableActionSpacing
    {...sanitizeListRestProps(rest)}
  >
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
      {filters &&
            cloneElement(filters, {
              resource,
              showFilter,
              displayedFilters,
              filterValues,
              context: 'button',
            })}
      {hasCreate && <CreateButton basePath={basePath} />}
      {/*
          <ExportButton
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
          />
          */}
      <RefreshButton />
    </div>
  </CardActions>
);

Actions.propTypes = {
  bulkActions: PropTypes.node,
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  currentSort: PropTypes.object,
  displayedFilters: PropTypes.object,
  exporter: PropTypes.func,
  filters: PropTypes.element,
  filterValues: PropTypes.object,
  hasCreate: PropTypes.bool,
  resource: PropTypes.string,
  onUnselectItems: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  showFilter: PropTypes.func,
};

Actions.defaultProps = {
  selectedIds: [],
};

export default onlyUpdateForKeys([
  'resource',
  'filters',
  'displayedFilters',
  'filterValues',
  'selectedIds',
])(withStyles(style)(Actions));
