import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { Button, resolveRedirectTo } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import ActionListIcon from '@material-ui/icons/List';
import AppBar from '@material-ui/core/AppBar';
import ContentAddIcon from '@material-ui/icons/Add';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import UpcomingEvents from 'components/Dashboard/UpcomingEvents';
import PastEvents from 'components/Dashboard/PastEvents';

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  bar: {
    marginBottom: theme.spacing(2),
  },
  barTitle: {
    marginRight: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  leftCol: {
    flex: '1 1 auto',
    marginRight: theme.spacing(1),
  },
  rightCol: {
    flex: '1 1 auto',
    marginRight: theme.spacing(1),
  },
});

class EventsDashboardPage extends React.Component {
  state = {
    anchorEl: null,
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar className={classes.bar} position='static' color='default' elevation={1}>
          <Toolbar>
            <Typography className={classes.barTitle} variant='h6' color='inherit'>
              Events Dashboard
            </Typography>

            <Button color='inherit' label='List All' component={Link} to={resolveRedirectTo('list', 'events')}>
              <ActionListIcon />
            </Button>
            <Button color='inherit' label='Create' component={Link} to={resolveRedirectTo('create', 'events')}>
              <ContentAddIcon />
            </Button>
            <Button color='inherit' label='Event Types' component={Link} to={resolveRedirectTo('list', 'event-types')}>
              <ActionListIcon />
            </Button>

            <div className={classes.grow} />
          </Toolbar>
        </AppBar>

        <div className={classes.flex}>
          <div className={classes.leftCol}>
            <UpcomingEvents />
            <PastEvents />
          </div>
          <div className={classes.rightCol} />
        </div>
      </div>
    );
  }
}

EventsDashboardPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(withRouter, withStyles(styles));

export default enhance(EventsDashboardPage);
