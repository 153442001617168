import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import _map from 'lodash/map';
import _upperFirst from 'lodash/upperFirst';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Toolbar from '@material-ui/core/Toolbar';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';

import { cities, countries, districts } from '../../reactAdmin/locations/constants';
import { externalProviders } from '../../reactAdmin/users/constants';

const MIN_CYCLE = 1;
const MAX_CYCLE = 60;


const styles = {
  toolbar: {
    backgroundColor: '#fff',
    justifyContent: 'space-between',
  },
  formControl: {
    minWidth: 120,
    maxWidth: 300,
    margin: '0 10px',
  },
  dateField: {
    width: 200,
  },
};

class RetentionToolbar extends React.Component {
  static propTypes = {
    isLoading: PropTypes.boolean,
    startFrom: PropTypes.instanceOf(Date),
    interval: PropTypes.oneOf(['year', 'month', 'isoWeek', 'week', 'day']),
    cycles: PropTypes.number,
    coach: PropTypes.array,
    place: PropTypes.array,
    country: PropTypes.array,
    city: PropTypes.array,
    district: PropTypes.array,
    provider: PropTypes.string,
    attended: PropTypes.boolean,
    newCustomers: PropTypes.boolean,
    uniqueUsers: PropTypes.boolean,
    excludeTrial: PropTypes.boolean,
    coaches: PropTypes.array,
    places: PropTypes.array,
    onChangeInterval: PropTypes.func,
    onChangeStartFrom: PropTypes.func,
    onChangeCycles: PropTypes.func,
    onChangeCoach: PropTypes.func,
    onChangePlace: PropTypes.func,
    onChangeCountry: PropTypes.func,
    onChangeCity: PropTypes.func,
    onChangeDistrict: PropTypes.func,
    onChangeProvider: PropTypes.func,
    onChangeAttended: PropTypes.func,
    onChangeNewCustomers: PropTypes.func,
    onChangeUniqueUsers: PropTypes.func,
    onChangeExcludeTrial: PropTypes.func,
  }

  static defaultProps = {
    isLoading: false,
    startFrom: new Date(),
    interval: 'month',
    cycles: 1,
    coach: [],
    place: [],
    country: [],
    city: [],
    district: [],
    provider: null,
    attended: false,
    newCustomers: true,
    uniqueUsers: true,
    excludeTrial: false,
    coaches: [],
    places: [],
    onChangeInterval: () => null,
    onChangeStartFrom: () => null,
    onChangeCycles: () => null,
    onChangeCoach: () => null,
    onChangePlace: () => null,
    onChangeCountry: () => null,
    onChangeCity: () => null,
    onChangeDistrict: () => null,
    onChangeProvider: () => null,
    onChangeAttended: () => null,
    onChangeNewCustomers: () => null,
    onChangeUniqueUsers: () => null,
    onChangeExcludeTrial: () => null,
  }

  getIntervalName = () => {
    const { interval } = this.props;

    if (interval === 'isoWeek') {
      return `Weeks`;
    }

    return `${_upperFirst(interval)}s`;
  }

  createInputHandler = (listener) => (event) => {
    listener(event.target.value);
  }

  createCheckboxHandler = (listener) => (event) => {
    listener(event.target.checked);
  }

  createInvertedCheckboxHandler = (listener) => (event) => {
    listener(!event.target.checked);
  }

  handleChangeStartFrom = (event) => {
    const { onChangeStartFrom } = this.props;

    onChangeStartFrom(event.target.value);
  }

  handleChangeCycles = (event) => {
    const { onChangeCycles } = this.props;

    let value = event.target.value;

    if (value < MIN_CYCLE) {
      value = MIN_CYCLE;
    }

    if (value > MAX_CYCLE) {
      value = MAX_CYCLE;
    }

    onChangeCycles(value);
  }

  handleChangeYear = (event) => {
    const { startFrom, onChangeStartFrom } = this.props;

    const date = moment(startFrom).year(event.target.value).toDate();
    onChangeStartFrom(date);
  }

  handleChangeMonth = (event) => {
    const { startFrom, onChangeStartFrom } = this.props;

    const date = moment(startFrom).month(event.target.value).toDate();
    onChangeStartFrom(date);
  }

  render () {
    const { isLoading, interval, startFrom, coach, place, country, city, district, provider, attended, newCustomers, uniqueUsers, excludeTrial, cycles, coaches, places } = this.props;
    const { onChangeStartFrom, onChangeInterval, onChangeCoach, onChangePlace, onChangeCountry, onChangeCity, onChangeDistrict, onChangeProvider, onChangeAttended, onChangeNewCustomers, onChangeUniqueUsers, onChangeExcludeTrial } = this.props;
    const month = moment(startFrom).month();
    const year = moment(startFrom).year();

    return (
      <div>
        <Toolbar style={styles.toolbar}>
          <div firstChild>
            <Select value={interval} disabled={isLoading} onChange={this.createInputHandler(onChangeInterval)}>
              <MenuItem value='day'>Daily Retention</MenuItem>
              <MenuItem value='isoWeek'>Weekly Retention (Starting on Monday)</MenuItem>
              <MenuItem value='month'>Monthly Retention</MenuItem>
              <MenuItem value='year'>Anual Retention</MenuItem>
            </Select>
          </div>
          <div>
            {['day', 'week', 'isoWeek'].includes(interval) ? (
              <TextField
                type='date'
                disabled={isLoading}
                onChange={this.createInputHandler(onChangeStartFrom)}
                value={startFrom}
                className={styles.dateField}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  max: moment().format('YYYY-MM-DD'),
                }}
              />
            )
              : [
                interval === 'month' ? (
                  <Select value={month} disabled={isLoading} onChange={this.handleChangeMonth}>
                    <MenuItem value={0}>January</MenuItem>
                    <MenuItem value={1}>Februar</MenuItem>
                    <MenuItem value={2}>March</MenuItem>
                    <MenuItem value={3}>April</MenuItem>
                    <MenuItem value={4}>May</MenuItem>
                    <MenuItem value={5}>June</MenuItem>
                    <MenuItem value={6}>July</MenuItem>
                    <MenuItem value={7}>August</MenuItem>
                    <MenuItem value={8}>September</MenuItem>
                    <MenuItem value={9}>October</MenuItem>
                    <MenuItem value={10}>November</MenuItem>
                    <MenuItem value={11}>December</MenuItem>
                  </Select>
                ) : null,
                <Select value={year} disabled={isLoading} onChange={this.handleChangeYear}>
                  <MenuItem value={2017}>2017</MenuItem>
                  <MenuItem value={2018}>2018</MenuItem>
                  <MenuItem value={2019}>2019</MenuItem>
                </Select>,
              ]
            }

          </div>
          <div>
            <TextField
              label={this.getIntervalName()}
              value={cycles}
              onChange={this.handleChangeCycles}
              type='number'
              InputLabelProps={{
                shrink: true,
              }}
              margin='normal'
            />
          </div>
        </Toolbar>
        <Divider />
        <Toolbar style={styles.toolbar}>
          <div firstChild>
            <FormControlLabel
              control={
                <Checkbox
                  checked={excludeTrial}
                  disabled={isLoading}
                  onChange={this.createCheckboxHandler(onChangeExcludeTrial)}
                  style={styles.checkbox}
                />
              }
              label='Exclude trial'
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={newCustomers}
                  disabled={isLoading}
                  onChange={this.createCheckboxHandler(onChangeNewCustomers)}
                  style={styles.checkbox}
                />
              }
              label='Only new customers'
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={attended}
                  disabled={isLoading}
                  onChange={this.createCheckboxHandler(onChangeAttended)}
                  style={styles.checkbox}
                />
              }
              label='By attendance'
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={!uniqueUsers}
                  disabled={isLoading}
                  onChange={this.createInvertedCheckboxHandler(onChangeUniqueUsers)}
                  style={styles.checkbox}
                />
              }
              label='Total bookings'
            />
          </div>
          <div>
            <FormControl style={styles.formControl}>
              <InputLabel>Trainer</InputLabel>
              <Select value={coach} disabled={isLoading} multiple onChange={this.createInputHandler(onChangeCoach)}>
                {_map(coaches, (c) => (
                  <MenuItem value={c.id}>{`${c.forename} <${c.email}>`}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={styles.formControl}>
              <InputLabel>Place</InputLabel>
              <Select value={place} disabled={isLoading} multiple onChange={this.createInputHandler(onChangePlace)}>
                {_map(places, (p) => (
                  <MenuItem value={p.id}>{p.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={styles.formControl}>
              <InputLabel>Provider</InputLabel>
              <Select value={provider} disabled={isLoading} onChange={this.createInputHandler(onChangeProvider)}>
                <MenuItem value={null}>Provider</MenuItem>
                <MenuItem value='none'>Gritspot</MenuItem>
                {_map(externalProviders, (p) => (
                  <MenuItem value={p.id}>{p.formattedName}</MenuItem>
                ))}
              ))}
              </Select>
            </FormControl>
            <FormControl style={styles.formControl}>
              <InputLabel>Country</InputLabel>
              <Select value={country} disabled={isLoading} multiple onChange={this.createInputHandler(onChangeCountry)}>
                {_map(countries, (c) => (
                  <MenuItem value={c.id}>{c.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={styles.formControl}>
              <InputLabel>City</InputLabel>
              <Select value={city} disabled={isLoading} multiple onChange={this.createInputHandler(onChangeCity)}>
                {_map(cities, (c) => (
                  <MenuItem value={c.id}>{c.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={styles.formControl}>
              <InputLabel>District</InputLabel>
              <Select value={district} disabled={isLoading} multiple onChange={this.createInputHandler(onChangeDistrict)}>
                {_map(districts, (d) => (
                  <MenuItem value={d.id}>{d.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Toolbar>
      </div>
    );
  }
}

export default RetentionToolbar;
