import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles';

class Totals extends React.PureComponent {
  render() {
    const { average, best, icon, id, title, total } = this.props;

    return (
      <div style={styles.section}>
        <div style={styles.mainTotal}>
          <img src={icon} alt={id} style={styles.icon} />

          <div>
            <h1 style={styles.text.h1}>Total {title}</h1>
            <div style={styles.text.bigTotal}>{total}</div>
          </div>
        </div>

        <div style={styles.sideTotals}>
          <div style={styles.text.light}>{id === 'workout' ? 'Tracked with sensor' : 'Workout Average'}</div>
          <div style={{ ...styles.text.total, ...styles.mb }}><b>{average}</b> {title}</div>

          <div style={styles.text.light}>{id === 'workout' ? 'Last Week' : 'Best Workout'}</div>
          <div style={styles.text.total}><b>{best}</b> {title}</div>
        </div>

      </div>
    );
  }
}

Totals.propTypes = {
  average: PropTypes.number.isRequired,
  best: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
};


export default Totals;
