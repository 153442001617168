import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';

const WeeklyStatsLinkField = () => {
  const record = useRecordContext();

  if (!record) return null;

  const ReportsLink = (props) => (
    <Link
      to={{
        pathname: `/reports/user_workout_stats/${record.id}/week`,
        search: `?result_id=${record.id}`,
      }}
      {...props}
    />
  );

  return (
    <Button variant='outlined' component={ReportsLink}>
      Weekly Stats
    </Button>
  );
};

WeeklyStatsLinkField.propTypes = {
  record: PropTypes.object,
  label: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  addLabel: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
};

WeeklyStatsLinkField.defaultProps = {
  addLabel: false,
  label: 'Links',
  record: null,
};

export default WeeklyStatsLinkField;
