import React from 'react';
import { Show, Tab, TabbedShowLayout } from 'react-admin';

import EmailListField from '../../tickets/inputs/EmailListField';
import EventShowActions from './EventShowActions';
import TabBookings from './TabBookings';
import TabOffers from './TabOffers';
import TabOverview from './TabOverview';
import TabResults from './TabResults';
import TicketHRDataReferenceManyField from './TicketHRDataReferenceManyField';
import TicketReferenceManyField from './TicketReferenceManyField';

function EventShow(props) {
  return (
    <Show title='Event Detail View' actions={<EventShowActions />} {...props}>
      <TabbedShowLayout>
        <TabOverview />
        <TabOffers eventId={props.id} />
        <TabBookings />
        <TabResults />
        <Tab label='HR Data'>
          <TicketHRDataReferenceManyField perPage={150} />
        </Tab>
        <Tab label='All Tickets'>
          <TicketReferenceManyField perPage={150} />
          <EmailListField perPage={100} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

export default EventShow;
