import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  fetchEnd,
  fetchStart,
  showNotification,
  GET_LIST,
  REDUX_FORM_NAME,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchIcon from '@material-ui/icons/Search';

import { restClient as dataProvider } from 'client';
import formattedFullNameEmail from './utils/formattedFullNameEmail';
import withReactFinalForm from 'components/withReactFinalForm'


const style = (theme) => ({
  filters: {
    marginTop: '1em',
    marginBottom: '1em',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1em',
    margin: '1em',
  },
});

class UserQuickSelectButton extends Component {
  constructor (props) {
    const defaultUserFilter = {
    };

    super(props);

    this.state = {
      showDialog: false,
      selectedUserId: null,
      users: null,
      selectedDate: null,
      filter: defaultUserFilter,
    };
  }

  queryUsers = async () => {
    const { filter } = this.state;
    const { fetchStart, fetchEnd, showNotification } = this.props;
    let users = null;

    if (!filter) {
      return;
    }

    const fetchOptions = {
      filter,
      pagination: { page: 1, perPage: 15 },
    };

    this.setState({ users: [], isLoading: true });

    fetchStart();

    try {
      users = await dataProvider(GET_LIST, 'users', fetchOptions).then((response) => response.data);
    } catch (error) {
      showNotification('Could not fetch users', 'error');
    }

    fetchEnd();

    this.setState({ users, isLoading: false });
  }

  clearUsers = () => {
    this.setState({ users: [], isLoading: false });
  }

  handleClickOpen = () => {
    this.setState({ showDialog: true });
  };

  handleClose = () => {
    this.setState({ showDialog: false });
  };

  handleSave = () => {
    const { reactFinalForm, formType, showNotification } = this.props;
    const { selectedUserId } = this.state;

    if (!selectedUserId) {
      showNotification('No User selected', 'warning');
      return;
    }

    const formName = formType === 'filter' ? 'filterForm' : REDUX_FORM_NAME;

    reactFinalForm.change(formName, 'user_id', selectedUserId);
    this.handleClose();
  }

  handleQStringChange = (ev) => {
    const q = ev.target.value;

    this.setState((state) => ({
      filter: { ...state.filter, q },
    }), () => {
      if (q && q.length >= 3) {
        this.queryUsers();
      } else if (q && q.length < 3) {
        this.clearUsers();
      }
    });
  };

  handleChangeSelectedUser = (ev) => {
    this.setState({ selectedUserId: ev.target.value });
  }

  renderLoading = () => (
    <div className={this.props.classes.loadingContainer}>
      <CircularProgress />
    </div>
  )

  render () {
    const { classes } = this.props;
    const { filter, users, selectedUserId, showDialog, isLoading } = this.state;
    const q = (filter && filter.q) || null;

    return (
      <Fragment>
        <Button onClick={this.handleClickOpen}>
          <SearchIcon /> Find User
        </Button>
        <Dialog
          open={showDialog}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>Find User</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Specify filter params to show a list of matching users.
            </DialogContentText>

            <div className={classes.filters}>
              <TextField
                label='Search'
                helperText='Searches first name, last name and email address.'
                value={q}
                onChange={this.handleQStringChange}
              />
            </div>

            {isLoading && this.renderLoading()}

            <RadioGroup
              ref={ref => {
                this.radioGroupRef = ref;
              }}
              aria-label='User'
              name='user'
              value={selectedUserId}
              onChange={this.handleChangeSelectedUser}
            >
              {users && users.map((user) => (
                <FormControlLabel value={user.id} key={user.id} control={<Radio />} label={formattedFullNameEmail(user)} />
              ))}
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color='primary'>
              Cancel
            </Button>
            <Button onClick={this.handleSave} disabled={!selectedUserId} color='primary'>
              Select
            </Button>
          </DialogActions>
        </Dialog>

      </Fragment>
    );
  }
}

UserQuickSelectButton.propTypes = {
  showNotification: PropTypes.func,
};
const mapDispatchToProps = {
  fetchEnd,
  fetchStart,
  showNotification,
};

export default connect(null, mapDispatchToProps)(withStyles(style)(withReactFinalForm(UserQuickSelectButton)));
