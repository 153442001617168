import React from 'react';
import PropTypes from 'prop-types';


const styles = {
  text: {
    color: 'rgba(0, 0, 0, 0.4)',
  },
  notSet: {
    fontStyle: 'italic',
    color: 'orange',
  },
};

const EmailTextField = ({ record, source }) => (
  <span style={styles.text}>
    {record[source]
      ? <span>{record[source]}</span>
      : <span style={styles.notSet}>NO EMAIL</span>}
    <span>,&nbsp;</span>
  </span>
);

EmailTextField.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object,
};

EmailTextField.defaultProps = {
  source: 'email',
  record: {},
};

export default EmailTextField;
