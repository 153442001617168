import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import { Admin, Resource } from 'react-admin';

import { authProvider, restProvider } from './client';
import LoginPage from './components/LoginPage/index';
import Dashboard from './containers/Dashboard';
import audioFiles from './reactAdmin/audio-files';
import audioPlayerReducers from './reactAdmin/audio-player/reducers';
import audioSets from './reactAdmin/audio-sets';
import eventTypeStructureFormReducers from './reactAdmin/event-type-structures/reducers';
import eventTypes from './reactAdmin/event-types';
import events from './reactAdmin/events';
import Layout from './reactAdmin/Layout';
import locations from './reactAdmin/locations';
import results from './reactAdmin/results';
import sensorPools from './reactAdmin/sensor_pools';
import sensors from './reactAdmin/sensors';
import tickets from './reactAdmin/tickets';
import trainers from './reactAdmin/trainers';
import userNotes from './reactAdmin/user_notes';
import users from './reactAdmin/users';
import customRoutes from './routes';
import theme from './theme';

const App = () => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <Admin
      title='Gritspot Admin'
      locale='en-GB'
      theme={theme}
      layout={Layout}
      dashboard={Dashboard}
      loginPage={LoginPage}
      authProvider={authProvider}
      dataProvider={restProvider}
      customRoutes={customRoutes}
      customReducers={{
        'event-type-structure-form-errors': eventTypeStructureFormReducers,
        'audio-player': audioPlayerReducers,
      }}
    >
      <Resource name='users' {...users} />
      <Resource name='user_notes' {...userNotes} />
      <Resource name='events' {...events} />
      <Resource name='event-types' options={{ label: 'Event Types' }} {...eventTypes} />
      <Resource name='tickets' {...tickets} />
      <Resource name='results' {...results} />
      <Resource name='sensors' {...sensors} />
      <Resource name='sensor_pools' {...sensorPools} />
      <Resource name='trainers' options={{ label: 'Coaches' }} {...trainers} />
      <Resource name='locations' {...locations} />
      <Resource name='offers' />
      <Resource name='event-type-structures' />
      <Resource name='assets' />
      <Resource name='asset-sets' />
      <Resource name='audio-files' {...audioFiles} />
      <Resource name='audio-sets' {...audioSets} />
    </Admin>
  </MuiPickersUtilsProvider>
);

export default App;
