import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import RaisedButton from '@material-ui/core/Button';

import { brand } from 'theme/colors';
import styles from './styles';


const List = ({ users, buttonLabel, disabled, onCheckIn, elStyle, loading }) => (
  <div style={elStyle}>
    <div style={styles.wrapper}>

      {loading &&
        <div style={styles.overlay}>
          <CircularProgress color={brand} size={80} />
        </div>}

      <div style={styles.listWrapper}>
        {users.map((user, i) => {
          let rowStyle = Object.assign({}, styles.row);

          if (i % 2 === 0) {
            rowStyle = Object.assign(rowStyle, styles.even);
          } else {
            rowStyle = Object.assign(rowStyle, styles.odd);
          }

          return (
            <div key={user.id} style={rowStyle}>
              <Avatar src={user.avatar} size={30} style={styles.avatar} />
              <div>
                <span style={styles.username}>{user.name}</span>
                {user.email && <span>({ user.email })</span>}
              </div>
              <RaisedButton
                style={styles.buttonContainer}
                buttonStyle={disabled ? styles.buttonDisabled : styles.button}
                onClick={() => onCheckIn(user.id)}
                disabled={disabled}
              >{buttonLabel}
              </RaisedButton>
            </div>
          );
        })}
      </div>
    </div>
  </div>
);

List.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onCheckIn: PropTypes.func,
  users: PropTypes.array.isRequired,
  elStyle: PropTypes.object.isRequired,
};

List.defaultProps = {
  disabled: false,
  loading: false,
  onCheckIn: (() => {}),
};

export default List;

/* <div style={rowStyle}>
  <Avatar src={user.avatar} size={30} style={styles.avatar} />
  <div>
    <span style={styles.username}>{user.name}</span>
    {user.email && <span>({ user.email })</span>}
  </div>
  <RaisedButton
    style={styles.buttonContainer}
    buttonStyle={disabled ? styles.buttonDisabled : styles.button}
    onClick={() => onCheckIn(user.id)}
    disabled={disabled}
  >
    {user.isLoading
      ? <CircularProgress color="white" size={10} innerStyle={styles.progress} />
      : buttonLabel
    }
  </RaisedButton>
</div> */
