import EventTypeIcon from '@material-ui/icons/Event';
// import EventTypeIcon from '@material-ui/core/svg-icons/social/group';
import EventTypeTitle from './EventTypeTitle';
import EventTypeShow from './EventTypeShow';
import EventTypeList from './EventTypeList';
// import EventTypeFilter from './EventTypeFilter';
import EventTypeEdit from './EventTypeEdit';
import EventTypeCreate from './EventTypeCreate';
import NameField from './NameField';


export default {
  icon: EventTypeIcon,
  list: EventTypeList,
  show: EventTypeShow,
  create: EventTypeCreate,
  edit: EventTypeEdit,
};

export {
  EventTypeIcon,
  EventTypeTitle,
  // EventTypeFilter,
  NameField,
};
