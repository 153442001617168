import React from 'react';

import AudioFileField from './AudioFileField';

const AudioSetAssetsField = (props) => (
  <>
    {props.record &&
      props.record.assets.map((asset, index) => (
        <AudioFileField
          key={`${asset.asset_id}${index}`}
          assetId={asset.asset_id}
          assignmentId={asset.assignment_id}
          props={props}
        />
      ))}
  </>
);

export default AudioSetAssetsField;
