import PropTypes from 'prop-types';
import React from 'react';
import { AutocompleteInput } from 'react-admin';

import { providers } from '../constants';

const onCreate = (value) => {
  const newProvider = value && { id: value.toLowerCase(), name: value };
  providers.push(newProvider);
  return newProvider;
};

const ProviderSelectInput = (props) => {
  return <AutocompleteInput choices={providers} onCreate={onCreate} {...props} />;
};

ProviderSelectInput.propTypes = {
  source: PropTypes.string,
  allowEmpty: PropTypes.bool,
  resettable: PropTypes.bool,
};

ProviderSelectInput.defaultProps = {
  source: 'provider',
  resettable: true,
};

export default ProviderSelectInput;
