import React from 'react';
import { Filter, NullableBooleanInput, NumberInput, TextInput } from 'react-admin';
import AutocompleteArrayInput from 'reactAdmin/inputs/AutocompleteArrayInput';

import { districts, cities, countries, locationTypes } from './constants';

const LocationFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Name' source='q' alwaysOn />
    <NullableBooleanInput source='is_published' label='Published' alwaysOn />
    <AutocompleteArrayInput
      source='district_code'
      label='District'
      choices={districts}
      optionText='fullName'
      alwaysOn
    />
    <AutocompleteArrayInput source='city_code' label='City' choices={cities} alwaysOn />
    <AutocompleteArrayInput source='country_code' label='Country' choices={countries} alwaysOn />
    <AutocompleteArrayInput source='type' label='Location Type' choices={locationTypes} alwaysOn />
    <NumberInput source='tier' label='Tier' alwaysOn min={1} max={10} />
  </Filter>
);

export default LocationFilter;
