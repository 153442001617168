import moment from 'moment-timezone';

const colorDanger = '#fdd';
const colorOkay = '#dfd';

const rowStyle = (record) => {
  if (!record && !record.payed_until) return {};

  const payedUntil = moment(record.payed_until);

  if (payedUntil.isBefore(moment())) return { backgroundColor: colorDanger };
  if (payedUntil.isAfter(moment())) return { backgroundColor: colorOkay };

  return {};
};

export default rowStyle;
