/* eslint-disable react/sort-comp */
import React from 'react';
import { withRouter } from 'react-router';

import CRRCohortChart from 'containers/CRRCohortChart';

const RetentionCohort = () => (
  <CRRCohortChart />
);

export default withRouter(RetentionCohort);
