import React from 'react';
import {Create} from 'react-admin';
import FileUpload from '../../components/FileUpload';

const AudioCreate = (props) =>
  <Create {...props}>
    <FileUpload />
  </Create>

export default AudioCreate;
