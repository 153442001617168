import {
  PLAY_AUDIO_FILE,
  STOP_AUDIO_FILE,
} from './actions';

export default (previousState = { playingFile: {} }, { type, payload }) => {
  if (type === PLAY_AUDIO_FILE) {
    return {
      ...previousState,
      playingFile: payload.data,
    };
  }

  if (type === STOP_AUDIO_FILE) {
    return {
      ...previousState,
      playingFile: {},
    };
  }

  return previousState;
};
