import ResultIcon from '@material-ui/icons/Assessment';
import ResultCreate from './ResultCreate';
import ResultEdit from './ResultEdit';
import ResultFilter from './ResultFilter';
import ResultList from './ResultList';
import ResultShow from './ResultShow';
import ResultTitle from './ResultTitle';

export default {
  icon: ResultIcon,
  list: ResultList,
  show: ResultShow,
  create: ResultCreate,
  edit: ResultEdit,
};

export {
  ResultIcon,
  ResultFilter,
  ResultTitle,
};
