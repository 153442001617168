import AudioSetList from './AudioSetList';
import AudioSetShow from './AudioSetShow';
import AudioSetEdit from './AudioSetEdit';
import AudioSetCreate from './AudioSetCreate';


export default {
  list: AudioSetList,
  show: AudioSetShow,
  create: AudioSetCreate,
  edit: AudioSetEdit,
};
