import React from 'react';
import { Edit } from 'react-admin';

import Form from './SensorPoolForm';


const SensorPoolEdit = (props) => (
  <Edit title='Edit Sensor Pool' {...props} undoable={false} >
    <Form isExisting />
  </Edit>
);


export default SensorPoolEdit;
