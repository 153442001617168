/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';


import AttendanceButton from './AttendanceButton';

const AttendanceActionsField = ({ record, source }) => <AttendanceButton record={record} source={source} />;

AttendanceActionsField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
  sortable: PropTypes.bool,
};

AttendanceActionsField.defaultProps = {
  label: 'Set Attendance',
  record: {},
  source: '_attendance_actions',
  sortable: true,
};

export default AttendanceActionsField;
