import UserIcon from '@material-ui/icons/Person';

import EmailField from './EmailField';
import UserCreate from './UserCreate';
import UserEdit from './UserEdit';
import UserFilter from './UserFilter';
import UserList from './UserList';
import UserShow from './UserShow';
import UserTitle from './UserTitle';
import formattedFullName from './utils/formattedFullName';
import formattedFullNameEmail from './utils/formattedFullNameEmail';
import profilePictureUrl from './utils/profilePictureUrl';

export default {
  icon: UserIcon,
  list: UserList,
  show: UserShow,
  create: UserCreate,
  edit: UserEdit,
};

export { UserIcon, UserFilter, UserTitle, EmailField, profilePictureUrl, formattedFullName, formattedFullNameEmail };
