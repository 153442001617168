import get from 'lodash/get';
import React from 'react';
import { Edit, SimpleForm, TextInput, FormDataConsumer, required, FunctionField, DateField } from 'react-admin';
import secondsToHMS from 'utils/secondsToHMS';

import AssetCategoryField from '../../components/FileUpload/Audio/AssetCategoryField';
import AssetGenreField from '../../components/FileUpload/Audio/AssetGenreField';
import AssetLanguageField from '../../components/FileUpload/Audio/AssetLanguageField';
import AssetFileField from '../fields/AssetFileField';

export const renderDuration = (record) => {
  if (!record) return '';
  const HMS = secondsToHMS(record.metadata.duration);
  return `${HMS.hh}:${HMS.mm}:${HMS.ss}`;
};

const AudioEdit = (props) => {
  return (
    <Edit title='Edit audio file' {...props}>
      <SimpleForm {...props}>
        <AssetFileField source='file' label='File' />
        <TextInput source='title' label='Audio name' />
        <AssetCategoryField />
        <FormDataConsumer>
          {({ formData }) => {
            const category = get(formData, 'metadata.category');
            return <AssetGenreField validate={category === 'music' && required()} />;
          }}
        </FormDataConsumer>
        <AssetLanguageField />
        <FunctionField label='duration' render={(record) => renderDuration(record)} />
        <DateField source='created_at' label='Created at' locales='de-DE' />
        <DateField source='updated_at' label='Last updated at' locales='de-DE' />
      </SimpleForm>
    </Edit>
  );
};

export default AudioEdit;
