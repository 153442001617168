import React from 'react';
import { AutocompleteArrayInput as ReactAdminAutocompleteArrayInput } from 'react-admin';

const AutocompleteArrayInput = ({ alwaysOn, allowEmpty, choices, ...props }) => {
  return <ReactAdminAutocompleteArrayInput alwaysOn={alwaysOn} allowEmpty={allowEmpty} choices={choices} {...props} />;
};

AutocompleteArrayInput.defaultProps = {
  alwaysOn: false,
  allowEmpty: false,
  resettable: false,
};

export default AutocompleteArrayInput;
