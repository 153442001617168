import React from 'react';
import { useUpdate, useNotify, useRedirect, SaveButton } from 'react-admin';
import objectDifference from 'utils/objectDifference';

const SaveRecurrentButton = ({ basePath, handleSubmit, handleSubmitWithRedirect, formData, record, ...props }) => {
  const redirect = useRedirect();
  const [update] = useUpdate();
  const notify = useNotify();

  const saveThisAndFollowing = async () => {
    const onlyUpdatedValues = objectDifference(formData, record);
    try {
      await update('events', formData.id, { ...onlyUpdatedValues, apply_to_following_events: true });
      redirect('show', '/events', formData.id);
    } catch (error) {
      notify(`Error: ${error.message}`, 'warning');
    }
  };

  return <SaveButton handleSubmitWithRedirect={saveThisAndFollowing} {...props} />;
};

export default SaveRecurrentButton;
