import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/MoreHoriz';
import EventCreate from 'reactAdmin/events/EventCreate';

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  bar: {
    marginBottom: theme.spacing(2),
  },
  barTitle: {
    marginRight: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  leftCol: {
    flex: '1 1 auto',
    marginRight: theme.spacing(1),
  },
  rightCol: {
    flex: '1 1 auto',
    marginRight: theme.spacing(1),
  },
});

class EventsDashboardPage extends React.Component {
  state = {
    anchorEl: null,
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div className={classes.root}>
        <AppBar className={classes.bar} position='static' color='default' elevation={1}>
          <Toolbar>
            <Typography className={classes.barTitle} variant='h6' color='inherit'>
              Create Recurring Event
            </Typography>

            <div className={classes.grow} />

            <div>
              <IconButton
                aria-owns={open ? 'menu-appbar' : null}
                aria-haspopup='true'
                onClick={this.handleMenu}
                color='inherit'
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id='menu-appbar'
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={this.handleClose}
              >
                <MenuItem onClick={this.handleClose}>Action 1</MenuItem>
                <MenuItem onClick={this.handleClose}>Action 2</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>

        <div className={classes.flex}>
          <div className={classes.rightCol}>
            <EventCreate />
          </div>
        </div>
      </div>
    );
  }
}

EventsDashboardPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(withRouter, withStyles(styles));

export default enhance(EventsDashboardPage);
