import React from 'react';
import {
  Datagrid,
  DateField,
  EditButton,
  NumberField,
  ReferenceManyField,
  Responsive,
  SimpleList,
  Tab,
} from 'react-admin';

import SecondsAsHhMmSsField from '../../fields/SecondsAsHhMmSsField';
import EmbeddedUserField from '../../users/EmbeddedUserField';
import SensorField from '../../results/SensorField';
import SimpleResultItem from '../../results/SimpleResultItem';
import DailyStatsButton from '../../results/DailyStatsButton';


const TabResults = (props) => (
  <Tab label='Results' {...props}>
    <ReferenceManyField
      reference='results'
      target='event_id'
      label='Results'
      perPage={50}
      sort={{ field: 'gritpoints', order: 'DESC' }}
    >
      <Responsive
        small={<SimpleList primaryText={(record) => <SimpleResultItem record={record} />} />}
        medium={
          <Datagrid>
            <EmbeddedUserField source='user_id' label='User' showAvatar={false} />

            <NumberField label='GPs' source='gritpoints' />
            <NumberField source='calories_burned' label='Cals' />
            <NumberField source='calories_epoc' label='Cals EPOC' />
            <SecondsAsHhMmSsField label='Time in Zone 4' source='time_in_zone4' />
            <SecondsAsHhMmSsField label='Time in Zone 3' source='time_in_zone3' />
            <SecondsAsHhMmSsField label='Time in Zone 2' source='time_in_zone2' />
            <SecondsAsHhMmSsField label='Time in Zone 1' source='time_in_zone1' />

            <SensorField sortable={false} />
            <NumberField source='measurement_stats.number_raw_readings' label='# Readings' />

            <DateField source='recorded_at' showTime locales='de-DE' />

            <DailyStatsButton />

            <EditButton />
          </Datagrid>
        }
      />
    </ReferenceManyField>
  </Tab>
);

export default TabResults;
