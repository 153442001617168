import { get } from 'lodash';
import React from 'react';
import { ReferenceField } from 'react-admin';

const AssetNameField = ({ record }) => {
  return <span>{get(record, 'name')}</span>;
};

const AudioSetsReferenceField = (props) => {
  return (
    <ReferenceField reference='audio-sets' source={props.source} label={props.label} {...props}>
      <AssetNameField />
    </ReferenceField>
  );
};

AudioSetsReferenceField.defaultProps = {
  addLabel: true,
  label: 'Music Asset Set',
  link: 'show',
  allowEmpty: true,
};

export default AudioSetsReferenceField;
