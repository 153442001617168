import React from 'react';
import { get } from 'lodash';
import { Button, Typography, Grid, Card } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import AvatarField from '../../reactAdmin/users/AvatarField';
import AccountInfo from './AccountInfo';

const useStyles = makeStyles({
  dialogtitle: {
    display: 'flex',
    alignItems: 'space-between',
    direction: 'row',
    padding: '24px 24px',
    fontSize: '16px',
  },
  dialogActions: {
    display: 'flex',
    alignItems: 'space-between',
    direction: 'row',
    padding: '24px',
  },
  dialogClose: {
    position: 'absolute',
    top: '24px',
    right: '24px',
    cursor: 'pointer',
  },
  dialog: {
    padding: '24px',
  },
  dialogCard: {
    padding: '16px',
    zIndex: '1',
    boxShadow: 'unset',
    borderRadius: '8px',
  },
  dialogCardDisabled: {
    border: (props) => `2px solid ${props.palette.grey[200]}`,
  },
  floatingLabel: {
    position: 'absolute',
    zIndex: 1,
    top: '0',
    backgroundColor: (props) => `${props.palette.grey[200]}`,
    borderRadius: '4px',
    fontSize: '12px',
    padding: '0px 4px',
  },
  seperator: {
    backgroundColor: (props) => `${props.palette.primary.main}`,
    width: '2px',
    height: '50px',
    alignSelf: 'center',
    marginBottom: '-12px',
  },
  dialogCardActive: {
    border: (props) => `2px solid ${props.palette.primary.main}`,
  },
  timelineArrow: {
    color: (props) => `${props.palette.primary.main}`,
  },
  dialogContent: {
    position: 'relative',
  },
  seperatorLabel: {
    position: 'absolute',
    backgroundColor: (props) => `${props.palette.primary.light}`,
    zIndex: '5',
    padding: '0px 4px',
    fontSize: '6px',
  },
});

const MergeAccountModal = ({ toggleModal, open, sourceUserRecord, destinationUserRecord, handleMergeAccount }) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  return (
    <>
      <Dialog className={styles.dialog} open={open} onClose={() => toggleModal(false)}>
        <DialogTitle className={styles.dialogtitle}>
          <Typography component='h5' variant=''>
            Merge Accounts?
          </Typography>
          <CloseIcon className={styles.dialogClose} onClick={() => toggleModal(false)} variant='contained' />
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <Grid direction='column'>
            <Grid item>
              <Card className={[styles.dialogCard, styles.dialogCardDisabled].join(' ')}>
                <div className={styles.floatingLabel}>
                  <Typography variant='caption' color='textSecondary'>
                    Will be deleted
                  </Typography>
                </div>
                <Grid item container direction='row' spacing={1} flex={1}>
                  <Grid item justifyContent='center'>
                    <AvatarField record={sourceUserRecord} size={40} />
                  </Grid>
                  <Grid item style={{ flex: 1 }}>
                    <AccountInfo record={sourceUserRecord} />
                  </Grid>
                </Grid>
              </Card>
              <Grid container direction='column'>
                <Grid item container justifyContent='center' alignItems='center' direction='column'>
                  <div className={styles.seperator} />
                  <div className={styles.seperatorLabel}>
                    <Typography color='primary' component='p'>
                      Add workouts To:
                    </Typography>
                  </div>
                  <ArrowDownwardIcon className={styles.timelineArrow} size={8} color={theme.palette.primary.main} />
                </Grid>
              </Grid>
              <Card className={[styles.dialogCard, styles.dialogCardActive].join(' ')}>
                <Grid item container direction='row' justifyContent='flex-start' spacing={1}>
                  <Grid item justifyContent='center'>
                    <AvatarField record={destinationUserRecord} size={40} />
                  </Grid>
                  <Grid item>
                    <Grid direction='column'>
                      <Typography component='h1' color='textPrimary'>
                        {get(destinationUserRecord, 'forename')}
                      </Typography>
                      <Typography component='h1' color='textSecondary'>
                        {get(destinationUserRecord, 'email')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Typography component='h1' color='textSecondary'>
            Merge Account is irreversible
          </Typography>
          <Button variant='contained' color='primary' onClick={handleMergeAccount}>
            <Typography>Confirm Merge</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MergeAccountModal;
