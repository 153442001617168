import React from 'react';
import { bool } from 'prop-types';
import get from 'lodash/get';
import includes from 'lodash/includes';
import {
  BooleanInput,
  FormDataConsumer,
  FormTab,
  ImageField,
  ImageInput,
  SelectArrayInput,
  SelectInput,
  TabbedForm,
  TextInput,
  NumberInput,
} from 'react-admin';

import { citiesMap, countries as countryChoices, districtsMap, locationTypes } from 'reactAdmin/locations/constants';
import { validateCoach } from 'utils/validateInputs';
import { languages as languageChoices } from './constants';
import UserReferenceInput from '../users/UserReferenceInput';
import TrainerProfilePictureField from './TrainerProfilePictureField';
import { genres } from '../audio-files/constants';

const defaultValues = { offers_individual_sessions: true };

function getCitiesFor(countryCode) {
  return get(citiesMap, countryCode, []);
}
function getDistrictsFor(countryCode, cityCode) {
  return get(districtsMap, `${countryCode}.${cityCode}`, []);
}

const TrainerForm = ({ isExisting, ...props }) => (
  <TabbedForm {...props} defaultValue={defaultValues} redirect={isExisting ? 'show' : 'list'}>
    <FormTab label='basic'>
      {isExisting && <TextInput disabled source='id' />}
      <BooleanInput source='is_published' label='Published' />
      <BooleanInput source='trial' label='Trial' />
      <UserReferenceInput />
      <TextInput source='forename' validate={validateCoach.name} />
      <TextInput source='surname' validate={validateCoach.name} />
      <TextInput source='email' validate={validateCoach.email} />
      <TextInput source='whatsapp_group_url' />
      <BooleanInput source='offers_individual_sessions' />
    </FormTab>

    {isExisting && (
      <FormTab path='profile_picture' label='profile picture'>
        <TrainerProfilePictureField size={128} />
        <ImageInput source='profile_image_virtual' label='Upload/Replace Profile Picture' accept='image/*'>
          <ImageField source='src' title='title' />
        </ImageInput>
      </FormTab>
    )}

    <FormTab path='details' label='details'>
      <SelectArrayInput source='languages' choices={languageChoices} validate={validateCoach.languages} />
      {isExisting && <NumberInput source='tier' min={1} validate={validateCoach.tier} />}
      <TextInput source='website' />
      <TextInput source='phone_numbers.mobile' label='Phone Mobile' validate={validateCoach.phone} />

      <TextInput source='facebook_url' />
      <TextInput source='twitter_url' />
      <TextInput source='instagram_url' />
      <TextInput source='intro_video_youtube_url' label='Intro Video (Youtube URL)' />

      <TextInput multiline source='about' />
      <TextInput source='tagline' />
      <TextInput source='quote' />
      <TextInput source='about_gym' />
      <TextInput source='about_music' />
      <TextInput source='about_pleasure' />
    </FormTab>

    <FormTab path='location' label='location'>
      <SelectInput
        source='homezone_country_code'
        label='Country'
        choices={countryChoices}
        validate={validateCoach.country}
      />
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <SelectInput
            source='homezone_city_code'
            label='City'
            choices={getCitiesFor(formData.homezone_country_code)}
            allowEmpty
            validate={validateCoach.city}
            {...rest}
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <SelectInput
            label='District'
            source='homezone_district_code'
            choices={getDistrictsFor(formData.homezone_country_code, formData.homezone_city_code)}
            allowEmpty
            {...rest}
          />
        )}
      </FormDataConsumer>
      <TextInput label='Zipcode' source='homezone_zipcode' />
      <SelectArrayInput
        label='Location Types'
        source='preferred_location_types'
        choices={locationTypes}
        validate={validateCoach.locationTypes}
      />
    </FormTab>

    <FormTab path='music' label='music'>
      <SelectArrayInput label='Music genres' source='preferred_music_genres' choices={genres} />
      {includes(props.record?.preferred_location_types, 'ride') && (
        <SelectArrayInput label='Ride Music genres' source='preferred_ride_music_genres' choices={genres} />
      )}
    </FormTab>
  </TabbedForm>
);

TrainerForm.propTypes = { isExisting: bool };
TrainerForm.defaultProps = { isExisting: false };

export default TrainerForm;
