import * as React from 'react';
import PropTypes from 'prop-types';
import { Labeled, useRecordContext } from 'react-admin';

const CapitalizedTextField = (props) => {
  const record = useRecordContext(props);
  const { source, label } = props;

  return record[source] ? (
    <Labeled label={label}>
      <span>{record[source].charAt(0).toUpperCase() + record[source].substring(1)}</span>
    </Labeled>
  ) : null;
};

CapitalizedTextField.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string.isRequired,
};

export default CapitalizedTextField;
