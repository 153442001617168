import React, { useState, useEffect } from 'react';
import { AutocompleteInput } from 'react-admin';
import { publicLinks } from '../constants';

const LinksSelectInput = (props) => {
  const selectedValues = props.formData.public_data;
  const [enabledLinksOptions, setEnabledLinksOptions] = useState(publicLinks);
  const [linkValues, setLinkValues] = useState(enabledLinksOptions);

  const updateEnrichedLinks = (selectedValues) => {
    const enrichedLinks = [...publicLinks];
    enrichedLinks.forEach((item) => {
      item.disabled = selectedValues && selectedValues.some((value) => value && value.name === item.id);
    });
    setEnabledLinksOptions(enrichedLinks);
  };

  useEffect(() => {
    updateEnrichedLinks(selectedValues);
  }, [selectedValues]);

  const onCreate = (value) => {
    const newLinkValue = {
      id: value.toLowerCase(),
      name: value,
      disabled: false,
    };
    value && setLinkValues([...linkValues, newLinkValue]);
    return newLinkValue;
  };

  return <AutocompleteInput choices={linkValues} onCreate={onCreate} {...props} translateChoice={false} />;
};

export default LinksSelectInput;
