import Avatar from '@material-ui/core/Avatar';
import has from 'lodash/has';
import PropTypes from 'prop-types';
import React from 'react';

const style = { verticalAlign: 'middle' };

const ProfilePictureField = ({ record, size }) => {
  if (has(record, 'profile_picture.small.url')) {
    return <Avatar src={`${record.profile_picture.small.url}?size=${size}x${size}`} size={size} style={style} />;
  }

  return <Avatar src={null} size={size} style={style} />;
};

ProfilePictureField.propTypes = {
  record: PropTypes.object,
  size: PropTypes.number,
};

ProfilePictureField.defaultProps = {
  record: {},
  size: 25,
};

export default ProfilePictureField;
