import 'react-app-polyfill/ie11';
import * as Sentry from '@sentry/browser';
import moment from 'moment-timezone';
import React from 'react';
import ReactDOM from 'react-dom';
import 'moment/locale/en-gb';

import './index.css';
import App from './App';
import setFavIcon from './utils/setFavIcon';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV,
  });
} else {
  console.log('Sentry not configured.');
}

moment.tz.setDefault('Europe/Berlin');
moment.locale('en-gb');

// Sets the favicon according to the environment
setFavIcon();

ReactDOM.render(<App />, document.getElementById('root'));
