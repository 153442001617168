import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { Paper, Typography } from '@material-ui/core';
import { SimpleForm, NumberInput, DateTimeInput, required, Toolbar, FormDataConsumer } from 'react-admin';

import ApplyToFollowingInput from 'reactAdmin/inputs/ApplyToFollowingInput';
import isEmpty from 'lodash/isEmpty';
import SaveOffersButton from './SaveOffersButton';
import TrainerReferenceInput from '../../trainers/TrainerReferenceInput';

import { createOffer as createOfferAction } from '../actions';
import CascadeBooleanInput from './CascadeBooleanInput';

const styles = {
  button: {
    display: 'block',
    minWidth: '100%',
    margin: '0.25em 0',
    zIndex: 10,
  },
};

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <FormDataConsumer>
      {({ formData }) => {
        return (
          <SaveOffersButton
            createOfferConfig={createOfferAction}
            formData={formData}
            style={styles.button}
            {...props}
          />
        );
      }}
    </FormDataConsumer>
  </Toolbar>
);

const OfferForm = (props) => {
  const { event } = props;

  const getDefaultDateTimeValue = () => {
    return moment().toISOString();
  };

  const validateOfferForm = (values) => {
    const errors = {};
    const coachIdEmpty = isEmpty(values.coach_id);
    const tierEmpty = !values.tier;
    if (coachIdEmpty) {
      if (!tierEmpty) return;
      errors.tier = 'Required';
    }
    if (tierEmpty) {
      if (!coachIdEmpty) return;
      errors.coach_id = 'Required';
    }
    return errors;
  };

  return (
    <Paper>
      <SimpleForm
        resource='offers'
        defaultValue={{
          valid_from: getDefaultDateTimeValue(),
          apply_to_following_events: false,
          cascade_offer: false,
        }}
        toolbar={<CustomToolbar {...props} />}
        validate={validateOfferForm}
      >
        <Typography variant='title'>Create an offer</Typography>
        <DateTimeInput
          label='Valid From'
          source='valid_from'
          validate={required()}
          options={{
            ampm: false,
            disablePast: true,
          }}
        />
        <CascadeBooleanInput />
        <FormDataConsumer>
          {(props) => {
            const isCascading = props.formData.cascade_offer;
            return (
              <>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                  <NumberInput
                    label={isCascading ? 'Starting tier' : 'Tier'}
                    source='tier'
                    min={1}
                    style={{ marginRight: '16px' }}
                  />
                  <NumberInput label='Delay time (minutes)' source='cascade_delay_mins' disabled={!isCascading} />
                </div>
              </>
            );
          }}
        </FormDataConsumer>
        <TrainerReferenceInput multiple />
        {/* TODO: localize me */}
        <NumberInput label='Offer Bonus (€)' source='bonus_amount' />
        {event?.recurrent_id && <ApplyToFollowingInput />}
      </SimpleForm>
    </Paper>
  );
};

OfferForm.propTypes = {
  event: PropTypes.object,
  valid_from: PropTypes.string,
  createOffer: PropTypes.func.isRequired,
};

OfferForm.defaultProps = {
  event: {},
  valid_from: '',
};

export default connect(null, {
  createOffer: createOfferAction,
})(OfferForm);
