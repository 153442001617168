import { gsBlack } from 'theme/colors';

export default {
  icon: {
    width: '55px',
    marginRight: '1rem',
  },
  text: {
    h1: {
      fontSize: '1.5rem',
      color: gsBlack,
      margin: 0,
      fontWeight: 'normal',
      overflow: 'visible',
      whiteSpace: 'nowrap',
    },
    bigTotal: {
      fontSize: '2.5rem',
      fontWeight: 'bold',
      color: gsBlack,
      overflow: 'visible',
      whiteSpace: 'nowrap',
    },
    light: {
      color: 'rgba(0, 0, 0, .5)',
      overflow: 'visible',
      whiteSpace: 'nowrap',
    },
    total: {
      overflow: 'visible',
      whiteSpace: 'nowrap',
    },
  },
  section: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    marginTop: '2rem',
    marginBottom: '1rem',
  },
  mainTotal: {
    display: 'flex',
    alignItems: 'center',
  },
  sideTotals: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    textAlign: 'right',
    lineHeight: '1.2',
  },
  mb: {
    marginBottom: '0.5rem',
  },
};
