import { CREATE, UPDATE } from 'react-admin';

export const UPDATE_EVENT_TYPE_STRUCTURE = 'UPDATE_EVENT_TYPE_STRUCTURE';
export const CREATE_EVENT_TYPE_STRUCTURE = 'CREATE_EVENT_TYPE_STRUCTURE';
export const CREATE_EVENT_TYPE_STRUCTURE_FAILURE = 'CREATE_EVENT_TYPE_STRUCTURE_FAILURE';
export const CREATE_EVENT_TYPE_STRUCTURE_SUCCESS = 'CREATE_EVENT_TYPE_STRUCTURE_SUCCESS';
export const UPDATE_EVENT_TYPE_STRUCTURE_FAILURE = 'UPDATE_EVENT_TYPE_STRUCTURE_FAILURE';
export const UPDATE_EVENT_TYPE_STRUCTURE_SUCCESS = 'UPDATE_EVENT_TYPE_STRUCTURE_SUCCESS';
export const RESET_EVENT_TYPE_STRUCTURE_FORM_ERRORS = 'RESET_EVENT_TYPE_STRUCTURE_FORM_ERRORS';

export const resetEventTypeStructureFormErrors = () => {
  return {
    type: RESET_EVENT_TYPE_STRUCTURE_FORM_ERRORS,
  };
};

export const updateEventTypeStructure = (id, data) => {
  const payload = { id, data };
  return {
    type: UPDATE_EVENT_TYPE_STRUCTURE,
    payload,
    meta: {
      resource: `event-type-structures`,
      fetch: UPDATE,
      refresh: true,
      cancelPrevious: false,
      onSuccess: {
        notification: {
          body: 'EventTypeStructure has been updated',
          level: 'info',
        },
      },
      onFailure: {
        notification: {
          body: 'An error occurred while updating the EventTypeStructure',
          level: 'warning',
        },
      },
    },
  };
};

export const createEventTypeStructure = (data) => {
  return {
    type: CREATE_EVENT_TYPE_STRUCTURE,
    payload: { data },
    meta: {
      resource: `event-type-structures`,
      fetch: CREATE,
      refresh: true,
      cancelPrevious: false,
      onSuccess: {
        notification: {
          body: 'EventTypeStructure has been created',
          level: 'info',
        },
      },
      onFailure: {
        notification: {
          body: 'An error occurred while creating the EventTypeStructure',
          level: 'warning',
        },
      },
    },
  };
};
