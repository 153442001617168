import get from 'lodash/get';
import React from 'react';
import {
  BooleanField,
  DateField,
  EditButton,
  Filter,
  List,
  NullableBooleanInput,
  NumberInput,
  Responsive,
  ShowButton,
  SimpleList,
  TextField,
  TextInput,
} from 'react-admin';

import CopyIdButton from 'components/CopyIdButton';
import Datagrid from '../Datagrid';
import SensorPoolReferenceField from '../sensor_pools/SensorPoolReferenceField';
import SensorPoolReferenceInput from '../sensor_pools/SensorPoolReferenceInput';
import SensorListActions from './SensorListActions';
import SensorModelField from './SensorModelField';

function getRowStyle(record) {
  if (!record) return null;
  const isComplete =
    record.serial_no &&
    record.slug &&
    record.hw_uid &&
    record.vendor_name &&
    record.model_name &&
    record.sensor_pool_id &&
    record.model_name !== 'Unknown' &&
    record.vendor_name !== 'Unknown';

  return {
    backgroundColor: !isComplete ? '#FFB9B5' : 'inherit',
  };
}

const SensorFilter = (props) => (
  <Filter {...props}>
    <SensorPoolReferenceInput alwaysOn />
    <TextInput label='Sensor Model' source='vendor_name' alwaysOn />
    <NullableBooleanInput source='is_allocatable' label='Allocatable' alwaysOn />
    <NumberInput source='current_battery_level_lte' label='Battery Level (lower or equal)' alwaysOn />
  </Filter>
);

const SensorList = (props) => (
  <List
    {...props}
    sort={{ field: 'slug', order: 'ASC' }}
    perPage={40}
    filters={<SensorFilter />}
    actions={<SensorListActions />}
    bulkActions={false}
  >
    <Responsive
      small={
        <SimpleList
          primaryText={(record) => record.slug || '(No Sensor ID)'}
          secondaryText={(record) =>
            get(record, 'is_allocatable', false) ? <span>Available</span> : <span>Unavailable</span>
          }
          tertiaryText={(record) => <SensorModelField record={record} />}
        />
      }
      medium={
        <Datagrid
          defaultColumns={['slug', 'sensor_pool_id', 'is_allocatable', 'current_battery_level']}
          rowStyle={getRowStyle}
        >
          <ShowButton />
          <CopyIdButton />
          <TextField source='slug' label='Sensor ID' />
          <TextField source='hw_uid' label='Hardware ID' />

          <SensorPoolReferenceField />

          <BooleanField source='is_allocatable' label='Allocatable' />
          <SensorModelField label='Model' />
          <TextField source='serial_no' label='Vendor Serial No.' />
          <TextField source='current_battery_level' label='Battery' />
          <DateField source='current_battery_level_updated_at' label='Battery Updated At' showTime locales='de-DE' />

          <DateField source='created_at' showTime locales='de-DE' />

          <EditButton />
        </Datagrid>
      }
    />
  </List>
);

export default SensorList;
