import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { Button } from '@material-ui/core';

const OfferApplicationConfirmModal = ({ isModalOpen, closeModal, applyOffer }) => {
  return (
    <Dialog open={isModalOpen} width='500px' onClose={closeModal}>
      <DialogTitle>Accept application</DialogTitle>
      <DialogContent>
        <div style={{ lineHeight: '26px' }}>
          By appecting this application, this coach will be assigned to this and all the following events in the series,
          and this will override previously assigned coaches. Other applications to this regular workout will be
          declined automatically.
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color='primary'>
          Close
        </Button>
        <Button color='primary' variant='contained' onClick={applyOffer}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OfferApplicationConfirmModal;
