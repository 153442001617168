import { sortBy } from 'lodash';
import React from 'react';
import { AutocompleteInput } from 'react-admin';
import AutocompleteArrayInput from 'reactAdmin/inputs/AutocompleteArrayInput';

/**
 * AutocompleteInput that sorts the location by city + location name
 */
const SortedLocationInput = (props) => {
  const Input = props.multiple ? AutocompleteArrayInput : AutocompleteInput;

  // As react admin doesn't allow use a custom sort we need to do it our way
  const sortedChoices = sortBy(props.choices, (location) => {
    return `${location.city_code} ${location.name}`;
  });

  return <Input {...props} choices={sortedChoices} />;
};

export default SortedLocationInput;
