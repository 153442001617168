import React from 'react';
import { Filter, TextInput } from 'react-admin';
import AutocompleteArrayInput from 'reactAdmin/inputs/AutocompleteArrayInput';

import { categories, genres, languages } from './constants';

const AudioFilesFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Name' source='q' alwaysOn allowEmpty />
    <AutocompleteArrayInput choices={categories} label='Category' source='metadata.category' alwaysOn resettable />
    <AutocompleteArrayInput choices={genres} label='Genre' source='metadata.genre' alwaysOn resettable />
    <AutocompleteArrayInput choices={languages} label='Language' source='metadata.language' alwaysOn resettable />
  </Filter>
);

export default AudioFilesFilter;
