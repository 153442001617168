import TrainerIcon from '@material-ui/icons/AccessibilityNew';
import TrainerTitle from './TrainerTitle';
import TrainerFilter from './TrainerFilter';
import TrainerList from './TrainerList';
import TrainerShow from './TrainerShow';
import TrainerEdit from './TrainerEdit';
import TrainerCreate from './TrainerCreate';
import formattedFullName from './utils/formattedFullName';
import formattedFullNameEmail from './utils/formattedFullNameEmail';

export default {
  icon: TrainerIcon,
  list: TrainerList,
  show: TrainerShow,
  create: TrainerCreate,
  edit: TrainerEdit,
};

export {
  TrainerIcon,
  TrainerTitle,
  TrainerFilter,
  formattedFullName,
  formattedFullNameEmail,
};
