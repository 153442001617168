import React, { useEffect, useState, useRef, createRef } from 'react';
import { get } from 'lodash';
import qs from 'qs';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, useTheme } from '@material-ui/core';
import AvatarField from 'reactAdmin/users/AvatarField';
import moment from 'moment-timezone';
import ParticipantIndicator from 'components/ParticipantIndicator';
import { httpClient } from 'client';

const apiUrl = process.env.REACT_APP_API_URL;

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    padding: '16px 0px',
    flexWrap: 'nowrap',
    overflowX: 'scroll',
    width: '70vw',
  },
  card: {
    flexDirection: 'column',
    display: 'flex',
    padding: '16px',
    borderRadius: '4px',
    marginRight: '16px',
    boxShadow: '0 0 10px 5px rgba(0,0,0,0.1)',
    minWidth: '120px',
    marginLeft: '16px',
    cursor: 'pointer',
  },
  text: {
    fontWeight: '500',
    fontColor: '#212121',
    fontSize: '20px',
  },
  headerText: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  timeBanner: {
    flexDirection: 'column',
    display: 'flex',
  },
  participantCount: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  scrollLoader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  error: {
    color: 'red',
  },
}));

const RecurringEvent = (props) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(50);
  const [total, setTotal] = useState(0);
  const scrollRef = createRef();
  const [error, setError] = useState(null);
  const skip = useRef({
    page: 0,
  });
  const theme = useTheme();
  const styles = useStyles();

  const getRecurringEvents = async () => {
    if (total > 0 && events.length >= total) return;
    try {
      const recurrent_id = get(props, 'recurrentId');
      const query = qs.stringify({
        recurrent_id,
        date_begin_gte: new Date().toISOString(),
        $limit: limit,
        $skip: skip.current.page,
        '$sort[date_begin]': 1,
        select: ['id', 'date_begin', 'current_participants_count', 'coach'],
        include: false,
      });
      const response = await httpClient(`${apiUrl}/events?${query}`).then(({ json }) => json);
      setEvents((prevEvents) => [...prevEvents, ...response.data]);
      setLimit(response.limit);
      setTotal(response.total);
      setLoading(false);
    } catch (error) {
      setError(error);
    }
  };

  const redirectToEvents = ({ id }) => {
    if (!id) return;
    window.open(`#/events/${id}/show`);
  };

  useEffect(() => {
    getRecurringEvents();
    return () => {
      setEvents([]);
      skip.current.page = 0;
      setError(null);
    };
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: [0, 0.5, 1],
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(() => {
        if (entries[0].isIntersecting) {
          skip.current.page = Number(skip.current.page) + limit;
          getRecurringEvents();
        }
      });
    }, options);

    if (scrollRef.current) {
      observer.observe(scrollRef.current);
    }
    return () => {
      if (scrollRef.current) {
        observer.unobserve(scrollRef.current);
      }
    };
  }, [scrollRef.current, loading]);

  if (error) {
    return <Typography className={styles.error}>{JSON.stringify(error)}</Typography>;
  }
  return (
    <div>
      {loading && <div className={styles.scrollLoader}>Loading...</div>}
      {!loading && (
        <>
          <Typography className={styles.headerText}>Upcoming Recurring Events({total})</Typography>
          <Box className={styles.container}>
            {events.map((event, index) => {
              return (
                <div
                  className={styles.card}
                  style={{ opacity: event?.flags?.needs_coach_replacement ? 0.5 : 1 }}
                  role='button'
                  tabIndex={0}
                  key={event.id}
                  data-id={index}
                  onClick={() => redirectToEvents(event)}
                  onKeyDown={() => { }}
                >
                  <div className={styles.header}>
                    <AvatarField size='20' record={event?.coach} />
                    <div className={styles.participantCount}>
                      <ParticipantIndicator
                        maxCount={event?.max_participants}
                        count={event?.participants_count}
                        theme={theme}
                      />
                    </div>
                  </div>
                  <div className={styles.timeBanner}>
                    <Typography className={styles.text}>{moment(event?.date_begin).format('ddd')}</Typography>
                    <Typography className={styles.text}>{moment(event?.date_begin).format('DD.MM.YY')}</Typography>
                  </div>
                </div>
              );
            })}
            <div className={styles.scrollLoader} ref={scrollRef}>
              {events.length < total && <div>Loading...</div>}
            </div>
          </Box>
        </>
      )}
    </div>
  );
};

export default RecurringEvent;
