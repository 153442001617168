import React from 'react';
import { Filter, RadioButtonGroupInput, TextInput } from 'react-admin';
import AutocompleteArrayInput from 'reactAdmin/inputs/AutocompleteArrayInput';

import { roles as roleChoices, locales as localeChoices, companies } from './constants';
import UscMembershipTypeInput from './inputs/UscMembershipTypeInput';

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
    <TextInput source='provider' label='Provider' />
    <TextInput source='provider_id' label='Provider ID' />
    <UscMembershipTypeInput />
    <RadioButtonGroupInput source='locale' choices={localeChoices} />
    <AutocompleteArrayInput source='roles' choices={roleChoices} />
    <AutocompleteArrayInput choices={companies} label='Company' source='company' />
    <TextInput source='promo_code' />
    <TextInput source='payment_situation' />
    <TextInput source='fitness_goal' />
    <TextInput source='fitness_goal_level' />
    <TextInput source='homezone_city_code' />
    <TextInput source='homezone_country_code' />
    <TextInput source='homezone_district_code' />
  </Filter>
);

export default UserFilter;
