import moment from 'moment-timezone';

export default (birthDay, birthMonth, birthYear) => {
  const now = moment();

  let age = now.year() - birthYear;
  const ageMonth = (now.month() + 1) - birthMonth;
  const ageDay = now.date() - birthDay;

  if (ageMonth < 0 || (ageMonth === 0 && ageDay < 0)) {
    age = parseInt(age, 10) - 1;
  }

  return age;
};
