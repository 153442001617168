import { httpClient } from 'client';

const apiUrl = process.env.REACT_APP_API_URL;

const addUploadCapabilities = (requestHandler) => (type, resource, parameters) => {
  const params = { ...parameters };
  if (type === 'UPDATE' && resource === 'groups') {
    if (params.data.images && params.data.images.length) {
      const images = params.data.images.filter((p) => p.rawFile instanceof File);
      // const url = config.uploadImageEndpoint;
      const url = `${apiUrl}/${resource}/${params.id}/images`;

      // the params contain the image as a fileInstance
      params.data.images = [];

      images.forEach((image) => {
        const form = new FormData();
        // let options = APIUtils.createOptionsForImageUpload(form);

        form.append('file', image.rawFile);

        const options = {
          method: 'POST',
          body: form,
        };

        return httpClient(url, options).then(() => (
          // params.data.images.push({id: res.json.content[0].id});
          requestHandler(type, resource, params)
        ));
      });
    }
  }

  return requestHandler(type, resource, params);
};

export default addUploadCapabilities;
