import { startCase } from 'lodash';
import React from 'react';
import { Show, SimpleShowLayout, TextField, DateField, FunctionField } from 'react-admin';

import AssetFileField from '../fields/AssetFileField';
import { renderDuration } from './AudioEdit';
import AudioShowActions from './AudioShowActions';

const AudioShow = (props) => (
  <Show title='Audio File Detail View' actions={<AudioShowActions />} {...props}>
    <SimpleShowLayout>
      <AssetFileField source='file' label='File' />
      <TextField source='title' label='Audio name' />
      <FunctionField
        source='metadata.category'
        label='Category'
        render={(record) => record && startCase(record.metadata.category)}
      />
      <FunctionField
        source='metadata.genre'
        label='Genre'
        render={(record) => record && startCase(record.metadata.genre)}
      />
      <FunctionField
        source='metadata.language'
        label='Language'
        render={(record) => record && record.metadata.language.toUpperCase()}
      />
      <FunctionField label='duration' render={(record) => record && renderDuration(record)} />
      <DateField source='created_at' label='Created at' locales='de-DE' />
      <DateField source='updated_at' label='Last updated at' locales='de-DE' />
    </SimpleShowLayout>
  </Show>
);

export default AudioShow;
