import React from 'react';
import { Filter, NumberInput, TextInput, DateTimeInput } from 'react-admin';
import AutocompleteArrayInput from 'reactAdmin/inputs/AutocompleteArrayInput';

import EventReferenceInput from '../events/EventReferenceInput';
import { cities, countries } from '../locations/constants';
import LocationReferenceInput from '../locations/LocationReferenceInput';
import TrainerReferenceInput from '../trainers/TrainerReferenceInput';
import UserReferenceInput from '../users/UserReferenceInput';

const ResultFilter = (props) => (
  <Filter {...props}>
    <EventReferenceInput formType='filter' />
    <UserReferenceInput formType='filter' multiple />
    <LocationReferenceInput />
    <TrainerReferenceInput multiple />
    <AutocompleteArrayInput choices={countries} label='Country' source='location_country_code' />
    <AutocompleteArrayInput choices={cities} label='City' source='location_city_code' />
    <DateTimeInput source='event_date_begin_gte' label='Event begins after' />
    <DateTimeInput source='event_date_begin_lte' label='Event begins before' />
    <TextInput source='sensor_slug' label='Sensor ID' />
    <NumberInput source='gritpoints' label='Gritpoints' />
    <NumberInput source='gritpoints_lte' label='Gritpoints less than equal' />
    <NumberInput source='gritpoints_gte' label='Gritpoints greater than equal' />
  </Filter>
);

export default ResultFilter;
