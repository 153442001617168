import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import {
  fetchEnd,
  fetchStart,
  showNotification,
  GET_LIST,
  REDUX_FORM_NAME,
  FieldTitle,
} from 'react-admin';
import { DatePicker } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchIcon from '@material-ui/icons/Search';

import { restClient as dataProvider } from 'client';
import EventList from 'components/EventList';
import withReactFinalForm from 'components/withReactFinalForm'


const style = (theme) => ({
  filters: {
    marginTop: '1em',
    marginBottom: '1em',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1em',
    margin: '1em',
  },
});

class EventQuickSelectButton extends Component {
  constructor(props) {
    const today = moment();
    const defaultEventFilter = {
      date_begin_gte: today.startOf('day').format(),
      date_begin_lte: today.endOf('day').format(),
    };

    super(props);

    this.state = {
      showDialog: false,
      selectedEventId: null,
      events: null,
      selectedDate: null,
      filter: defaultEventFilter,
    };
  }

  queryEvents = async () => {
    const { filter } = this.state;
    const { fetchStart, fetchEnd, showNotification } = this.props;
    let events = null;

    if (!filter) {
      return;
    }

    const fetchOptions = {
      filter,
      sort: { field: 'date_begin', order: 'desc' },
      pagination: { page: 1, perPage: 50 },
    };

    this.setState({ events: [], isLoading: true });

    fetchStart();

    try {
      events = await dataProvider(GET_LIST, 'events', fetchOptions).then((response) => response.data);
    } catch (error) {
      showNotification('Could not fetch events', 'error');
    }

    fetchEnd();

    this.setState({ events, isLoading: false });
  }

  handleClickOpen = () => {
    this.queryEvents();
    this.setState({ showDialog: true });
  };

  handleClose = () => {
    this.setState({ showDialog: false });
  };

  handleSave = () => {
    const { change, formType, showNotification } = this.props;
    const { selectedEventId } = this.state;

    if (!selectedEventId) {
      showNotification('No Event selected', 'warning');
      return;
    }

    const formName = formType === 'filter' ? 'filterForm' : REDUX_FORM_NAME;

    this.props.reactFinalForm.change(formName, 'event_id', selectedEventId);
    this.handleClose();
  }

  handleDateChange = (date) => {
    const day = moment(date);

    this.setState((state) => ({
      filter: {
        ...state.filter,
        date_begin_gte: day.clone().startOf('day').format(),
        date_begin_lte: day.clone().endOf('day').format(),
      },
      selectedDate: date,
    }), () => {
      this.queryEvents();
    });
  };

  handleChangeSelectedEvent = (event) => {
    this.setState({ selectedEventId: event.id });
  }

  renderLoading = () => (
    <div className={this.props.classes.loadingContainer}>
      <CircularProgress />
    </div>
  )

  render() {
    const { classes } = this.props;
    const { selectedDate, events, selectedEventId, showDialog, isLoading } = this.state;

    return (
      <Fragment>
        <Button onClick={this.handleClickOpen}>
          <SearchIcon /> Find Event
        </Button>
        <Dialog
          open={showDialog}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>Find Event</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Specify filter params to show a list of matching events.
            </DialogContentText>

            <div className={classes.filters}>
              <DatePicker
                label={<FieldTitle label='Event Date' />}
                value={selectedDate}
                onChange={this.handleDateChange}
              />
            </div>

            {isLoading && this.renderLoading()}

            <EventList
              events={events}
              selectedEventId={selectedEventId}
              onSelected={this.handleChangeSelectedEvent}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color='primary'>
              Cancel
            </Button>
            <Button onClick={this.handleSave} disabled={!selectedEventId} color='primary'>
              Select
            </Button>
          </DialogActions>
        </Dialog>

      </Fragment>
    );
  }
}


const mapStateToProps = state => ({});

const mapDispatchToProps = {
  fetchEnd,
  fetchStart,
  showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(withReactFinalForm(EventQuickSelectButton)));
