import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Chart } from 'react-google-charts';

import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';

import { brand, gsYellow } from 'theme/colors';

import calculateAfterBurn from 'utils/calculateAfterBurn';

import Totals from './Totals';
import styles from './styles';

const logo = 'images/gritlogo.png';
const statsIcon = 'images/reports/user_workout_stats/stats.png';
const enduranceIcon = 'images/reports/user_workout_stats/endurance.png';
const trophyIcon = 'images/reports/user_workout_stats/trophy.png';
const fireIcon = 'images/reports/user_workout_stats/fire.png';

class Report extends React.Component {
  constructor (props) {
    super(props);

    const weekBegin = moment(props.date).format('MMM, DD');
    const weekEnd = moment(props.date).endOf('week').format('MMM, DD');

    this.state = {
      weekBegin,
      weekEnd,
    };
  }

  componentWillReceiveProps (nextProps) {
    const isSame = moment(nextProps.date).isSame(this.props.date);

    if (!isSame) {
      const weekBegin = moment(nextProps.date).startOf('week').format('MMM, DD');
      const weekEnd = moment(nextProps.date).endOf('week').format('MMM, DD');

      this.setState({ weekBegin, weekEnd });
    }
  }

  drawChart = (id, values) => {
    const colors = {
      tracked: brand,
      notTracked: gsYellow,
      text: '#919191',
    };

    const data = [[
      'Total per week',
      'Total this week',
      { role: 'style' },
    ]];

    let hasUntrackedWorkouts = false;
    values.forEach((v) => {
      if (v.hasUntrackedWorkouts) hasUntrackedWorkouts = true;
      const entry = [v.date, v.total, (v.hasUntrackedWorkouts ? colors.untracked : colors.tracked)];
      data.push(entry);
    });

    const options = {
      chartArea: {
        width: '90%',
        right: 0,
      },
      legend: {
        position: 'none',
      },
      colors: [colors.notTracked, colors.tracked],
      bar: { groupWidth: '80%' },
      hAxis: {
        textStyle: { color: colors.text },
      },
      vAxis: {
        textStyle: { color: colors.text },
      },
    };

    return (
      <span>
        {hasUntrackedWorkouts &&
          <p style={styles.chartLegend}>
            <div style={styles.chartLegendKey} />
            Not all workouts were tracked with a sensor
          </p>}
        <Chart
          chartType='ColumnChart'
          data={data}
          options={options}
          width='90%'
        />
      </span>
    );
  }


  buildTotalsData = () => {
    const { weeks } = this.props;

    if (weeks.length === 0) return {};

    const week = weeks[weeks.length - 1];
    const lastWeek = weeks[weeks.length - 2];

    const gritpointsAvg = Math.round(week.gritpoints.total / week.results.length) || 0;
    const gritpointsBest = week.results.map((r) => r.gritpoints).reduce((acc, cals) => Math.max(acc, cals), 0);

    const caloriesAvg = Math.round(week.calories.total / week.results.length) || 0;
    const caloriesBest = week.results.map((r) => {
      const { gritpoints, calories_burned } = r;
      const extraCalories = calculateAfterBurn(gritpoints, calories_burned);
      return (r.calories_burned + extraCalories);
    }).reduce((acc, cals) => Math.max(acc, cals), 0);

    return {
      workouts: {
        total: Math.max(week.tickets.length, week.results.length),
        tracked: week.results.length,
        lastWeek: Math.max(lastWeek.tickets.length, lastWeek.results.length),
      },
      gritpoints: {
        total: week.gritpoints.total,
        average: gritpointsAvg,
        best: gritpointsBest,
      },
      calories: {
        total: week.calories.total,
        average: caloriesAvg,
        best: caloriesBest,
      },
    };
  }

  render () {
    const { avatar, elStyles, username, weeks } = this.props;

    const workoutsData = weeks.map((week) => week.workouts) || [];
    const gritpointsData = weeks.map((week) => week.gritpoints) || [];
    const caloriesData = weeks.map((week) => week.calories) || [];

    const totals = this.buildTotalsData();
    const thisWeekHasData = (totals.workouts && totals.workouts.total > 0);

    return (
      <div id='weeklystats' style={elStyles}>
        <div style={{ ...styles.section, ...styles.header }}>
          <div style={styles.center}>
            <div>
              <img src={statsIcon} alt='weeklystats' style={styles.statsIcon} />
            </div>
            <div style={styles.statsText}>Weekly Stats</div>
          </div>
          <div style={styles.gritLogo}>
            <img src={logo} alt='logo' width='60' height='60' />
          </div>
        </div>

        <div style={styles.banner}>
          <div style={styles.avatar}>
            <Avatar src={avatar} size={60} />
          </div>
          <div style={styles.column}>
            <div style={styles.username}>Hi {username}!</div>
            <div style={styles.date}>Here are your stats for {this.state.weekBegin} - {this.state.weekEnd}!</div>
          </div>
        </div>

        {totals.workouts &&
          <Totals
            title='Workouts'
            id='workout'
            icon={enduranceIcon}
            total={totals.workouts.total}
            average={totals.workouts.tracked}
            best={totals.workouts.lastWeek}
          />}

        {!thisWeekHasData &&
          <div style={styles.noWorkouts.section}>
            <h1 style={styles.noWorkouts.header}>We missed you this week!</h1>
            <p>
              Did you have a stressful week? Try to do at least one or two workouts per week in order to maintain your
              fitness and health condition. You will feel much better!
            </p>
          </div>
        }

        {thisWeekHasData && totals.gritpoints &&
          <Totals
            title='Gritpoints'
            id='gritpoints'
            icon={trophyIcon}
            total={totals.gritpoints.total}
            average={totals.gritpoints.average}
            best={totals.gritpoints.best}
          />}

        {thisWeekHasData && totals.calories &&
          <Totals
            title='Burned Calories'
            id='calories'
            icon={fireIcon}
            total={totals.calories.total}
            average={totals.calories.average}
            best={totals.calories.best}
          />}

        <Divider style={styles.divider} />

        <div style={styles.chart}>
          <h1 style={styles.header}>Workouts per Week</h1>
          {this.drawChart('workouts', workoutsData)}
        </div>

        <Divider style={styles.divider} />

        <div style={styles.chart}>
          <h1 style={styles.header}>Gritpoints per Week</h1>
          {this.drawChart('gritpoints', gritpointsData)}
        </div>

        <Divider style={styles.divider} />

        <div style={styles.chart}>
          <h1 style={styles.header}>Calories per Week</h1>
          {this.drawChart('calories', caloriesData)}
        </div>
      </div>
    );
  }
}

Report.propTypes = {
  avatar: PropTypes.string,
  elStyles: PropTypes.object,
  date: PropTypes.any.isRequired,
  username: PropTypes.string,
  weeks: PropTypes.array,
};

Report.defaultProps = {
  avatar: null,
  elStyles: {},
  username: null,
  weeks: [],
};


export default Report;
