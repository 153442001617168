import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import get from 'lodash/get';
import React from 'react';
import { Link } from 'react-admin';

import FullNameField from './FullNameField';

const styles = (theme) => ({
  link: {
    color: theme.palette.primary.main,
  },
});

const EmbeddedTrainerField = ({ record, source, className, classes = {}, isLoading, ...rest }) => (
  <Link to={{ pathname: `/trainers/${get(record, source)}/show` }} className={className}>
    <FullNameField
      className={classnames(
        classes.link, // force color override for Typography components
      )}
      record={record.coach || record.trainer}
      {...rest}
    />
  </Link>
);

EmbeddedTrainerField.defaultProps = {
  source: 'coach_id',
  addLabel: true,
  label: 'Coach',
  link: 'show',
  allowEmpty: true,
  record: {},
};

export default withStyles(styles)(EmbeddedTrainerField);
