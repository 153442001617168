import React from 'react';
import classnames from 'classnames';

import CustomizableDatagrid from 'ra-customizable-datagrid';

const Datagrid = (props) => (
  <CustomizableDatagrid {...props} className={classnames(props.className, 'customizable-datagrid')} />
);

export default Datagrid;
