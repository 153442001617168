import React from 'react';
import { Edit, SaveButton, Toolbar } from 'react-admin';

import EventTypeForm from './EventTypeForm';

const EditToolbar = props => (
  <Toolbar {...props} >
      <SaveButton />
  </Toolbar>
);

const EventTypeEdit = (props) => (
  <Edit title='Edit Event Type' {...props} undoable={false} >
    <EventTypeForm isExisting toolbar={<EditToolbar />} />
  </Edit>
);


export default EventTypeEdit;
