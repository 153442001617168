import React from 'react';
import {
  ArrayField,
  BooleanField,
  ChipField,
  Datagrid,
  DateField,
  EditButton,
  EmailField,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  Show,
  SelectField,
  ShowButton,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  Pagination,
  FunctionField,
  List,
  Filter,
  BooleanInput,
  TextInput,
  SimpleShowLayout,
} from 'react-admin';
import CustomDateField from 'reactAdmin/fields/DateField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CopyIdButton from 'components/CopyIdButton';
import { IdField } from 'components/IdField';
import EventFullNameField from '../events/FullNameField';
import TicketPagination from '../tickets/TicketPagination';
import { companies } from './constants';
import UserShowActions from './UserShowActions';
import UserTitle from './UserTitle';
import { UserFullFillment } from './UserFullFillment';
import MergeAccount from './MergeAccount';
import WeeklyStatsLinkField from './WeeklyStatsLinkField';
import UserAvatarField from './AvatarField';
import { ActiveSubscriptionsContent } from './ActiveSubscriptionsContent';
import FullNameField from './FullNameField';

const PrimaryTypeChipField = ({ record, source, ...rest }) => (
  <ChipField record={{ label: record }} source='label' {...rest} />
);

const ResultPagination = (props) => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} {...props} />;

const UserTicketFilters = (props) => {
  return (
    <Filter {...props}>
      <TextInput alwaysOn q source='charge_provider' label='Provider' />
      <TextInput alwaysOn q source='charge_remote_plan_id' label='Membership' />
      <BooleanInput alwaysOn source='is_chargeable' label='Chargeable' />
    </Filter>
  );
};

const UserTickets = ({ userId }) => {
  return (
    <List
      resource='tickets'
      basePath='/tickets'
      filter={{ user_id: userId }}
      filters={<UserTicketFilters />}
      pagination={<TicketPagination />}
      bulkActionButtons={false}
      sort={{ field: 'event_date_begin', order: 'DESC' }}
      perPage={50}
    >
      <Datagrid>
        <ShowButton />
        <CopyIdButton />
        <CustomDateField
          source='event.date_begin'
          label='Begin date'
          showTime
          timezoneSource='event.location.timezone'
          sortBy='event_date_begin'
        />
        <ReferenceField label='Event' source='event_id' reference='events' link='show'>
          <EventFullNameField showDate={false} />
        </ReferenceField>
        <BooleanField label='On Waitinglist' source='is_waitinglist' style={{ textAlign: 'center' }} sortable={false} />
        <BooleanField source='is_cancelled' style={{ textAlign: 'center' }} sortable={false} />
        <BooleanField source='has_attended' style={{ textAlign: 'center' }} sortable={false} />
        <FunctionField label='Fulfillment Source' render={(record) => <UserFullFillment record={record} />} />
        <DateField source='created_at' showTime locales='de-DE' />

        <EditButton />
      </Datagrid>
    </List>
  );
};

const UserShow = (props) => {
  return (
    <Show title={<UserTitle />} actions={<UserShowActions />} {...props}>
      <TabbedShowLayout>
        <Tab label='Base'>
          <Grid container columnSpacing={{ xs: 0 }}>
            <Grid item sm={3}>
              <SimpleShowLayout className='simpleShowLayoutContainer'>
                <UserAvatarField size={100} />
                <IdField id='id' />
                <FunctionField source='name' render={(record) => `${record.forename} ${record.surname}`} />
                <EmailField source='email' />
                <TextField source='phone_number' />
                <TextField source='gender' />
                <DateField source='birthday' locales='de-DE' />
                <NumberField source='age' />
                <TextField label='Height (in cm)' source='height' />
                <TextField label='Weight (in Kg)' source='weight' />
                <TextField label='Max Heart Rate' source='max_heart_rate' />
                <DateField source='last_logged_in_at' showTime locales='de-DE' />
                <DateField source='created_at' showTime locales='de-DE' />
                <DateField source='updated_at' showTime locales='de-DE' />
                <ReferenceField label='Coach' source='coach.id' reference='trainers' link='show'>
                  <FullNameField />
                </ReferenceField>
                <TextField source='facebook_id' />
                <ArrayField source='roles'>
                  <SingleFieldList>
                    <PrimaryTypeChipField />
                  </SingleFieldList>
                </ArrayField>
              </SimpleShowLayout>
            </Grid>
            <Grid item sm={3}>
              <SimpleShowLayout className='simpleShowLayoutContainer'>
                <Typography variant='button'>Payment and provider info</Typography>
                <TextField source='payment_situation' />
                <TextField source='provider' />
                <TextField source='provider_id' label='Provider ID (e.g. USC Member No.)' />
                <TextField source='usc_membership_type' label='USC Membership Type' />
                <FunctionField
                  source='active_subscriptions'
                  render={(record) => <ActiveSubscriptionsContent record={record} />}
                />
                <TextField source='promo_code' />
                <DateField source='payed_until' showTime locales='de-DE' />
                <BooleanField source='is_on_trial' addLabel />
                <FunctionField
                  source='provider_meta'
                  render={(record) => {
                    if (!record.provider_meta) return null;
                    return (
                      <div>
                        {Object.entries(record.provider_meta).map(([key, value]) => (
                          <Typography variant='body1' key={key}>
                            {key}: {JSON.stringify(value)}
                          </Typography>
                        ))}
                      </div>
                    );
                  }}
                />
              </SimpleShowLayout>
            </Grid>
            <Grid item sm={3}>
              <SimpleShowLayout className='simpleShowLayoutContainer'>
                <Typography variant='button'>Other info</Typography>
                <FunctionField
                  source='location_type_attended_workout_count'
                  render={(record) => (
                    <div>
                      <Typography variant='body1'>Ride: {record.location_type_attended_workout_count.ride}</Typography>
                      <Typography variant='body1'>
                        Indoor: {record.location_type_attended_workout_count.indoor}
                      </Typography>
                      <Typography variant='body1'>
                        Outdoor: {record.location_type_attended_workout_count.outdoor}
                      </Typography>
                      <Typography variant='body1'>
                        Online: {record.location_type_attended_workout_count.online}
                      </Typography>
                    </div>
                  )}
                />
                <NumberField source='total_events_attended' />
                <NumberField source='weekly_workout_streak' />
                <TextField source='fitness_goal' />
                <TextField source='fitness_goal_level' />
                <SelectField source='company' label='Company' choices={companies} allowEmpty />
                <TextField source='locale' />
                <TextField source='performance_eval' label='Performance' />
                <ChipField source='segments' />
                <TextField source='type' />
                <BooleanField source='is_vaccinated' addLabel />
                <BooleanField source='is_virtual' label='Virtual User' addLabel />
                <BooleanField source='is_consent_update_required' label='Consent update required' addLabel />
                <BooleanField
                  source='is_consenting_hr_data_processing'
                  label='Consent "Heart rate and body stats"'
                  addLabel
                />
                <BooleanField
                  source='is_consenting_public_profile'
                  label='Consent "Workout participation (public profile)"'
                  addLabel
                />
                <BooleanField
                  source='is_consenting_newsletters'
                  label='Consent "Gritspot updates (newsletters)"'
                  addLabel
                />
              </SimpleShowLayout>
            </Grid>

            <Grid item sm={3}>
              <SimpleShowLayout>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                  <MergeAccount />
                  <WeeklyStatsLinkField />
                </div>
              </SimpleShowLayout>
            </Grid>
          </Grid>
        </Tab>
        <Tab path='tickets' label='Tickets'>
          <UserTickets userId={props.id} />
        </Tab>
        <Tab path='results' label='Results'>
          <ReferenceManyField
            reference='results'
            target='user_id'
            addLabel={false}
            sort={{ field: 'created_at', order: 'DESC' }}
            pagination={<ResultPagination />}
          >
            <Datagrid>
              <ShowButton />
              <ReferenceField label='Event' source='event_id' reference='events' link='show'>
                <EventFullNameField />
              </ReferenceField>
              <NumberField label='GritPoints' source='gritpoints' />
              <NumberField source='calories_burned' />
              <DateField source='recorded_at' showTime locales='de-DE' />
              <DateField source='created_at' showTime locales='de-DE' />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default UserShow;
