import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles';

const icon = 'images/results/daily_stats/target.png';

const GritText = ({ text, size, breakText }) => (
  <div style={styles[size].text}>
    <b>GRIT</b>
    {breakText && <br />}
    <span>
      {text === 'gritpoints' && <span>P<img src={icon} alt='o' style={styles[size].img} />ints</span>}
      {text === 'calories' && <span>Cal<img src={icon} alt='o' style={styles[size].img} />ries</span>}
    </span>
  </div>
);

GritText.propTypes = {
  text: PropTypes.oneOf(['gritpoints', 'calories']).isRequired,
  size: PropTypes.oneOf(['normal', 'header']),
  breakText: PropTypes.bool,
};

GritText.defaultProps = {
  size: 'normal',
  breakText: false,
};

export default GritText;
