/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router'; // eslint-disable-line
import moment from 'moment-timezone';

import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';

import { GET_ONE, GET_LIST, UPDATE } from 'react-admin';

import { restClient } from 'client';

import { profilePictureUrl } from '../../users';

import Collapsible from './Collapsible';
import List from './List';
import styles from './styles';

const logo = 'images/gritlogo.png';


class UserCheckIn extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      date: '',
      trainer: '',
      notCheckedIn: [],
      checkedIn: [],
      loading: true,
      totalTickets: 0,
      totalCheckedIn: 0,
    };
  }

  componentDidMount () {
    this.fetchEvent();
    this.fetchTickets();
  }

  fetchEvent = () => {
    restClient(GET_ONE, 'events', {
      id: this.props.match.params.event_id,
    })
      .then((response) => response.data)
      .then((event) => {
        const date = event.date_begin;
        const { trainer } = event;
        const name = [this.capitalize(trainer.forename), this.capitalize(trainer.surname)].join(' ').trim();

        this.setState({ date, trainer: name });
      });
  }


  fetchTickets = () => {
    return restClient(GET_LIST, 'tickets', {
      filter: {
        event_id: this.props.match.params.event_id,
        is_cancelled: false,
      },
      sort: { field: 'created_at', order: 'ASC' },
      pagination: { page: 1, perPage: 100 },
    })
      .then((response) => response.data)
      .then((tickets) => {
        const promises = [];
        tickets.forEach((ticket) => promises.push(this.fetchUser(ticket)));

        return Promise.all(promises)
          .then((users) => {
            const notCheckedIn = users.filter((user) => !user.hasAttended);
            const checkedIn = users.filter((user) => user.hasAttended);
            const totalTickets = users.length || 0;
            const totalCheckedIn = checkedIn.length || 0;

            this.setState({ notCheckedIn, checkedIn, totalCheckedIn, totalTickets, loading: false });
          });
      });
  }

  fetchUser = (ticket) => {
    return restClient(GET_ONE, 'users', { id: ticket.user_id })
      .then((response) => response.data)
      .then((user) => {
        const { forename, surname, email } = user;
        const avatar = profilePictureUrl(user, 'medium');
        const name = [this.capitalize(forename), this.capitalize(surname)].join(' ').trim();
        const censoredEmail = this.censorEmail(email);
        return {
          id: ticket.id,
          name,
          email: censoredEmail,
          avatar,
          hasAttended: (ticket.has_attended || false),
        };
      });
  };

  updateTicket = (id) => {
    return restClient(UPDATE, 'tickets', {
      id,
      data: {
        has_attended: true,
      },
    })
      .then((response) => response.data);
  };


  capitalize = (str) => {
    if (!str || str.length === 0) return '';

    const words = str.toLowerCase().split(' ');
    return words.reduce((acc, name) => `${acc} ${name.charAt(0).toUpperCase()}${name.slice(1)}`, '');
  }

  censorEmail = (email) => {
    if (!email || email.length === 0) return '';

    let censored = '';
    const aux = email.split('@');
    censored = `${aux[0].replace(/\B(.)*/g, '*'.repeat(aux[0].length - 1))}@${aux[1]}`;
    return censored;
  }

  handleCheckIn = (id) => {
    this.setState({ loading: true });

    this.updateTicket(id)
      .then(() => this.fetchTickets());
  }


  render() {
    const date = moment(this.state.date).format('dddd, DD.MM.YYYY');
    const time = moment(this.state.date).format('hh:mm A');

    return (
      <MuiThemeProvider>
        <div style={styles.wrapper}>
          <div style={styles.sidebar}>
            <img style={styles.logo} src={logo} alt='logo' width='90' height='90' />

            <div style={styles.header}>
              <div style={styles.date}>
                <div>{date}</div>
                <div>{time}</div>
              </div>
              {this.state.trainer && this.state.trainer.length > 0 &&
                <div>With {this.state.trainer}</div>}
            </div>

            <div style={styles.footerNotes}>
              <p style={styles.userNotes}>* Please check-in only for yourself.</p>
              <p style={styles.userNotes}>Thank you so much for your cooperation!</p>

              <div style={styles.coachArea.main}>
                <Collapsible title='COACH AREA' collapsed={false}>
                  <div style={styles.coachArea.content}>
                    <p style={styles.coachArea.counter}>
                      # Participants: {this.state.totalTickets}
                    </p>
                    <p style={styles.coachArea.counter}>
                      # Checked-in: {this.state.totalCheckedIn}
                    </p>
                  </div>
                </Collapsible>
              </div>
            </div>
          </div>

          <div style={styles.main}>
            {/* {this.state.loading ? (
              <div style={this.state.notCheckedIn}>
                <CircularProgress color='white' size={20} innerStyle={styles.progress} />
              </div>
            ) : ( */}
            <List
              users={this.state.notCheckedIn}
              buttonLabel='CHECK IN'
              onCheckIn={this.handleCheckIn}
              elStyle={styles.lists.unchecked}
              loading={this.state.loading}
            />
            {/* )} */}
            <hr style={styles.divider} />
            <List
              users={this.state.checkedIn}
              buttonLabel='CHECKED IN'
              disabled
              elStyle={styles.lists.checked}
            />
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

UserCheckIn.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(UserCheckIn);
