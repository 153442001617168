import get from 'lodash/get';
import has from 'lodash/has';

const addCompanyFilterCapabilities = (requestHandler) => (type, resource, parameters) => {
  if (type === 'GET_LIST' && ['events'].includes(resource)) {
    const filter = { ...get(parameters, 'filter', {}) };
    if (has(filter, 'company')) {
      delete filter.with_company;
    }
    const params = { ...parameters, filter };
    return requestHandler(type, resource, params);
  }

  return requestHandler(type, resource, parameters);
};

export default addCompanyFilterCapabilities;
