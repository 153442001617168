import { withStyles } from '@material-ui/core/styles';
import LoadingIcon from '@material-ui/icons/Cached';
import ErrorIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import PropTypes from 'prop-types';
import React from 'react';

const style = () => ({
  icon: {
    width: '0.8em',
    height: '0.8em',
    marginRight: '20px',
  },
});

const SaveProgressIcon = ({ status, classes }) => {
  const iconToRender = () => {
    if (status === 'success') return <DoneIcon color='primary' className={classes.icon} />;
    if (status === 'loading') return <LoadingIcon color='secondary' className={classes.icon} />;
    if (status === 'error') return <ErrorIcon className={classes.icon} />;
  };

  return <div>{iconToRender()}</div>;
};

SaveProgressIcon.propTypes = {
  status: PropTypes.string,
  classes: PropTypes.object,
};

export default withStyles(style)(SaveProgressIcon);
