import React from 'react';
import PropTypes from 'prop-types';
import PlayableButton from '../../components/PlayableButton';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { playAudioFile, stopAudioFile } from './actions';

const REDUX_AUDIO_PLAYER_NAME = 'audio-player';
const API_URL = process.env.REACT_APP_API_URL;

const AssetPlayableButton = ({
  assetId,
  file,
  playLabel,
  stopLabel,
  playingFile,
  playAudioFile,
  stopAudioFile,
}) => {
  const isPlaying = playingFile.assetId === assetId;
  const filePath = `${API_URL}/assets/${assetId}/download/original`;
  const contentType = file.mime_type;

  const onPlay = () => {
    return playAudioFile({
      assetId,
      filePath,
      contentType,
    });
  };

  const onStop = () => {
    return stopAudioFile({
      assetId,
      filePath,
      contentType,
    });
  };

  return <PlayableButton
    playLabel={playLabel}
    stopLabel={stopLabel}
    isPlaying={isPlaying}
    onPlay={onPlay}
    onStop={onStop}
  />;
};

AssetPlayableButton.propTypes = {
  assetId: PropTypes.string,
  file: PropTypes.object,
  playLabel: PropTypes.string,
  stopLabel: PropTypes.string,
  playingFile: PropTypes.object,
  playAudioFile: PropTypes.func,
  stopAudioFile: PropTypes.func,
};

AssetPlayableButton.defaultProps = {
  assetId: null,
  filePath: null,
  playLabel: null,
  stopLabel: null,
  playingFile: {},
  playAudioFile: null,
  stopAudioFile: null,
};

const mapStateToProps = (state) => state[REDUX_AUDIO_PLAYER_NAME];

const mapDispatchToProps = {
  playAudioFile,
  stopAudioFile,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(AssetPlayableButton);
