import { get } from 'lodash';

class HttpError extends Error {
  constructor (message, status, body = null) {
    super(message);
    this.name = 'HttpError';
    this.details = get(body, 'details');
    this.code = get(body, 'code');
    this.body = body;

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
    this.stack = new Error().stack;
  }
}

export default HttpError;
