import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import secondsToHMS from 'utils/secondsToHMS';


const SecondsAsHhMmSsField = ({ record = {}, source }) => {
  const time = secondsToHMS(get(record, source));

  return (
    <span>{time.hh}:{time.mm}:{time.ss}</span>
  );
};

SecondsAsHhMmSsField.propTypes = {
  label: PropTypes.string, // eslint-disable-line
  addLabel: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

SecondsAsHhMmSsField.defaultProps = {
  addLabel: true,
  record: {},
};

export default SecondsAsHhMmSsField;
