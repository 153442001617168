import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import isEmpty from 'lodash/isEmpty';

import formattedFullName from './utils/formattedFullName';
import AvatarField from './AvatarField';


const FullNameField = ({ record, size, showAvatar }) => {
  if (isEmpty(record)) {
    return null;
  }

  return (
    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
      {showAvatar && <AvatarField record={record} size={size} />}
      <span style={{ marginLeft: showAvatar ? '.5em' : '0' }}>{formattedFullName(record)}</span>
    </div>
  );
};

FullNameField.propTypes = {
  record: PropTypes.object,
  showAvatar: PropTypes.bool,
  size: PropTypes.number,
};

FullNameField.defaultProps = {
  source: 'last_name',
  label: 'resources.users.fields.name',
  record: null,
  showAvatar: true,
};

export default pure(FullNameField);
