import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import TicketIcon from '@material-ui/icons/ConfirmationNumber';
import get from 'lodash/get';
import { stringify } from 'query-string';
import React from 'react';
import { Link } from 'react-router-dom';

const styles = {
  icon: { paddingRight: '0.5em' },
  link: {
    display: 'inline-flex',
    alignItems: 'center',
  },
};

const LinkToRelatedTickets = ({ classes, record }) => (
  <Button
    size='small'
    color='primary'
    component={Link}
    to={{
      pathname: '/tickets',
      search: stringify({
        filter: JSON.stringify({ event_id: get(record, 'id', '') }),
      }),
    }}
    className={classes.link}
    label='Tickets'
  >
    <TicketIcon className={classes.icon} />
  </Button>
);

export default withStyles(styles)(LinkToRelatedTickets);
