import _times from 'lodash/times';
import _map from 'lodash/map';
import moment from 'moment';

export const entityDefaultValues = {
  is_archived: false,
  date_begin: new Date(),
  duration: 45,
  max_participants: 24,
};

export const dow = [
  { id: 1, name: 'Monday' },
  { id: 2, name: 'Tuesday' },
  { id: 3, name: 'Wednesday' },
  { id: 4, name: 'Thursday' },
  { id: 5, name: 'Friday' },
  { id: 6, name: 'Saturday' },
  { id: 7, name: 'Sunday' },
];

export const offerStatuses = [
  { id: 'open', name: 'Open' },
  { id: 'lost', name: 'Lost' },
  { id: 'accepted', name: 'Accepted' },
  { id: 'cancelled', name: 'Cancelled' },
];

export const languages = [
  { id: 'en', name: 'English' },
  { id: 'de', name: 'German' },
];

export const hod = _map(_times(24), (n) => ({ id: n, name: `${n}h` }));

export const datetimeFormat = {
  weekday: 'short',
  year: '2-digit',
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
};

// dd.MM.yyyy mm.hh ddd (e.g. 07.07.2018 Mon, or 01.12.2018 Tue)
export const datetimeFormat2 = 'dd.MM.yyyy mm.hh ddd';
//
// {
//   weekday: 'short',
//   year: '2-digit',
//   month: 'numeric',
//   day: 'numeric',
//   hour: '2-digit',
//   minute: '2-digit',
// };

export const cancellingReasons = [
  { id: 'mistake', name: 'Created by mistake', automatic_email: false },
  { id: 'bad_weather', name: 'Bad weather', automatic_email: true },
  { id: 'coach_sickness', name: 'Coach sickness', automatic_email: true },
  { id: 'not_enough_participants', name: 'Not enough participants', automatic_email: true },
  { id: 'other', name: 'Other reason', automatic_email: false },
];

export const USC_ALERT_THRESHOLD = moment.duration({ days: 14 }).asSeconds()

export const USC_ALERT_MESSAGE = "This event starts less than 14 days in the future. If you change the time of the event, it will not change on the USC platform and result in customer issues. Other changes can be made without an issue.";

export const eventCancellationReasonsAndOutcomes = {
  coach_no_show: {
    user_compensation: "refund",
    coach_penalty: false,
    customer_email: "coach_no_show"
  },
  location_unavailable: {
    user_compensation: "refund",
    coach_penalty: false,
    customer_email: "location_unavailable"
  },
  not_enough_bookings: {
    user_compensation: "refund",
    coach_penalty: false,
    customer_email: "not_enough_bookings"
  },
  no_cover_found: {
    user_compensation: "refund",
    coach_penalty: false,
    customer_email: "no_cover_found"
  },
  unforseen_circumstances: {
    user_compensation: "refund",
    coach_penalty: false,
    customer_email: "unforseen_circumstances"
  },
  sublease_unavailable: {
    user_compensation: "refund",
    coach_penalty: false,
    customer_email: "sublease_unavailable"
  },
  no_regular_coach: {
    user_compensation: "refund",
    coach_penalty: false,
    customer_email: "no_regular_coach"
  },
  coach_on_vacation: {
    user_compensation: "refund",
    coach_penalty: false,
    customer_email: "coach_on_vacation"
  },
  other: {
    user_compensation: "refund",
    coach_penalty: false,
    customer_email: "unforseen_circumstances",
    cancellation_reason_comment: "other"
  }
};
