import React from 'react';
import { required, SimpleForm, TextInput } from 'react-admin';

import LocationReferenceInput from '../locations/LocationReferenceInput';

const SensorPoolForm = ({ isExisting, ...props }) => (
  <SimpleForm {...props} redirect={isExisting ? 'show' : 'list'}>
    <TextInput source='name' validate={required()} />
    <LocationReferenceInput source='place_id' resettable />
  </SimpleForm>
);

export default SensorPoolForm;
