import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import React from 'react';
import { Link } from 'react-admin';

import FullNameField from './FullNameField';

const styles = (theme) => ({
  link: {
    color: theme.palette.primary.main,
  },
});

const EmbeddedUserField = ({ record, className, classes = {}, isLoading, ...rest }) =>
  record.user ? (
    <Link to={{ pathname: `/users/${record.user_id}/show` }} className={className}>
      <FullNameField
        className={classnames(
          classes.link, // force color override for Typography components
        )}
        record={record.user}
        {...rest}
      />
    </Link>
  ) : null;

EmbeddedUserField.defaultProps = {
  source: 'user_id',
  addLabel: true,
  label: 'User',
  link: 'show',
  allowEmpty: true,
  record: {},
};

export default withStyles(styles)(EmbeddedUserField);
