import React from 'react';
import Typography from '@material-ui/core/Typography';

export const ActiveSubscriptionsContent = ({ record }) => {
  if (!record.active_subscriptions || !record?.active_subscriptions?.length) return null;
  return (
    <div>
      {record.active_subscriptions.map((subscription) => (
        <div style={{ marginBottom: 8 }} key={subscription.id}>
          <Typography variant='body1'>type: {subscription.type}</Typography>
          <Typography variant='body1'>plan_id: {subscription.plan_id}</Typography>
          <Typography variant='body1'>
            activated_at: {new Date(subscription.activated_at * 1000).toLocaleDateString('de-DE')}
          </Typography>
          <Typography variant='body1'>
            cancelled_at: {new Date(subscription.cancelled_at * 1000).toLocaleDateString('de-DE')}
          </Typography>
          <Typography variant='body1'>status: {subscription.status}</Typography>
        </div>
      ))}
    </div>
  );
};
