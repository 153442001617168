import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import get from 'lodash/get';
import { useRecordContext } from 'react-admin';

const AvatarField = ({ size, style = {}, record }) => {
  const userRecord = record && Object.keys(record).length > 0 ? record : useRecordContext();

  let url = null;

  if (size <= 28) {
    url = get(userRecord, 'profile_picture.medium.url');
  } else if (size > 28) {
    url = get(userRecord, 'profile_picture.large.url');
  }

  return <Avatar src={url} style={{ width: size, height: size, ...style }} />;
};

AvatarField.propTypes = {
  record: PropTypes.object,
  size: PropTypes.number,
};

AvatarField.defaultProps = {
  size: 28,
};

export default AvatarField;
