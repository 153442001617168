import React, { cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';


const styles = (theme) => ({
  root: {
    // color: theme.palette.text.secondary,
    // display: 'flex',
    // alignItems: 'flex-start',
  },
  active: {
    // color: theme.palette.text.primary,
  },
  // icon: { paddingRight: '1.2em' },
});

export class MenuItemLink extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    leftIcon: PropTypes.node,
    onClick: PropTypes.func,
    primaryText: PropTypes.string,
    staticContext: PropTypes.object,
    to: PropTypes.string.isRequired,
  };

  handleMenuTap = () => {
    this.props.onClick && this.props.onClick();
  };

  render () {
    const {
      classes,
      className,
      primaryText,
      leftIcon,
      staticContext,
      ...props
    } = this.props;

    return (
      <ListItem
        button
        className={classnames(classes.root, className)}
        activeClassName={classes.active}
        component={NavLink}
        {...props}
        onClick={this.handleMenuTap}
      >
        {leftIcon && (
          <ListItemIcon>
            {cloneElement(leftIcon, { titleAccess: primaryText })}
          </ListItemIcon>
        )}
        <ListItemText inset primary={primaryText} />
      </ListItem>
    );
  }
}

export default withStyles(styles)(MenuItemLink);
