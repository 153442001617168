import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, NumberField, SimpleShowLayout, TextField } from 'react-admin';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import IconImageEye from '@material-ui/icons/RemoveRedEye';
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import BooleanField from 'reactAdmin/fields/VerbalBooleanField';
import CustomDateField from 'reactAdmin/fields/CustomDateField';

const styles = (theme) => ({
  field: {
    // These styles will ensure our drawer don't fully cover our
    // application when teaser or title are very long
    '& span': {
      display: 'inline-block',
      maxWidth: '20em',
    },
  },
});

const EventPreviewView = ({ classes, ...props }) => (
  <SimpleShowLayout {...props}>
    <TextField source='id' label='Event ID' className={classes.field} />
    <CustomDateField
      source='date_begin'
      timezoneSource='location.timezone'
      label='Starts At'
      className={classes.field}
    />
    <BooleanField source='is_published' label='Published' className={classes.field} />
    <BooleanField source='is_cancelled' inverted label='Cancelled' className={classes.field} />
    {/* <TrainerReferenceField className={classes.field} /> */}
    {/* <LocationReferenceField className={classes.field} /> */}
    {/* <GroupReferenceField className={classes.field} /> */}
    <TextField source='location.name' label='Location' className={classes.field} />
    <TextField source='coach.forename' label='Coach' className={classes.field} />
    <NumberField source='max_participants' className={classes.field} />
    <NumberField source='duration' label='Duration (in minutes)' className={classes.field} />
  </SimpleShowLayout>
);

const mapStateToProps = (state, props) => ({
  // Get the record by its id from the react-admin state.
  record: state.admin.resources[props.resource] ? state.admin.resources[props.resource].data[props.id] : null,
  version: state.admin.ui.viewVersion,
});

const EventPreview = connect(mapStateToProps, {})(withStyles(styles)(EventPreviewView));

class EventQuickPreviewButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPanel: false,
    };
  }

  handleClick = () => {
    this.setState({ showPanel: true });
  };

  handleCloseClick = () => {
    this.setState({ showPanel: false });
  };

  render() {
    const { showPanel } = this.state;
    const { id } = this.props;

    return (
      <>
        <Button onClick={this.handleClick} label='Preview'>
          <IconImageEye />
        </Button>
        <Drawer anchor='right' open={showPanel} onClose={this.handleCloseClick}>
          <div>
            <Button label='Close' onClick={this.handleCloseClick}>
              <IconKeyboardArrowRight />
            </Button>
          </div>
          <EventPreview id={id} basePath='/events' resource='events' />
        </Drawer>
      </>
    );
  }
}

export default connect()(EventQuickPreviewButton);
