import _pick from 'lodash/pick';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import React from 'react';
import { SimpleForm, BooleanInput, TextInput } from 'react-admin';
import { useLocation } from 'react-router-dom';

import EventReferenceField from '../events/EventReferenceField';
import EventReferenceInput from '../events/EventReferenceInput';
import UserReferenceField from '../users/UserReferenceField';
import UserReferenceInput from '../users/UserReferenceInput';

const TicketForm = ({ isExisting, ...props }) => {
  const location = useLocation();
  const { event_id: eventId } = parse(location.search);

  const redirect = eventId ? `/events/${eventId}/show/1` : isExisting ? 'show' : 'list';

  return (
    <SimpleForm
      {...props}
      initialValues={{ event_id: eventId }}
      redirect={redirect}
      save={(data, ...args) => {
        if (isExisting) {
          return props.save(_pick(data, ['is_chargeable'], ...args));
        }

        props.save(data, ...args);
      }}
    >
      {isExisting && <TextInput disabled source='id' />}

      {isExisting ? <EventReferenceField /> : <EventReferenceInput />}

      {isExisting ? <UserReferenceField /> : <UserReferenceInput />}

      {isExisting && <BooleanInput source='is_chargeable' label='Is Chargeable' defaultValue />}
    </SimpleForm>
  );
};

TicketForm.propTypes = {
  isExisting: PropTypes.bool,
};

TicketForm.defaultProps = {
  isExisting: false,
};

export default TicketForm;
