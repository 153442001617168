import React from 'react';
import PropTypes from 'prop-types';

import FullNameField from './FullNameField';


const TrainerTitle = ({ record }) => record ? <FullNameField record={record} size={64} /> : null;

TrainerTitle.propTypes = {
  record: PropTypes.object,
};
TrainerTitle.defaultProps = {
  record: {},
};

export default TrainerTitle;
