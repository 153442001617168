import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';

import { useRecordContext } from 'react-admin';
import sanitizeRestProps from './sanitizeRestProps';

const TruthyBooleanField = (props) => {
  const { source, ...rest } = props;
  const record = useRecordContext(props);
  let text = 'no';

  if (get(record, source)) {
    text = 'yes';
  }

  return (
    <Typography
      component='span'
      // className={classnames({
      //   [classes.small]: props.record[props.source] < 100,
      //   [classes.big]: props.record[props.source] >= 100,
      // })}
      // {...sanitizeRestProps(rest)}
      {...sanitizeRestProps(rest)}
    >
      {text}
    </Typography>
  );
};

TruthyBooleanField.propTypes = {
  addLabel: PropTypes.bool,
  basePath: PropTypes.string,
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.object,
  sortBy: PropTypes.string,
  source: PropTypes.string.isRequired,
};

const PureVerbalBooleanField = pure(TruthyBooleanField);

PureVerbalBooleanField.defaultProps = {
  addLabel: true,
  label: 'Bool',
  record: null,
  positiveOnly: false,
  colored: true,
  inverted: false,
};

export default PureVerbalBooleanField;
