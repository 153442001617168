import compact from 'lodash/compact';
import join from 'lodash/join';

const formattedFullName = (record = {}) => {
  const name = join(compact([record.forename, record.surname]), ' ');
  const provider = record.provider ? `${record.provider}${record.usc_membership_type || ''}` : undefined;
  const info = record.is_virtual ? 'F' : join(compact([record.locale, record.performance_eval, provider]), '/');

  return info ? `${name} [${info}]` : name;
};

export default formattedFullName;
