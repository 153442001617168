import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

const FormattableTextField = ({source, record, format}) => {
  let formatted = null;
  const value = get(record, source);
  if (format) formatted = format(value);
  return <span>{formatted || value}</span>;
};

FormattableTextField.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object,
  format: PropTypes.func,
};

FormattableTextField.defaultProps = {
  source: null,
  record: {},
  format: null,
};

export default FormattableTextField;
