import React from 'react';
import { get } from 'lodash';
import { ReferenceField, TextField } from 'react-admin';

import CopyIdButton from 'components/CopyIdButton';

import FullNameField from './FullNameField';

const UserReferenceField = (props) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <ReferenceField reference='users' {...props}>
        {props.showMail ? <TextField source='email' /> : <FullNameField />}
      </ReferenceField>
      <CopyIdButton id={get(props, `record.${props.source}`)} />
    </div>
  );
};

UserReferenceField.defaultProps = {
  source: 'user_id',
  addLabel: true,
  label: 'User',
  link: 'show',
  allowEmpty: true,
};

export default UserReferenceField;
