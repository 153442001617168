import { Grid, Card, CardHeader, withStyles } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { get, isEmpty } from 'lodash';
import React, { useState } from 'react';
import { SimpleForm, SaveButton, Toolbar, TextInput, useCreate, useNotify, useUpdate } from 'react-admin';

const styles = (theme) => ({
  errorCard: {
    margin: '1em 0',
    color: theme.palette.error.main,
    text: {
      color: theme.palette.error.main,
    },
  },
});

const jsonStructurePlaceholder = {
  steps: [],
};

const EventTypeStructureForm = ({ record = {}, eventTypeId, classes }) => {
  const [create] = useCreate();
  const [update] = useUpdate();
  const notify = useNotify();
  const [errors, setErrors] = useState(null);

  const parsedRecord = {
    ...record,
    structure: JSON.stringify(get(record, 'structure')),
  };
  const handleSave = (values) => {
    const parsedValues = {
      ...values,
      structure: JSON.parse(get(values, 'structure')),
    };
    const postData = {
      event_type_id: eventTypeId,
      ...parsedValues,
    };

    if (values.id) {
      update('event-type-structures', values.id, parsedValues, null, {
        onSuccess: () => notify('EventTypeStructure has been updated', 'info'),
        onFailure: (e) => setErrors(e.body.errors),
      });
    }

    if (!values.id) {
      create('event-type-structures', postData, {
        onSuccess: () => notify('EventTypeStructure has been created', 'info'),
        onFailure: (e) => setErrors(e.body.errors),
      });
    }
  };

  const validate = (values) => {
    const errors = {};

    if (values.structure) {
      try {
        JSON.parse(values.structure);
      } catch (e) {
        errors.structure = 'Structure should be a valid JSON';
      }
    }

    return errors;
  };

  const ErrorList = ({ errors }) => {
    return (
      <Card className={classes.errorCard}>
        {errors.map((error, index) => {
          return <CardHeader key={index} disableTypography avatar={<ErrorIcon />} title={error.message} />;
        })}
      </Card>
    );
  };

  const StructureFormToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );

  return (
    <Grid container spacing={8}>
      <Grid item xs={12} sm={6}>
        <SimpleForm
          resource='event-type-structures'
          save={handleSave}
          record={parsedRecord}
          validate={validate}
          toolbar={<StructureFormToolbar />}
        >
          <TextInput
            style={{ width: '100%' }}
            multiline
            rowsMax='20'
            source='structure'
            placeholder={JSON.stringify(jsonStructurePlaceholder)}
          />
        </SimpleForm>
      </Grid>
      <Grid item xs={12} sm={6}>
        {!isEmpty(errors) && <ErrorList errors={errors} />}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(EventTypeStructureForm);
