import React from 'react';
import Typography from '@material-ui/core/Typography';
import UserReferenceField from 'reactAdmin/users/UserReferenceField';

export const StatusHistory = ({ record }) => {
  if (!record.status_history || !record?.status_history?.length) return null;
  return (
    <div>
      {record.status_history.map((item) => (
        <div style={{ marginBottom: 8 }} key={item.id}>
          <Typography variant='body1'>
            <b>Status</b>: {item.status_name}
          </Typography>
          {item.meta && (
            <Typography variant='body1'>
              <b>Meta</b>: {JSON.stringify(item.meta)}
            </Typography>
          )}
          <Typography variant='body1'>
            <b>Transitioned at</b>: {new Date(item.transitioned_at).toLocaleString('de-DE')}
          </Typography>
          <Typography variant='body1'>
            <b>Created by</b>: {item.created_by ? item.created_by : 'System'}
          </Typography>
          <UserReferenceField record={record} source='status_history.created_by' />
        </div>
      ))}
    </div>
  );
};
