import PropTypes from 'prop-types';
import React from 'react';

const NameField = ({ record }) =>
  record ? (
    <span>
      [{record.city_code}] {record.name}
    </span>
  ) : null;

NameField.propTypes = {
  record: PropTypes.object,
};

NameField.defaultProps = {
  record: {},
};

export default NameField;
