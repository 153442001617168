import { brand } from 'theme/colors';

export default {
  wrapper: {
    backgroundColor: '#292B2C',
    minHeight: '100%',
    position: 'fixed',
    height: '100%',
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    padding: '1rem',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingBottom: '1rem',
  },
  logo: {
    display: 'block',
    margin: '0 auto',
  },
  header: {
    color: 'white',
    fontSize: '1.2rem',
    marginTop: '2rem',
  },
  date: {
    marginBottom: '1rem',
  },
  small: {
    fontSize: '0.9rem',
  },
  main: {
    height: '95%',
    width: '95%',
    margin: '0 2rem 0 1rem',
    display: 'flex',
    flexDirection: 'column',
  },
  lists: {
    // header: {
    //   color: 'white',
    //   paddingLeft: '1rem',
    //   marginBottom: '0.5rem',
    //   height: '1rem',
    // },
    unchecked: {
      height: '65%',
    },
    checked: {
      height: '35%',
    },
  },
  divider: {
    width: '97%',
    margin: '1rem auto',
    border: `1px solid ${brand}`,
  },
  footerNotes: {
    selfAlign: 'flex-end',
    margin: 'auto 0 2rem',
  },
  userNotes: {
    fontSize: '1rem',
    color: 'white',
  },
  coachArea: {
    main: {
      borderTop: `1px solid ${brand}`,
    },
    content: {
      padding: '0 0.5rem',
    },
    counter: {
      color: 'white',
    },
    btn: {
      color: 'white',
      background: brand,
      padding: '0 1.5rem',
      margin: '0 auto',
    },
  },
};
