import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ReportIcon from '@material-ui/icons/Assignment';


const Reports = () => (
  <Card>
    <CardContent>
      <CardHeader title='Reports' />

      <List component='nav'>
        <ListSubheader>Users</ListSubheader>

        <ListItem
          component={Link}
          to={{ pathname: '/reports/birthdays' }}
        >
          <ListItemIcon>
            <ReportIcon />
          </ListItemIcon>
          <ListItemText
            primary='Birthdays'
            secondary='Lists all birthdays within a period'
          />
        </ListItem>


        <ListSubheader>Results</ListSubheader>
        <ListItem
          component={Link}
          to={{ pathname: '/reports/results/leaderboard' }}
        >
          <ListItemIcon>
            <ReportIcon />
          </ListItemIcon>
          <ListItemText
            primary='Leaderboard'
            secondary='Top 30 ranking of users by GRITPoints'
          />
        </ListItem>

        <ListSubheader>Retention</ListSubheader>
        <ListItem
          component={Link}
          to={{ pathname: '/reports/retention/chart' }}
        >
          <ListItemIcon>
            <ReportIcon />
          </ListItemIcon>
          <ListItemText
            primary='Cohort analysis'
            secondary='Cohort chart with customer retention rate'
          />
        </ListItem>
      </List>
    </CardContent>
  </Card>
);

export default withRouter(Reports);
