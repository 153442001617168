import React from 'react';
import { Login, LoginForm } from 'ra-ui-materialui';
import { withStyles } from '@material-ui/core/styles';
import { beat81Brand } from 'theme/colors';

const styles = {
  login: {
    main: {
      background: beat81Brand,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    card: {
      padding: '40px',
      margin: '0px',
    },
  },
  form: {
    button: {
      backgroundColor: beat81Brand,
      padding: '12px',
      '&:hover': {
        backgroundColor: beat81Brand,
      },
    },
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
};

const StyledLoginForm = withStyles(styles.form)(LoginForm);

const CustomLoginForm = () => (
    <>
      <div style={styles.center}>
        <img src='images/Beat81.png' alt='logo' />
      </div>
      <StyledLoginForm />
    </>
);

const MyLogin = (props) =>  (
  <Login backgroundImage='' {...props}>
    <CustomLoginForm />
  </Login>
);

export default withStyles(styles.login)(MyLogin);
