import React from 'react';
import { Filter, SelectArrayInput, SelectInput } from 'react-admin';

import UserReferenceInput from '../users/UserReferenceInput';
import EventReferenceInput from '../events/EventReferenceInput';
import {
  types as typeChoices,
  scopeRestrictions as scopeRestrictionChoices,
} from './constants';


const NoteFilter = (props) => (
  <Filter {...props}>
    <UserReferenceInput formType='filter' />
    <SelectInput source='type' choices={typeChoices} />
    <SelectArrayInput label='Restricted To' source='scope_restrictions' choices={scopeRestrictionChoices} />
    <EventReferenceInput formType='filter' />
  </Filter>
);


export default NoteFilter;
