import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';

import formattedNameString from './utils/formattedNameString';

const FullNameField = ({ record, showDate }) => {
  if (!record) {
    return null;
  }

  return (
    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
      {formattedNameString(record, showDate)}
    </div>
  );
};

FullNameField.propTypes = { record: PropTypes.object, showDate: PropTypes.bool };

FullNameField.defaultProps = {
  label: 'resources.users.fields.name',
  record: null,
  showDate: true,
  source: 'last_name',
};

export default pure(FullNameField);
