import withStyles from '@material-ui/core/styles/withStyles';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React from 'react';
import {
  BooleanInput,
  FormTab,
  ImageField,
  ImageInput,
  TabbedForm,
  TextInput,
  FormDataConsumer,
  NumberInput,
} from 'react-admin';

import TrainerReferenceInput from '../trainers/TrainerReferenceInput';
import { languages } from './constants';
import LocationTab from './location-form/LocationTab';
import LocationMapImageField from './LocationMapImageField';

const styles = () => ({
  fieldRow: {
    width: '100%',
  },
  detail: {
    marginRight: '2em',
  },
  fullWidthInput: {
    width: '100%',
  },
});

class LocationForm extends React.Component {
  render() {
    const { classes, isExisting, ...props } = this.props;
    const { record } = props;
    const canEditAnalyticsName = !isExisting || (record && isEmpty(record.analytics_name));

    const handleSave = (values) => {
      ['links', 'rules', 'codes'].forEach((attr) => {
        if (isEmpty(values[attr])) values[attr] = null;
      });
      return props.save(values);
    };

    const validateLocationForm = (values) => {
      const errors = {};
      if (!values.name_en) {
        errors.name_en = 'Required';
      } else if (!values.name_de) {
        errors.name_de = 'Required';
      }
      return errors;
    };

    return (
      <TabbedForm {...props} redirect={isExisting ? 'show' : 'list'} save={handleSave} validate={validateLocationForm}>
        <FormTab label='Basic'>
          {isExisting && <TextInput disabled source='id' />}
          <BooleanInput source='is_published' label='Published' />
          <NumberInput source='tier' max={10} min={1} defaultValue={10} />
          <TrainerReferenceInput label='Location Coach' optional />
          {canEditAnalyticsName ? (
            <TextInput label='Analytics Name' source='analytics_name' />
          ) : (
            <TextInput disabled label='Analytics Name' source='analytics_name' />
          )}
        </FormTab>

        <FormTab path='location' label='Location'>
          <FormDataConsumer>
            {(formDataProps) => <LocationTab {...formDataProps} {...props} classes={classes} />}
          </FormDataConsumer>
        </FormTab>

        <FormTab label='Features'>
          <BooleanInput source='flags.automatic_cancellation' label='Automatic Cancellation' defaultValue={false} />
        </FormTab>

        {isExisting && (
          <FormTab path='map_image' label='Map Image'>
            <LocationMapImageField />
            <ImageInput source='map_image_virtual' label='Upload/Replace Map Image' accept='image/*'>
              <ImageField source='src' title='title' />
            </ImageInput>
          </FormTab>
        )}

        {map(languages, (language) => (
          <FormTab key={language.code} path={`translation_${language.code}`} label={`${language.name} Translation`}>
            <TextInput label='Name' source={`name_${language.code}`} />
            <TextInput
              multiline
              label='Description'
              source={`description_${language.code}`}
              className={classes.fullWidthInput}
            />
            <TextInput
              multiline
              label='Short Description'
              source={`description_short_${language.code}`}
              className={classes.fullWidthInput}
            />
            <TextInput
              multiline
              label='Address Description'
              source={`description_address_${language.code}`}
              className={classes.fullWidthInput}
            />
          </FormTab>
        ))}
      </TabbedForm>
    );
  }
}

LocationForm.propTypes = {
  classes: PropTypes.object,
  isExisting: PropTypes.bool,
};
LocationForm.defaultProps = { classes: {}, isExisting: false };

export default withStyles(styles)(LocationForm);
