import PropTypes from 'prop-types';
import React from 'react';
import { Datagrid, FunctionField, ReferenceManyField, ReferenceField, Responsive, SelectField } from 'react-admin';

import BirthdayAgeField from '../../fields/BirthdayAgeField';
import { genders as genderChoices } from '../../users/constants';
import UserFullNameField from '../../users/FullNameField';

const styles = {
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  gridColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '20%',
  },
  center: {
    alignItems: 'center',
  },
  alignEnd: {
    alignItems: 'flex-end',
  },
  light: {
    color: 'rgba(0, 0, 0, 0.3)',
  },
  referenceField: {
    textDecoration: 'none',
  },
  username: {
    textDecoration: 'underline',
  },
};

const TicketHRDataReferenceManyField = (props) => (
  <ReferenceManyField
    {...props}
    reference='tickets'
    filter={{ status_ne: 'cancelled' }}
    target='event_id'
    sort={{ field: 'created_at', order: 'ASC' }}
  >
    <Datagrid>
      <ReferenceField
        label='Not cancelled tickets data'
        reference='users'
        source='user_id'
        link='show'
        elStyle={styles.referenceField}
      >
        <FunctionField
          render={(record) => {
            if (!record) return null;
            return (
              <Responsive
                small={
                  <div style={styles.row}>
                    <UserFullNameField record={record} size={25} elStyle={styles.username} />
                    <div style={{ ...styles.column, ...styles.alignEnd }}>
                      {record.dob_year && record.dob_month && record.dob_day ? (
                        <BirthdayAgeField
                          record={record}
                          label='Birthday'
                          srcDay='dob_day'
                          srcMonth='dob_month'
                          srcYear='dob_year'
                        />
                      ) : (
                        <div style={styles.light}>birthday</div>
                      )}
                      {record.gender ? (
                        <SelectField record={record} source='gender' choices={genderChoices} />
                      ) : (
                        <div style={styles.light}>gender</div>
                      )}
                      {record.height ? <div>{record.height} cm</div> : <div style={styles.light}>height</div>}
                      {record.weight ? <div>{record.weight} kg</div> : <div style={styles.light}>weight</div>}
                    </div>
                  </div>
                }
                medium={
                  <div style={styles.row}>
                    <div style={styles.gridColumn}>
                      <UserFullNameField record={record} size={25} elStyle={styles.username} />
                    </div>
                    <div style={{ ...styles.gridColumn, ...styles.center }}>
                      {record.dob_year && record.dob_month && record.dob_day ? (
                        <BirthdayAgeField
                          record={record}
                          label='Birthday'
                          srcDay='dob_day'
                          srcMonth='dob_month'
                          srcYear='dob_year'
                        />
                      ) : (
                        <div style={styles.light}>birthday</div>
                      )}
                    </div>
                    <div style={{ ...styles.gridColumn, ...styles.center }}>
                      {record.gender ? (
                        <SelectField record={record} source='gender' choices={genderChoices} />
                      ) : (
                        <div style={styles.light}>gender</div>
                      )}
                    </div>
                    <div style={{ ...styles.gridColumn, ...styles.center }}>
                      {record.height ? <div>{record.height} cm</div> : <div style={styles.light}>height</div>}
                    </div>
                    <div style={{ ...styles.gridColumn, ...styles.center }}>
                      {record.weight ? <div>{record.weight} kg</div> : <div style={styles.light}>weight</div>}
                    </div>
                  </div>
                }
              />
            );
          }}
        />
      </ReferenceField>
    </Datagrid>
  </ReferenceManyField>
);

TicketHRDataReferenceManyField.propTypes = {
  filter: PropTypes.object,
  hideCancellationFields: PropTypes.bool,
};

TicketHRDataReferenceManyField.defaultProps = {
  filter: {},
  hideCancellationFields: false,
};

export default TicketHRDataReferenceManyField;
