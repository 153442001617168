import isArray from 'lodash/isArray';

import { httpClient } from 'client';

const apiUrl = process.env.REACT_APP_API_URL;

const addUploadCapabilities = (requestHandler) => (type, resource, parameters) => {
  const params = { ...parameters };
  if (type === 'UPDATE' && resource === 'users') {
    if (params.data.avatar && isArray(params.data.avatar)) {
      const url = `${apiUrl}/media`;
      const images = params.data.avatar.filter((p) => p.rawFile instanceof File);
      const form = new FormData();
      const requestOptions = {
        method: 'PATCH',
        body: form,
      };

      // the params contain the image as a fileInstance
      // params.data.images = [];

      form.append('target', 'user');
      form.append('target_id', params.id);

      images.forEach((image) => {
        form.append('files', image.rawFile);
      });

      return httpClient(url, requestOptions).then(() => {
        delete (params.data.avatar);

        return requestHandler(type, resource, params);
      });
    }
  }

  return requestHandler(type, resource, params);
};

export default addUploadCapabilities;
