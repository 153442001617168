import { useQuery } from 'react-admin';
import React from 'react';
import { Typography } from '@material-ui/core';
import Cached from '@material-ui/icons/Cached';

const UserTextField = ({ id }) => {
	const
		{ data, error, loading } = useQuery({
			type: 'getOne',
			resource: 'users',
			payload: { id },
		});
	if (error) {
		return <Typography variant='caption' color='error'>Error</Typography>
	}
	if (data) {
		return <Typography variant='primary'>{`${data.forename} ${data.surname}`}</Typography>
	}
	if (loading) {
		return <Cached fontSize='small'></Cached>
	}
	return null;
}

export default UserTextField;
