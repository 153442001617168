import React from 'react';
import { ReferenceField } from 'react-admin';
import { get } from 'lodash';

import CopyIdButton from 'components/CopyIdButton';
import NameField from './NameField';

const LocationReferenceField = (props) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <ReferenceField {...props} reference='locations' allowEmpty>
        <NameField />
      </ReferenceField>
      <CopyIdButton id={get(props, 'record.location_id')} />
    </div>
  );
};

LocationReferenceField.defaultProps = {
  addLabel: true,
  record: null,
  label: 'Location',
  link: 'show',
  source: 'location_id',
};

export default LocationReferenceField;
