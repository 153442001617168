import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EventsCard from './EventsCard';
import NewUsers from './NewUsers';
import Reports from './Reports';
import UpcomingEvents from './UpcomingEvents';
import PastEvents from './PastEvents';
// import ThisMonthsAttendances from './ThisMonthsAttendances';
// import SignupChart from './SignupChart';
// import BookingChart from './BookingChart';
import styles from './styles';


// eslint-disable-next-line
class Dashboard extends Component {
  render() {
    const { newUsers } = this.props;

      // <PageLayout>
    return (
        <div style={styles.flex}>
          <div style={styles.leftCol}>
            {/* <ThisMonthsAttendances value={32} /> */}
            <UpcomingEvents />
            <PastEvents />
            {/*
              <BookingChart />
              <SignupChart />
            */}
          </div>

          <div style={styles.rightCol}>
            <Reports />
            {/* <NewUsers users={newUsers} /> */}
          </div>

        </div>
    );
      // </PageLayout>
  }
}

Dashboard.propTypes = {
  newUsers: PropTypes.array,
};

Dashboard.defaultProps = {
  newUsers: null,
};

export default Dashboard;
