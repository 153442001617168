import { get } from 'lodash';
import { httpClient } from 'client';
import createMergeQuery from '../helpers/createMergeQuery';

const apiUrl = process.env.REACT_APP_API_URL;

const useMergeAccount = async ({ destinationUserRecord, sourceUserRecord }) => {
  try {
    const requestBody = createMergeQuery({
      destinationUserId: get(destinationUserRecord, 'id'),
      sourceUserId: get(sourceUserRecord, 'id'),
    });
    const data = await httpClient(`${apiUrl}/account-management`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
    }).then(({ json }) => json.data);
    return { data, success: true, error: false };
  } catch (error) {
    return { data: null, success: false, error: error?.message };
  }
};

export default useMergeAccount;
