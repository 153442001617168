
import React, { useEffect, useState } from 'react';
import { httpClient } from 'client';

const apiUrl = process.env.REACT_APP_API_URL;

export const UserFullFillment = ({ record }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const fetchData = async () => {
        await httpClient(`${apiUrl}/payments/v2/credits/${record.credit_id}`)
            .then(({ json }) => setData(json?.data))
            .catch((error) => setError(error))
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        if (record?.credit_id && !record?.remote_plan_id) {
            fetchData();
        }
    }, [record.credit_id]);
    if (loading) {
        return <p>Loading...</p>;
    }
    if (error) {
        return <p>Error</p>;
    }
    if (record?.remote_plan_id) {
        return <p>{record.remote_plan_id}</p>
    }
    if (data?.remote_plan_id) {
        return <p>{data.remote_plan_id}</p>
    }
    if (record?.payment?.provider) {
        return <p>{record?.payment?.provider}</p>
    }
    if (record.payment?.charge?.fulfillment) {
        return <p>{record.payment.charge.fulfillment}</p>
    }
    return null
};


