import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Done from '@material-ui/icons/Done';
import Close from '@material-ui/icons/Close';
import capitalize from 'lodash/capitalize';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '2rem',
  },
  primary: {
    fontSize: theme.fontSize.primary,
  },
}));

const PartnerSyncStatus = ({ partner, status }) => {
  const classes = useStyles();
  const partnerName = partner === 'myclubs' ? 'MyClubs' : capitalize(partner);

  return status ? (
    <div className={classes.container}>
      <Done style={{ color: 'green' }} />
      <span className={classes.primary}>{`Successfully updated on ${partnerName}`}</span>
    </div>
  ) : (
    <div className={classes.container}>
      <Close style={{ color: 'red' }} /> <span className={classes.primary}>{`Failed to update on ${partnerName}`}</span>
    </div>
  );
};

export default PartnerSyncStatus;
