export const resourceMap = {
  'audio-files': {
    targetResource: 'assets',
    defaultQuery: {
      type: 'audio',
    },
  },
  'audio-sets': {
    targetResource: 'asset-sets',
    defaultQuery: {
      type: 'audio',
    },
  },
};
