import { isEmpty } from 'lodash';
import React from 'react';
import { AudioPlayer as AudioPlayerComponent } from './../../components/AudioPlayer';
import { connect } from 'react-redux';
import { compose } from 'recompose';

const REDUX_AUDIO_PLAYER_NAME = 'audio-player';

const AudioPlayer = ({ playingFile, style }) => {
  const renderPlayer = () => {
    if (isEmpty(playingFile)) return <></>;
    return <AudioPlayerComponent file={playingFile} style={style} />;
  };

  return renderPlayer();
};

const mapStateToProps = (state) => state[REDUX_AUDIO_PLAYER_NAME];

const enhance = compose(
  connect(mapStateToProps)
);

export default enhance(AudioPlayer);
