import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import get from 'lodash/get';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import sanitizeRestProps from './sanitizeRestProps';

const styles = theme => ({
  positive: {
    color: 'green',
  },
  negative: {
    color: 'red',
  },
  nil: {
    fontStyle: 'italic',
    color: '#bbb',
  },
});

const VerbalBooleanText = ({
  className,
  source,
  record,
  classes,
  notSetText,
  positiveOnly,
  colored,
  inverted,
  strict,
  ...rest
}) => {
  let newClassName = classnames(className, classes.nil);
  let text = notSetText || 'n/a';

  const isValueEmpty =
    strict === true ? get(record, source) === false : !get(record, source);

  const isValuePresent =
    strict === true ? get(record, source) === true : get(record, source);

  if (!positiveOnly && isValueEmpty) {
    // return <span className={classnames(colored && (inverted ? classes.positive : classes.negative))} {...rest}>no</span>;
    newClassName = classnames(
      className,
      colored && (inverted ? classes.positive : classes.negative)
    );
    text = 'no';
  }

  if (isValuePresent) {
    // return <span className={classnames(colored && (inverted ? classes.negative : classes.positive))} {...rest}>yes</span>;
    newClassName = classnames(
      className,
      colored && (inverted ? classes.negative : classes.positive)
    );
    text = 'yes';
  }

  return (
    <Typography
      component='span'
      className={newClassName}
      // className={classnames({
      //   [classes.small]: props.record[props.source] < 100,
      //   [classes.big]: props.record[props.source] >= 100,
      // })}
      // {...sanitizeRestProps(rest)}
      {...sanitizeRestProps(rest)}
    >
      {text}
    </Typography>
  );
};

const StyledVerbalBooleanText = withStyles(styles)(VerbalBooleanText);

const VerbalBooleanField = props => <StyledVerbalBooleanText {...props} />;

VerbalBooleanField.propTypes = {
  addLabel: PropTypes.bool,
  basePath: PropTypes.string,
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.object,
  sortBy: PropTypes.string,
  source: PropTypes.string.isRequired,

  classes: PropTypes.object,
  positiveOnly: PropTypes.bool,
  colored: PropTypes.bool,
  inverted: PropTypes.bool,
  notSetText: PropTypes.string,
};

const PureVerbalBooleanField = pure(VerbalBooleanField);

PureVerbalBooleanField.defaultProps = {
  addLabel: true,
  label: 'Bool',
  record: null,
  positiveOnly: false,
  colored: true,
  inverted: false,
  strict: true,
};

export default PureVerbalBooleanField;
