import React from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from '../../components/EventCreate/RecurringEventForm';
import { USC_ALERT_MESSAGE } from './constants';
import { isEventBeforeUSCThreshold } from './utils/calculateEventsWarningTime';

export const EventFormWarning = ({ startTime, message = USC_ALERT_MESSAGE }) => {
    const styles = useStyles();
    const showUSCWarning = isEventBeforeUSCThreshold(new Date(), startTime);
    if (showUSCWarning) {
        return <Box className={styles.warningMessage}>{message}</Box>
    }
    return null;
};
