/* eslint react/no-danger: 0 */
import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles';


class GritpointsMeter extends React.Component {
  componentWillMount () {
    this.calculateDynamicStyle();
  }

  componentWillReceiveProps (nextProps) {
    if (typeof (nextProps.prevPoints) !== 'undefined' && this.props.prevPoints !== nextProps.prevPoints) {
      this.calculateLead1(Math.max(0, nextProps.prevPoints));
    }
    if (typeof (nextProps.avgPoints) !== 'undefined' && this.props.avgPoints !== nextProps.avgPoints) {
      this.calculateLead2(nextProps.avgPoints);
    }
  }


  calculateLead1 = (prevPoints) => {
    const diff = this.props.record.gritpoints - prevPoints;
    const options = {
      equal: 'Same as<br />your last workout',
      lower: `${Math.abs(diff)} points less compared<br />to your last workout`,
      lowerSingular: '1 point less compared<br />to your last workout',
      higher: `${Math.abs(diff)} points more compared<br />to your last workout`,
      higherSingular: '1 point more compared<br />to your last workout',
    };

    let opt;
    if (diff < 0 && Math.abs(diff) === 1) {
      opt = options.lowerSingular;
    } else if (diff < 0) {
      opt = options.lower;
    } else if (diff > 0 && Math.abs(diff) === 1) {
      opt = options.higherSingular;
    } else if (diff > 0) {
      opt = options.higher;
    } else {
      opt = options.equal;
    }

    this.setState({ lead1: opt });
  }

  calculateLead2 = (avgPoints) => {
    if (!avgPoints || avgPoints === '') return;
    const diff = this.props.record.gritpoints - avgPoints;

    const options = {
      equal: `<b>Same as the average</b> GRITpoints (<b>${avgPoints}</b>) of this workout`,
      lower: `<b>${Math.abs(diff)} points bellow the average</b> GRITpoints (<b>${avgPoints}</b>) of this workout`,
      lowerSingular: `<b>1 point bellow the average</b> GRITpoints (<b>${avgPoints}</b>) of this workout`,
      higher: `<b>${Math.abs(diff)} points above the average</b> GRITpoints (<b>${avgPoints}</b>) of this workout`,
      higherSingular: `<b>1 point above the average</b> GRITpoints (<b>${avgPoints}</b>) of this workout`,
    };

    let opt;
    if (diff < 0 && Math.abs(diff) === 1) {
      opt = options.lowerSingular;
    } else if (diff < 0) {
      opt = options.lower;
    } else if (diff > 0 && Math.abs(diff) === 1) {
      opt = options.higherSingular;
    } else if (diff > 0) {
      opt = options.higher;
    } else {
      opt = options.equal;
    }
    this.setState({ lead2: opt });
  }

  calculateDynamicStyle = () => {
    const percent = (this.props.record.gritpoints / 30) * 100;
    const dynamicStyle = (this.props.record.gritpoints > 24)
      ? { paddingLeft: 'calc(100% - 80px)' }
      : { paddingLeft: `${percent}%` };

    this.setState({ dynamicStyle });
  }

  createMarkup = (text = '') => ({ __html: text });


  render () {
    return (
      <div style={{ ...styles.section }}>
        <div style={{ ...styles.points.section, ...this.state.dynamicStyle }}>
          <div style={styles.points.balloon}>

            {this.props.record.gritpoints > 13 &&
              <div dangerouslySetInnerHTML={this.createMarkup(this.state.lead1)} style={styles.points.leftText} />}

            <div style={styles.points.rectangle}>
              {this.props.record.gritpoints}
            </div>
            <div style={styles.points.triangle} />

            {this.props.record.gritpoints <= 13 &&
              <div dangerouslySetInnerHTML={this.createMarkup(this.state.lead1)} style={styles.points.rightText} />}
          </div>
        </div>


        <div style={styles.performance.bar}>
          <div>
            <span style={styles.performance.text}>innefficient</span>
            <br />
            <span>0-8</span>
          </div>

          <div style={styles.performance.divider} />

          <div>
            <span style={styles.performance.text}>efficient</span>
            <br />
            <span>9-16</span>
          </div>

          <div style={styles.performance.divider} />

          <div>
            <span style={styles.performance.text}>super efficient</span>
            <br />
            <span>17+</span>
          </div>
        </div>

        {this.state.lead2 &&
          <div dangerouslySetInnerHTML={this.createMarkup(this.state.lead2)} style={styles.lead2} />}
      </div>
    );
  }
}

GritpointsMeter.propTypes = {
  prevPoints: PropTypes.number,
  avgPoints: PropTypes.number,
  record: PropTypes.object,
};

GritpointsMeter.defaultProps = {
  prevPoints: null,
  avgPoints: null,
  record: {},
};

export default GritpointsMeter;
