/* eslint-disable react/sort-comp */
import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { GET_LIST } from 'react-admin';
import moment from 'moment-timezone';
import get from 'lodash/get';

import { restClient } from 'client';
// eslint-disable-next-line
import Leaderboard from 'components/ResultsLeaderboard';


class LeaderboardPage extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      page: 1,
      perPage: 30,
      sort: 'sum_points',
      order: 'ASC',
    };
  }

  getCurrentYear = () => moment().year();
  getCurrentMonth = () => moment().month() + 1;
  getCurrentWeek = () => moment().week();

  componentDidMount () {
    this.fetchLeaderboard({ month: this.getCurrentMonth() });
  }

  fetchLeaderboard = async (params) => {
    try {
      const interval = params.interval ? params.interval : (params.month ? 'monthly' : 'weekly');
      const year = params.year || this.state.year || this.getCurrentYear();
      const filter = { interval, year };

      if (interval === 'monthly') {
        filter.month = params.month || this.state.month || this.getCurrentMonth();
      } else {
        filter.week = params.week || this.state.week || this.getCurrentWeek();
      }

      this.setState({ isLoading: true });

      const { page, perPage, sort, order } = this.props;
      const response = await restClient(GET_LIST, 'reports/results/leaderboard', {
        pagination: { page, perPage },
        filter,
        sort: { field: sort, order },
      });

      this.setState({
        items: get(response, 'data.data', []),
        isLoading: false,
        ...filter,
      });
    } catch (error) {
      this.setState({ isLoading: true });
      throw error;
    }
  }

  handleChangeInterval = (interval) => this.fetchLeaderboard({ interval });

  handleChangePeriod = (period) => this.fetchLeaderboard(period);

  render () {
    const { isLoading, items, interval, year, month, week } = this.state;

    return (
      <Leaderboard
        loading={isLoading}
        items={items}
        interval={interval}
        year={year}
        month={month}
        week={week}
        onChangeInterval={this.handleChangeInterval}
        onChangePeriod={this.handleChangePeriod}
      />
    );
  }
}

LeaderboardPage.propTypes = {
  page: PropTypes.number,
  perPage: PropTypes.number,
  sort: PropTypes.string,
  order: PropTypes.string,
};

LeaderboardPage.defaultProps = {
  page: 1,
  perPage: 30,
  sort: 'sum_points',
  order: 'ASC',
};

export default withRouter(LeaderboardPage);
