import { CREATE } from 'react-admin';

export const ATTENDANCE_APPROVE = 'ATTENDANCE_APPROVE';
export const ATTENDANCE_APPROVE_LOADING = 'ATTENDANCE_APPROVE_LOADING';
export const ATTENDANCE_APPROVE_FAILURE = 'ATTENDANCE_APPROVE_FAILURE';
export const ATTENDANCE_APPROVE_SUCCESS = 'ATTENDANCE_APPROVE_SUCCESS';

export const attendanceApprove = (id, data, basePath) => ({
  type: ATTENDANCE_APPROVE,
  payload: { data: { status_name: 'attended' } },
  meta: { resource: `tickets/${id}/status`, fetch: CREATE, refresh: true, cancelPrevious: false },
});

export const ATTENDANCE_REJECT = 'ATTENDANCE_REJECT';
export const ATTENDANCE_REJECT_LOADING = 'ATTENDANCE_REJECT_LOADING';
export const ATTENDANCE_REJECT_FAILURE = 'ATTENDANCE_REJECT_FAILURE';
export const ATTENDANCE_REJECT_SUCCESS = 'ATTENDANCE_REJECT_SUCCESS';

export const attendanceNegate = (id, data, basePath) => ({
  type: ATTENDANCE_REJECT,
  payload: { data: { status_name: 'missed' } },
  meta: { resource: `tickets/${id}/status`, fetch: CREATE, refresh: true, cancelPrevious: false },
});

export const ATTENDANCE_RESET = 'ATTENDANCE_RESET';
export const ATTENDANCE_RESET_LOADING = 'ATTENDANCE_RESET_LOADING';
export const ATTENDANCE_RESET_FAILURE = 'ATTENDANCE_RESET_FAILURE';
export const ATTENDANCE_RESET_SUCCESS = 'ATTENDANCE_RESET_SUCCESS';

export const attendanceReset = (id, data, basePath) => {
  return ({
    type: ATTENDANCE_RESET,
    payload: { data: { status_name: 'booked' } },
    meta: { resource: `tickets/${id}/status`, fetch: CREATE, refresh: true, cancelPrevious: false },
  });
};

export const REDEEM_WAITINGLIST = 'REDEEM_WAITINGLIST';
export const REDEEM_WAITINGLIST_LOADING = 'REDEEM_WAITINGLIST_LOADING';
export const REDEEM_WAITINGLIST_FAILURE = 'REDEEM_WAITINGLIST_FAILURE';
export const REDEEM_WAITINGLIST_SUCCESS = 'REDEEM_WAITINGLIST_SUCCESS';

export const redeemWaitinglist = (id, data, basePath) => {
  return ({
    type: REDEEM_WAITINGLIST,
    payload: { data: { status_name: 'booked' } },
    meta: { resource: `tickets/${id}/status`, fetch: CREATE, refresh: true, cancelPrevious: false },
  });
};

export const TICKET_CANCEL = 'TICKET_CANCEL';
export const TICKET_CANCEL_LOADING = 'TICKET_CANCEL_LOADING';
export const TICKET_CANCEL_FAILURE = 'TICKET_CANCEL_FAILURE';
export const TICKET_CANCEL_SUCCESS = 'TICKET_CANCEL_SUCCESS';

export const cancelTicket = (id, data, basePath) => {
  return ({
    type: TICKET_CANCEL,
    payload: { data: { status_name: 'cancelled' } },
    meta: { resource: `tickets/${id}/status`, fetch: CREATE, refresh: true, cancelPrevious: false },
  });
};
