import React from 'react';
import { Create } from 'react-admin';

import SensorForm from './SensorForm';


const SensorCreate = (props) => (
  <Create title='Create Sensor' {...props} undoable={false} >
    <SensorForm />
  </Create>
);


export default SensorCreate;
