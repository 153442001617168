import React from 'react';
import { Create } from 'react-admin';

import TicketForm from './TicketForm';


const TicketCreate = (props) => (
  <Create title='Create Ticket' {...props} undoable={false} >
    <TicketForm />
  </Create>
);

export default TicketCreate;
