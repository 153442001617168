import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import join from 'lodash/join';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
// import { CloudinaryContext, Image, Transformation } from 'cloudinary-react';


const ListItem = ({ item }) => {
  console.log('item', item);

  const username = join([get(item, 'user.forename'), get(item, 'user.surname')], ' ');
  const email = get(item, 'user.email');
  const locale = get(item, 'user.locale');
  // const cloudinaryPublicId = _get(item, 'user.profile_picture.public_id');

  return (
    <TableRow hoverable rowNumber={item.rank}>
      <TableCell numeric>{item.rank}</TableCell>
      <TableCell>
        {/*
        <CloudinaryContext cloudName="meet2move">
          <Image publicId={cloudinaryPublicId} secure="true">
            <Transformation width="28" height="28" gravity="face" radius="max" crop="crop" />
            <Transformation width="28" crop="scale" />
          </Image>
        </CloudinaryContext>
        */}
        {username}{locale && ` [${locale}]`}
      </TableCell>
      <TableCell>{email}</TableCell>
      <TableCell numeric>{item.sum_gritpoints}</TableCell>
      <TableCell numeric>{item.avg_gritpoints}</TableCell>
      <TableCell numeric>{item.sum_calories_burned}</TableCell>
      <TableCell numeric>{item.avg_calories_burned}</TableCell>
      <TableCell numeric>{item.num_results}</TableCell>
    </TableRow>
  );
};

ListItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ListItem;
