import React from 'react';
import PropTypes from 'prop-types';

import BatteryAlert from '@material-ui/icons/BatteryAlert';
// import Battery20Icon from '@material-ui/icons/Battery20';
import Battery30Icon from '@material-ui/icons/Battery30';
import Battery50Icon from '@material-ui/icons/Battery50';
import Battery60Icon from '@material-ui/icons/Battery60';
import Battery80Icon from '@material-ui/icons/Battery80';
import Battery90Icon from '@material-ui/icons/Battery90';
import BatteryFull from '@material-ui/icons/BatteryFull';
import BatteryUnknown from '@material-ui/icons/BatteryUnknown';


const BatteryLevelIcon = ({ level }) => {
  const defaultProps = {
    fontSize: 'inherit',
  };

  if (!level) {
    return <BatteryUnknown {...defaultProps} />;
  }

  if (level <= 20) {
    return <BatteryAlert {...defaultProps} />;
  } else if (level <= 30) {
    return <Battery30Icon {...defaultProps} />;
  } else if (level <= 50) {
    return <Battery50Icon {...defaultProps} />;
  } else if (level <= 60) {
    return <Battery60Icon {...defaultProps} />;
  } else if (level <= 80) {
    return <Battery80Icon {...defaultProps} />;
  } else if (level <= 90) {
    return <Battery90Icon {...defaultProps} />;
  } else if (level <= 100) {
    return <BatteryFull {...defaultProps} />;
  }

  return <BatteryUnknown {...defaultProps} />;
};

BatteryLevelIcon.propTypes = {
  level: PropTypes.number.isRequired,
};

export default BatteryLevelIcon;
