import Icon from '@material-ui/icons/InsertChart';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-admin';
import { Link } from 'react-router-dom';
import shouldUpdate from 'recompose/shouldUpdate';

const DailyStatsButton = ({ label, record, ...rest }) => (
  <Button
    component={Link}
    to={{
      pathname: '/reports/results/daily_stats',
      search: `?result_id=${record.id}`,
    }}
    label={label}
    {...rest}
  >
    <Icon />
  </Button>
);

DailyStatsButton.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
  label: PropTypes.string,
  record: PropTypes.object,
};

DailyStatsButton.defaultProps = {
  basePath: '',
  label: 'DailyStats',
  record: {},
};

const enhance = shouldUpdate(
  (props, nextProps) =>
    props.translate !== nextProps.translate ||
    (props.record && nextProps.record && props.record.id !== nextProps.record.id) ||
    props.basePath !== nextProps.basePath ||
    (props.record == null && nextProps.record != null),
);

export default enhance(DailyStatsButton);
