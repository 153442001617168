import React from 'react';
import { Show, SimpleShowLayout, DateField, TextField } from 'react-admin';

const NoteShow = (props) => (
  <Show title="Note Detail View" {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      {/* <RichTextField source="body" /> */}
      <DateField source="created_at" showTime locales="de-DE" />
    </SimpleShowLayout>
  </Show>
);


export default NoteShow;
