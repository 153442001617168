import EventIcon from '@material-ui/icons/Event';
import EventCreate from './EventCreate';
import EventEdit from './EventEdit';
import EventList from './EventList';
import EventShow from './EventShow';
import formattedNameString from './utils/formattedNameString';

export default {
  icon: EventIcon,
  list: EventList,
  show: EventShow,
  create: EventCreate,
  edit: EventEdit,
};

export { EventIcon, formattedNameString };
