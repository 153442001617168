import React from 'react';
import PropTypes from 'prop-types';
import { ListButton, EditButton } from 'react-admin';

import CardActions from '@material-ui/core/CardActions';

import CancelButton from './CancelButton';
import RedeemButton from './RedeemButton';
import AttendanceActionsField from './AttendanceActionsField';

const styles = {
  row: { display: 'flex', justifyContent: 'flex-end', flexFlow: 'row wrap' },
  cardAction: { zIndex: 2, display: 'inline-block', float: 'right', textAlign: 'right' },
};

const EventShowActions = ({ basePath, data, hasEdit, hasList }) => {
  return (
    <CardActions>
      <div style={styles.row}>
        {hasEdit && <EditButton basePath={basePath} record={data} />}
        {hasList && <ListButton basePath={basePath} />}
      </div>
      <div style={styles.row}>
        <AttendanceActionsField record={data} label='Set Attendance' />
      </div>
      <div style={styles.row}>
        <CancelButton record={data} />
        <RedeemButton record={data} />
      </div>
    </CardActions>
  );
};

EventShowActions.propTypes = {
  basePath: PropTypes.string,
  data: PropTypes.object,
  hasEdit: PropTypes.bool,
  hasList: PropTypes.bool,
};

EventShowActions.defaultProps = { hasEdit: true, hasList: true };

export default EventShowActions;
