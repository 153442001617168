const setFavIcon = () => {
  const envLevel = process.env.REACT_APP_ENV_LEVEL;
  const publicUrl = process.env.PUBLIC_URL;
  const favicon = document.getElementById('favicon');

  if (envLevel) {
    favicon.href = `${publicUrl}/favIcon/${envLevel}-favicon.png`;
  }
};

export default setFavIcon;
