import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

const TimeField = ({source, record}) => {
  let formattedTime = '--:--:--';
  const time = get(record, source);
  if (time) formattedTime = new Date(1000 * parseInt(time)).toISOString().substr(11, 8);
  return <span>{formattedTime}</span>;
};

TimeField.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object,
};

TimeField.defaultProps = {
  source: null,
  record: {},
};

export default TimeField;
