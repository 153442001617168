import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

const styles = {
  list: { display: 'flex' },
};

const TextArrayField = ({ record = {}, sources }) => {
  const list = sources.map((source, i) => {
    const val = get(record, source);

    return (val && (
      <span key={source}>
        {val}
        {i < (sources.length - 1) && ','}
        &nbsp;
      </span>
    ));
  });

  return <div style={styles.list}>{list}</div>;
};

TextArrayField.propTypes = {
  label: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
  addLabel: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  record: PropTypes.object,
  sources: PropTypes.array.isRequired,
};

TextArrayField.defaultProps = {
  addLabel: true,
  record: {},
};

export default TextArrayField;
