import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';


const DailyStatsLinkField = ({ record }) => {
  const ReportsLink = (props) => (
    <Link
      // href
      to={{
        pathname: '/reports/results/daily_stats',
        search: `?result_id=${record.id}`,
      }}
      {...props}
    />
  );

  return (
    <span>
      <Button variant='outlined' component={ReportsLink}>
        DailyStats
      </Button>
    </span>
  );
};

DailyStatsLinkField.propTypes = {
  record: PropTypes.object,
  label: PropTypes.string,
  addLabel: PropTypes.bool,
};

DailyStatsLinkField.defaultProps = {
  addLabel: true,
  label: 'Links',
  record: null,
};

export default DailyStatsLinkField;
