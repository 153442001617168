import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import moment from 'moment-timezone';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';


const style = (theme) => ({
  root: {
    display: 'flex',
    // justifyContent: 'center',
    // justifyContent: 'center',
    flexWrap: 'wrap',
    paddingTop: theme.spacing.unit / 2,
    paddingBottom: theme.spacing.unit / 2,
  },
  chip: {
    marginRight: theme.spacing.unit / 2,
  },
  chipInfo: {
    height: '22px',
    marginRight: theme.spacing.unit / 2,
    backgroundColor: blue[500],
    color: 'white',
  },
  chipSuccess: {
    height: '22px',
    marginRight: theme.spacing.unit / 2,
    backgroundColor: green[500],
    color: 'white',
  },
  chipWarning: {
    height: '22px',
    marginRight: theme.spacing.unit / 2,
    backgroundColor: amber[500],
  },
  chipDanger: {
    height: '22px',
    marginRight: theme.spacing.unit / 2,
    backgroundColor: red[500],
    color: 'white',
  },
});

const SensorFlags = ({ sensor, classes }) => {
  const dateBegin = moment(sensor.date_begin);
  const isPassed = moment().isAfter(dateBegin.add(1, 'hour'));
  const isPublished = get(sensor, 'is_published', true);
  const isCancelled = get(sensor, 'is_cancelled', false);
  const resultsCount = get(sensor, 'results_count', 0);

  const participantsCount = get(sensor, 'participants_count', 0);
  const attendeesCount = get(sensor, 'attendees_count', 0);
  const noShowsCount = get(sensor, 'no_shows_count', 0);
  const hasAbnormalParticipansCount = participantsCount !== (attendeesCount + noShowsCount);

  return (
    <span className={classes.root}>
      {!isPublished && (
        <Chip
          label='Not Published'
          className={classes.chipInfo}
        />
      )}
      {isCancelled && (
        <Chip
          label='Cancelled'
          className={classes.chipWarning}
        />
      )}
      {isPassed && resultsCount <= 0 && (
        <Chip
          label='No Results'
          className={classes.chipDanger}
        />
      )}
      {isPassed && resultsCount > 0 && (
        <Chip
          label='✔ Results'
          className={classes.chipSuccess}
        />
      )}
      {isPassed && hasAbnormalParticipansCount && (
        <Chip
          label='Unknown Attendances'
          className={classes.chipWarning}
        />
      )}
    </span>
  );
};

SensorFlags.propTypes = {
  sensor: PropTypes.object,
};

SensorFlags.defaultProps = {
  sensor: null,
};

export default withStyles(style)(SensorFlags);
