import CardMedia from '@material-ui/core/CardMedia';
import has from 'lodash/has';
import PropTypes from 'prop-types';
import React from 'react';

const MapImageField = ({ record }) => {
  if (has(record, 'map_image.small.url')) {
    return (
      <CardMedia>
        <img
          srcSet={`${record.map_image.small.url} 512w, ${record.map_image.medium.url} 1024w, ${record.map_image.large.url} 2048w`}
          src={record.map_image.small.url}
          alt=''
        />
      </CardMedia>
    );
  }

  return null;
};

MapImageField.propTypes = {
  record: PropTypes.object,
};

MapImageField.defaultProps = {
  record: {},
};

export default MapImageField;
