import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import map from 'lodash/map';

import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import { cancellingReasons as cancellingReasonsChoices } from './constants';

import {
  cancelEvent as cancelEventAction,
} from './statusActions';

const styles = {
  text: {
    color: '#f44336',
  },
};

const ALLOWED_COMMENT_ROLES = ['admin', 'head_coach', 'customer_support_manager']

class CancelButton extends Component {
  constructor () {
    super();

    this.state = {
      showReasonDialog: false,
      showRecurrentDialog: false,
      reason: 'mistake',
      comment: '',
      shouldShowComment: false,
    };
  }

  componentDidMount () {
    this.checkIfUserShouldSeeComment();
  }

  checkIfUserShouldSeeComment = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user.roles && user.roles.some(role => ALLOWED_COMMENT_ROLES.includes(role))) {
      this.setState({ shouldShowComment: true });
    }
  }

  handleClick = (modal) => () => {
    this.setState({ [modal]: true });
  };

  handleCloseClick = (modal) => () => {
    this.setState({ [modal]: false });
  };

  handleSelect = (event) => {
    this.setState({ reason: event.target.value });
  };

  handleAction = (following) => () => {
    const { cancelEvent, record } = this.props;
    const { reason } = this.state;

    if (record.recurrent_id && following === undefined) {
      return this.handleClick('showRecurrentDialog')();
    }

    cancelEvent(record.id, { apply_to_following_events: following }, {
      reason,
      comment: this.state.comment,
    });
    this.setState({ showReasonDialog: false, showRecurrentDialog: false });
  }

  render () {
    const { record, classes } = this.props;
    const { showReasonDialog, showRecurrentDialog, reason } = this.state;

    return (
      <Fragment>
        <Button
          onClick={this.handleClick('showReasonDialog')}
          classes={classes}
          color='primary'
          disabled={get(record, 'current_status.status_name') !== 'scheduled'}
        >
          <CancelIcon /> Cancel event
        </Button>
        <Dialog fullWidth open={showReasonDialog} onClose={this.handleCloseClick('showReasonDialog')} aria-label='Are you sure?'>
          <DialogTitle>
            Are you sure you want to <strong>cancel</strong> this event?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <strong>Please select a reason to cancel it.</strong>
              <br/>
              <Select value={reason} onChange={this.handleSelect}>
                {map(cancellingReasonsChoices, (reason) => (
                  <MenuItem key={reason.id} value={reason.id}>{reason.name}{!reason.automatic_email && ` (no email will be sent)`}</MenuItem>
                ))}
              </Select>
              <br/>
              <small>If you select "Created by mistake" it will no longer be listed on the admin.</small>

              { this.state.shouldShowComment && (
                <TextField
                  label='Comment'
                  style={{ marginTop: 16, width: '85%' }}
                  value={this.state.comment}
                  onChange={(e) => this.setState({ comment: e.target.value })}
                />
              )}
            </DialogContentText>
          </DialogContent>


          <DialogActions>
            <Button onClick={this.handleCloseClick('showReasonDialog')}>
              No
            </Button>
            <Button onClick={this.handleAction()} classes={classes} key='button'>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog fullWidth open={showRecurrentDialog} onClose={this.handleCloseClick('showRecurrentDialog')} aria-label='Are you sure?'>
          <DialogTitle>
            Do you want to cancel only this event or this and the following recurent?
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.handleCloseClick('showRecurrentDialog')}>
              Cancel
            </Button>
            <Button onClick={this.handleAction(false)} classes={classes} key='button'>
              Cancel this event
            </Button>
            <Button onClick={this.handleAction(true)} classes={classes} key='button'>
              Cancel this and following events
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

CancelButton.propTypes = {
  record: PropTypes.object,
  cancelEvent: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

CancelButton.defaultProps = {
  record: {},
};

export default connect(null, {
  cancelEvent: cancelEventAction,
})(withStyles(styles)(CancelButton));
