import React from 'react';
import { Edit } from 'react-admin';

import SensorForm from './SensorForm';


const SensorEdit = (props) => (
  <Edit title='Edit Sensor' {...props} undoable={false} >
    <SensorForm isExisting />
  </Edit>
);


export default SensorEdit;
