import { RRule } from 'rrule';
import _get from 'lodash/get';
import moment from 'moment-timezone';

const createRuleSet = ({ date, frequency, eventLocation, occurence, every }) => {
  let freq;
  if (frequency === 'daily') {
    freq = RRule.DAILY;
  } else if (frequency === 'weekly') {
    freq = RRule.WEEKLY;
  } else if (frequency === 'monthly') {
    freq = RRule.MONTHLY;
  } else if (frequency === 'hourly') {
    freq = RRule.HOURLY;
  }
  const timezone = _get(eventLocation, 'timezone', 'Europe/Berlin');
  const dateBegin = moment(date);

  const ruleset = {
    freq,
    interval: every,
    dtstart: dateBegin.toDate(),
    count: occurence,
    tzid: timezone,
  };
  const rule = new RRule(ruleset);
  const occurrences = rule.all();
  return occurrences;
};

export default createRuleSet;
