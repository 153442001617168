import { diff } from 'deep-object-diff';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import {
  BooleanInput,
  FormTab,
  ImageField,
  ImageInput,
  NumberInput,
  SelectArrayInput,
  SelectInput,
  TabbedForm,
  FormDataConsumer,
  TextInput,
} from 'react-admin';
import { citiesMap, countries as countryChoices, districtsMap } from 'reactAdmin/locations/constants';

import UserAvatarField from './AvatarField';
import {
  roles as roleChoices,
  segments as segmentChoices,
  locales as localeChoices,
  genders as genderChoices,
  fitnessGoals,
  fitnessGoalLevels,
  companies,
} from './constants';
import ProviderSelectInput from './inputs/ProviderSelectInput';
import UscMembershipTypeInput from './inputs/UscMembershipTypeInput';
import { validateUser } from '../../utils/validateInputs';

function getCitiesFor(countryCode) {
  return get(citiesMap, countryCode, []);
}
function getDistrictsFor(countryCode, cityCode) {
  return get(districtsMap, `${countryCode}.${cityCode}`, []);
}

const UserForm = ({ isExisting, ...props }) => {
  const redirect = (isExisting) => (isExisting && props.record ? `/users/${props.record.id}/show` : 'list');
  return (
    <TabbedForm
      {...props}
      save={(data, ...args) => {
        props.save({ ...diff(props.record, data), roles: data.roles }, ...args);
      }}
      redirect={redirect(isExisting)}
    >
      <FormTab label='Account'>
        <TextInput source='forename' label='First Name' autoComplete='off' validate={validateUser.forename} />
        <TextInput source='surname' label='Last Name' autoComplete='off' />

        <TextInput source='email' validate={validateUser.email} />
        <BooleanInput source='is_vaccinated' />
        {!isExisting && <TextInput source='password' />}

        <ProviderSelectInput />
        <TextInput source='provider_id' label='Provider ID (e.g. USC Member No.)' />
        <UscMembershipTypeInput />
        <TextInput source='promo_code' label='Promo Code' />
        <SelectInput source='locale' label='Language' choices={localeChoices} allowEmpty resettable />
        <SelectInput label='Company' source='company' choices={companies} allowEmpty resettable />
      </FormTab>

      <FormTab path='personal_data' label='personal data'>
        <SelectInput source='gender' choices={genderChoices} allowEmpty />

        <NumberInput source='dob_day' step={1} validate={validateUser.dobDay} />
        <NumberInput source='dob_month' step={1} validate={validateUser.dobMonth} />
        <NumberInput source='dob_year' step={1} validate={validateUser.dobYear} />

        <NumberInput label='Height (in cm)' source='height' step={1} validate={validateUser.height} />
        <NumberInput label='Weight (in Kg)' source='weight' step={1} validate={validateUser.weight} />

        <SelectInput
          source='homezone_country_code'
          label='Country'
          choices={countryChoices}
          validate={validateUser.country}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <SelectInput
              source='homezone_city_code'
              label='City'
              choices={getCitiesFor(formData.homezone_country_code)}
              validate={validateUser.city}
              {...rest}
            />
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <SelectInput
              label='District'
              source='homezone_district_code'
              choices={getDistrictsFor(formData.homezone_country_code, formData.homezone_city_code)}
              allowEmpty
              {...rest}
            />
          )}
        </FormDataConsumer>

        <TextInput source='phone_number' />
        <BooleanInput source='phone_number_verified' />
      </FormTab>

      <FormTab path='fitness_data' label='fitness data'>
        <NumberInput
          source='performance_eval'
          label='Performance'
          step={1}
          validate={validateUser.fitnessPerformance}
        />
        <SelectInput source='fitness_goal' choices={fitnessGoals} allowEmpty />
        <SelectInput source='fitness_goal_level' choices={fitnessGoalLevels} allowEmpty />
        <NumberInput label='Max Heart Rate' source='max_heart_rate' step={1} />
      </FormTab>

      {isExisting && (
        <FormTab path='profile_picture' label='profile picture'>
          <UserAvatarField size={128} />
          <ImageInput source='avatar' label='Upload/Replace Image' accept='image/*'>
            <ImageField source='src' title='title' />
          </ImageInput>
        </FormTab>
      )}

      <FormTab path='advanced' label='advanced'>
        <BooleanInput source='is_virtual' label='Virtual User' />
        <SelectArrayInput source='segments' choices={segmentChoices} />
        <SelectArrayInput source='roles' choices={roleChoices} />
        <BooleanInput source='is_suspended' label='Account Suspended' />
      </FormTab>
    </TabbedForm>
  );
};

UserForm.propTypes = {
  isExisting: PropTypes.bool,
};

UserForm.defaultProps = {
  isExisting: false,
};

export default UserForm;
