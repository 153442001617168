import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  Button,
  Confirm,
  EditButton,
  DELETE,
  refreshView as refreshViewAction,
  showNotification as showNotificationAction,
} from 'react-admin';
import { Delete } from '@material-ui/icons';
import CardActions from '@material-ui/core/CardActions';
import {
  withStyles,
} from '@material-ui/core';
import { compose } from 'recompose';

import { restProvider as dataProvider } from '../../client';
import DeleteAssetErrorDialog from '../assets/DeleteAssetErrorDialog';

const styles = (theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
    text: {
      color: theme.palette.error.main,
    },
  },
});

const AudioShowActions = ({
  classes,
  basePath,
  data,
  resource,
  refreshView,
  showNotification,
}) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [error, setError] = useState();

  const handleConfirm = () => {
    dataProvider(DELETE, resource, { id: data.id })
      .then(() => {
        showNotification('Audio file deleted', 'info');
      })
      .catch((error) => {
        if (error.code === 'asset_is_referenced_by_asset_sets') {
          const audioSetIds = get(error, 'details.asset_set_ids');
          setError({
            title: 'Cannot delete audio file',
            message: 'The audio file is referenced by the following Audio Sets:',
            assetSetIds: audioSetIds,
          });
        } else {
          showNotification('An error occurred, audio file not deleted', 'warning');
        }
      })
      .then(() => {
        setIsConfirmOpen(false);
        refreshView();
      });
  };

  return (
    <CardActions>
      <EditButton basePath={basePath} record={data} />
      <Button
        label='Delete'
        className={classes.deleteButton}
        onClick={() => setIsConfirmOpen(true)}>
          <>
            <Delete />
          </>
      </Button>
      <Confirm
        // Workaround to force the state clean up of the modal
        key={isConfirmOpen}
        isOpen={isConfirmOpen}
        title='Delete Audio File'
        content={`Are you sure you want to permanently delete the Audio File '${get(data, 'title')}'?`}
        onConfirm={handleConfirm}
        onClose={() => setIsConfirmOpen(false)}
      />
      {error &&
        <DeleteAssetErrorDialog
          error={error}
          isOpen={!!error}
          onClose={() => setError(null)}
        />}
    </CardActions>
  );
};

AudioShowActions.propTypes = {
  basePath: PropTypes.string,
  data: PropTypes.object,
  classes: PropTypes.object,
  resource: PropTypes.string,
  refreshView: PropTypes.func,
  showNotification: PropTypes.func,
};

const mapActionsToProps = {
  refreshView: refreshViewAction,
  showNotification: showNotificationAction,
};

const enhance = compose(
  connect(null, mapActionsToProps),
  withStyles(styles)
);

export default enhance(AudioShowActions);
