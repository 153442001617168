import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { Responsive } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ListIcon from '@material-ui/icons/List';
import AddIcon from '@material-ui/icons/Add';
import ReportsIcon from '@material-ui/icons/TrendingUp';
import Audio from '@material-ui/icons/LibraryMusic';
import AudioFiles from '@material-ui/icons/MusicNote';
import AudioSets from '@material-ui/icons/QueueMusic';

import MenuItemLink from './MenuItemLink';
import { UserIcon } from './users';
import { EventIcon } from './events';
import { ResultIcon } from './results';
import { LocationIcon } from './locations';
import { TrainerIcon } from './trainers';
import { SensorIcon } from './sensors';
import { SensorPoolIcon } from './sensor_pools';

const styles = (theme) => ({
  root: {
    width: '100%',
    maxWidth: 400,
  },
  nested: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(6),
  },
});

class Menu extends React.Component {
  state = {
    open: true,
    submenu: {
      events: false,
      sensors: false,
      audio: false,
    },
  };

  handleClick = () => {
    this.setState((state) => ({ open: !state.open }));
  };

  toggleSubmenu = (id) => () => {
    this.setState((state) => ({
      submenu: {
        ...state.submenu,
        [id]: !state.submenu[id],
      },
    }));
  };

  render() {
    const { submenu } = this.state;
    const { classes, onMenuClick, logout } = this.props;

    return (
      <div className={classes.root}>
        <List component='nav'>
          <MenuItemLink exact to='/' primaryText='Dashboard' leftIcon={<DashboardIcon />} onClick={onMenuClick} />

          <MenuItemLink key='users' to='/users' primaryText='Users' leftIcon={<UserIcon />} onClick={onMenuClick} />

          <ListItem button onClick={this.toggleSubmenu('events')}>
            <ListItemIcon>
              <EventIcon />
            </ListItemIcon>
            <ListItemText inset primary='Events' />
            {submenu.events ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={submenu.events} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              <MenuItemLink
                className={classes.nested}
                to='/events-dashboard'
                primaryText='Dashboard'
                leftIcon={<DashboardIcon />}
                onClick={onMenuClick}
              />
              <MenuItemLink
                className={classes.nested}
                to='/events/create'
                primaryText='Create'
                leftIcon={<AddIcon />}
                onClick={onMenuClick}
              />
              <MenuItemLink
                className={classes.nested}
                to='/events'
                primaryText='List Events'
                leftIcon={<ListIcon />}
                onClick={onMenuClick}
              />
              <MenuItemLink
                className={classes.nested}
                to='/events/calendar'
                primaryText='Calendar'
                leftIcon={<EventIcon />}
                onClick={onMenuClick}
              />
              <MenuItemLink
                className={classes.nested}
                to='/event-types'
                primaryText='Event Types'
                leftIcon={<EventIcon />}
                onClick={onMenuClick}
              />
            </List>
          </Collapse>

          <ListItem button onClick={this.toggleSubmenu('audio')}>
            <ListItemIcon>
              <Audio />
            </ListItemIcon>
            <ListItemText inset primary='Audio' />
            {submenu.audio ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={submenu.audio} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              <MenuItemLink
                className={classes.nested}
                key='audio'
                to='/audio-files'
                primaryText='Audio Files'
                leftIcon={<AudioFiles />}
                onClick={onMenuClick}
              />
              <MenuItemLink
                className={classes.nested}
                to='/audio-sets'
                primaryText='Audio Sets'
                leftIcon={<AudioSets />}
                onClick={onMenuClick}
              />
            </List>
          </Collapse>

          <MenuItemLink
            key='results'
            to='/results'
            primaryText='Results'
            leftIcon={<ResultIcon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            key='locations'
            to='/locations'
            primaryText='Locations'
            leftIcon={<LocationIcon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            key='trainers'
            to='/trainers'
            primaryText='Coaches'
            leftIcon={<TrainerIcon />}
            onClick={onMenuClick}
          />

          <ListItem button onClick={this.toggleSubmenu('sensors')}>
            <ListItemIcon>
              <SensorIcon />
            </ListItemIcon>
            <ListItemText inset primary='Sensors' />
            {submenu.sensors ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={submenu.sensors} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              <MenuItemLink
                className={classes.nested}
                to='/sensors-dashboard'
                primaryText='Dashboard'
                leftIcon={<DashboardIcon />}
                onClick={onMenuClick}
              />
              <MenuItemLink
                className={classes.nested}
                to='/sensors'
                primaryText='List Sensors'
                leftIcon={<ListIcon />}
                onClick={onMenuClick}
              />
              <MenuItemLink
                className={classes.nested}
                to='/sensor_pools'
                primaryText='Sensor Pools'
                leftIcon={<SensorPoolIcon />}
                onClick={onMenuClick}
              />
            </List>
          </Collapse>

          <MenuItemLink
            key='reports'
            to='/reports'
            primaryText='Reports'
            leftIcon={<ReportsIcon />}
            onClick={onMenuClick}
          />

          <Responsive xsmall={logout} medium={null} />
        </List>
      </div>
    );
  }
}

Menu.propTypes = {
  classes: PropTypes.object.isRequired,
  logout: PropTypes.object,
  onMenuClick: PropTypes.func,
};

const enhance = compose(
  withRouter,
  connect(
    (state) => ({
      theme: state.theme,
    }),
    {},
  ),
  withStyles(styles),
);

export default enhance(Menu);
