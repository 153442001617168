import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router'; // eslint-disable-line
import moment from 'moment-timezone';
import qs from 'query-string';

import BirthdaysList from 'containers/BirthdaysList';


class Birthdays extends React.Component {
  constructor (props) {
    super(props);

    this.defaultParams = {
      page: 1,
      perPage: 20,
      sort: 'dob_day',
      order: 'ASC',
      dobMonth: (moment().month() + 1),
    };

    if (props.history.location.search === '') {
      this.state = this.defaultParams;
    } else {
      const currentParams = this.getUrlParams();
      const params = Object.assign({}, this.defaultParams, currentParams);
      this.state = params;
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.history.location.search !== this.props.location.search) {
      const params = this.getUrlParams();
      this.setState(params);
    }
  }

  onChangePage = (page) => {
    this.updateUrl({ page: parseInt(page || 1, 10) });
  }

  getUrlParams = () => {
    const params = qs.parse(this.props.history.location.search);
    if (params.page) params.page = parseInt(params.page || this.defaultParams.page, 10);
    if (params.perPage) params.perPage = parseInt(params.perPage || this.defaultParams.perPage, 10);
    if (params.dobMonth) params.dobMonth = parseInt(params.dobMonth || this.defaultParams.dobMonth, 10);
    return params;
  }

  updateUrl = (params) => {
    this.setState({ page: params.page });

    const { history, location } = this.props;

    if (location.search === '') {
      const query = Object.assign({}, this.state, params);
      history.push({
        pathname: location.pathname,
        search: `?${qs.stringify(query)}`,
      });
    } else {
      const currentParams = this.getUrlParams();
      const query = Object.assign({}, currentParams, params);
      history.replace({
        pathname: location.pathname,
        search: `?${qs.stringify(query)}`,
      });
    }
  }

  render () {
    return (
      <BirthdaysList
        page={this.state.page}
        perPage={this.state.perPage}
        sort={this.state.sort}
        order={this.state.order}
        dobMonth={this.state.dobMonth}
        onChangePage={this.onChangePage}
      />
    );
  }
}

Birthdays.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(Birthdays);
