import get from 'lodash/get';
import React from 'react';
import {
  DateField,
  EditButton,
  Filter,
  List,
  Responsive,
  ShowButton,
  SimpleList,
  TextField,
  TextInput,
} from 'react-admin';
import LocationReferenceInput from 'reactAdmin/locations/LocationReferenceInput';

import Datagrid from '../Datagrid';
import LocationReferenceField from '../locations/LocationReferenceField';
import SensorPoolListActions from './SensorPoolListActions';

const SensorFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label='Name' source='q' alwaysOn />
      <LocationReferenceInput source='place_id' alwaysOn required={false} />
    </Filter>
  );
};

const SensorPoolList = (props) => (
  <List
    {...props}
    title='Sensor Pools List'
    sort={{ field: 'name', order: 'ASC' }}
    perPage={40}
    filters={<SensorFilter />}
    bulkActions={false}
    actions={<SensorPoolListActions />}
  >
    <Responsive
      small={<SimpleList primaryText={(record) => get(record, 'name', '')} />}
      medium={
        <Datagrid defaultColumns={['name', 'place_id']}>
          <ShowButton />
          <TextField source='name' />
          <LocationReferenceField source='place_id' />
          <DateField source='created_at' showTime locales='de-DE' />
          <EditButton />
        </Datagrid>
      }
    />
  </List>
);

export default SensorPoolList;
