import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { SimpleForm, Button, withDataProvider } from 'react-admin';

import RowForm from './RowForm';

const style = (theme) => ({
  nameInput: {
    fontSize: '0.8em',
    width: '200px',
  },
  styledInput: {
    fontSize: '0.8em',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'baseline',
    fontSize: '0.8em',
    width: '100%',
  },
  disabled: {
    opacity: '0.3',
  },
  hidden: {
    display: 'none',
  },
  fullWidth: {
    width: '100%',
  },
  deleteButton: {
    color: theme.palette.error.main,
    text: {
      color: theme.palette.error.main,
    },
  },
});

const Row = ({
  disableForm,
  row,
  classes,
  createAsset,
  adminProps,
  removeFiles,
  status,
  clearSavedFile,
  updateStatus,
  formState,
  getFormState,
}) => {
  const [initialValues, setInitialValues] = useState(null);

  const handleAssetCreate = async () => {
    try {
      await createAsset(formState[row.path].values);
      updateStatus(row.path, 'success');
    } catch (error) {
      updateStatus(row.path, 'error');
    }
  };

  const ToolBar = (props) => {
    return <Button label='save' id={props.formId} className={classes.hidden} onClick={handleAssetCreate} />;
  };

  const { name } = row.file;
  const { path } = row;

  const getDefaultCategory = (name) => (name.includes('timer') ? 'timer' : 'music');

  const genres = ['techno', 'hiphop', 'pop'];
  const getDefaultGenre = (name) => genres.find((genre) => name.includes(genre));

  const getLanguage = (name) => (name.includes('_de') ? 'de' : 'en');

  useEffect(() => {
    const category = getDefaultCategory(name);
    const genre = getDefaultGenre(name);
    const language = getLanguage(name);
    const values = {
      title: name,
      type: 'audio',
      file: {
        file_path: path,
      },
      metadata: {
        category,
        genre,
        language,
        size: row.file.size,
      },
    };
    setInitialValues(values);
  }, [name]);

  return (
    <SimpleForm
      resource='assets'
      toolbar={<ToolBar formId={path} className={classes.hidden} {...adminProps} />}
      key={path}
      className={classes.fullWidth}
      initialValues={initialValues}
      validateOnChange
      {...adminProps}
      redirect={false}
    >
      <RowForm
        row={row}
        path={path}
        name={name}
        disableForm={disableForm}
        removeFiles={removeFiles}
        status={status}
        clearSavedFile={clearSavedFile}
        classes={classes}
        getFormState={getFormState}
      />
    </SimpleForm>
  );
};

Row.propTypes = {
  disableForm: PropTypes.bool,
  row: PropTypes.object,
  classes: PropTypes.object,
  createAsset: PropTypes.func,
  adminProps: PropTypes.object,
  removeFiles: PropTypes.func,
  status: PropTypes.string,
  clearSavedFile: PropTypes.func,
};

export default withDataProvider(withStyles(style)(Row));
