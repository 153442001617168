import AudioIcon from '@material-ui/icons/Event';

import AudioCreate from './AudioCreate';
import AudioEdit from './AudioEdit';
import AudioList from './AudioList';
import AudioShow from './AudioShow';

export default {
  icon: AudioIcon,
  list: AudioList,
  show: AudioShow,
  create: AudioCreate,
  edit: AudioEdit,
};

export { AudioIcon };
