import React from 'react';
import PropTypes from 'prop-types';
import { DateField } from 'react-admin';

//  locales='de-DE'
// locales='de-DE'
const CustomDateField = (props) => (
  <DateField
    showTime
    locales={'en-GB'}
    options={{
      weekday: 'long',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }}
    {...props}
  />
);

CustomDateField.propTypes = {
  addLabel: PropTypes.bool,
};

CustomDateField.defaultProps = {
  addLabel: true,
};

export default CustomDateField;
