import React from 'react';
import PropTypes from 'prop-types';
import { Responsive } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';

import EventListItem from './EventListItem';


const style = (theme) => ({});

class EventList extends React.Component {
  handleSelect = (event) => this.props.onSelected(event);

  render () {
    const { events, selectedEventId, onSelected } = this.props;
    return (
      <List >
        {events && events.map((record) => {
          if (!record) return null;
          return (
          <Responsive
            key={record.id}
            small={<EventListItem event={record} selected={record.id === selectedEventId} onSelected={onSelected} />}
            medium={<EventListItem event={record} selected={record.id === selectedEventId} onSelected={onSelected} />}
          />
        )})}
      </List>
    );
  }
}

EventList.propTypes = {
  events: PropTypes.array,
  onSelected: PropTypes.func,
};

EventList.defaultProps = {
  events: [],
  onSelected: null,
};

export default withStyles(style)(EventList);
