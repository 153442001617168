import { restClient as dataProvider } from 'client';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  AutocompleteInput,
  GET_ONE,
  useNotify,
  required,
} from 'react-admin';
import { useForm } from 'react-final-form';

const AudioFileAssignment = ({ eventTypeId, category, genre, language, type }) => {
  const [structure, setStructure] = useState(null);
  const form = useForm();
  const notify = useNotify();

  useEffect(() => {
    type === 'create' && updateInitialValues();
  }, [category, eventTypeId, structure, assignmentIds]);

  const updateInitialValues = () => {
    let initalValue = uniq(assignmentIds).map((id) => ({ assignment_id: id }));
    const musicDefaultValues = [{ assignment_id: 'background_music' }, { assignment_id: 'post-workout_music' }];
    if (category === 'music') initalValue = [...musicDefaultValues, ...initalValue];
    form.change('assets', initalValue);
  };

  const queryEventType = async () => {
    let eventTypeStructureId = null;
    let eventTypeStructure = null;

    try {
      eventTypeStructureId = await dataProvider(GET_ONE, 'event-types', { id: eventTypeId }).then(
        (response) => response.data.event_type_structure_id,
      );
    } catch (error) {
      notify(`Could not fetch event type`, 'warning');
    }

    try {
      eventTypeStructure = await dataProvider(GET_ONE, 'event-type-structures', { id: eventTypeStructureId }).then(
        (response) => response.data,
      );
    } catch (error) {
      notify(`Could not fetch event type structure`, 'warning');
    }

    setStructure(eventTypeStructure);
  };

  useEffect(() => {
    !eventTypeId && setStructure(null);
    eventTypeId && queryEventType();
  }, [eventTypeId]);

  const assignmentIds = [];
  const findNextAssignmentId = (data, type) => {
    data.forEach((element) => {
      element.events &&
        element.events.forEach((event) => {
          if (event.assignmentId && event.track === type) {
            assignmentIds.push(event.assignmentId);
          }
        });
      if (element.steps) findNextAssignmentId(element.steps, type);
    });
  };

  const data = get(structure, 'structure.steps');
  data && category && findNextAssignmentId(data, category);

  const filter = {};
  if (category) filter['metadata.category'] = category;
  if (genre) filter['metadata.genre'] = genre;
  if (language) filter['metadata.language'] = language;

  return (
    <>
      <ArrayInput source='assets' label=''>
        <SimpleFormIterator>
          <TextInput source='assignment_id' label='Assignment ID' validate={required()} />
          <ReferenceInput
            source='asset_id'
            reference='assets'
            label='Audio file name'
            validate={required()}
            filter={filter}
          >
            <AutocompleteInput optionText='title' />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};

AudioFileAssignment.propTypes = {
  eventTypeId: PropTypes.string,
  category: PropTypes.string,
  genre: PropTypes.string,
  language: PropTypes.string,
  type: PropTypes.string,
};

AudioFileAssignment.defaultProps = {
  type: 'create',
};

export default AudioFileAssignment;
