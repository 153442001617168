import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';

import { ListButton, EditButton, DeleteButton, RefreshButton } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
// import Button from '@material-ui/core/Button';
// import ContentAddIcon from '@material-ui/icons/Add';

// const styles = {
//   row: { display: 'flex', justifyContent: 'flex-end', flexFlow: 'row wrap' },
//   cardAction: { zIndex: 2, display: 'inline-block', float: 'right', textAlign: 'right' },
// };

const UserShowActions = ({ basePath, className, data, resource, hasDelete, hasEdit, hasList }) => {
  // const userId = data && data.id ? data.id : null;
  // const AddUserNoteLink = (props) => (
  //   <Link
  //     to={{
  //       pathname: '/user_notes/create',
  //       search: `?user_id=${userId}`,
  //     }}
  //     {...props}
  //   />
  // );

  return (
    <CardActions
      className={className}
      // style={styles.cardAction}
    >
      {/* <div style={styles.row}> */}
      {hasEdit && <EditButton basePath={basePath} record={data} />}
      {hasList && <ListButton basePath={basePath} />}
      {hasDelete && <DeleteButton basePath={basePath} record={data} resource={resource} />}
      <RefreshButton />
      {/* </div> */}

      {/* <div style={styles.row}>
        <Button
          color='primary'
          container={AddUserNoteLink}
          // icon={<ContentAddIcon />}
        >
          Add Note
        </Button>
      </div> */}
    </CardActions>
  );
};

UserShowActions.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.object,
  hasDelete: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasList: PropTypes.bool,
  resource: PropTypes.string,
};

UserShowActions.defaultProps = {
  hasDelete: false,
  hasEdit: true,
  hasList: true,
};

export default UserShowActions;
