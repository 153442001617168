import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import AssetPlayableButton from '../audio-player/AssetPlayableButton';

const AssetFileField = ({ source, record }) => {
  const file = get(record, source);

  const assetId = record.id;
  if (isEmpty(file)) return null;

  return <AssetPlayableButton assetId={assetId} file={file} playLabel={file.filename} stopLabel={file.filename} />;
};

AssetFileField.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object,
};

AssetFileField.defaultProps = {
  source: null,
  record: {},
};

export default AssetFileField;
