import PropTypes from 'prop-types';
import React from 'react';

const ResultTitle = ({ record }) => <span>{record ? `'${record.gritpoints}'` : ''}</span>;

ResultTitle.propTypes = {
  record: PropTypes.object,
};

ResultTitle.defaultProps = {
  record: {},
};

export default ResultTitle;
