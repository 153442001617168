import get from 'lodash/get';
import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  FormDataConsumer,
  required,
  DateField,
  SelectInput,
  ReferenceInput,
} from 'react-admin';

import AudioFileAssignment from './AudioFileAssignment';
import { categories, genres, languages } from './constants';

const AudioSetEdit = (props) => (
  <Edit title='Edit audio set' {...props}>
    <SimpleForm {...props}>
      <TextInput source='name' label='Audio set name' validate={required()} />
      <SelectInput label='Category' source='metadata.category' choices={categories} validate={required()} />
      <SelectInput label='Genre' source='metadata.genre' choices={genres} resettable />
      <SelectInput label='Language' source='metadata.language' choices={languages} resettable />
      <ReferenceInput source='event_type_id' reference='event-types' label='Event type'>
        <SelectInput optionText='name' resettable />
      </ReferenceInput>
      <DateField source='created_at' label='Created at' locales='de-DE' />
      <DateField source='updated_at' label='Last updated at' locales='de-DE' />
      <FormDataConsumer>
        {({ formData }) => {
          const eventTypeId = get(formData, 'event_type_id');
          const category = get(formData, 'metadata.category');
          const genre = get(formData, 'metadata.genre');
          const language = get(formData, 'metadata.language');
          return (
            <AudioFileAssignment
              eventTypeId={eventTypeId}
              category={category}
              genre={genre}
              language={language}
              type='edit'
            />
          );
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);

export default AudioSetEdit;
