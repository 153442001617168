import React, { useState } from 'react';
import { Button, Input, Card, Typography, Grid, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useRecordContext } from 'react-admin';
import MergeAccountModal from '../../components/MergeAccount/Modal';
import Success from '../../components/MergeAccount/Success';
import Error from '../../components/MergeAccount/Error';
import useMergeAccount from './hooks/mergeAccount';
import getUser from './hooks/getUser';

const useStyles = makeStyles({
  card: {
    boxShadow: 'unset',
    padding: '8px 16px',
    border: (props) => `1px solid ${props.palette.primary.main}`,
  },
  input: {
    margin: '16px 16px 16px 0px',
    width: '100%',
  },
  seperator: {
    backgroundColor: (props) => `${props.palette.primary.main}`,
    width: '2px',
    height: '30px',
    alignSelf: 'center',
    marginBottom: '-12px',
  },
  timelineArrow: {
    color: (props) => `${props.palette.primary.main}`,
  },
  dialogContent: {
    position: 'relative',
  },
  error: {
    boxShadow: 'unset',
    padding: '8px 16px',
    border: (props) => `1px solid ${props.palette.error.main}`,
  },
  success: {
    boxShadow: 'unset',
    padding: '8px 16px',
    color: (props) => `${props.palette.success.main}`,
    border: (props) => `1px solid ${props.palette.success.main}`,
  },
});

const MergeAccount = (props) => {
  const [showTextInput, toggleTextInput] = useState(false);
  const [sourceUserId, setUserId] = useState('');
  const [sourceUserRecord, setSourceUserRecord] = useState({});
  const [open, toggleModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const theme = useTheme();
  const styles = useStyles(theme);
  const destinationUserRecord = useRecordContext(props);

  const getUserDetails = async () => {
    setError(false);
    const { data, error } = await getUser({ userId: sourceUserId });
    if (data) {
      setSourceUserRecord(data);
    }
    setError(error);
    toggleModal(true);
  };

  const handleMergeAccount = async () => {
    const { success, error } = await useMergeAccount({ destinationUserRecord, sourceUserRecord });
    setSuccess(success);
    setError(error);
  };

  const handleReset = () => {
    setSourceUserRecord({});
    toggleModal(false);
    toggleTextInput(false);
    setSuccess(false);
    setError(false);
  };

  if (success) {
    return <Success />;
  }
  if (error) {
    return <Error handleReset={handleReset} errorMessage={error} />;
  }

  if (showTextInput) {
    return (
      <Card className={styles.card}>
        <Grid md={12} lg={12}>
          <Grid direction='column'>
            <Typography variant='caption' component='h5' color='textSecondary'>
              Merge Account:
            </Typography>
          </Grid>
          <Grid container direction='row' alignItems='center'>
            <Input
              className={styles.input}
              label='User Id'
              placeholder='Paste user ID'
              autoFocus
              required
              onChange={(e) => setUserId(e.target.value)}
            />
            <Button variant='contained' color='primary' onClick={getUserDetails} disabled={!sourceUserId}>
              Merge
            </Button>
          </Grid>
        </Grid>
        <MergeAccountModal
          open={open}
          error={error}
          success={success}
          toggleModal={toggleModal}
          sourceUserRecord={sourceUserRecord}
          destinationUserRecord={destinationUserRecord}
          handleMergeAccount={handleMergeAccount}
        />
      </Card>
    );
  }
  return (
    <Button variant='outlined' color='primary' onClick={() => toggleTextInput(true)}>
      Merge Accounts
    </Button>
  );
};
export default MergeAccount;
