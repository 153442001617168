
import React from 'react';
import { useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Select, Typography, Grid, InputLabel, FormControl, MenuItem, Checkbox, Button, Input, Box } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { eventCancellationReasonsAndOutcomes } from './constants'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useRefresh, useNotify } from 'react-admin';
import snakeCaseToCapitalizeWord from 'utils/snakeCaseToCapitalizedWords';
import { httpClient } from 'client';

const apiUrl = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
	section: {
		border: `1px solid ${theme.palette.primary.grey}`,
		padding: theme.spacing(2),
		margin: `${theme.spacing(2)}px 0px`
	},
	select: {
		minWidth: '200px'
	},
	checkbox: {
		paddingLeft: 0
	},
	dialogActions: {
		padding: theme.spacing(2)
	},
	comment: {
		minWidth: '100%'
	},
	formControl: {
		margin: theme.spacing(1)
	},
	cancelEventButtom: {
		color: theme.palette.error.main
	},
	cancelButton: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.common.white,
		'&:hover': {
			backgroundColor: theme.palette.error.dark
		}
	},
	alertSuccess: {
		color: theme.palette.success.main,
		border: `1px solid ${theme.palette.success.main}`,
		padding: theme.spacing(2),
	},
	alertError: {
		color: theme.palette.error.main,
		border: `1px solid ${theme.palette.error.main}`,
		padding: theme.spacing(2),
		borderRadius: '4px'
	}
}));

const postEventCancel = async (eventId, payload) => {
	try {
		await httpClient(`${apiUrl}/events/${eventId}/status`, {
			method: 'POST',
			body: JSON.stringify(payload),
		}).then(({ json }) => json.data);
		return { data: {}, success: true, error: false };
	} catch (error) {
		return { data: null, success: false, error: true };
	}
}

const getDefaultOptions = () => {
	const reasons = Object.keys(eventCancellationReasonsAndOutcomes);
	const customerEmails = Object.values(eventCancellationReasonsAndOutcomes).map(reason => reason.customer_email);
	const userCompensations = new Set([...Object.values(eventCancellationReasonsAndOutcomes).map(reason => reason.user_compensation)]);
	userCompensations.add('refund_and_extra_credit');
	return { reasons, customerEmails, userCompensations }
};

export const EventCancelModal = ({ event }) => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const { reasons, customerEmails, userCompensations } = getDefaultOptions();
	const [reason, setReason] = useState('');
	const [open, handleModal] = useState(false);
	const [userCompensation, setUserCompensation] = useState('');
	const [customerEmail, setCustomerEmail] = useState('');
	const [comment, setComment] = useState('');
	const [showComment, setShowComment] = useState(false);
	const [coachFine, setCoachFine] = useState(false);
	const [loading, setLoading] = useState(false);
	const [applyToRecurringEvent, setApplyToRecurringEvent] = useState(false);
	const refresh = useRefresh();
	const notify = useNotify();

	const handleReset = () => {
		setReason('');
		setUserCompensation('');
		setCustomerEmail('');
		setComment('');
		setShowComment(false);
		setCoachFine(false);
		setApplyToRecurringEvent(false);
		handleModal(false);
		setLoading(false);
	}

	const handleReasonChange = (e) => {
		const defaultOptions = eventCancellationReasonsAndOutcomes[e.target.value];
		setReason(e.target.value);
		setUserCompensation(defaultOptions.user_compensation);
		setCoachFine(defaultOptions.coach_penalty);
		setCustomerEmail(defaultOptions.customer_email);
		if (e.target.value === 'other') {
			setShowComment(true);
		} else {
			setShowComment(false);
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		const payload = {
			status_name: 'cancelled',
			meta: {
				reason: reason,
				source: 'admin_portal',
				user_compensation: userCompensation,
				customer_email: customerEmail,
				cancellation_reason_comment: comment,
				coach_penalty: coachFine,
			},
			apply_to_following_events: applyToRecurringEvent
		}
		try {
			const { success } = await postEventCancel(event.id, payload);
			if (success) {
				notify('Event Cancelled Successfully', 'success');
				handleReset(e);
				refresh();
			}
		} catch (error) {
			notify('Error in Cancelling Event', 'error');
			handleReset();
			refresh();
		}
		finally {
			setLoading(false);
		}
	}

	return (
		<div>
			<Button onClick={() => handleModal(true)} className={styles.cancelEventButtom}><HighlightOffIcon color='inherit' />  Cancel Event  </Button>
			<Dialog open={open} onClose={() => handleModal(false)}>
				<form onSubmit={handleSubmit}>
					<DialogContent>
						<DialogTitle>
							<Typography variant="h6" >Are you sure you want to cancel this event?</Typography>
						</DialogTitle>
						<DialogContent>
							<Grid>
								<Grid className={styles.section}>
									<Grid>
										<Typography variant="primary">1. Select a reason:</Typography>
									</Grid>
									<Grid xs={12} >
										<FormControl required>
											<InputLabel><Typography variant='caption' color='textSecondary'> Select a reason</Typography></InputLabel>
											<Select label='Select a reason' name='reason' onChange={(e) => handleReasonChange(e)} className={styles.select} value={reason}>
												{reasons.map(reason => (
													<MenuItem value={reason}>{snakeCaseToCapitalizeWord(reason)}</MenuItem>
												))}
											</Select>
										</FormControl>

									</Grid>
									{
										showComment &&
										<Grid xs={12} >
											<FormControl className={styles.comment} required>
												<InputLabel><Typography variant='caption' color='textSecondary'> Add a comment:</Typography></InputLabel>
												<Input value={comment} onChange={(e) => setComment(e.target.value)} required></Input>
											</FormControl>

										</Grid>
									}
								</Grid>
								<Grid className={styles.section} spacing={2}>
									<Grid>
										<Typography variant="primary">2. Desired Outcomes(s):</Typography>
									</Grid>
									<Grid>
										<Typography variant="caption" color='textSecondary'> When you select a reason, the suggested outcomes are applied. You can select different outcomes if you need to.</Typography>
									</Grid>
									<Grid direction="row" container alignItems='center'>
										<FormControl className={styles.formControl} required>
											<Grid item>
												<Checkbox className={styles.checkbox} color={'primary'} checked={coachFine} name='coach_penalty' onChange={() => setCoachFine(!coachFine)}></Checkbox>
											</Grid>
										</FormControl>
										<Grid item>
											<Typography variant='textSecondary'>Charge Coach Cancellation Penalty</Typography>
										</Grid>
									</Grid>
									<Grid xs={12}>
										<FormControl className={styles.formControl} required>
											<InputLabel><Typography variant='caption' color='textSecondary'> User Compensation</Typography></InputLabel>
											<Select name="user_compenstation" className={styles.select} value={userCompensation} onChange={(e) => setUserCompensation(e.target.value)} >
												{Array.from(userCompensations).map(compensation => (
													<MenuItem value={compensation}>{snakeCaseToCapitalizeWord(compensation)}</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid xs={12} >
										<FormControl className={styles.formControl} required>
											<InputLabel><Typography variant='caption' color='textSecondary'> User Email</Typography></InputLabel>
											<Select name='cutomer_email' className={styles.select} value={customerEmail} onChange={(e) => setCustomerEmail(e.target.value)}  >
												{customerEmails.map(email => (
													<MenuItem value={email}>{snakeCaseToCapitalizeWord(email)}</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
								<Grid className={styles.section} spacing={2} alignItems='center' direction='column'>
									<Grid>
										<Checkbox color='error' checked={applyToRecurringEvent} onChange={() => setApplyToRecurringEvent(!applyToRecurringEvent)}></Checkbox>
										<Typography variant='textSecondary'>Apply this cancellation reason to all future events</Typography>
									</Grid>
									<Grid>
										{
											applyToRecurringEvent &&
											<Box className={styles.alertError}>
												<Typography variant='caption' color='error'>This will cancel all future events.
												</Typography>
												<Typography variant='caption' color='error'> This action cannot be undone.</Typography>
											</Box>
										}
									</Grid>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<FormControl>
								<Grid spacing={2} className={styles.dialogActions} direction='column'>
									<Grid item>
										<Button variant='outline' type='cancel' onClick={handleReset}>Don't Cancel</Button>
										<Button variant='contained' className={styles.cancelButton} type='submit' disabled={loading}>{`Cancel ${applyToRecurringEvent ? 'All Events' : 'Event'} `}</Button>
									</Grid>
								</Grid>
							</FormControl>
						</DialogActions>
					</DialogContent>
				</form>
			</Dialog>
		</div>
	)
};
