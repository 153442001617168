import React from 'react';
import { Filter, NullableBooleanInput, NumberInput, TextInput } from 'react-admin';
import AutocompleteArrayInput from 'reactAdmin/inputs/AutocompleteArrayInput';
import { cities, locationTypes } from 'reactAdmin/locations/constants';

import UserReferenceInput from '../users/UserReferenceInput';

const TrainerFilter = (props) => (
  <Filter {...props}>
    <NullableBooleanInput source='is_published' label='Published' alwaysOn />
    <AutocompleteArrayInput choices={cities} label='City' source='homezone_city_code' alwaysOn />
    <TextInput label='Search' source='q' alwaysOn />
    <AutocompleteArrayInput
      choices={locationTypes}
      label='Preferred location types'
      source='preferred_location_types_contains'
      alwaysOn
    />
    <NumberInput
      source='stats.first_time_conversion.conversion_rate_gte'
      label='First Time Conversion greater than'
      format={(v) => v * 100}
      parse={(v) => v / 100}
    />
    <NumberInput
      source='stats.first_time_conversion.conversion_rate_lte'
      label='First Time Conversion less than'
      format={(v) => v * 100}
      parse={(v) => v / 100}
    />
    <NumberInput source='tier' label='Tier' alwaysOn />
    <NumberInput source='stats.total_event_stats.gritpoints_avg_lte' label='Gritpoints Avg (All time) less than' />
    <NumberInput source='stats.total_event_stats.gritpoints_avg_gte' label='Gritpoints Avg (All time) greater than' />
    <NumberInput
      source='stats.this_month_event_stats.gritpoints_avg_lte'
      label='Gritpoints Avg (This month) less than'
    />
    <NumberInput
      source='stats.this_month_event_stats.gritpoints_avg_gte'
      label='Gritpoints Avg (This month) greater than'
    />
    <NumberInput
      source='stats.past_month_event_stats.gritpoints_avg_lte'
      label='Gritpoints Avg (Past month) less than'
    />
    <NumberInput
      source='stats.past_month_event_stats.gritpoints_avg_gte'
      label='Gritpoints Avg (Past month) greater than'
    />
    <NullableBooleanInput source='trial' label='Trial' alwaysOn />
    <UserReferenceInput formType='filter' multiple />
  </Filter>
);

export default TrainerFilter;
