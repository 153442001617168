import React from 'react';
import PropTypes from 'prop-types';
import {
  DateInput,
  TextInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
} from 'react-admin';
import queryString from 'query-string';

import EventReferenceInput from '../events/EventReferenceInput';
import UserReferenceInput from '../users/UserReferenceInput';
import {
  types as typeChoices,
  scopeRestrictions as scopeRestrictionChoices,
} from './constants';


const NoteForm = ({ isExisting, ...props }) => {
  const { location } = props;
  const queryParams = queryString.parse(location.search);
  const defaultEventId = queryParams.event_id ? queryParams.event_id : null;
  const defaultUserId = queryParams.user_id ? queryParams.user_id : null;

  return (
    <SimpleForm {...props} redirect={isExisting ? 'show' : 'list'}>
      {isExisting && <TextInput disabled source='id' />}

      {defaultUserId
        ? <TextInput disabled source='user_id' />
        : <UserReferenceInput defaultValue={defaultUserId} />
      }

      {defaultEventId
        ? <TextInput disabled source='event_id' />
        : <EventReferenceInput defaultValue={defaultEventId} />
      }

      <SelectInput source='type' choices={typeChoices} />
      <TextInput multiline source='body' label='Text' />

      <SelectArrayInput
        label='Restricted To'
        source='scope_restrictions'
        choices={scopeRestrictionChoices}
        options={{ newChipKeyCodes: [] }}
      />

      <DateInput source='reminder_from' />
      <DateInput source='reminder_until' />
      <DateInput source='valid_from' />
      <DateInput source='valid_until' />
    </SimpleForm>
  );
};

NoteForm.propTypes = {
  isExisting: PropTypes.bool,
  location: PropTypes.object,
};

NoteForm.defaultProps = {
  isExisting: false,
  location: {},
};

export default NoteForm;
