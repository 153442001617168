import React from 'react';
import PropTypes from 'prop-types';

import OfferForm from './OfferForm';

const styles = {
  container: {
    marginBottom: '1em',
  },
};

const CreateOfferForm = (props) => {
  return (
    <div style={styles.container}>
      <OfferForm event={props.record} />
    </div>
  );
};

CreateOfferForm.propTypes = {
  record: PropTypes.object,
};

CreateOfferForm.defaultProps = {
  record: {},
};

export default CreateOfferForm;
