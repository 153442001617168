import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import LinearProgress from '@material-ui/core/LinearProgress';

import Toolbar from './Toolbar';
import List from './List';
import Subtitle from './Subtitle';


class CohortChart extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    startFrom: PropTypes.instanceOf(Date),
    interval: PropTypes.oneOf(['year', 'month', 'isoWeek', 'week', 'day']),
    cycles: PropTypes.number,
    coach: PropTypes.array,
    place: PropTypes.array,
    country: PropTypes.array,
    city: PropTypes.array,
    district: PropTypes.array,
    provider: PropTypes.string,
    attended: PropTypes.boolean,
    newCustomers: PropTypes.boolean,
    uniqueUsers: PropTypes.boolean,
    excludeTrial: PropTypes.boolean,
    coaches: PropTypes.array,
    places: PropTypes.array,
    retention: PropTypes.array,
    onChangeStartFrom: PropTypes.func,
    onChangeInterval: PropTypes.func,
    onChangeCycles: PropTypes.func,
    onChangeCoach: PropTypes.func,
    onChangePlace: PropTypes.func,
    onChangeCountry: PropTypes.func,
    onChangeCity: PropTypes.func,
    onChangeDistrict: PropTypes.func,
    onChangeProvider: PropTypes.func,
    onChangeAttended: PropTypes.func,
    onChangeNewCustomers: PropTypes.func,
    onChangeUniqueUsers: PropTypes.func,
    onChangeExcludeTrial: PropTypes.func,
  }

  static defaultProps = {
    isLoading: false,
    startFrom: new Date(),
    interval: 'month',
    cycles: 1,
    coach: [],
    place: [],
    country: [],
    city: [],
    district: [],
    provider: null,
    attended: false,
    newCustomers: false,
    uniqueUsers: false,
    excludeTrial: false,
    coaches: [],
    places: [],
    retention: [],
    onChangeStartFrom: () => null,
    onChangeInterval: () => null,
    onChangeCycles: () => null,
    onChangeCoach: () => null,
    onChangePlace: () => null,
    onChangeCountry: () => null,
    onChangeCity: () => null,
    onChangeDistrict: () => null,
    onChangeProvider: () => null,
    onChangeAttended: () => null,
    onChangeNewCustomers: () => null,
    onChangeUniqueUsers: () => null,
    onChangeExcludeTrial: () => null,
  }
  render () {
    const {
      isLoading,
      startFrom,
      interval,
      cycles,
      coach,
      attended,
      newCustomers,
      uniqueUsers,
      excludeTrial,
      place,
      country,
      city,
      district,
      provider,
      coaches,
      places,
      retention,
      onChangeCycles,
      onChangeStartFrom,
      onChangeInterval,
      onChangeCoach,
      onChangePlace,
      onChangeCountry,
      onChangeCity,
      onChangeDistrict,
      onChangeProvider,
      onChangeAttended,
      onChangeNewCustomers,
      onChangeUniqueUsers,
      onChangeExcludeTrial,
    } = this.props;


    return (
      <Card>
        <CardHeader
          title='Cohort analysis'
          subtitle='Cohort chart with customer retention rate'
        />
        <CardContent>
          <div style={{ marginBottom: '1rem' }}>
            <Toolbar
              loading={isLoading}
              startFrom={startFrom}
              interval={interval}
              cycles={cycles}
              coach={coach}
              place={place}
              country={country}
              city={city}
              district={district}
              provider={provider}
              attended={attended}
              newCustomers={newCustomers}
              uniqueUsers={uniqueUsers}
              excludeTrial={excludeTrial}
              coaches={coaches}
              places={places}
              onChangeStartFrom={onChangeStartFrom}
              onChangeInterval={onChangeInterval}
              onChangeCycles={onChangeCycles}
              onChangeCoach={onChangeCoach}
              onChangePlace={onChangePlace}
              onChangeCountry={onChangeCountry}
              onChangeCity={onChangeCity}
              onChangeDistrict={onChangeDistrict}
              onChangeProvider={onChangeProvider}
              onChangeAttended={onChangeAttended}
              onChangeNewCustomers={onChangeNewCustomers}
              onChangeUniqueUsers={onChangeUniqueUsers}
              onChangeExcludeTrial={onChangeExcludeTrial}
            />
          </div>
          {isLoading
            ? <LinearProgress mode='indeterminate' />
            : <List items={retention} cycles={cycles} interval={interval} newCustomers={newCustomers} />
          }
          {isLoading
            ? null
            : <Subtitle
              startFrom={startFrom}
              interval={interval}
              cycles={cycles}
              coach={coach}
              place={place}
              provider={provider}
              attended={attended}
              newCustomers={newCustomers}
              uniqueUsers={uniqueUsers}
              excludeTrial={excludeTrial}
              coaches={coaches}
              places={places}
            />
          }
        </CardContent>
      </Card>
    );
  }
}

export default CohortChart;
