import React from 'react';
import { DateField, Show, SimpleShowLayout, TextField, SelectField } from 'react-admin';

import { IdField } from 'components/IdField';
import SecondsAsHhMmSsField from '../fields/SecondsAsHhMmSsField';
import UserReferenceField from '../users/UserReferenceField';
import EventReferenceField from '../events/EventReferenceField';
import TrainerReferenceField from '../trainers/TrainerReferenceField';
import LocationReferenceField from '../locations/LocationReferenceField';
import SensorField from './SensorField';
import DailyStatsLinkField from './DailyStatsLinkField';

import { cities, countries } from '../locations/constants';

const ResultShow = (props) => {
  return (
    <Show title='Result Detail View' {...props}>
      <SimpleShowLayout>
        <IdField id='id' />
        <DailyStatsLinkField />

        <UserReferenceField />
        <EventReferenceField />
        <TrainerReferenceField />
        <LocationReferenceField />

        <SensorField />

        <SelectField source='location_country_code' label='Country' choices={countries} />
        <SelectField source='location_city_code' label='City' choices={cities} />

        <TextField label='Beat points' source='beat_points' />
        <TextField label='Sweat points' source='sweat_points' />
        <TextField label='Recovery points' source='recovery_points' />
        <TextField source='calories_burned' />
        <TextField label='HR Avg' source='hr_avg' />
        <TextField label='% HR Avg' source='hr_avg_perc' />
        <TextField label='HR Max' source='achieved_max_hr' />
        <TextField label='% HR Max' source='achieved_max_hr_perc' />

        <SecondsAsHhMmSsField label='Time in Zone 4' source='time_in_zone4' />
        <SecondsAsHhMmSsField label='Time in Zone 3' source='time_in_zone3' />
        <SecondsAsHhMmSsField label='Time in Zone 2' source='time_in_zone2' />
        <SecondsAsHhMmSsField label='Time in Zone 1' source='time_in_zone1' />

        <UserReferenceField source='created_by' label='Created By' />

        {/*
        session/sensor/host info
        */}

        <DateField source='created_at' showTime locales='de-DE' />
      </SimpleShowLayout>
    </Show>
  );
};

export default ResultShow;
