import React from 'react';

import { ReferenceManyField, SingleFieldList, ReferenceField } from 'react-admin';

import EmailTextField from '../../fields/EmailTextField';

const EmailListField = (props) => (
  <ReferenceManyField {...props} reference='tickets' target='event_id' sort={{ field: 'created_at', order: 'ASC' }}>
    <SingleFieldList>
      <ReferenceField label='User' source='user_id' reference='users' link='false'>
        <EmailTextField />
      </ReferenceField>
    </SingleFieldList>
  </ReferenceManyField>
);

export default EmailListField;
