import { CREATE } from 'react-admin';

export const CANCEL_OFFER = 'CANCEL_OFFER';
export const CREATE_OFFER = 'CREATE_OFFER';

export const cancelOffer = (id, data, basePath) => ({
  type: CANCEL_OFFER,
  payload: {},
  meta: { resource: `offers/${id}/actions/cancel`, fetch: CREATE, refresh: true, cancelPrevious: false },
});

export const createOffer = (data, basePath) => {
  const notifications = {
    success: {
      single: 'The offer has been created.',
      multiple: 'Request successful, the offers will be created shortly.',
    },
    error: {
      single: 'An error has occurred, please try again',
      multiple: 'An error has occurred, offers will not be generated.',
    },
  };

  return {
    type: CREATE_OFFER,
    payload: { data },
    meta: {
      resource: `offers`,
      fetch: CREATE,
      refresh: true,
      cancelPrevious: false,
      onSuccess: {
        notification: {
          body: notifications.success[data.apply_to_following_events ? 'multiple' : 'single'],
          level: 'info',
        },
        redirectTo: `/events/${data.event_id}/show/1`,
        basePath,
      },
      onFailure: {
        notification: {
          body: notifications.error[data.apply_to_following_events ? 'multiple' : 'single'],
          level: 'warning',
        },
      },
    },
  };
};
