import NoteIcon from '@material-ui/icons/SpeakerNotes';
// import NoteTitle from './NoteTitle';
import NoteFilter from './NoteFilter';
import NoteList from './NoteList';
import NoteShow from './NoteShow';
import NoteEdit from './NoteEdit';
import NoteCreate from './NoteCreate';

export default {
  icon: NoteIcon,
  list: NoteList,
  show: NoteShow,
  create: NoteCreate,
  edit: NoteEdit,
};

export {
  NoteIcon,
  NoteFilter,
};
