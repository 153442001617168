import React from 'react';
import { Route } from 'react-router-dom';

import BirthdaysPage from 'pages/reports/birthdays';
import ReportsPage from 'pages/reports';
import ResultsLeaderboardPage from 'pages/reports/results/leaderboard';
import RetentionCohort from 'pages/reports/retention/cohort';
import DailyStatsPage from 'pages/reports/results/daily_stats';
import { WeeklyStatsPage } from 'pages/reports/users';
import EventsDashboardPage from 'pages/events-dashboard';
import EventsCreateWizardPage from 'pages/events-create-wizard';
import EventCalendarPage from 'pages/EventCalendarPage';
import SensorsDashboardPage from 'pages/sensors-dashboard';

import UserCheckInPage from 'reactAdmin/events/UserCheckIn';


export default [
  <Route path='/reports' exact component={() => <ReportsPage />} />,
  <Route path='/reports/birthdays' component={() => <BirthdaysPage />} />,
  <Route path='/reports/results/leaderboard' component={() => <ResultsLeaderboardPage />} />,
  <Route path='/reports/results/daily_stats' component={() => <DailyStatsPage />} />,
  <Route path='/reports/user_workout_stats/:user/week' component={(props) => <WeeklyStatsPage {...props} />} />,
  <Route path='/reports/retention/chart' component={() => <RetentionCohort />} />,

  <Route path='/events-dashboard' component={() => <EventsDashboardPage />} />,
  <Route path='/events-create-wizard' component={() => <EventsCreateWizardPage />} />,
  <Route path='/events/calendar' component={() => <EventCalendarPage />} />,
  <Route path='/events/:event_id/check-in' component={() => <UserCheckInPage />} noLayout />,

  <Route path='/sensors-dashboard' component={() => <SensorsDashboardPage />} />,
];
