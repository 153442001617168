import PropTypes from 'prop-types';
import React from 'react';
import { Datagrid, DateField, ReferenceManyField, ShowButton, ReferenceField } from 'react-admin';

import BooleanField from '../../fields/VerbalBooleanField';
import AttendanceActionsField from '../../tickets/AttendanceActionsField';
import EmbeddedUserField from '../../users/EmbeddedUserField';

const styles = {
  center: { textAlign: 'center' },
};

const TicketReferenceManyField = (props) => {
  return (
    <ReferenceManyField reference='tickets' target='event_id' sort={{ field: 'created_at', order: 'ASC' }} {...props}>
      <Datagrid>
        <ShowButton label='View Ticket' />
        <EmbeddedUserField source='user_id' label='User' />
        <ReferenceField label='Vaccination Check' source='user_id' reference='users'>
          <BooleanField source='is_vaccinated' />
        </ReferenceField>
        <DateField source='created_at' showTime locales='de-DE' />
        {!props.hideWaitinglist && (
          <BooleanField
            positiveOnly
            label='On Waitinglist'
            source='is_waitinglist'
            style={styles.center}
            headerStyle={styles.center}
          />
        )}
        {!props.hideCancellationFields && (
          <BooleanField
            positiveOnly
            label='Cancelled'
            inverted
            source='is_cancelled'
            style={styles.center}
            headerStyle={styles.center}
          />
        )}
        <BooleanField source='has_attended' label='Attended' style={styles.center} headerStyle={styles.center} />
        <AttendanceActionsField source='_attendance_actions' label='Set Attendance' />
      </Datagrid>
    </ReferenceManyField>
  );
};

TicketReferenceManyField.propTypes = {
  basePath: PropTypes.string,
  filter: PropTypes.object,
  hideCancellationFields: PropTypes.bool,
  hideWaitinglist: PropTypes.bool,
};

TicketReferenceManyField.defaultProps = {
  basePath: '',
  filter: {},
  hideCancellationFields: false,
  hideWaitinglist: false,
};

export default TicketReferenceManyField;
