export default {
  welcome: {
    marginRight: '.5em',
    marginLeft: '.5em',
    marginBottom: '2em',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  leftCol: {
    flex: '1 1 auto',
    marginRight: '.5em',
    marginLeft: '.5em',
  },
  rightCol: {
    flex: '1 1 auto',
    marginRight: '.5em',
    marginLeft: '.5em',
  },
};
