import { brand, gsBlack } from 'theme/colors';

export default {
  avatar: {
    marginRight: '1rem',
  },
  username: {
    fontWeight: 'bold',
  },
  wrapper: {
    position: 'relative',
    width: '100%',
    height: 'calc(100% - 1.5rem)',
  },
  listWrapper: {
    overflowY: 'scroll',
    width: '100%',
    height: 'calc(100% - 1.5rem)',
    borderTopLeftRadius: '7px',
    borderTopRightRadius: '1px',
    borderBottomRightRadius: '7px',
    borderBottomLeftRadius: '1px',
  },
  row: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    padding: '0.5rem 1rem',
    boxSizing: 'border-box',
    color: gsBlack,
    height: '3rem',
    minHeight: '4rem',
  },
  even: {
    backgroundColor: 'rgba(255,255,255,0.9)',
  },
  odd: {
    backgroundColor: 'rgba(255,255,255,1)',
  },
  button: {
    color: 'white',
    background: brand,
    // padding: '0 1.5rem',
    width: '9rem',
  },
  buttonDisabled: {
    color: gsBlack,
    backgroundColor: 'rgba(255,255,255,0.7)',
    padding: '0 1.5rem',
  },
  buttonContainer: {
    marginLeft: 'auto',
  },
  progress: {
    display: 'flex',
  },
  overlay: {
    height: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(255,255,255,0.5)',
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
