import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    secondary: {
      main: '#30354b',
    },
    primary: {
      light: '#E3E6F7',
      main: '#4153AF',
      dark: '#4153AF',
      grey: '#E6E6E6',
    },
    success: {
      main: '#1C805C',
    },
    error: {
      main: '#E25241',
    },
  },
  icon: {
    success: {
      main: '#1C805C',
    },
    error: {
      main: '#E25241',
    },
    primary: {
      main: '#212121',
    },
    secondary: {
      main: '#50505099',
    },
  },
  button: {
    error: {
      main: '#E25241',
    },
  },
  typography: {
    typography: {
      useNextVariants: true,
    },

    // Use the system font instead of the default Roboto font.
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
  },

  fontSize: {
    primary: '0.875rem',
  },

  overrides: {
    MuiListItemText: {
      inset: {
        paddingLeft: '0px',
      },
    },
    MuiTableCell: {
      sizeSmall: {
        padding: '0 12px',
      },
      root: {
        fontSize: '0.875rem',
      },
    },
    MuiTypography: {
      body1: {
        fontSize: '0.875rem',
      },
    },
    MuiTableRow: {
      root: {
        height: '64px',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'transparent',
      },
    },
  },
});

export default theme;
