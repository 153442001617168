import React from 'react';
import { Typography } from '@material-ui/core';
import { People } from '@material-ui/icons';

const getFontColor = (maxCount, count, theme) => {
  if (maxCount === count) {
    return theme.icon.success.main;
  }
  if (count === 0) {
    return theme.icon.secondary.main;
  }
  if (count < 4) {
    return theme.icon.error.main;
  }
  if (count >= 4 && count <= 10) {
    return theme.icon.primary.main;
  }
  return theme.icon.success.main;
};
const ParticipantIndicator = ({ maxCount, count, theme }) => {
  const customFontColor = getFontColor(maxCount, count, theme);
  return (
    <>
      <Typography style={{ fontSize: '24px', color: `${customFontColor}` }}>{count}</Typography>
      <People style={{ fontSize: '20px', color: `${customFontColor}` }} />
    </>
  );
};

export default ParticipantIndicator;
