import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { linkToRecord, GET_MANY } from 'react-admin';
import { Link } from 'react-router-dom';
import {
  Button as MuiButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { restProvider as dataProvider } from '../../client';

const DeleteAssetErrorDialog = ({
  resource = 'asset-sets',
  error,
  isOpen,
  onClose,
}) => {
  const [assetSets, setAssetSets] = useState([]);

  useEffect(() => {
    error.assetSetIds && fetchAssetSetsAndSetError(error.assetSetIds);
  }, [error]);

  const fetchAssetSetsAndSetError = (ids) => {
    dataProvider(GET_MANY, resource, { ids }).then((audioSets) => {
      setAssetSets(audioSets.data);
    });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>
        {error.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {error.message}
        </DialogContentText>
        <List>
          {assetSets.map((assetSet) => {
            const linkToSet = linkToRecord('/audio-sets', assetSet.id, 'show');
            return (
              <ListItem key={assetSet.id}>
                <ListItemText>
                  <Link to={linkToSet} target='_blank'>{assetSet.name}</Link>
                </ListItemText>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <MuiButton onClick={onClose}>
          Close
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
};

DeleteAssetErrorDialog.propTypes = {
  resource: PropTypes.string,
  error: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default DeleteAssetErrorDialog;
