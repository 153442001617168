import React from 'react';
import { Edit, SaveButton, Toolbar } from 'react-admin';

import ResultForm from './ResultForm';

const EditToolbar = props => (
  <Toolbar {...props} >
      <SaveButton />
  </Toolbar>
);

const ResultEdit = (props) => (
  <Edit title='Edit Result' {...props} undoable={false} >
    <ResultForm isExisting toolbar={<EditToolbar />} />
  </Edit>
);

export default ResultEdit;
