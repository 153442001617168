import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { Button, resolveRedirectTo } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ActionListIcon from '@material-ui/icons/List';
import ContentAddIcon from '@material-ui/icons/Add';

import LowBattSensorsCard from 'components/LowBattSensorsCard';


const styles = (theme) => ({
  root: {
    width: '100%',
  },
  bar: {
    marginBottom: theme.spacing(2),
  },
  barTitle: {
    marginRight: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  leftCol: {
    flex: '1 1 auto',
    marginRight: theme.spacing(1),
  },
  rightCol: {
    flex: '1 1 auto',
    marginRight: theme.spacing(1),
  },
});

class SensorsDashboardPage extends React.Component {
  render () {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar
          className={classes.bar}
          position='static'
          color='default'
          elevation={1}
        >
          <Toolbar>
            <Typography className={classes.barTitle} variant='h6' color='inherit'>
              Sensors Dashboard
            </Typography>

            <Button
              color='inherit'
              label='List All'
              component={Link}
              to={resolveRedirectTo('list', 'sensors')}
            >
              <ActionListIcon />
            </Button>
            <Button
              color='inherit'
              label='Create'
              component={Link}
              to={resolveRedirectTo('create', 'sensors')}
            >
              <ContentAddIcon />
            </Button>

            <div className={classes.grow} />
          </Toolbar>
        </AppBar>

        <div className={classes.flex}>
          <div className={classes.leftCol}>
            <LowBattSensorsCard />
          </div>
        </div>
      </div>
    );
  }
}

SensorsDashboardPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withRouter,
  withStyles(styles),
);

export default enhance(SensorsDashboardPage);
