import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Query, showNotification } from 'react-admin';
import { connect } from 'react-redux';

import EventTypeStructureForm from '../event-type-structures/EventTypeStructureForm';

const EventTypeStructureFormField = ({ record, showNotification }) => {
  const { event_type_structure_id: eventTypeStructureId } = record;

  if (!eventTypeStructureId) return <EventTypeStructureForm eventTypeId={record.id} />;
  return (
    <Query type='GET_ONE' resource='event-type-structures' payload={{ id: eventTypeStructureId }}>
      {({ data, loading, error }) => {
        if (loading) {
          return <CircularProgress />;
        }
        if (error) {
          showNotification(`An error has occurred: ${error.message}`);
        }
        return <EventTypeStructureForm record={data} eventTypeId={record.id} />;
      }}
    </Query>
  );
};

const mapDispatchToProps = {
  showNotification,
};

EventTypeStructureFormField.propTypes = {
  record: PropTypes.object,
  showNotification: PropTypes.func.isRequired,
};

EventTypeStructureFormField.defaultProps = {
  record: {},
};

export default connect(null, mapDispatchToProps)(EventTypeStructureFormField);
