import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Confirm,
  DELETE_MANY,
  refreshView as refreshViewAction,
  showNotification as showNotificationAction,
  setListSelectedIds as setListSelectedIdsAction,
} from 'react-admin';
import {
  withStyles,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { compose } from 'recompose';
import { restProvider as dataProvider } from '../../client';
import { get, uniq, flatten } from 'lodash';

import DeleteAssetErrorDialog from '../assets/DeleteAssetErrorDialog';

const styles = (theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
    text: {
      color: theme.palette.error.main,
    },
  },
});

const AudioBulkDeleteButton = ({
  resource,
  selectedIds,
  refreshView,
  showNotification,
  setListSelectedIds,
  ...props
}) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [error, setError] = useState(null);

  const handleConfirm = () => {
    dataProvider(DELETE_MANY, resource, { ids: selectedIds })
      .then(() => {
        setListSelectedIds(resource, []);
        showNotification(`${selectedIds.length} audio files deleted`, 'info');
      })
      .catch((error) => {
        if (error.code === 'asset_is_referenced_by_asset_sets') {
          const audioSetIds = flatten(
            uniq(
              error.details.map(
                (details) => get(details, 'error.details.asset_set_ids')
              )
            )
          );
          setError({
            title: 'Cannot delete all audio files',
            message: 'Some audio files cannot be deleted because they are referenced by the following Audio Sets:',
            assetSetIds: audioSetIds,
          });
        } else {
          showNotification('An error occurred while deleting the audio files', 'warning');
        }
      })
      .then(() => {
        setIsConfirmOpen(false);
        refreshView();
      });
  };

  const handleClose = () => {
    setError(null);
    setListSelectedIds(resource, []);
  };

  return (
    <>
      <Button
        label='Delete'
        className={props.classes.deleteButton}
        onClick={() => setIsConfirmOpen(true)}>
          <>
            <Delete />
          </>
      </Button>
      <Confirm
        // Workaround to force the state clean up of the modal
        key={isConfirmOpen}
        isOpen={isConfirmOpen}
        title='Delete Audio Files'
        content='Are you sure you want to delete the selected Audio Files?'
        onConfirm={handleConfirm}
        onClose={() => setIsConfirmOpen(false)}
      />
      {error &&
        <DeleteAssetErrorDialog
          error={error}
          isOpen={!!error}
          onClose={handleClose}
        />}
    </>
  );
};

const mapActionsToProps = {
  refreshView: refreshViewAction,
  showNotification: showNotificationAction,
  setListSelectedIds: setListSelectedIdsAction,
};

const enhance = compose(
  connect(null, mapActionsToProps),
  withStyles(styles)
);

export default enhance(AudioBulkDeleteButton);
