import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';

import AvatarField from '../users/AvatarField';
import formattedFullName from './utils/formattedFullName';


const FullNameField = ({ record, size, showAvatar, ...rest }) => {
  if (!record) {
    return null;
  }

  return (
    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
      {showAvatar && <AvatarField record={record} size={size} style={{ marginRight: '.5em' }} />}
      <span>{formattedFullName(record)}</span>
    </div>
  );
};

FullNameField.propTypes = {
  record: PropTypes.object,
  showAvatar: PropTypes.bool,
  size: PropTypes.number,
};

FullNameField.defaultProps = {
  record: null,
  showAvatar: true,
};

export default pure(FullNameField);
