import React from 'react';
import { Create } from 'react-admin';

import NoteForm from './NoteForm';


const NoteCreate = (props) => (
  <Create title='Create Note' {...props} undoable={false} >
    <NoteForm />
  </Create>
);

export default NoteCreate;
