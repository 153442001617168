import { Grid } from '@material-ui/core';
import { get, isString } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { NumberInput, required, SelectInput, TextInput, ArrayInput, SimpleFormIterator } from 'react-admin';

import { districtsMap, citiesMap, countries, locationTypes, timezones } from '../constants';
import AmenitiesInput from './AmenitiesInput';
import LinksSelectInput from './LinksSelectInput';
import PublicLinksInput from './PublicLinksInput';

const getCitiesFor = (countryCode) => get(citiesMap, countryCode, []);

const getDistrictsFor = (countryCode, cityCode) => get(districtsMap, `${countryCode}.${cityCode}`, []);

const LocationTab = (props) => {
  const { formData } = props;

  return (
    <Grid container spacing={8}>
      <Grid item xs={12} sm={6}>
        <Grid item xs={12}>
          <SelectInput
            xs={12}
            label='Country'
            source='country_code'
            choices={countries}
            allowEmpty
            resettable
            validate={required()}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectInput
            label='City'
            source='city_code'
            choices={getCitiesFor(formData.country_code)}
            allowEmpty
            resettable
            validate={required()}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectInput
            label='District'
            source='district_code'
            choices={getDistrictsFor(formData.country_code, formData.city_code)}
            allowEmpty
            resettable
          />
        </Grid>

        <Grid item xs={12}>
          <SelectInput
            label='Timezone'
            source='timezone'
            choices={timezones}
            allowEmpty
            resettable
            validate={required()}
          />
        </Grid>

        <Grid item xs={12}>
          <NumberInput source='lat' label='Latitude' />
        </Grid>

        <Grid item xs={12}>
          <NumberInput source='lon' label='Longitude' />
        </Grid>

        <Grid item xs={12}>
          <TextInput label='Street' source='address.address1' />
        </Grid>

        <Grid item xs={12}>
          <TextInput label='Postal Code' source='address.zip' />
        </Grid>

        <Grid item xs={12}>
          <SelectInput label='Location Type' source='type' choices={locationTypes} resettable validate={required()} />
        </Grid>

        <Grid item xs={12}>
          <AmenitiesInput />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Grid item xs={12}>
          <TextInput multiline label='News' source='news' />
        </Grid>

        <Grid item xs={12}>
          <ArrayInput label='Links' source='links'>
            <SimpleFormIterator>
              <LinksSelectInput source='name' label='Name' validate={required()} formData={formData} />
              <TextInput source='url' label='URL' validate={required()} parse={decodeURI} />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
        <Grid item xs={12}>
          <ArrayInput label='Codes' source='codes'>
            <SimpleFormIterator>
              <TextInput source='code' label='Item' validate={required()} />
              <TextInput source='value' label='Code' validate={required()} />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
        <Grid item xs={12}>
          <ArrayInput label='Rules' source='rules'>
            <SimpleFormIterator>
              <TextInput label='Rule' format={(data) => (isString(data) ? data : '')} />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
        <Grid item xs={12}>
          <ArrayInput label='Public Links' source='public_data'>
            <SimpleFormIterator>
              <PublicLinksInput source='name' label='Name' validate={required()} formData={formData} />
              <TextInput source='url' label='URL' validate={required()} parse={decodeURI} />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
      </Grid>
    </Grid>
  );
};

LocationTab.propTypes = { formData: PropTypes.object };
LocationTab.defaultProps = { formData: {} };

export default LocationTab;
