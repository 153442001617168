import React from 'react';
import { ReferenceField, TextField } from 'react-admin';


const SensorPoolReferenceField = props => (
  <ReferenceField reference="sensor_pools" {...props}>
    <TextField source="name" />
  </ReferenceField>
);

SensorPoolReferenceField.defaultProps = {
  source: 'sensor_pool_id',
  addLabel: true,
  label: 'Sensor Pool',
  link: 'show',
  allowEmpty: true,
};

export default SensorPoolReferenceField;
