import React from 'react';
import { ReferenceArrayInput, useQuery } from 'react-admin';
import AutocompleteArrayInput from 'reactAdmin/inputs/AutocompleteArrayInput';

const EventTypeReferenceInput = (props) => {
  const { total } = useQuery({
    type: 'getList',
    resource: 'event-types',
  });

  return (
    <ReferenceArrayInput
      {...props}
      reference='event-types'
      perPage={total}
      allowEmpty={false}
      filter={props.hideArchived && { is_archived: false }}
    >
      <AutocompleteArrayInput />
    </ReferenceArrayInput>
  );
};
EventTypeReferenceInput.defaultProps = {
  source: 'event_type_id',
  label: 'Event Type',
  hideArchived: false,
};

export default EventTypeReferenceInput;
