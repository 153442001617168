import React from 'react';
import { EditButton, List, TextField, ShowButton } from 'react-admin';
import { get, find } from 'lodash';
import PropTypes from 'prop-types';
import Datagrid from '../Datagrid';
import CustomDateField from '../fields/CustomDateField';
import TimeField from '../fields/TimeField';
import FormattableTextField from '../fields/FormattableTextField';
import AssetFileField from '../fields/AssetFileField';
import AudioFilesFilter from './AudioFilesFilter';
import AudioBulkDeleteButton from './AudioBulkDeleteButton';
import { categories, genres } from './constants';

const AudioBulkActions = (props) => {
  return (
    <>
      <AudioBulkDeleteButton label='Delete' {...props} />
    </>
  );
};

const AudioList = (props) => (
  <List
    {...props}
    sort={{ field: 'created_at', order: 'desc' }}
    perPage={25}
    filters={<AudioFilesFilter />}
    bulkActionButtons={<AudioBulkActions />}
  >
    <Datagrid
      defaultColumns={[
        'created_at',
        'updated_at',
        'title',
        'file',
        'metadata.category',
        'metadata.genre',
        'metadata.language',
        'metadata.duration',
      ]}
    >
      <ShowButton />
      <CustomDateField source='created_at' timezoneSource='location.timezone' label='Created' />
      <CustomDateField source='updated_at' timezoneSource='location.timezone' label='Last Edited' />
      <TextField source='title' label='Name' />

      <AssetFileField source='file' label='File' />
      <FormattableTextField
        source='metadata.category'
        label='Category'
        format={(value) => get(find(categories, { id: value }), 'name')}
      />
      <FormattableTextField
        source='metadata.genre'
        label='Genre'
        format={(value) => get(find(genres, { id: value }), 'name')}
      />
      <TextField source='metadata.language' label='Language' />
      <TimeField source='metadata.duration' label='Duration' />

      <EditButton />
    </Datagrid>
  </List>
);

export default AudioList;

AudioList.propTypes = {
  basePath: PropTypes.string,
};
