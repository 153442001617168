import PropTypes from 'prop-types';
import React from 'react';
import { SelectInput, required } from 'react-admin';

import { genres } from '../../../reactAdmin/audio-files/constants';

function AssetGenreField({ inputProps, defaultValue, validate }) {
  return (
    <SelectInput
      inputProps={inputProps}
      source='metadata.genre'
      label='Genre'
      defaultValue={defaultValue}
      choices={genres}
      validate={validate}
      resettable
    />
  );
}

AssetGenreField.propTypes = {
  inputProps: PropTypes.object,
  defaultValue: PropTypes.string,
  validate: PropTypes.func,
};

AssetGenreField.defaultProps = {
  validate: required(),
};

export default AssetGenreField;
