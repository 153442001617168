import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import moment from 'moment-timezone';

import { ReferenceField } from 'react-admin';

import UserFullNameField from '../users/FullNameField';
import EventFullNameField from '../events/FullNameField';

const styles = {
  eventname: { color: 'rgba(0,0,0,0.7)', fontSize: '14px' },
  header: { display: 'flex', justifyContent: 'space-between', marginBottom: '10px', fontWeight: 'bold' },
  results: { fontSize: '14px', color: 'rgba(0,0,0,0.5)', marginTop: '10px', fontWeight: 'bold' },
};

const SimpleResultItem = ({ basePath, record }) => {
  const calories = `${get(record, 'calories_burned') || 0} Calories`;
  const points = `${get(record, 'gritpoints') || 0} Gritpoints`;

  const zones = ['time_in_zone1', 'time_in_zone2', 'time_in_zone3', 'time_in_zone4'];
  const total = zones.reduce((acc, zone) => acc + get(record, zone), 0);
  const formattedTotal = moment(total).format('HH:MM:SS');

  return (
    <div>
      <div style={styles.header}>
        <ReferenceField basePath={basePath} record={record} label='User' source='user_id' reference='users' link={false}>
          <UserFullNameField />
        </ReferenceField>
      </div>
      <ReferenceField basePath={basePath} record={record} label='Event' source='event_id' reference='events' link={false}>
        <EventFullNameField elStyle={styles.eventname} />
      </ReferenceField>
      <div style={styles.results}>
        {[calories, points].join(' | ')}
        <div>Workout time: {formattedTotal}</div>
      </div>
    </div>
  );
};

SimpleResultItem.propTypes = {
  basePath: PropTypes.string,
  record: PropTypes.object,
};

SimpleResultItem.defaultProps = {
  basePath: '',
  record: {},
};


export default SimpleResultItem;
