import React from 'react';
import PropTypes from 'prop-types';

import GritText from '../GritText';

import styles from './styles';


const NumberField = ({ gritLabel, icon, label, value, w50, mb1 }) => {
  const dynStyle = {
    w50: w50 ? styles.w50 : {},
    mb1: mb1 ? styles.mb1 : {},
  };
  return (
    <div style={{ ...styles.row, ...dynStyle.mb1 }}>
      <div style={{ ...styles.column.left, ...dynStyle.w50 }}>
        <div>
          <img src={icon} alt="icon" style={styles.img} />
        </div>
        <div style={styles.inputNumber}>
          {value}
        </div>
      </div>
      <div style={{ ...styles.column.right, ...dynStyle.w50 }}>
        {gritLabel ? (
          <GritText text={gritLabel} breakText />
        ) : (
          label
        )}
      </div>
    </div>
  );
};

NumberField.propTypes = {
  gritLabel: PropTypes.string,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.number,
  w50: PropTypes.bool,
  mb1: PropTypes.bool,
};

NumberField.defaultProps = {
  gritLabel: '',
  label: '',
  value: undefined,
  w50: false,
  mb1: false,
};

export default NumberField;
