import React from 'react';
import { Edit, SaveButton, Toolbar } from 'react-admin';

import LocationForm from './LocationForm';

const EditToolbar = props => (
  <Toolbar {...props} >
    <SaveButton />
  </Toolbar>
);

const LocationEdit = (props) => (
  <Edit title='Edit Location' {...props} undoable={false} >
    <LocationForm isExisting toolbar={<EditToolbar />} />
  </Edit>
);


export default LocationEdit;
