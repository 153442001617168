import React, { useState, useEffect } from 'react';
import { GET_ONE, Labeled, showNotification } from 'react-admin';
import { restClient as dataProvider } from 'client';
import AssetFileField from '../fields/AssetFileField';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const style = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 'fit-content',
  },
});

const AudioFileField = ({ assetId, assignmentId, classes, showNotification }) => {
  const [file, setFile] = useState(null);

  const getAsset = async (id) => {
    let asset;
    try {
      asset = await dataProvider(
        GET_ONE, 'assets', { id }
      ).then((response) => response.data);
    } catch (error) {
      return showNotification('Could not fetch asset', 'warning');
    }
    setFile(asset);
  };

  useEffect(() => {
    assetId && getAsset(assetId);
  }, [assetId]);

  return (
    <div className={classes.container}>
      <Labeled label={assignmentId}>
        {file && <AssetFileField source='file' record={file} />}
      </Labeled>
    </div>
  );
};

AudioFileField.propTypes = {
  assetId: PropTypes.string,
  assignmentId: PropTypes.string,
  classes: PropTypes.object,
  showNotification: PropTypes.func,
};


const mapDispatchToProps = {
  showNotification,
};

export default connect(null, mapDispatchToProps)(withStyles(style)(AudioFileField));
