import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import IconImageEye from '@material-ui/icons/RemoveRedEye';
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import {
  BooleanField,
  Button,
  ChipField,
  EmailField,
  SimpleShowLayout,
  SelectField,
  TextField,
} from 'react-admin';

import { providers as providerChoices } from './constants';


const styles = (theme) => ({
  field: {
    // These styles will ensure our drawer don't fully cover our
    // application when teaser or title are very long
    '& span': {
      display: 'inline-block',
      maxWidth: '20em'
    }
  }
});

const UserPreviewView = ({ classes, ...props }) => (
  <SimpleShowLayout {...props}>
    <TextField source='forename' className={classes.field} />
    <TextField source='surname' className={classes.field} />
    <EmailField source='email' className={classes.field} />
    <SelectField source='provider' choices={providerChoices} className={classes.field} />
    <TextField source='provider_id' label='Provider ID (e.g. USC Member No.)' className={classes.field} />
    <TextField source='usc_membership_type' label='USC Membership Type' className={classes.field} />
    <ChipField source='roles' className={classes.field} />
    <BooleanField source='is_virtual' label='Virtual User' className={classes.field} />
  </SimpleShowLayout>
);

const mapStateToProps = (state, props) => ({
  // Get the record by its id from the react-admin state.
  record: state.admin.resources[props.resource]
    ? state.admin.resources[props.resource].data[props.id]
    : null,
  version: state.admin.ui.viewVersion
});

const UserPreview = connect(mapStateToProps, {})(withStyles(styles)(UserPreviewView));

class UserQuickPreviewButton extends Component {
  constructor (props) {
    super(props);
    this.state = {
      showPanel: false,
    };
  }

  handleClick = () => {
    this.setState({ showPanel: true });
  };

  handleCloseClick = () => {
    this.setState({ showPanel: false });
  };

  render() {
    const { showPanel } = this.state;
    const { id } = this.props;

    return (
      <Fragment>
        <Button onClick={this.handleClick} label='Preview'>
          <IconImageEye />
        </Button>
        <Drawer
          anchor='right'
          open={showPanel}
          onClose={this.handleCloseClick}
        >
          <div>
            <Button label='Close' onClick={this.handleCloseClick}>
              <IconKeyboardArrowRight />
            </Button>
          </div>
          <UserPreview id={id} basePath='/users' resource='users' />
        </Drawer>
      </Fragment>
    );
  }
}

export default connect()(UserQuickPreviewButton);
