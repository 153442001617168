import { withStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import React from 'react';
import { Link } from 'react-admin';

import NameField from './NameField';

const styles = (theme) => ({
  link: {
    color: theme.palette.primary.main,
  },
});

const EmbeddedLocationField = ({ record, source, className }) => (
  <Link to={{ pathname: `/locations/${get(record, source)}/show` }} className={className}>
    <NameField record={record.location || record.place} />
  </Link>
);

EmbeddedLocationField.defaultProps = {
  source: 'location_id',
  addLabel: true,
  label: 'Location',
  link: 'show',
  allowEmpty: true,
  record: {},
};

export default withStyles(styles)(EmbeddedLocationField);
