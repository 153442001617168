export default {
  button: {
    marginRight: 12,
  },
  export: {
    marginTop: '2rem',
    marginLeft: 0,
    marginRight: 0,
  },
  note: {
    fontSize: '0.7rem',
    color: 'rgba(0, 0, 0, 0.4)',
  },
  error: {
    color: 'red',
  },
  previewwrapper: {
    maxWidth: '100%',
    height: 'auto',
    border: '1px solid lightgray',
    marginTop: '4rem',
  },
  previewnote: {
    padding: '1rem',
    textAlign: 'center',
  },
  dateField: {
    width: 200,
  },
};
