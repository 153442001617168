import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  FormHelperText,
} from '@material-ui/core';

const renderItem = (item, index) => {
  return (
    <ListItem key={index}>
      <ListItemText primary={item} />
    </ListItem>
  );
};

const SimpleArrayField = ({
  source,
  emptyText = 'This list is empty.',
  record = [],
}) => {
  if (!record[source]) return <FormHelperText>{emptyText}</FormHelperText>;

  return <List dense>{record[source].map(renderItem)}</List>;
};

SimpleArrayField.defaultProps = {
  addLabel: true,
};

export default SimpleArrayField;
