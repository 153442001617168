import React from 'react';
import PropTypes from 'prop-types';
import { GET_LIST } from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import BatteryAlertIcon from '@material-ui/icons/BatteryAlert';

import { restClient } from 'client';
import SensorList from 'components/SensorList';


const style = (theme) => ({
  card: {
    marginBottom: '1em',
  },
  rightListItemText: {
    marginRight: '1em',
  },
  loadingContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '1em',
  },
});

class LowBattSensorsCard extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      sensors: [],
      isLoading: false,
    };
  }

  componentDidMount () {
    this.fetchSensors();
  }

  fetchSensors = async () => {
    let filter = {
      is_allocatable: true,
      current_battery_level_lte: 50,
    };
    const fetchOptions = {
      filter,
      sort: { field: 'current_battery_level', order: 'ASC' },
      pagination: { page: 1, perPage: 50 },
    };

    this.setState({ sensors: [], isLoading: true });

    const sensors = await restClient(GET_LIST, 'sensors', fetchOptions).then((response) => response.data);

    this.setState({ sensors, isLoading: false });
  }

  renderLoading = () => (
    <div className={this.props.classes.loadingContainer}>
      <CircularProgress />
    </div>
  )

  render () {
    const { classes } = this.props;
    const { sensors, isLoading } = this.state;

    return (
      <Card className={classes.card}>
        <CardHeader
          avatar={<BatteryAlertIcon />}
          title='Critical Battery Levels'
          subheader='Sensors with 50% or lower battery level'
        />
        {isLoading
          ? this.renderLoading()
          : <SensorList sensors={sensors} />
        }
      </Card>
    );
  }
}

LowBattSensorsCard.propTypes = {
  classes: PropTypes.object,
};

LowBattSensorsCard.defaultProps = {
  classes: {},
};

export default withStyles(style)(LowBattSensorsCard);
