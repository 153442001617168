import PropTypes from 'prop-types';
import React from 'react';

const LocationTitle = ({ record }) => <span>Location {record ? `'${record.name}'` : ''}</span>;

LocationTitle.propTypes = {
  record: PropTypes.object,
};

LocationTitle.defaultProps = {
  record: {},
};

export default LocationTitle;
