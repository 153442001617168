import React from 'react';
import { Edit, SaveButton, Toolbar } from 'react-admin';

import TrainerForm from './TrainerForm';

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const TrainerEdit = (props) => (
  <Edit title='Edit Trainer' {...props} undoable={false}>
    <TrainerForm isExisting toolbar={<EditToolbar />} />
  </Edit>
);

export default TrainerEdit;
