import React from 'react';
import {
  BooleanField,
  DateField,
  EmailField,
  FunctionField,
  NumberField,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  UrlField,
} from 'react-admin';
import { IdField } from 'components/IdField';
import humanizeRecord from 'utils/humanizeRecord';
import UserFullNameField from '../users/FullNameField';
import TrainerTitle from './TrainerTitle';
import VerbalBooleanField from '../fields/VerbalBooleanField';

const TrainerShow = (props) => (
  <Show title={<TrainerTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label='Basic'>
        <IdField id='id' />
        <VerbalBooleanField source='is_published' label='Published' />
        <VerbalBooleanField source='trial' label='Trial' />
        <TextField source='forename' />
        <TextField source='surname' />
        <EmailField source='email' />
        <EmailField source='whatsapp_group_url' />
        <ReferenceField label='User' source='user_id' reference='users' link='show'>
          <UserFullNameField />
        </ReferenceField>
        <DateField source='created_at' showTime locales='de-DE' />
      </Tab>

      <Tab label='Stats'>
        <NumberField
          source='stats.first_time_conversion.conversion_rate'
          label='First Time Conversion'
          options={{ style: 'percent' }}
        />
        <NumberField source='stats.first_time_conversion.all_customers' label='First Time Customers' />
        <NumberField source='stats.total_event_stats.total_workouts' label='Total workouts' />
        <NumberField
          source='stats.total_event_stats.gritpoints_avg'
          label='Gritpoints Avg (All time)'
          options={{ maximumFractionDigits: 1 }}
        />
        <NumberField
          source='stats.this_month_event_stats.gritpoints_avg'
          label='Gritpoints Avg (This month)'
          options={{ maximumFractionDigits: 1 }}
        />
        <NumberField
          source='stats.past_month_event_stats.gritpoints_avg'
          label='Gritpoints Avg (Past month)'
          options={{ maximumFractionDigits: 1 }}
        />
      </Tab>

      <Tab label='Details'>
        <FunctionField
          label='Languages'
          render={(record) => record && record.languages && record.languages.join(', ')}
        />
        <NumberField source='tier' />
        <UrlField source='website' />
        <TextField source='phone_numbers.mobile' label='Phone Mobile' />

        <TextField source='facebook_url' />
        <TextField source='twitter_url' />
        <TextField source='instagram_url' />
        <TextField source='intro_video_youtube_url' label='Intro Video (Youtube URL)' />

        <TextField source='about' />
        <TextField source='tagline' />

        <TextField source='quote' />
        <TextField source='about_gym' />
        <TextField source='about_music' />
        <TextField source='about_pleasure' />

        <BooleanField source='offers_individual_sessions' />
      </Tab>

      <Tab label='Location'>
        <TextField source='homezone_zipcode' />
        <TextField source='homezone_country_code' />
        <TextField source='homezone_city_code' />
        <TextField source='homezone_district_code' />
        <FunctionField
          label='Location Types'
          render={(record) => record?.preferred_location_types && humanizeRecord(record.preferred_location_types)}
        />
      </Tab>

      <Tab label='Music'>
        <FunctionField
          label='Music Genres'
          render={(record) => record?.preferred_music_genres && humanizeRecord(record.preferred_music_genres)}
        />
        <FunctionField
          label='Ride Music Genres'
          render={(record) => record?.preferred_ride_music_genres && humanizeRecord(record.preferred_ride_music_genres)}
        />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default TrainerShow;
