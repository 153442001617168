import { httpClient } from 'client';
import isArray from 'lodash/isArray';


const apiUrl = process.env.REACT_APP_API_URL;

const addProfileImageUploadCapabilities = (requestHandler) => (type, resource, parameters) => {
  const params = { ...parameters };
  if (type === 'UPDATE' && resource === 'trainers') {
    if (params.data.profile_image_virtual) {
      const virtualImages = isArray(params.data.profile_image_virtual)
        ? params.data.profile_image_virtual
        : [params.data.profile_image_virtual];

      if (!virtualImages.length) {
        return requestHandler(type, resource, params);
      }

      const url = `${apiUrl}/media`;
      const images = virtualImages.filter((p) => p.rawFile instanceof File);
      const form = new FormData();
      const requestOptions = {
        method: 'PATCH',
        body: form,
      };

      // the params contain the image as a fileInstance
      // params.data.images = [];

      form.append('target', 'trainer');
      form.append('target_id', params.id);
      form.append('type', 'profile_picture');

      images.forEach((image) => {
        form.append('files', image.rawFile);
      });

      return httpClient(url, requestOptions).then(() => {
        delete (params.data.profile_picture);

        return requestHandler(type, resource, params);
      });
    }
  }

  return requestHandler(type, resource, params);
};

export default addProfileImageUploadCapabilities;
