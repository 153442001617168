import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';


const SensorField = ({ record, elStyle, showHwId, showBattery }) => {
  const name = get(record, 'measurement_profile.device_history.0.sensor_slug');
  const hwId = get(record, 'measurement_profile.device_history.0.device_id');
  const batteryLevel = get(record, 'measurement_profile.device_history.0.battery_level', get(record, ''));

  return (
    <span style={elStyle}>
      {name &&
        <span style={{ whiteSpace: 'nowrap' }}>{name}</span>
      }
      {showHwId && hwId &&
        <span style={{ whiteSpace: 'nowrap' }}> ({hwId})</span>
      }
      {showBattery && batteryLevel &&
        <span style={{ whiteSpace: 'nowrap' }}> ({batteryLevel}%)</span>
      }
    </span>
  );
};

SensorField.propTypes = {
  elStyle: PropTypes.object,
  record: PropTypes.object,
  label: PropTypes.string, 
  addLabel: PropTypes.bool, 
  showHwId: PropTypes.bool,
  showBattery: PropTypes.bool,
};

SensorField.defaultProps = {
  elStyle: {},
  record: {},
  addLabel: true,
  label: 'Sensor',
  showHwId: false,
  source: 'sensor_slug',
  showBattery: false,
};

export default SensorField;
