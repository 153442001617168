import React from 'react';
import { ReferenceArrayInput, ReferenceInput, AutocompleteInput, required } from 'react-admin';
import AutocompleteArrayInput from 'reactAdmin/inputs/AutocompleteArrayInput';

import formattedFullNameEmail from './utils/formattedFullNameEmail';

const SingleTrainerReferenceInput = (props) => (
  <ReferenceInput reference='trainers' perPage={10} {...props} validate={!props.optional && [required()]}>
    <AutocompleteInput optionText={formattedFullNameEmail} />
  </ReferenceInput>
);

const MultipleTrainersReferenceInput = (props) => (
  <ReferenceArrayInput reference='trainers' perPage={10} {...props}>
    <AutocompleteArrayInput optionText={formattedFullNameEmail} />
  </ReferenceArrayInput>
);

const TrainerReferenceInput = (props) => {
  if (props.multiple) return <MultipleTrainersReferenceInput {...props} />;
  return <SingleTrainerReferenceInput {...props} />;
};

TrainerReferenceInput.defaultProps = {
  multiple: false,
  resource: 'trainers',
  source: 'coach_id',
  label: 'Coach',
  filter: { is_published: true },
  sort: { field: 'forename', order: 'ASC' },
  optional: false,
};

export default TrainerReferenceInput;
