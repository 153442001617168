import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { GET_LIST } from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';

import { restClient } from 'client';
import EventList from 'components/EventList';


const style = (theme) => ({
  card: {
    marginBottom: '1em',
  },
  rightListItemText: {
    marginRight: '1em',
  },
  tab: {
    backgroundColor: theme.palette.background.paper,
  },
  loadingContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '1em',
  },
});

class UpcomingEvents extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      events: [],
      tab: 0,
      isLoading: false,
    };
  }

  componentDidMount () {
    this.fetchEvents();
  }

  fetchEvents = async (timespan = 'today') => {
    const today = moment();
    const tomorrow = moment().add(1, 'day');
    let filter = {
      hide_created_by_mistake: true,
    };

    switch (timespan) {
      case 'today':
        filter = {
          ...filter,
          date_begin_gte: today.clone().subtract(20, 'minutes').format(),
          date_begin_lte: today.clone().endOf('day').format(),
        };
        break;
      case 'tomorrow':
        filter = {
          ...filter,
          date_begin_gte: tomorrow.clone().startOf('day').format(),
          date_begin_lte: tomorrow.clone().endOf('day').format(),
        };
        break;
      default:
        break;
    }

    const fetchOptions = {
      filter,
      sort: { field: 'date_begin', order: 'desc' },
      pagination: { page: 1, perPage: 50 },
    };

    this.setState({ events: [], isLoading: true });

    const events = await restClient(GET_LIST, 'events', fetchOptions).then((response) => response.data);

    this.setState({ events, isLoading: false });
  }

  handleChangeTab = (event, tab) => {
    this.setState({ tab });

    if (tab === 0) {
      this.fetchEvents('today');
    } else if (tab === 1) {
      this.fetchEvents('tomorrow');
    } else {
    }
  };

  renderLoading = () => (
    <div className={this.props.classes.loadingContainer}>
      <CircularProgress />
    </div>
  )

  render () {
    const { classes } = this.props;
    const { events, tab, isLoading } = this.state;

    return (
      <Card className={classes.card}>
        <CardHeader title='Upcoming Events' />
        <AppBar position='static' elevation={0} color='inherit'>
          <Tabs
            value={tab}
            onChange={this.handleChangeTab}
            indicatorColor='primary'
            textColor='primary'
          >
            <Tab label='Today' />
            <Tab label='Tomorrow' />
          </Tabs>
        </AppBar>
        {isLoading
          ? this.renderLoading()
          : <EventList events={events} />
        }
        {/*
        <CardActions>
          <Button size='small' color='primary'>
            Load More
          </Button>
          <Button size='small' color='primary'>
            All Today's Events
          </Button>
          <Button size='small' color='primary'>
            All Yesterday's Events
          </Button>
        </CardActions>
        */}
      </Card>
    );
  }
}

UpcomingEvents.propTypes = {
  classes: PropTypes.object.isRequired,
};

UpcomingEvents.defaultProps = {
};

export default withStyles(style)(UpcomingEvents);
