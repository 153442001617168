import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Cancel';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import getBlobDuration from 'get-blob-duration';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { TextInput, required, Button, FormDataConsumer } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import usePrevious from 'utils/customHooks/usePrevious';
import secondsToHMS from 'utils/secondsToHMS';

import AssetCategoryField from './AssetCategoryField';
import AssetGenreField from './AssetGenreField';
import AssetLanguageField from './AssetLanguageField';
import SaveProgressIcon from './SaveProgressIcon';

const RowForm = ({ row, path, name, disableForm, removeFiles, status, clearSavedFile, classes, getFormState }) => {
  const [duration, setDuration] = useState(null);
  const [category, setCategory] = useState('');

  const form = useForm();

  const formState = useFormState();
  const prevFormState = usePrevious(formState);

  useEffect(() => {
    if (isEqual(prevFormState, formState)) return;
    getFormState(path, formState);
  }, [formState]);

  useEffect(() => {
    getDuration();
  }, []);

  useEffect(() => {
    const { category } = get(formState, 'values.metadata', '');
    category && setCategory(category);
  }, [formState.values.metadata]);

  const formattedDuration = () => {
    const formatted = secondsToHMS(duration);
    const hh = formatted.hh === '00' ? '' : `${formatted.hh}:`;
    return `${hh}${formatted.mm}:${formatted.ss}`;
  };

  const getDuration = async function () {
    const preview = URL.createObjectURL(row.file);
    const duration = await getBlobDuration(preview);
    setDuration(duration);
    form.change('metadata.duration', duration);
    return duration;
  };

  return (
    <div className={classes.row} key={path}>
      <SaveProgressIcon status={status} />
      <div className={disableForm ? `${classes.row} ${classes.disabled}` : classes.row}>
        <div className={classes.nameInput}>
          <Typography>{name}</Typography>
        </div>
        <div>
          <TextInput inputProps={{ className: classes.nameInput }} source='title' label='Title' validate={required()} />
        </div>
        <div>
          <AssetCategoryField inputProps={{ className: classes.styledInput }} />
        </div>
        <div>
          <AssetGenreField
            inputProps={{ className: classes.styledInput }}
            validate={category === 'music' && required()}
            key={category === 'music' ? 1 : 0}
          />
        </div>
        <div>
          <AssetLanguageField inputProps={{ className: classes.styledInput }} />
        </div>
        <div>
          <Typography>{formattedDuration(duration)}</Typography>
        </div>
      </div>
      {status === 'success' ? (
        <Button onClick={() => clearSavedFile(path)} label='clear'>
          <ClearIcon />
        </Button>
      ) : (
        <Button onClick={() => removeFiles(path)} className={classes.deleteButton} label='Remove'>
          <RemoveIcon />
        </Button>
      )}
    </div>
  );
};
RowForm.propTypes = {
  disableForm: PropTypes.bool,
  row: PropTypes.object,
  classes: PropTypes.object,
  removeFiles: PropTypes.func,
  status: PropTypes.string,
  clearSavedFile: PropTypes.func,
};

export default RowForm;
