import { get } from 'lodash';
import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

import CopyIdButton from 'components/CopyIdButton';

const EventTypeReferenceField = (props) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <ReferenceField reference='event-types' allowEmpty {...props}>
        <TextField source='name' />
      </ReferenceField>
      <CopyIdButton id={get(props, 'record.event_type_id')} />
    </div>
  );
};

EventTypeReferenceField.defaultProps = {
  source: 'event_type_id',
  addLabel: true,
  label: 'EventType',
  link: 'show',
};

export default EventTypeReferenceField;
