const createMergeQuery = ({ destinationUserId, sourceUserId }) => {
  return {
    action: 'merge',
    data: {
      destination_account: {
        query: { id: destinationUserId },
      },
      source_account: {
        query: { id: sourceUserId },
      },
    },
  };
};

export default createMergeQuery;
