import React from 'react';
import { DateField, EditButton, List, ShowButton, TextField } from 'react-admin';

import Datagrid from '../Datagrid';
import UserReferenceField from '../users/UserReferenceField';
import NoteFilter from './NoteFilter';
import NoteListActions from './NoteListActions';

const NoteList = (props) => (
  <List
    {...props}
    title='User Notes List'
    sort={{ field: 'created_at', order: 'desc' }}
    perPage={40}
    filters={<NoteFilter />}
    actions={<NoteListActions />}
    bulkActions={false}
  >
    <Datagrid defaultColumns={['user_id', 'type', 'body', 'created_at']}>
      <ShowButton />
      <UserReferenceField showAvatar={false} />
      <TextField source='type' />
      <TextField source='body' label='Text' />
      <DateField source='valid_from' locales='de-DE' />
      <DateField source='valid_to' locales='de-DE' />
      <DateField source='created_at' showTime locales='de-DE' />
      <EditButton />
    </Datagrid>
  </List>
);

export default NoteList;
