import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

const SensorModelField = ({ record = {} }) => {
  const vendor = get(record, 'vendor_name', '');
  const model = get(record, 'model_name', '');

  const name = [vendor, model].join(' ').trim();

  return <div>{name}</div>;
};

SensorModelField.propTypes = {
  record: PropTypes.object, // eslint-disable-line
};

SensorModelField.defaultProps = {
  record: {},
};

export default SensorModelField;
