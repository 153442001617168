import React from 'react';
import { Edit, SaveButton, Toolbar } from 'react-admin';

import TicketForm from './TicketForm';

const EditToolbar = props => (
  <Toolbar {...props} >
      <SaveButton />
  </Toolbar>
);

const TicketEdit = (props) => (
  <Edit title='Edit Ticket' {...props} undoable={false} >
    <TicketForm isExisting toolbar={<EditToolbar />}  />
  </Edit>
);

export default TicketEdit;
