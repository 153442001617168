import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  mailNotSet: {
    fontWeight: 'bold',
    fontStyle: 'italic',
    color: 'orange',
  },
};

const EmailField = ({ record, source }) =>
  record[source] ? <span>{record[source]}</span> : <span style={styles.mailNotSet}>NO EMAIL</span>;

EmailField.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object,
  label: PropTypes.string, // eslint-disable-line
  addLabel: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
};

EmailField.defaultProps = {
  source: 'email',
  record: {},
  addLabel: true,
};

export default EmailField;
