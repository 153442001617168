import React from 'react';
import { BooleanInput } from 'react-admin';
import { useForm } from 'react-final-form';

const CascadeBooleanInput = () => {
  const form = useForm();

  return (
    <BooleanInput
      label='Cascading'
      source='cascade_offer'
      onChange={(value) => {
        const tierValue = value ? 1 : null;
        const delayValue = value ? 30 : null;
        form.change('tier', tierValue);
        form.change('cascade_delay_mins', delayValue);
      }}
    />
  );
};

export default CascadeBooleanInput;
