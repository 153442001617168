
export const types = [
  { id: 'injury', name: 'Injury' },
  { id: 'health_condition', name: 'Health Condition' },
  { id: 'vacation', name: 'vacation' },
];

export const scopeRestrictions = [
  { id: 'admin', name: 'Admins' },
];
