import { CREATE } from 'react-admin';

export const EVENT_CANCEL = 'EVENT_CANCEL';
export const EVENT_CANCEL_LOADING = 'EVENT_CANCEL_LOADING';
export const EVENT_CANCEL_FAILURE = 'EVENT_CANCEL_FAILURE';
export const EVENT_CANCEL_SUCCESS = 'EVENT_CANCEL_SUCCESS';

export const cancelEvent = (id, data, { reason, comment = '' } = {}) => ({
  type: EVENT_CANCEL,
  payload: { data: { status_name: 'cancelled', meta: { reason, cancellation_reason_comment: comment }, ...data } },
  meta: { resource: `events/${id}/status`, fetch: CREATE, refresh: true, cancelPrevious: false },
});

export const EVENT_MEASURE = 'EVENT_MEASURE';
export const EVENT_MEASURE_LOADING = 'EVENT_MEASURE_LOADING';
export const EVENT_MEASURE_FAILURE = 'EVENT_MEASURE_FAILURE';
export const EVENT_MEASURE_SUCCESS = 'EVENT_MEASURE_SUCCESS';

export const measureEvent = (id, data) => ({
  type: EVENT_MEASURE,
  payload: { data: { status_name: 'measured' } },
  meta: { resource: `events/${id}/status`, fetch: CREATE, refresh: true, cancelPrevious: false },
});
