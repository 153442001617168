import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import styles from './styles';

const Form = ({ date, elStyles, error, onDateChanged, onExportClicked }) => (
  <div style={elStyles}>
    <h1>Weekly Stats</h1>

    <TextField
      type='date'
      onChange={onDateChanged}
      value={date}
      className={styles.dateField}
      InputLabelProps={{
        shrink: true,
      }}
    />

    <div style={{ marginTop: '1rem' }}>
      <Button variant='contained' color='primary' onClick={onExportClicked}>
        Export image*
      </Button>
    </div>

    <p style={styles.note}>*Use Google Chrome to export the image!</p>

    {error &&
      <p style={styles.error}>{error}</p>}

    <div id='smallpreview' style={styles.previewwrapper}>
      <p style={styles.previewnote}>
        If the automatically download fails,
        a preview of the report will appear here,
        which you can save manually.
      </p>
    </div>
  </div>
);

Form.propTypes = {
  date: PropTypes.object.isRequired,
  elStyles: PropTypes.object,
  error: PropTypes.string,
  onDateChanged: PropTypes.func.isRequired,
  onExportClicked: PropTypes.func.isRequired,
};

Form.defaultProps = {
  elStyles: {},
  error: null,
};

export default Form;
