import React from 'react';
import PropTypes from 'prop-types';
import {
  DateField,
  EditButton,
  List,
  SelectField,
  NumberField,
  Responsive,
  SimpleList,
  ShowButton,
  Pagination,
} from 'react-admin';

import CopyIdButton from 'components/CopyIdButton';
import Datagrid from '../Datagrid';
import EmbeddedUserField from '../users/EmbeddedUserField';
import UserFlagsReferenceField from '../users/UserFlagsReferenceField';
import EmbeddedEventField from '../events/EmbeddedEventField';
import TrainerReferenceField from '../trainers/TrainerReferenceField';
import LocationReferenceField from '../locations/LocationReferenceField';
import SecondsAsHhMmSsField from '../fields/SecondsAsHhMmSsField';
import ResultFilter from './ResultFilter';
import SimpleResultItem from './SimpleResultItem';
import SensorField from './SensorField';
import DailyStatsButton from './DailyStatsButton';
import HeartRateGraphField from './HeartRateGraphField';
import TimeInZonesGraphField from './TimeInZonesGraphField';
import ResultListActions from './ResultListActions';

import { cities, countries } from '../locations/constants';

const ResultPagination = (props) => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} {...props} />;

const ResultList = (props) => (
  <List
    {...props}
    filters={<ResultFilter />}
    actions={<ResultListActions />}
    sort={{ field: 'created_at', order: 'desc' }}
    perPage={25}
    bulkActions={false}
    pagination={<ResultPagination />}
  >
    <Responsive
      small={
        <SimpleList primaryText={(record) => <SimpleResultItem record={record} basePath={props.location.pathname} />} />
      }
      medium={
        <Datagrid
          // rowClick='show'
          defaultColumns={['user_id', 'event_id', 'gritpoints', 'calories_burned']}
        >
          <ShowButton />
          <CopyIdButton />
          <EmbeddedUserField source='user_id' label='User' showAvatar={false} />
          <EmbeddedEventField source='event_id' label='Event' />
          <TrainerReferenceField />
          <LocationReferenceField />

          <UserFlagsReferenceField />

          <SelectField source='location_country_code' label='Country' choices={countries} />
          <SelectField source='location_city_code' label='City' choices={cities} />

          <NumberField source='event.gritpoints_avg' label='Event Average Gritpoints' sortable={false} />
          <NumberField label='Beatpoints' source='beat_points' />
          <NumberField source='calories_burned' label='Cals' />
          <NumberField source='calories_epoc' label='Cals EPOC' />
          <SecondsAsHhMmSsField label='Time in Zone 4' source='time_in_zone4' />
          <SecondsAsHhMmSsField label='Time in Zone 3' source='time_in_zone3' />
          <SecondsAsHhMmSsField label='Time in Zone 2' source='time_in_zone2' />
          <SecondsAsHhMmSsField label='Time in Zone 1' source='time_in_zone1' />

          <SensorField />
          <NumberField source='measurement_stats.number_raw_readings' label='# Readings' />

          <DateField source='recorded_at' showTime locales='de-DE' />
          <DateField source='created_at' showTime locales='de-DE' />

          <DailyStatsButton />

          <EditButton />
        </Datagrid>
      }
      large={
        <Datagrid
          // rowClick='show'
          defaultColumns={['user_id', 'event_id', 'gritpoints', 'calories_burned']}
        >
          <ShowButton />
          <CopyIdButton />
          <EmbeddedUserField source='user_id' label='User' showAvatar={false} />
          <EmbeddedEventField source='event_id' label='Event' />
          <TrainerReferenceField />
          <LocationReferenceField />

          <UserFlagsReferenceField />

          <SelectField source='location_country_code' label='Country' choices={countries} />
          <SelectField source='location_city_code' label='City' choices={cities} />

          <HeartRateGraphField />
          <TimeInZonesGraphField />

          <NumberField
            source='event.gritpoints_avg'
            label='Event Average Gritpoints'
            sortable={false}
            options={{ maximumFractionDigits: 1 }}
          />
          <NumberField label='Beatpoints' source='beat_points' />
          <NumberField source='calories_burned' label='Cals' />
          <NumberField source='calories_epoc' label='Cals EPOC' />
          <SecondsAsHhMmSsField label='Time in Zone 4' source='time_in_zone4' />
          <SecondsAsHhMmSsField label='Time in Zone 3' source='time_in_zone3' />
          <SecondsAsHhMmSsField label='Time in Zone 2' source='time_in_zone2' />
          <SecondsAsHhMmSsField label='Time in Zone 1' source='time_in_zone1' />

          <SensorField />
          <NumberField source='measurement_stats.number_raw_readings' label='# Readings' />

          <DateField source='recorded_at' showTime locales='de-DE' />
          <DateField source='created_at' showTime locales='de-DE' />

          <DailyStatsButton />

          <EditButton />
        </Datagrid>
      }
    />
  </List>
);
// small={<SimpleList primaryText={(record) => <SimpleResultItem record={record} />} />}

ResultList.propTypes = {
  location: PropTypes.object,
};

ResultList.defaultProps = {
  location: {},
};

export default ResultList;
