import React from 'react';
import { Filter, TextInput } from 'react-admin';
import AutocompleteArrayInput from 'reactAdmin/inputs/AutocompleteArrayInput';

import EventTypeReferenceInput from '../events/EventTypeReferenceInput';
import { categories, genres } from './constants';

const AudioFilesFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Name' source='q' alwaysOn allowEmpty resettable />
    <AutocompleteArrayInput choices={categories} label='Category' source='metadata.category' alwaysOn resettable />
    <AutocompleteArrayInput choices={genres} label='Genre' source='metadata.genre' alwaysOn resettable />
    <EventTypeReferenceInput alwaysOn />
  </Filter>
);

export default AudioFilesFilter;
