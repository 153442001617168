import React from 'react';
import { EditButton, List, TextField, ShowButton } from 'react-admin';
import { get, find } from 'lodash';
import { IdField } from 'components/IdField';
import Datagrid from '../Datagrid';
import CustomDateField from '../fields/CustomDateField';
import FormattableTextField from '../fields/FormattableTextField';
import EventTypeReferenceField from '../event-types/EventTypeReferenceField';
import AudioSetFilter from './AudioSetFilter';
import { categories, genres } from './constants';

const AudioSetList = (props) => (
  <List {...props} sort={{ field: 'created_at', order: 'desc' }} perPage={25} filters={<AudioSetFilter />}>
    <Datagrid
      defaultColumns={['created_at', 'updated_at', 'name', 'metadata.category', 'metadata.genre', 'event_type_id']}
    >
      <ShowButton />
      <IdField source='id' />
      <CustomDateField source='created_at' timezoneSource='location.timezone' label='Created' />
      <CustomDateField source='updated_at' timezoneSource='location.timezone' label='Last Edited' />
      <TextField source='name' label='Name' />

      <FormattableTextField
        source='metadata.category'
        label='Category'
        format={(value) => get(find(categories, { id: value }), 'name')}
      />
      <FormattableTextField
        source='metadata.genre'
        label='Genre'
        format={(value) => get(find(genres, { id: value }), 'name')}
      />

      <EventTypeReferenceField source='event_type_id' />
      <EditButton />
    </Datagrid>
  </List>
);

export default AudioSetList;
