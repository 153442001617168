/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { GET_LIST } from 'react-admin';
import moment from 'moment-timezone';

import { restClient } from 'client';

import List from 'components/BirthdaysList';


class BirthdaysList extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      users: null,
      total: 0,
    };
  }

  componentDidMount () {
    this.fetchUsers(this.props.page);
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.page !== nextProps.page) {
      this.fetchUsers(nextProps.page);
    }
  }

  fetchUsers = (page = 1) => {
    const { dobMonth, order, perPage, sort } = this.props;

    restClient(GET_LIST, 'users', {
      pagination: { page, perPage },
      filter: { dob_month: dobMonth },
      sort: { field: sort, order },
    })
      .then((response) => {
        this.setState({
          users: response.data,
          total: parseInt(response.total || 0, 10),
        });
      });
  }

  onChangePage = (page) => {
    this.props.onChangePage(page);
    this.fetchUsers(page);
  }

  render () {
    return (
      <List
        users={this.state.users}
        total={this.state.total}
        page={this.props.page}
        perPage={this.props.perPage}
        onChangePage={this.onChangePage}
      />
    );
  }
}

BirthdaysList.propTypes = {
  page: PropTypes.number,
  perPage: PropTypes.number.isRequired,
  sort: PropTypes.string,
  order: PropTypes.string,
  dobMonth: PropTypes.number,
  onChangePage: PropTypes.func,
};

BirthdaysList.defaultProps = {
  page: null,
  sort: 'dob_month',
  order: 'ASC',
  dobMonth: (moment().month() + 1),
  onChangePage: () => {},
};

export default BirthdaysList;
