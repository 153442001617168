import React from 'react';
import { Filter, FormDataConsumer, SelectInput } from 'react-admin';

import TrainerReferenceInput from 'reactAdmin/trainers/TrainerReferenceInput';
import EventTypeReferenceInput from 'reactAdmin/event-types/EventTypeReferenceInput';
import LocationReferenceArrayInput from 'reactAdmin/locations/LocationReferenceArrayInput';

import { cities } from 'reactAdmin/locations/constants';
import { hod } from 'reactAdmin/events/constants';

function EventCalendarFilter ({ ...props }) {
  return (
    <Filter {...props}>
      <SelectInput label='City' source='location_city_code' choices={cities} alwaysOn />
      <FormDataConsumer alwaysOn source='location_id'>
        {({ formData, dispatch, ...rest }) => (
          <LocationReferenceArrayInput
            city={formData.location_city_code}
            district={formData.location_district_code}
            {...rest}
          />
        )}
      </FormDataConsumer>
      <TrainerReferenceInput />
      <EventTypeReferenceInput />
      <SelectInput label='Hour of Day' source='date_begin_hour_of_day' choices={hod} />
    </Filter>
  );
}

export default EventCalendarFilter;
