import React from 'react';
import PropTypes from 'prop-types';
import { Responsive } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';

import SensorListItem from './SensorListItem';


const style = (theme) => ({});

class SensorList extends React.Component {
  handleSelect = (sensor) => this.props.onSelected(sensor);

  render () {
    const { sensors, selectedId, onSelected } = this.props;

    return (
      <List >
        {sensors && sensors.map((record) => {
          if(!record) return null;
          return (
          <Responsive
            key={record.id}
            small={<SensorListItem sensor={record} selected={record.id === selectedId} onSelected={onSelected} />}
            medium={<SensorListItem sensor={record} selected={record.id === selectedId} onSelected={onSelected} />}
          />
        )})}
      </List>
    );
  }
}

SensorList.propTypes = {
  sensors: PropTypes.array,
  selectedId: PropTypes.string,
  onSelected: PropTypes.func,
};

SensorList.defaultProps = {
  sensors: [],
  selectedId: null,
  onSelected: null,
};

export default withStyles(style)(SensorList);
