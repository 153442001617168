export default {
  row: {
    display: 'flex',
  },
  column: {
    left: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    right: {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      paddingLeft: '0.5rem',
    },
  },
  w50: {
    width: '50%',
  },
  mb1: {
    marginBottom: '1rem',
  },
  inputNumber: {
    fontSize: '3rem',
    fontWeight: 'bold',
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
    color: 'rgba(0, 0, 0 , .7)',
  },
  img: {
    width: '50px',
  },
};
