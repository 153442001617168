import FormControl from '@material-ui/core/FormControl';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { get, startCase } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import {
  Tab,
  Datagrid,
  DateField,
  FunctionField,
  Pagination,
  NumberField,
  ReferenceField,
  List,
  Filter,
  AutocompleteArrayInput,
} from 'react-admin';

import OfferActionsField from '../../offers/OfferActionsField';
import TrainerReferenceField from '../../trainers/TrainerReferenceField';
import { offerStatuses } from '../constants';

const tabOfferFilterChoices = [...offerStatuses, { id: 'applied', name: 'Applied' }];

const eventRowStyle = (record) => {
  const opacity = get(record, 'current_status.status_name') === 'cancelled' ? '0.3' : '1';
  return {
    opacity,
  };
};

const renderStatusName = (record) => {
  if (!record) return null;
  const iconStyle = {
    display: 'inline-block',
    verticalAlign: 'middle',
  };
  const statusName = startCase(record.current_status.status_name);
  const isPublished = record.current_status.status_name === 'pending' || moment().isBefore(record.valid_from);
  return (
    <>
      {statusName}
      {isPublished && (
        <VisibilityOffIcon titleAccess='Offer is not visible to the coach.' fontSize='small' style={iconStyle} />
      )}
    </>
  );
};

const renderBonusValue = (record) => {
  if (!record.bonus_amount) return null;
  const value = new Intl.NumberFormat('de-DE', { style: 'currency', currency: record.bonus_currency }).format(
    record.bonus_amount,
  );
  return value;
};

const OffersPagination = (props) => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} {...props} />;

const OffersFilters = (props) => {
  return (
    <Filter {...props}>
      <AutocompleteArrayInput
        label='Status'
        alwaysOn
        source='status_name'
        choices={tabOfferFilterChoices}
        optionText='id'
        allowEmpty={false}
      />
    </Filter>
  );
};

const OffersTable = (props) => {
  return (
    <List
      resource='offers'
      basePath='/offers'
      filter={{ event_id: props.eventId }}
      sort={{ field: 'valid_from', order: 'DESC' }}
      pagination={<OffersPagination />}
      bulkActionButtons={false}
      filters={<OffersFilters />}
    >
      <Datagrid rowStyle={eventRowStyle}>
        <FunctionField label='Status' render={renderStatusName} sortBy='status_status_name' />
        <TrainerReferenceField />
        <ReferenceField label='Tier' source='coach_id' reference='trainers'>
          <NumberField source='tier' />
        </ReferenceField>
        <DateField label='Valid from' source='valid_from' showTime locales='de-DE' />
        <DateField label='Seen at' source='seen_at' showTime locales='de-DE' />
        <FunctionField label='Bonus' render={renderBonusValue} sortBy='bonus_amount' />
        <OfferActionsField />
      </Datagrid>
    </List>
  );
};

const TabOffers = (props) => (
  <Tab label='Offers' {...props}>
    <FormControl fullWidth>
      <OffersTable {...props} />
    </FormControl>
  </Tab>
);

export default TabOffers;
