import React from 'react';
import { Labeled, TextField, useRecordContext } from 'react-admin';
import CopyIdButton from './CopyIdButton';

export const IdField = (props) => {
  const record = useRecordContext(props);

  const { id } = props;

  if (!record && !id) return null;

  return (
    <Labeled source={id}>
      <div>
        <TextField source={id} />
        <CopyIdButton id={record[id]} />
      </div>
    </Labeled>
  );
};
