import React from 'react';
import { BooleanInput, Filter, NullableBooleanInput, SelectInput, SelectArrayInput, DateTimeInput } from 'react-admin';
import AutocompleteArrayInput from 'reactAdmin/inputs/AutocompleteArrayInput';
import { companies } from 'reactAdmin/users/constants';

import { makeStyles } from '@material-ui/core/styles';
import { cities, locationTypes } from '../locations/constants';
import LocationReferenceInput from '../locations/LocationReferenceInput';
import TrainerReferenceInput from '../trainers/TrainerReferenceInput';
import { dow, hod, offerStatuses, eventCancellationReasonsAndOutcomes } from './constants';
import EventTypeReferenceInput from './EventTypeReferenceInput';
import snakeCaseToCapitalizedWords from '../../utils/snakeCaseToCapitalizedWords';


const styles = {
  locationTypeInput: {
    minWidth: '10em',
  },
};

const useStyles = makeStyles((theme) => ({
  listContainer: {
    marginTop: 0,
    marginBottom: theme.spacing(2),
  },
}));

const EventFilter = (props) => {
  const classes = useStyles();

  const eventCancellationReason = Object.keys(eventCancellationReasonsAndOutcomes).map((key) => {
    return { id: key, name: snakeCaseToCapitalizedWords(key) }
  });
  return (
    <Filter {...props} className={classes.listContainer}>
      <DateTimeInput source='date_begin_gte' label='Begins after' alwaysOn />
      <DateTimeInput source='date_begin_lte' label='Begins before' alwaysOn />

      <AutocompleteArrayInput
        alwaysOn
        allowEmpty={false}
        choices={cities}
        label='City'
        source='location_city_code'
        optionValue='id'
      />

      <TrainerReferenceInput multiple alwaysOn />

      <LocationReferenceInput alwaysOn required={false} multiple />
      <NullableBooleanInput source='is_cancelled' label='Cancelled' alwaysOn />

      <NullableBooleanInput source='is_published' label='Published' />
      <BooleanInput source='special' defaultValue />

      <BooleanInput label='Find coach' source='flags.needs_coach_replacement' />
      <SelectInput defaultValue='open' label='Offer Status' source='offer_status_name' choices={offerStatuses} />
      <SelectInput defaultValue='open' label='Offer Status Not' source='offer_status_name_ne' choices={offerStatuses} />

      <EventTypeReferenceInput hideArchived />

      <SelectArrayInput style={styles.locationTypeInput} label='Location Type' source='type' choices={locationTypes} />

      <SelectInput label='Cancellation Reason' source="cancellation_reason" choices={eventCancellationReason} />
      <AutocompleteArrayInput choices={companies} label='Company' source='company' />
      <SelectInput label='Day of Week' source='date_begin_day_of_week' choices={dow} />
      <SelectInput label='Hour of Day' source='date_begin_hour_of_day' choices={hod} />

      <DateTimeInput source='offer_valid_from_gte' label='Offers validity after' />
      <DateTimeInput source='offer_valid_from_lte' label='Offers validity before' />

      <DateTimeInput source='offer_earliest_valid_from_gte' label='Earliest offer validity after' />
      <DateTimeInput source='offer_earliest_valid_from_lte' label='Earliest offer validity before' />
    </Filter>
  );
};

export default EventFilter;
