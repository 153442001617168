import { brand, gsBlack } from 'theme/colors';


export default {
  container: {
    width: '780px',
    backgroundColor: '#fff',
  },
  section: {
    paddingTop: '1rem',
    paddingBottom: '1rem',

    textAlign: 'center',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  center: {
    alignItems: 'center',
  },
  header: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: gsBlack,
    marginBottom: '1rem',
    marginLeft: 'auto',
    marginRight: 'auto',

  },
  icon: {},
  value: {
    fontSize: '3rem',
    fontWeight: 'bold',
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
    color: gsBlack,
  },
  divider: {
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  verticalDivider: {
    borderLeft: '0.5px solid rgba(0, 0, 0, 0.15)',
    borderRight: '0.5px solid rgba(0, 0, 0, 0.15)',
    marginTop: '1rem',
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  error: {
    fontWeight: 'bold',
    color: 'red',
  },

  // -------------- SECTION: DAILY STATS --------------
  dailyStats: {
    section: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
    textWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      width: '22px',
    },
    text: {
      fontSize: '1rem',
      fontWeight: 'bold',
      marginLeft: '0.5rem',
      color: gsBlack,
      whiteSpace: 'nowrap',
    },
    logo: {
      marginTop: '0.5rem',
    },
  },
  // -------------- SECTION: BANNER --------------
  banner: {
    backgroundColor: brand,
    display: 'flex',
    color: 'white',
    alignItems: 'center',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
  avatar: {
    marginRight: '1rem',
  },
  name: {
    fontSize: '2rem',
  },
  date: {
    whiteSpace: 'nowrap',
  },
  hrchart: {
    width: '100%',
    maxWidth: '100%',
    height: 'auto',
  },
  // -------------- SECTION: TOTALS --------------
  totals: {
    section: {
      display: 'flex',
      justifyContent: 'center',
    },
    label: {
      fontSize: '2rem',
      color: 'rgba(0, 0, 0, 0.7)',
      marginBottom: '2rem',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
};
