import moment from 'moment-timezone';

const getTimezone = (date) => moment(date).zoneName();

const transformDatesToCorrectUTCdate = (occurrences, passedTimezone) => {
  const timezoneOffsetOfFirstDate = moment(occurrences[0]).tz(passedTimezone).utcOffset();
  const timezoneOfFirstDate = getTimezone(occurrences[0]);

  return occurrences.map((occurrence) => {
    const tz = getTimezone(occurrence);
    if (tz !== timezoneOfFirstDate) {
      const difference = timezoneOffsetOfFirstDate - moment.tz(occurrence, passedTimezone).utcOffset();
      if (difference) occurrence.setMinutes(occurrence.getMinutes() + difference);
    }
    return moment.tz(occurrence, passedTimezone).set({ second: 0, ms: 0 }).toISOString();
  });
};

export default transformDatesToCorrectUTCdate;
