import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import TrainerIcon from '@material-ui/icons/Person';
import LocationIcon from '@material-ui/icons/Place';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { Responsive } from 'react-admin';
import eventDateBeginFormattedString from 'reactAdmin/events/utils/dateBeginFormattedString';

const styles = {
  card: { marginBottom: '1em' },
};

const EventsCard = ({ title, subtitle, events }) => (
  <Card style={styles.card}>
    <CardHeader title={title} subheader={subtitle} />

    {events && Array.isArray(events) ? (
      <List component='nav'>
        {events.map((record) => {
          if (!record) return null;

          const participants = get(record, 'participants_count', 0);
          const groupAndLocationName = `${get(record, 'place.name', '')} | ${get(record, 'group.name', '')}`;
          const published = get(record, 'is_published', false);
          const placeName = get(record, 'place.name');
          const trainerName = get(record, 'coach.forename');

          return (
            <Responsive
              key={record.id}
              small={
                <ListItem
                  href={`#/events/${record.id}/show`}
                  primaryText={eventDateBeginFormattedString(record.date_begin, {
                    timezone: get(record, 'place.timezone'),
                  })}
                  secondaryText={
                    <span>
                      <p>{groupAndLocationName}</p>
                      <p>
                        <b>
                          {participants}/{record.max_participants || '∞'} Participants
                          {!published && <span> - Not published</span>}
                        </b>
                      </p>
                    </span>
                  }
                />
              }
              medium={
                <ListItem button component='a' href={`#/events/${record.id}/show`}>
                  <ListItemText
                    primary={
                      <span>
                        {eventDateBeginFormattedString(record.date_begin, { timezone: get(record, 'place.timezone') })}
                        &nbsp;
                        {!published && <span>Not published</span>}
                      </span>
                    }
                    secondary={
                      <span>
                        {placeName && (
                          <span>
                            <LocationIcon fontSize='inherit' /> {placeName}&nbsp;|&nbsp;
                          </span>
                        )}
                        {trainerName && (
                          <span>
                            <TrainerIcon fontSize='inherit' /> {trainerName}&nbsp;|&nbsp;
                          </span>
                        )}
                      </span>
                    }
                  />
                  <ListItemSecondaryAction>
                    <span>
                      {participants}/{record.max_participants || '∞'} Participants
                    </span>
                  </ListItemSecondaryAction>
                </ListItem>
              }
            />
          );
        })}
      </List>
    ) : (
      <CardContent>
        <Typography component='p'>No events were found</Typography>
      </CardContent>
    )}
  </Card>
);

EventsCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  events: PropTypes.array,
};

EventsCard.defaultProps = {
  subtitle: null,
  events: null,
};

export default EventsCard;
