import PropTypes from 'prop-types';
import React from 'react';
import AutocompleteArrayInput from 'reactAdmin/inputs/AutocompleteArrayInput';

import { providers } from '../constants';

const ProviderArrayInput = (props) => <AutocompleteArrayInput choices={providers} {...props} />;

ProviderArrayInput.propTypes = {
  source: PropTypes.string,
  label: PropTypes.string,
};

ProviderArrayInput.defaultProps = {
  source: 'provider',
  label: 'Provider',
};

export default ProviderArrayInput;
