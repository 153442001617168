import React from 'react';
import moment from 'moment';
import { List } from 'react-admin';

import EventCalendarFilter from 'reactAdmin/events/EventCalendarFilter';
import EventListActions from 'reactAdmin/events/EventListActions';
import EventCalendar, { EventCalendarPagination } from 'reactAdmin/events/EventCalendar';

function EventCalendarContainer(props) {
  moment.locale('de');
  const now = moment();
  const currentWeekStart = now.startOf('week').toISOString();
  const currentWeekEnd = now.endOf('week').toISOString();

  return (
    <List
      actions={<EventListActions showNextPrevious />}
      filter={{ is_published: true, perPage: 200 }}
      filterDefaultValues={{
        date_begin_gte: currentWeekStart,
        date_begin_lte: currentWeekEnd,
        location_city_code: 'berlin',
      }}
      filters={<EventCalendarFilter />}
      perPage={200}
      pagination={<EventCalendarPagination />}
      sort={{ field: 'location_id', order: 'ASC' }}
      {...props}
    >
      <EventCalendar />
    </List>
  );
}

export default EventCalendarContainer;
