import React from 'react';
import get from 'lodash/get';
import { DateField, EditButton, List, Responsive, ShowButton, SimpleList, TextField, NumberField } from 'react-admin';
import Avatar from '@material-ui/core/Avatar';

import ChipArrayField from 'reactAdmin/fields/ChipArrayField';
import CopyIdButton from 'components/CopyIdButton';
import Datagrid from '../Datagrid';
import BooleanField from '../fields/VerbalBooleanField';
import UserReferenceField from '../users/UserReferenceField';
import { profilePictureUrl, EmailField } from '../users';
import TrainerFilter from './TrainerFilter';
import TrainerListActions from './TrainerListActions';
import TrainerProfilePictureField from './TrainerProfilePictureField';

const styles = {
  highlight: {
    fontWeight: 'bold',
    fontStyle: 'italic',
    color: 'orange',
  },
};

const TrainerList = (props) => (
  <List
    {...props}
    sort={{ field: 'forename', order: 'ASC' }}
    filters={<TrainerFilter />}
    actions={<TrainerListActions />}
    perPage={25}
    bulkActions={false}
    bulkActionButtons={false}
  >
    <Responsive
      small={
        <SimpleList
          leftAvatar={(record) => <Avatar src={profilePictureUrl(record)} />}
          primaryText={(record) => `${get(record, 'forename')} ${get(record, 'surname', '')}`}
          secondaryText={(record) => (
            <div>
              {get(record, 'user.email') || <EmailField record={record} />}
              {get(record, 'is_published') === false && <span style={styles.highlight}> - Not published</span>}
            </div>
          )}
          tertiaryText={(record) => new Date(get(record, 'created_at')).toLocaleDateString('de-DE')}
        />
      }
      medium={
        <Datagrid
          defaultColumns={[
            'forename',
            'surname',
            'phone_numbers.mobile',
            'user_id',
            'stats.total_event_stats.gritpoints_avg',
            'homezone_city_code',
            'preferred_location_types',
            'tier',
            'is_published',
            'trial',
          ]}
        >
          <ShowButton />
          <CopyIdButton />

          <TrainerProfilePictureField />
          <TextField source='forename' label='First Name' />
          <TextField source='surname' label='Last Name' />
          <TextField source='email' />
          <TextField source='phone_numbers.mobile' label='Phone' />
          <TextField source='homezone_city_code' label='City' />
          <NumberField
            source='stats.first_time_conversion.conversion_rate'
            label='First Time Conversion'
            options={{ style: 'percent' }}
          />
          <NumberField source='stats.first_time_conversion.all_customers' label='First Time Customers' />
          <NumberField source='stats.total_event_stats.total_workouts' label='Total workouts' />
          <NumberField
            source='stats.total_event_stats.gritpoints_avg'
            label='Gritpoints Avg (All time)'
            options={{ maximumFractionDigits: 1 }}
          />
          <NumberField
            source='stats.this_month_event_stats.gritpoints_avg'
            label='Gritpoints Avg (This month)'
            options={{ maximumFractionDigits: 1 }}
          />
          <NumberField
            source='stats.past_month_event_stats.gritpoints_avg'
            label='Gritpoints Avg (Past month)'
            options={{ maximumFractionDigits: 1 }}
          />
          <UserReferenceField label='User email' showMail />

          <ChipArrayField source='preferred_location_types' label='Location Types' />

          <NumberField source='tier' label='Tier' />

          <BooleanField source='is_published' label='Published' />
          <DateField source='created_at' showTime locales='de-DE' />
          <BooleanField source='trial' label='Trial' />

          <EditButton />
        </Datagrid>
      }
    />
  </List>
);

export default TrainerList;
