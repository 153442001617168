import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import CustomerIcon from '@material-ui/icons/PersonAdd';
import PropTypes from 'prop-types';
import React from 'react';
import { Responsive } from 'react-admin';
import { profilePictureUrl, formattedFullName, EmailField } from 'reactAdmin/users';
import formatDateTime from 'utils/formatDateTime';

import styles from './styles';

const NewUsers = ({ users }) => (
  <Card style={styles.card}>
    <CardContent>
      <CustomerIcon style={styles.icon} />
      <Typography variant='h4' title='New Users' subtitle='Showing the last 10 signups.' />

      {users ? (
        <List>
          {users.map((record) => {
            if (!record) return null;
            return (
              <Responsive
                key={record.id}
                small={
                  <ListItem
                    href={`#/users/${record.id}/show`}
                    leftAvatar={<Avatar src={`${profilePictureUrl(record)}`} />}
                    primaryText={formattedFullName(record)}
                    secondaryText={
                      <span>
                        <p>{record.email || <EmailField record={record} />}</p>
                        <p>
                          <b>{formatDateTime(record.created_at)}</b>
                        </p>
                      </span>
                    }
                  />
                }
                medium={
                  <ListItem
                    href={`#/users/${record.id}/show`}
                    leftAvatar={<Avatar src={`${profilePictureUrl(record)}`} size={32} />}
                    primaryText={formattedFullName(record)}
                    secondaryText={record.email || <EmailField record={record} />}
                    rightAvatar={<span>{formatDateTime(record.created_at)}</span>}
                  />
                }
              />
            );
          })}
        </List>
      ) : (
        <Typography>No users found</Typography>
      )}
    </CardContent>
  </Card>
);

NewUsers.propTypes = {
  users: PropTypes.array,
};

NewUsers.defaultProps = {
  users: null,
};
export default NewUsers;
