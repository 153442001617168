import React from 'react';
import { Edit } from 'react-admin';

import NoteForm from './NoteForm';


const NoteEdit = (props) => (
  <Edit title='Edit Note' {...props} undoable={false} >
    <NoteForm isExisting />
  </Edit>
);

export default NoteEdit;
