import _get from 'lodash/get';
import moment from 'moment-timezone';
import React from 'react';
import { Edit, SaveButton, Toolbar, FormDataConsumer } from 'react-admin';

import EventForm from './EventForm';
import SaveRecurrentButton from './SaveRecurrentButton';

const EditToolbar = (props) => {
  if (!_get(props, 'record.recurrent_id')) {
    return (
      <Toolbar {...props}>
        <SaveButton />
      </Toolbar>
    );
  }
  return (
    <Toolbar {...props}>
      <SaveButton />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          if (formData && formData.recurrent_id) {
            const timezone = _get(props, 'record.location.timezone');
            const mmtBefore = moment.tz(_get(props, 'record.date_begin'), timezone);
            const mmtAfter = moment.tz(formData.date_begin, timezone);
            const isDisabled = !mmtBefore.isSame(mmtAfter, 'day');
            return (
              <SaveRecurrentButton
                disabled={isDisabled}
                label='Save this and following'
                variant='flat'
                {...rest}
                formData={formData}
              />
            );
          }
        }}
      </FormDataConsumer>
    </Toolbar>
  );
};

const EventEdit = (props) => (
  <Edit title='Edit Event' {...props} undoable={false}>
    <EventForm isExisting toolbar={<EditToolbar />} />
  </Edit>
);

export default EventEdit;
