import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import CustomDateField from 'reactAdmin/fields/DateField';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useRecordContext } from 'react-admin';
import snakeCaseToCapitalizedWords from '../../../utils/snakeCaseToCapitalizedWords';
import UserTextField from '../../users/UserTextField';

const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px solid ${theme.palette.error.main}`,
    padding: theme.spacing(2),
    backgroundColor: '#e252411a',
    borderRadius: '10px',
  },
  header: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  label: {
    fontSize: '12px',
    color: theme.palette.text.secondary,
  },
  text: {
    fontSize: '14px',
  },
  section: {
    margin: '8px 0px',
  },
}));

export const EventCancelled = () => {
  const record = useRecordContext();
  const theme = useTheme();
  const styles = useStyles(theme);
  return (
    <Grid lg={12} container className={styles.container} spacing={2}>
      <Grid item xs={1} md={1} alignItems='center'>
        <Grid container alignItems='center'>
          <HighlightOffIcon color='error' fontSize='12' />
        </Grid>
      </Grid>
      <Grid item xs={11} md={11} alignItems='center'>
        <Grid>
          <Typography className={styles.header}>This event is cancelled</Typography>
        </Grid>
        <Grid direction='column' className={styles.section}>
          <Grid>
            <Typography variant='caption' className={styles.label}>
              {' '}
              Cancelled At{' '}
            </Typography>
          </Grid>
          <Grid>
            <Typography variant='primary' className={styles.text}>
              <CustomDateField label='Cancelled At' source='current_status.transitioned_at' />
            </Typography>
          </Grid>
        </Grid>
        <Grid direction='column' className={styles.section}>
          <Grid>
            <Typography variant='caption' className={styles.label}>
              {' '}
              Cancelled By{' '}
            </Typography>
          </Grid>
          <Grid>
            <Typography variant='primary' className={styles.text}>
              <UserTextField id={record.current_status.created_by} />
            </Typography>
          </Grid>
        </Grid>
        {record?.current_status?.meta?.cancellation_reason_comment && (
          <Grid direction='column' className={styles.section}>
            <Grid>
              <Typography variant='caption' className={styles.label}>
                Comment{' '}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant='primary' className={styles.text}>
                {record.current_status.meta.cancellation_reason_comment}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid direction='column' className={styles.section}>
          <Grid>
            <Typography className={styles.label}>Reason</Typography>
          </Grid>
          <Grid>
            <Typography variant='primary' className={styles.text}>
              {snakeCaseToCapitalizedWords(record.current_status.meta.reason)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container xs={12} className={styles.section}>
          <Grid xs={4} sm={4} direction='column'>
            <Grid>
              <Typography className={styles.label}>Coach Compensation </Typography>
            </Grid>
            <Grid>
              {record.current_status.meta.coach_fine && (
                <Typography variant='caption' className={styles.text}>
                  {String(record.current_status.meta.coach_fine)}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid xs={4} sm={4} direction='column'>
            <Grid>
              <Typography className={styles.label}>User Compensation </Typography>
            </Grid>
            <Grid>
              <Typography variant='caption' className={styles.text}>
                {snakeCaseToCapitalizedWords(record.current_status.meta.user_compensation)}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={4} sm={4} direction='column'>
            <Grid>
              <Typography className={styles.label}>Email Sent </Typography>
            </Grid>
            <Grid>
              <Typography variant='caption' className={styles.text}>
                {snakeCaseToCapitalizedWords(record.current_status.meta.customer_email)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
