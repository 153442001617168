import React from 'react';
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';
import { TextInput, required } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { DateTimePicker } from '@material-ui/pickers';
import { map } from 'lodash';
import EventTypeReferenceInput from 'reactAdmin/event-types/EventTypeReferenceInput';
import LocationReferenceInput from 'reactAdmin/locations/LocationReferenceInput';
import TrainerReferenceInput from 'reactAdmin/trainers/TrainerReferenceInput';
import { languages } from 'reactAdmin/events/constants';
import { companies } from 'reactAdmin/users/constants';

export const useStyles = makeStyles((theme) => ({
  root: {},
  formRow: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  rowText: {
    display: 'flex',
    alignSelf: 'end',
    marginRight: theme.spacing(2),
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(1),
  },
  blockField: {
    marginTop: theme.spacing(1),
    width: 300,
  },
  inlineField: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: 100,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dateInput: {
    minWidth: 200,
  },
  warningMessage: {
    borderRadius: '4px',
    backgroundColor: 'rgba(255, 152, 0, 0.5)',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    fontSize: '0.8em',
    maxWidth: 300,
    '-webkit-box-sizing': 'border-box',
  },
}));

const RecurringEventForm = ({
  every,
  setEvery,
  frequency,
  setFrequency,
  occurence,
  setOccurence,
  setLanguage,
  setLocationId,
  event,
  handleEventChange,
  showLanguageWarningMessage,
}) => {
  const classes = useStyles();

  return (
    <>
      {/* Start Date */}
      <div className={classes.formRow}>
        <div>
          <DateTimePicker
            ampm={false}
            value={event.date_begin}
            onChange={(date) => handleEventChange('date_begin', date.toISOString())}
          />
        </div>
      </div>

      {/* Occurence */}
      <div className={classes.formRow}>
        <div className={classes.rowText}>Repeat every</div>
        <div>
          <TextField
            id='every-number'
            name='every'
            value={every}
            onChange={(event) => setEvery(event.target.valueAsNumber)}
            type='number'
            className={classes.inlineField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div>
          <FormControl className={classes.formControl}>
            <Select
              value={frequency}
              name='frequency'
              onChange={(event) => setFrequency(event.target.value)}
              inputProps={{
                name: 'frequency',
                id: 'frequency-select',
              }}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              <MenuItem value='daily'>day</MenuItem>
              <MenuItem value='weekly'>week</MenuItem>
              <MenuItem value='monthly'>month</MenuItem>
              <MenuItem value='hourly'>hour</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <div className={classes.formRow}>
        <div className={classes.rowText}>Ends after</div>
        <div>
          <TextField
            id='occurence-number'
            name='occurence'
            value={occurence}
            onChange={(event) => setOccurence(event.target.valueAsNumber)}
            type='number'
            className={classes.inlineField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={classes.rowText}>occurrences</div>
      </div>

      {/* Event Type */}
      <div className={classes.row}>
        <EventTypeReferenceInput
          className={classes.blockField}
          record={event}
          onChange={(event) => handleEventChange('event_type_id', event.target.value)}
        />
      </div>

      {/* Location */}
      <div className={classes.row}>
        <LocationReferenceInput
          className={classes.blockField}
          record={event}
          required
          onChange={(locationId) => {
            setLocationId(locationId);
          }}
        />
      </div>

      {/* Coach */}
      <div className={classes.row}>
        <TrainerReferenceInput
          className={classes.blockField}
          record={event}
          onChange={(coachId) => handleEventChange('coach_id', coachId)}
        />
      </div>

      {/* Find Coach */}
      <FormGroup row>
        <FormControlLabel
          label='Find Coach'
          control={
            <Switch
              checked={event.flags?.needs_coach_replacement}
              onChange={(event) => handleEventChange('flags', event.target.checked, 'needs_coach_replacement')}
              value='needs_coach_replacement'
              name='needs_coach_replacement'
            />
          }
        />
      </FormGroup>

      <div className={classes.row}>
        {/* Duration */}
        <div>
          <TextField
            id='duration-number'
            name='duration'
            label='Duration (in minutes)'
            value={event.duration || ''}
            onChange={(event) => handleEventChange('duration', Number(event.target.value))}
            type='number'
            className={classes.blockField}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        {/* Max Participants */}
        <div>
          <TextField
            id='maxParticipants-number'
            name='max_participants'
            label='Max. Participants'
            value={event.max_participants || ''}
            onChange={(event) => handleEventChange('max_participants', Number(event.target.value))}
            type='number'
            className={classes.blockField}
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </div>

      <div className={classes.row}>
        {/* Language */}
        <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor='age-label-placeholder'>
            Event Language
          </InputLabel>
          <Select
            value={event.language || ''}
            name='language'
            className={classes.blockField}
            onChange={(event) => setLanguage(event.target.value)}
          >
            {map(languages, (language, idx) => (
              <MenuItem key={idx} value={language.id}>
                {language.name}
              </MenuItem>
            ))}
          </Select>
          {showLanguageWarningMessage && (
            <div className={classes.warningMessage}>
              Warning: The language of the selected coach does not match the event language
            </div>
          )}
        </FormControl>

        {/* Company */}
        <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor='age-label-placeholder'>
            Company
          </InputLabel>
          <Select
            id='company-string'
            name='company'
            label='Company'
            value={event.company || ''}
            onChange={(event) => handleEventChange('company', event.target.value)}
            className={classes.blockField}
          >
            <MenuItem value={null}>
              <em>None</em>
            </MenuItem>
            {map(companies, (company, idx) => (
              <MenuItem key={idx} value={company.id}>
                {company.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Is Published */}
        <FormGroup row>
          <FormControlLabel
            label='Is Published'
            control={
              <Switch
                checked={event.is_published}
                onChange={(event) => handleEventChange('is_published', event.target.checked)}
                value='is_published'
                name='is_published'
              />
            }
          />
        </FormGroup>

        {/* Is Chargeable */}
        <FormGroup row>
          <FormControlLabel
            label='Chargeable'
            control={
              <Switch
                checked={event.is_chargeable}
                onChange={(event) => handleEventChange('is_chargeable', event.target.checked)}
                value='is_chargeable'
                name='is_chargeable'
              />
            }
          />
        </FormGroup>

        {/* Special */}
        <FormControlLabel
          label='Special'
          control={
            <Switch
              name='special'
              value='special'
              checked={event.special}
              onChange={(event) => handleEventChange('special', event.target.checked)}
            />
          }
        />

        {event && event.special && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TextInput
              id='special_event_name'
              name='special_event_name'
              label='Special Event Name'
              value={event.special_event_name || ''}
              onChange={(event) => handleEventChange('special_event_name', event.target.value)}
              type='string'
              InputLabelProps={{ shrink: true }}
              validate={[required()]}
              margin='dense'
            />
            <TextInput
              id='special_event_description'
              name='special_event_description'
              label='Special Event Description'
              value={event.special_event_description || ''}
              onChange={(event) => handleEventChange('special_event_description', event.target.value)}
              type='string'
              InputLabelProps={{ shrink: true }}
              margin='dense'
              multiline
            />
          </div>
        )}
      </div>
    </>
  );
};

export default RecurringEventForm;
