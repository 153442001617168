export default (timeInSecs) => {
  const use2Digits = (x) => {
    if (x.toString().length > 1) return `${x}`.slice(-2);
    return `0${x}`.slice(-2);
  };

  const hh = use2Digits(Math.trunc(timeInSecs / 3600));
  const mm = use2Digits(Math.trunc((timeInSecs % 3600) / 60));
  const ss = use2Digits(Math.trunc(timeInSecs % 3600 % 60));

  return { hh, mm, ss };
};
