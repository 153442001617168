export const vendorNameOptions = [
  { id: 'Polar', name: 'Polar' },
  { id: 'SmartLAB', name: 'SmartLAB' },
  { id: 'Fitcare', name: 'Fitcare' },
  { id: 'Scosche', name: 'Scosche' },
  { id: 'No Name', name: 'No Name' },
  { id: 'other', name: 'Other' },
];

export const modelNameOptions = [
  { id: 'H7', name: 'H7' },
  { id: 'hrmW', name: 'hrmW' },
  { id: 'HRM803', name: 'HRM803' },
  { id: 'H10', name: 'H10' },
  { id: 'OH1', name: 'OH1' },
  { id: 'Rhythm+', name: 'Rhythm+' },
  { id: 'other', name: 'Other' },
];

export const replacementReasons = [
  { id: 'missing', name: 'Missing' },
  { id: 'defect', name: 'Defect' },
  { id: 'na', name: 'N/A' },
];
