import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ListItem from './ListItem';


const List = ({ items }) => (
  <Table>
    <TableHead adjustForCheckbox={false} displaySelectAll={false} enableSelectAll={false}>
      <TableRow>
        <TableCell>Rank</TableCell>
        <TableCell>Name</TableCell>
        <TableCell>Email</TableCell>
        <TableCell numeric>&sum; GRITPoints</TableCell>
        <TableCell numeric>&oslash; GRITPoints</TableCell>
        <TableCell numeric>&sum; Calories</TableCell>
        <TableCell numeric>&oslash; Calories</TableCell>
        <TableCell numeric>Total Results</TableCell>
      </TableRow>
    </TableHead>
    <TableBody stripedRows>
      {items.map((item, idx) => <ListItem key={idx} item={item} />)}
    </TableBody>
  </Table>
);

List.propTypes = {
  items: PropTypes.array.isRequired,
};

export default List;
