import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { Pagination, Responsive } from 'react-admin';
import { Link } from 'react-router-dom';
import { profilePictureUrl, formattedFullName } from 'reactAdmin/users';
import EmailField from 'reactAdmin/users/EmailField';
import UserFullNameField from 'reactAdmin/users/FullNameField';
import calculateAge from 'utils/calculateAge';

const BirthdaysList = ({ users, total, page, perPage, onChangePage }) => (
  <Card>
    <CardHeader title='Birthdays this month' />
    <CardContent>
      {total === 0 && <div>There are no birthdays this month</div>}

      {users && total > 0 && (
        <Responsive
          small={
            <List>
              {users.map((record) => {
                if (!record) return null;
                const age = moment().year() - record.dob_year;
                const month = moment()
                  .month(record.dob_month - 1)
                  .format('MMM');
                return (
                  <ListItem key={record.id} component={Link} to={{ pathname: `/users/${record.id}/show` }}>
                    <ListItemIcon>
                      <Avatar src={profilePictureUrl(record)} />
                    </ListItemIcon>
                    <ListItemText
                      primary={formattedFullName(record)}
                      secondary={
                        <div>
                          {age} years on
                          <b>
                            {' '}
                            {month}, {record.dob_day}
                          </b>
                        </div>
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
          }
          medium={
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>User</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell numeric>Age</TableCell>
                  <TableCell>Birthday</TableCell>
                </TableRow>
              </TableHead>
              <TableBody displayRowCheckbox={false}>
                {users.map((record) => {
                  if (!record) return null;
                  const ageToday = calculateAge(record.dob_day, record.dob_month, record.dob_year);
                  const ageAfterBirthday = moment().year() - record.dob_year;
                  return (
                    <TableRow key={record.id} selectable={false}>
                      <TableCell>
                        <UserFullNameField record={record} size={32} />
                      </TableCell>
                      <TableCell>
                        <EmailField record={record} addLabel={false} />
                      </TableCell>
                      <TableCell numeric>
                        {ageToday}
                        {ageToday !== ageAfterBirthday && (
                          <span>
                            <br />
                            will turn {ageAfterBirthday}
                          </span>
                        )}
                      </TableCell>
                      <TableCell>
                        {record.dob_day}.{record.dob_month}.{record.dob_year}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          }
        />
      )}

      {page && total > perPage && <Pagination total={total} page={page} perPage={perPage} setPage={onChangePage} />}
    </CardContent>
  </Card>
);

BirthdaysList.propTypes = {
  users: PropTypes.array,
  total: PropTypes.number.isRequired,
  page: PropTypes.number,
  perPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func,
};

BirthdaysList.defaultProps = {
  page: null,
  users: null,
  onChangePage: () => {},
};

export default BirthdaysList;
