import React from 'react';
import PropTypes from 'prop-types';
import { EditButton, DeleteButton } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';

const AudioSetShowActions = ({basePath, data, resource}) => (
  <CardActions>
    <EditButton basePath={basePath} record={data} />
    <DeleteButton basePath={basePath} record={data} resource={resource} />
  </CardActions>
);

AudioSetShowActions.propTypes = {
  basePath: PropTypes.string,
  data: PropTypes.object,
  resource: PropTypes.string,
};


export default AudioSetShowActions;
