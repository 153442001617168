export const languages = [
  { code: 'en', name: 'English' },
  { code: 'de', name: 'German' },
  { code: 'es', name: 'Spanish' },
];

export const cityDefaultLanguages = {
  en: ['berlin', 'amsterdam', 'rotterdam', 'london', 'stockholm', 'los_angeles'],
  de: ['munich', 'hamburg', 'duesseldorf', 'cologne', 'muenster', 'vienna', 'bonn', 'frankfurt'],
  es: ['barcelona'],
};

export const countries = [
  { id: 'at', name: 'Austria' },
  { id: 'de', name: 'Germany' },
  { id: 'es', name: 'Spain' },
  { id: 'gb', name: 'United Kingdom' },
  { id: 'nl', name: 'Netherlands' },
  { id: 'se', name: 'Sweden' },
  { id: 'dk', name: 'Denmark' },
  { id: 'us', name: 'US' },
];

export const cities = [
  { id: 'amsterdam', name: 'Amsterdam' },
  { id: 'rotterdam', name: 'Rotterdam' },
  { id: 'barcelona', name: 'Barcelona' },
  { id: 'berlin', name: 'Berlin' },
  { id: 'cologne', name: 'Cologne' },
  { id: 'hamburg', name: 'Hamburg' },
  { id: 'bonn', name: 'Bonn' },
  { id: 'frankfurt', name: 'Frankfurt' },
  { id: 'london', name: 'London' },
  { id: 'munich', name: 'Munich' },
  { id: 'vienna', name: 'Vienna' },
  { id: 'stockholm', name: 'Stockholm' },
  { id: 'copenhagen', name: 'Copenhagen' },
  { id: 'muenster', name: 'Münster' },
  { id: 'duesseldorf', name: 'Düsseldorf' },
  { id: 'los_angeles', name: 'Los Angeles' },
];

export const timezones = [
  { id: 'Europe/Amsterdam', name: 'Amsterdam' },
  { id: 'Europe/Berlin', name: 'Berlin' },
  { id: 'Europe/London', name: 'London' },
  { id: 'Europe/Madrid', name: 'Madrid' },
  { id: 'Europe/Stockholm', name: 'Stockholm' },
  { id: 'Europe/Copenhagen', name: 'Copenhagen' },
  { id: 'Europe/Vienna', name: 'Vienna' },
  { id: 'America/Los_Angeles', name: 'Los Angeles' },
];

export const citiesMap = {
  at: [{ id: 'vienna', name: 'Vienna' }],
  de: [
    { id: 'berlin', name: 'Berlin' },
    { id: 'cologne', name: 'Cologne' },
    { id: 'hamburg', name: 'Hamburg' },
    { id: 'munich', name: 'Munich' },
    { id: 'muenster', name: 'Münster' },
    { id: 'duesseldorf', name: 'Düsseldorf' },
    { id: 'bonn', name: 'Bonn' },
    { id: 'frankfurt', name: 'Frankfurt' },
  ],
  es: [{ id: 'barcelona', name: 'Barcelona' }],
  nl: [
    { id: 'amsterdam', name: 'Amsterdam' },
    { id: 'rotterdam', name: 'Rotterdam' },
  ],
  gb: [{ id: 'london', name: 'London' }],
  se: [{ id: 'stockholm', name: 'Stockholm' }],
  dk: [{ id: 'copenhagen', name: 'Copenhagen' }],
  us: [{ id: 'los_angeles', name: 'Los Angeles' }],
};

export const districtsMap = {
  at: {
    vienna: [
      { id: '1_bezirk', name: '1. Bezirk' },
      { id: '2_bezirk', name: '2. Bezirk' },
      { id: '3_bezirk', name: '3. Bezirk' },
      { id: '4_bezirk', name: '4. Bezirk' },
      { id: '5_bezirk', name: '5. Bezirk' },
      { id: '6_bezirk', name: '6. Bezirk' },
      { id: '7_bezirk', name: '7. Bezirk' },
      { id: '8_bezirk', name: '8. Bezirk' },
      { id: '9_bezirk', name: '9. Bezirk' },
      { id: '10_bezirk', name: '10. Bezirk' },
      { id: '11_bezirk', name: '11. Bezirk' },
      { id: '12_bezirk', name: '12. Bezirk' },
      { id: '13_bezirk', name: '13. Bezirk' },
      { id: '14_bezirk', name: '14. Bezirk' },
      { id: '15_bezirk', name: '15. Bezirk' },
      { id: '16_bezirk', name: '16. Bezirk' },
      { id: '17_bezirk', name: '17. Bezirk' },
      { id: '18_bezirk', name: '18. Bezirk' },
      { id: '19_bezirk', name: '19. Bezirk' },
      { id: '20_bezirk', name: '20. Bezirk' },
      { id: '21_bezirk', name: '21. Bezirk' },
      { id: '22_bezirk', name: '22. Bezirk' },
      { id: '23_bezirk', name: '23. Bezirk' },
    ],
  },
  nl: {
    amsterdam: [
      { id: 'amsterdam_centre', name: 'Amsterdam Centre' },
      { id: 'west', name: 'West' },
      { id: 'oost', name: 'Oost' },
      { id: 'zuid', name: 'Zuid' },
      { id: 'nieuw_west', name: 'Nieuw-West' },
    ],
    rotterdam: [
      { id: 'stadscentrum', name: 'Stadscentrum' },
      { id: 'delfshaven', name: 'Delfshaven' },
      { id: 'overschie', name: 'Overschie' },
      { id: 'noord', name: 'Noord' },
      { id: 'hillegersberg_schiebroek', name: 'Hillegersberg-Schiebroek' },
      { id: 'kralingen_crooswijk', name: 'Kralingen-Crooswijk' },
      { id: 'feijenoord', name: 'Feijenoord' },
      { id: 'ijsselmonde', name: 'IJsselmonde' },
      { id: 'pernis', name: 'Pernis' },
      { id: 'prins_alexander', name: 'Prins Alexander' },
      { id: 'charlois', name: 'Charlois' },
      { id: 'hoogvliet', name: 'Hoogvliet' },
      { id: 'hookof_holland', name: 'Hookof Holland' },
      { id: 'spaanse_polder', name: 'Spaanse Polder' },
      { id: 'nieuw_mathenesse', name: 'Nieuw Mathenesse' },
      { id: 'waalhaven_eemhaven', name: 'Waalhaven-Eemhaven' },
      { id: 'vondelingsplaat', name: 'Vondelingsplaat' },
      {
        id: 'botlek_europoort_maasvlakte',
        name: 'Botlek-Europoort-Maasvlakte',
      },
      { id: 'rotterdam_noord_west', name: 'Rotterdam-Noord-West' },
      { id: 'rivium', name: 'Rivium' },
      { id: 'bedrijventerrein_schieveen', name: 'Bedrijventerrein Schieveen' },
      { id: 'rozenburg', name: 'Rozenburg' },
    ],
  },
  de: {
    berlin: [
      { id: 'lichtenberg', name: 'Lichtenberg' },
      { id: 'mitte', name: 'Mitte' },
      { id: 'pankow', name: 'Pankow' },
      { id: 'charlottenburg_wilmersdorf', name: 'Charlottenburg-Wilmersdorf' },
      { id: 'tempelhof_schoeneberg', name: 'Tempelhof-Schöneberg' },
      { id: 'prenzlauer_berg', name: 'Prenzlauer Berg' },
      { id: 'moabit_wedding', name: 'Moabit-Wedding' },
      { id: 'friedrichshain', name: 'Friedrichshain' },
      { id: 'kreuzberg_neukoelln', name: 'Kreuzberg-Neukölln' },
      { id: 'treptow', name: 'Treptow' },
      { id: 'weissensee', name: 'Weißensee' },
    ],
    cologne: [
      { id: 'lindenthal', name: 'Lindenthal' },
      { id: 'ehrenfeld', name: 'Ehrenfeld' },
      { id: 'nippes', name: 'Nippes' },
      { id: 'altstadt_nord', name: 'Altstadt-Nord' },
      { id: 'neustadt_nord', name: 'Neustadt-Nord' },
      { id: 'belgisches_viertel', name: 'Belgisches Viertel' },
      { id: 'suedstadt', name: 'Südstadt' },
      { id: 'deutz', name: 'Deutz' },
      { id: 'suelz', name: 'Sülz' },
      { id: 'zollstock', name: 'Zollstock' },
      { id: 'muengersdorf', name: 'Müngersdorf' },
      { id: 'innenstadt', name: 'Innenstadt' },
      { id: 'rodenkirchen', name: 'Rodenkirchen' },
      { id: 'chorweiler', name: 'Chorweiler' },
      { id: 'porz', name: 'Porz' },
      { id: 'kalk', name: 'Kalk' },
      { id: 'muelheim', name: 'Mülheim' },
    ],
    hamburg: [
      { id: 'altona', name: 'Altona' },
      { id: 'st_pauli', name: 'St. Pauli' },
      { id: 'harvestehude', name: 'Harvestehude' },
      { id: 'barmbek_sued', name: 'Barmbek-Süd' },
      { id: 'eppendorf', name: 'Eppendorf' },
      { id: 'sternschanze', name: 'Sternschanze' },
      { id: 'winterhude', name: 'Winterhude' },
      { id: 'hamburg_mitte', name: 'Hamburg-Mitte' },
      { id: 'eimsbuettel', name: 'Eimsbüttel' },
      { id: 'hamburg_nord', name: 'Hamburg-Nord' },
      { id: 'wandsbek', name: 'Wandsbek' },
      { id: 'bergedorf', name: 'Bergedorf' },
      { id: 'harburg', name: 'Harburg' },
    ],
    munich: [
      { id: 'ludwigsvorstadt_isarvorstadt', name: 'Ludwigsvorstadt-Isarvorstadt' },
      { id: 'altstadt_lehel', name: 'Altstadt-Lehel' },
      { id: 'maxvorstadt', name: 'Maxvorstadt' },
      { id: 'ramersdorf_perlach', name: 'Ramersdorf-Perlach' },
      { id: 'neuhausen_nymphenburg', name: 'Neuhausen-Nymphenburg' },
      { id: 'schwabing_freimann', name: 'Schwabing-Freimann' },
      { id: 'sendling_westpark', name: 'Sendling-Westpark' },
      { id: 'schwabing_west', name: 'Schwabing-West' },
      { id: 'au_haidhausen', name: 'Au-Haidhausen' },
      { id: 'sendling', name: 'Sendling' },
      { id: 'schwanthalerhoehe', name: 'Schwanthalerhöhe' },
      { id: 'moosach', name: 'Moosach' },
      { id: 'milbertshofen_am_hart', name: 'Milbertshofen-Am Hart' },
      { id: 'bogenhausen', name: 'Bogenhausen' },
      { id: 'berg_am_laim', name: 'Berg am Laim' },
      { id: 'trudering_riem', name: 'Trudering-Riem' },
      { id: 'obergiesing_fasangarten', name: 'Obergiesing-Fasangarten' },
      { id: 'untergiesing_harlaching', name: 'Untergiesing-Harlaching' },
      { id: 'thalkirchen_obersendling', name: 'Thalkirchen-Obersendling' },
      { id: 'hadern', name: 'Hadern' },
      { id: 'pasing_obermenzing', name: 'Pasing-Obermenzing' },
      { id: 'aubing_lochhausen', name: 'Aubing-Lochhausen' },
      { id: 'allach_untermenzing', name: 'Allach-Untermenzing' },
      { id: 'feldmoching_hasenbergl', name: 'Feldmoching-Hasenbergl' },
      { id: 'laim', name: 'Laim' },
    ],
    muenster: [
      { id: 'nord', name: 'Nord' },
      { id: 'ost', name: 'Ost' },
      { id: 'west', name: 'West' },
      { id: 'mitte', name: 'Mitte' },
      { id: 'hiltrup', name: 'Hiltrup' },
      { id: 'sud-ost', name: 'Süd-Ost' },
    ],
    duesseldorf: [
      { id: 'golzheim', name: 'Golzheim' },
      { id: 'pempelfort', name: 'Pempelfort' },
      { id: 'unterblick', name: 'Unterbilk' },
      { id: 'duesseltal', name: 'Düsseltal' },
      { id: 'stadtmitte', name: 'Stadtmitte' },
      { id: 'carlstadt', name: 'Carlstadt' },
      { id: 'bilk', name: 'Bilk' },
      { id: 'oberbilk', name: 'Oberbilk' },
      { id: 'hafen', name: 'Hafen' },
      { id: 'altstadt', name: 'Altstadt' },
      { id: 'oberkassel', name: 'Oberkassel' },
      { id: 'flingern_nord', name: 'Flingern-Nord' },
      { id: 'derendorf', name: 'Derendorf' },
      { id: 'moersenbroich', name: 'Mörsenbroich' },
    ],
    bonn: [
      { id: 'bonn_zentrum', name: ' Bonn-Zentrum' },
      { id: 'bonn_castell', name: ' Bonn-Castell' },
      { id: 'beuel', name: ' Beuel' },
      { id: 'nordstadt', name: 'Nordstadt' },
      { id: 'weststadt', name: 'Weststadt' },
      { id: 'suedstadt', name: 'Südstadt' },
      { id: 'poppelsdorf', name: 'Poppelsdorf' },
      { id: 'gronau', name: 'Gronau' },
      { id: 'hochskreuz', name: 'Hochskreuz' },
      { id: 'friesdorf', name: 'Friesdorf' },
      { id: 'dottendorf', name: 'Dottendorf' },
      { id: 'kessenich', name: 'Kessenich' },
      { id: 'venusberg', name: 'Venusberg' },
      { id: 'ippendorf', name: 'Ippendorf' },
      { id: 'endenich', name: 'Endenich' },
    ],
    frankfurt: [
      { id: 'westend', name: 'Westend' },
      { id: 'nordend', name: 'Nordend' },
      { id: 'ostend', name: 'Ostend' },
      { id: 'innenstadt', name: 'Innenstadt' },
      { id: 'gallusviertel', name: 'Gallusviertel' },
      { id: 'bahnhofsviertel', name: 'Bahnhofsviertel' },
      { id: 'bornheim', name: 'Bornheim' },
      { id: 'bockenheim', name: 'Bockenheim' },
      { id: 'sachsenhausen', name: 'Sachsenhausen' },
      { id: 'ginnheim', name: 'Ginnheim' },
      { id: 'dornbusch', name: 'Dornbusch' },
      { id: 'eckenheim', name: 'Eckenheim' },
      { id: 'eschersheim', name: 'Eschersheim' },
    ],
  },
  es: {
    barcelona: [
      { id: 'barceloneta', name: 'Barceloneta' },
      { id: 'ciutat_vella', name: 'Ciutat Vella' },
      { id: 'eixample_dreta', name: 'Eixample Dreta' },
      { id: 'eixample_esquerre', name: 'Eixample Esquerre' },
      { id: 'gracia', name: 'Gràcia' },
      { id: 'horta_guinardo', name: 'Horta-Guinardò' },
      { id: 'les_corts', name: 'Les Corts' },
      { id: 'nou_barris', name: 'Nou Barris' },
      { id: 'sant_andreu', name: 'Sant Andreu' },
      { id: 'sant_marti', name: 'Sant Martì' },
      { id: 'sants_montjiuc_poble_sec', name: 'Sants-Montjiuc - Poble Sec' },
      { id: 'ciutat_vella', name: 'Ciutat Vella' },
      { id: 'poblenou', name: 'Poblenou' },
      { id: 'sant_gervasi_galvani', name: 'Sant Gervasi Galvani' },
      { id: 'sarria_sant_gervasi', name: 'Sarriá Sant Gervasi' },
    ],
  },
  gb: {
    london: [
      { id: 'hackney', name: 'Hackney' },
      { id: 'islington', name: 'Islington' },
      { id: 'tower_hamlets', name: 'Tower Hamlets' },
    ],
  },
  se: {
    stockholm: [
      { id: 'gamla_stan', name: 'Gamla stan' },
      { id: 'sodermalm', name: 'Södermalm' },
      { id: 'ostermalm', name: 'Östermalm' },
      { id: 'norrmalm', name: 'Norrmalm' },
      { id: 'vasastan', name: 'Vasastan' },
      { id: 'kungsholmen', name: 'Kungsholmen' },
    ],
  },
  dk: {
    copenhagen: [
      { id: 'indre_by', name: 'Indre By' },
      { id: 'vesterbro_kongens_enghave', name: 'Vesterbro/Kongens Enghave' },
      { id: 'norrebro', name: 'Nørrebro' },
      { id: 'osterbro', name: 'Østerbro' },
      { id: 'amager_ost', name: 'Amager Øst' },
      { id: 'amager_vest', name: 'Amager Vest' },
      { id: 'valby', name: 'Valby' },
      { id: 'vanlose', name: 'Vanløse ' },
      { id: 'bronshoj-husum', name: 'Brønshøj-Husum' },
      { id: 'bispebjerg', name: 'Bispebjerg' },
    ],
  },
  us: {
    los_angeles: [
      { id: 'downtown', name: 'Downtown' },
      { id: 'hollywood', name: 'Hollywood' },
      { id: 'west_hollywood', name: 'West Hollywood' },
      { id: 'beach_cities', name: 'Beach Cities/ LAX' },
      { id: 'santa_monica', name: 'Santa Monica' },
      { id: 'westside', name: 'Westside' },
      { id: 'beverly_hills', name: 'Beverly Hills' },
      { id: 'the_valley', name: 'The Valley' },
      { id: 'pasadena', name: 'Pasadena' },
    ],
  },
};

export const districts = [
  ...districtsMap.at.vienna.map((d) => ({
    ...d,
    fullName: `Vienna - ${d.name}`,
  })),
  ...districtsMap.nl.amsterdam.map((d) => ({
    ...d,
    fullName: `Amsterdam - ${d.name}`,
  })),
  ...districtsMap.nl.rotterdam.map((d) => ({
    ...d,
    fullName: `Rotterdam - ${d.name}`,
  })),
  ...districtsMap.de.berlin.map((d) => ({
    ...d,
    fullName: `Berlin - ${d.name}`,
  })),
  ...districtsMap.de.cologne.map((d) => ({
    ...d,
    fullName: `Cologne - ${d.name}`,
  })),
  ...districtsMap.de.hamburg.map((d) => ({
    ...d,
    fullName: `Hamburg - ${d.name}`,
  })),
  ...districtsMap.de.munich.map((d) => ({
    ...d,
    fullName: `Munich - ${d.name}`,
  })),
  ...districtsMap.de.muenster.map((d) => ({
    ...d,
    fullName: `Münster - ${d.name}`,
  })),
  ...districtsMap.de.duesseldorf.map((d) => ({
    ...d,
    fullName: `Düsseldorf - ${d.name}`,
  })),
  ...districtsMap.de.bonn.map((d) => ({
    ...d,
    fullName: `Bonn - ${d.name}`,
  })),
  ...districtsMap.de.frankfurt.map((d) => ({
    ...d,
    fullName: `Frankfurt - ${d.name}`,
  })),
  ...districtsMap.es.barcelona.map((d) => ({
    ...d,
    fullName: `Barcelona - ${d.name}`,
  })),
  ...districtsMap.gb.london.map((d) => ({
    ...d,
    fullName: `London - ${d.name}`,
  })),
  ...districtsMap.us.los_angeles.map((d) => ({
    ...d,
    fullName: `Los Angeles - ${d.name}`,
  })),
];

export const locationTypes = [
  { id: 'outdoor', name: 'Outdoor' },
  { id: 'indoor', name: 'Indoor' },
  { id: 'online', name: 'Online' },
  { id: 'ride', name: 'Ride' },
];

export const locationDetailLinks = [
  { id: 'bike-parking-location', name: 'bike-parking-location' },
  { id: 'whatsapp-group', name: 'whatsapp-group' },
  { id: 'equipment', name: 'equipment' },
  { id: 'tech', name: 'tech' },
  { id: 'how-to-get-in', name: 'how-to-get-in' },
  { id: 'how-to-park-the-bike', name: 'how-to-park-the-bike' },
  { id: 'report-incident', name: 'report-incident' },
  { id: 'location-feedback', name: 'location-feedback' },
  { id: 'coach-hub', name: 'coach-hub' },
  { id: 'exercise-lists', name: 'exercise-lists' },
  { id: 'online-schedule', name: 'online-schedule' },
  { id: 'faq', name: 'faq' },
];

export const publicLinks = [{ id: 'google-maps-review', name: 'google-maps-review' }];
