/* eslint-disable import/prefer-default-export */
import moment from 'moment-timezone';
import { required, minLength, maxLength, minValue, maxValue, number, email } from 'react-admin';

const currentYear = moment().year();

export const validateUser = {
  forename: [required(), minLength(1), maxLength(255)],
  email: [required('Please provide an email address'), email()],
  dobDay: [number(), minValue(1), maxValue(31)],
  dobMonth: [number(), minValue(1), maxValue(12)],
  dobYear: [number(), minValue(1900), maxValue(currentYear)],
  height: [number(), minValue(50), maxValue(300)],
  weight: [number(), minValue(10), maxValue(500)],
  fitnessPerformance: [number(), minValue(1), maxValue(5)],
  country: required('Please select a country'),
  city: required('Please select a city'),
};

export const validateCoach = {
  languages: required(),
  name: [required(), minLength(2)],
  email: [required(), email()],
  phone: required('Phone number is required to contact the coach'),
  city: required('Please assign the city the coach is training in'),
  country: required('Please select the country'),
  locationTypes: required('Please select location types'),
  tier: required("Please select coach's tier"),
};
