import React from 'react';
import { object, string } from 'prop-types';
import { ListButton, EditButton } from 'react-admin';

import CardActions from '@material-ui/core/CardActions';

const cardActionStyles = {
  display: 'flex',
  justifyContent: 'flex-end',
};

function EventTypeShowActions ({ basePath, data }) {
  return (
    <CardActions style={cardActionStyles}>
      <EditButton basePath={basePath} record={data} />
      <ListButton basePath={basePath} />
    </CardActions>
  );
}
EventTypeShowActions.propTypes = { basePath: string, data: object };
EventTypeShowActions.defaultProps = { hasEdit: true, hasList: true };

export default EventTypeShowActions;
