import Chip from '@material-ui/core/Chip';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React from 'react';

const FlagsField = ({ record }) => {
  if (!record) {
    return null;
  }

  return (
    <span>
      {record.roles && map(record.roles, (role) => <Chip label={role} />)}
      {record.is_new && <Chip label='New' />}
      {record.is_on_trial && <Chip label='Active Trial' />}
      {record.is_virtual && <Chip label='Virtual' />}
    </span>
  );
};

FlagsField.propTypes = {
  label: PropTypes.string, // eslint-disable-line
  record: PropTypes.object,
  addLabel: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
};

FlagsField.defaultProps = {
  label: 'Flags',
  record: {},
  addLabel: true,
};

export default FlagsField;
