import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput } from 'react-admin';

import { uscMembershipTypes } from '../constants';


const UscMembershipTypeInput = (props) => (
  <SelectInput
    {...props}
    choices={uscMembershipTypes}
  />
);

UscMembershipTypeInput.propTypes = {
  source: PropTypes.string,
  label: PropTypes.string,
  allowEmpty: PropTypes.bool,
  resettable: PropTypes.bool,
};

UscMembershipTypeInput.defaultProps = {
  source: 'usc_membership_type',
  label: 'USC Membership Type',
  allowEmpty: true,
  resettable: true,
};

export default UscMembershipTypeInput;
