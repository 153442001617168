import axios from 'axios';
import _partition from 'lodash/partition';
import PQueue from 'p-queue';
import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';

import UploadedAudioFilesTable from './Audio/UploadedAudioFilesTable';
import UploadQueue from './UploadQueue';

const queue = new PQueue({ concurrency: 2 });
const apiUrl = process.env.REACT_APP_API_URL;
const uploadSignedUrlBaseUrl = `${apiUrl}/upload-signed-urls`;
const authToken = localStorage.getItem('token');

export default (props) => {
  const [files, setFiles] = useState({});
  const [filesFromInput, setFilesFromInput] = useState([]);

  useEffect(() => {
    filesFromInput.map((file) => {
      setFile({ file });
      return queueUpload(file);
    });
  }, [filesFromInput]);

  const queueUpload = (file) => {
    return queue.add(async () => {
      const upload = await getSignedUrl(file);
      if (upload) await uploadToGCP(upload);
    });
  };

  const setFile = ({ file, path, url, progress, uploadFailed }) => {
    if (!path) return;
    setFiles((prevState) => {
      const newState = {
        ...(prevState[path] && prevState[path]),
        ...(file && { file }),
        ...(path && { path }),
        ...(url && { url }),
        ...(progress && { progress }),
        ...(uploadFailed && { uploadFailed }),
      };
      return {
        ...prevState,
        [path]: newState,
      };
    });
  };

  const removeFiles = (ids) => {
    const newState = { ...files };
    [].concat(ids).map((id) => delete newState[id]);
    setFiles(newState);
  };

  const clearSavedFile = (key) => {
    const newState = { ...files };
    delete newState[key];
    setFiles(newState);
  };

  const handleUploadError = (upload) => {
    upload.uploadFailed = true;
    setFile(upload);
  };

  const getSignedUrl = (file) => {
    const payload = {
      content_type: file.type,
      filename: file.name,
    };
    return axios
      .post(uploadSignedUrlBaseUrl, payload, {
        headers: {
          Authorization: authToken,
          'Content-Type': 'application/json',
        },
      })
      .then((data) => {
        return { file, ...data.data };
      })
      .catch(() => {
        return handleUploadError({ file });
      });
  };

  const uploadToGCP = (upload) => {
    return axios
      .put(upload.url, upload.file, {
        headers: {
          'Content-Type': upload.file.type,
        },
        onUploadProgress: (progressEvent) => {
          const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          upload.progress = percentage;
          setFile(upload);
        },
      })
      .then((data) => {
        return data;
      })
      .catch(() => {
        return handleUploadError(upload);
      });
  };

  const [filesToBeSaved, filesUploading] = _partition(files, (item) => item.progress === 100);

  const fileInputStyle = {
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem',
    color: 'rgba(0, 0, 0, 0.38)',
    background: '#fafafa',
    cursor: 'pointer',
    margin: '1rem',
  };

  return (
    <div>
      <Dropzone onDrop={setFilesFromInput}>
        {({ getRootProps, getInputProps }) => (
          <div style={fileInputStyle} {...getRootProps()}>
            <input {...getInputProps()} accept='.m4a,.mp3,.mp4' />
            <p>Drop a file to upload, or click to select it</p>
          </div>
        )}
      </Dropzone>
      <UploadedAudioFilesTable
        files={filesToBeSaved}
        adminProps={props}
        removeFiles={removeFiles}
        clearSavedFile={clearSavedFile}
      />
      {!!filesUploading.length && <UploadQueue files={filesUploading} onRetry={queueUpload} />}
    </div>
  );
};
