import { withStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import React from 'react';
import { Link, TextField } from 'react-admin';

const styles = (theme) => ({
  link: {
    color: theme.palette.primary.main,
  },
});

const EmbeddedEventTypeField = ({ record, source, className, classes = {} }) => (
  <Link to={{ pathname: `/event-types/${get(record, source)}/show` }} className={className}>
    <TextField record={record.type} source='name' className={classes.link} />
  </Link>
);

EmbeddedEventTypeField.defaultProps = {
  source: 'event_type_id',
  addLabel: true,
  label: 'Event Type',
  link: 'show',
  allowEmpty: true,
  record: {},
};

export default withStyles(styles)(EmbeddedEventTypeField);
