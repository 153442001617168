import LocationIcon from '@material-ui/icons/Place';
import LocationTitle from './LocationTitle';
import LocationShow from './LocationShow';
import LocationList from './LocationList';
// import LocationFilter from './LocationFilter';
import LocationEdit from './LocationEdit';
import LocationCreate from './LocationCreate';

export default {
  icon: LocationIcon,
  list: LocationList,
  show: LocationShow,
  create: LocationCreate,
  edit: LocationEdit,
};

export {
  LocationIcon,
  LocationTitle,
  // LocationFilter,
};
