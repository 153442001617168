import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import MeasureIcon from '@material-ui/icons/Equalizer';

import {
  measureEvent as measureEventAction,
} from './statusActions';


class MeasureButton extends Component {
  constructor () {
    super();

    this.state = {
      showDialog: false,
    };
  }

  handleClick = () => {
    this.setState({ showDialog: true });
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };

  handleAction = () => {
    const { measureEvent, record } = this.props;
    measureEvent(record.id, record);
    this.setState({ showDialog: false });
  }

  render () {
    const { record } = this.props;
    const { showDialog } = this.state;

    return (
      <Fragment>
        <Button
          onClick={this.handleClick}
          color='primary'
          disabled={['cancelled', 'measured', 'completed'].includes(get(record, 'current_status.status_name'))}
        >
          <MeasureIcon /> Measure Event
        </Button>
        <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label='Are you sure?'>
          <DialogTitle>Are you sure you want to <strong>measure</strong> this event?</DialogTitle>
          <DialogActions>
            <Button onClick={this.handleCloseClick}>
              No
            </Button>
            <Button onClick={this.handleAction} key='button'>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

MeasureButton.propTypes = {
  record: PropTypes.object,
  measureEvent: PropTypes.func.isRequired,
};

MeasureButton.defaultProps = {
  record: {},
};

export default connect(null, {
  measureEvent: measureEventAction,
})(MeasureButton);
