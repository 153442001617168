import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ReferenceArrayInput, SelectArrayInput } from 'react-admin';

const styles = {
  root: {
    minWidth: '10rem',
  },
};

const LocationReferenceInput = ({ classes, ...props }) => {
  const filter = { is_published: true, city_code: props.city };

  // when a city code is provided to the component only show location name
  function getOptionText (item) {
    if (props.city) {
      return item.name;
    }
    return `[${item.city_code}] ${item.name}`;
  }

  return (
    <ReferenceArrayInput reference='locations' perPage={100} filter={filter} {...props}>
      <SelectArrayInput optionText={getOptionText} classes={{ root: classes.root }} />
    </ReferenceArrayInput>
  );
};

LocationReferenceInput.defaultProps = {
  resource: 'locations',
  source: 'location_id',
  city: undefined,
  label: 'Location',
  allowEmpty: true,
  sort: { field: 'city_code', order: 'ASC' },
};

export default withStyles(styles)(LocationReferenceInput);
