import React from 'react';
import { ReferenceInput, SelectInput, required } from 'react-admin';

const EventTypeReferenceInput = (props) => (
  <ReferenceInput reference='event-types' perPage={100} {...props}>
    <SelectInput optionText='name' validate={[required()]} />
  </ReferenceInput>
);

EventTypeReferenceInput.defaultProps = {
  resource: 'event-types',
  source: 'event_type_id',
  label: 'Event Type',
  filter: { is_archived: false },
  sort: { field: 'name', order: 'ASC' },
};

export default EventTypeReferenceInput;
