
const apiUrl = process.env.REACT_APP_API_URL;
import { httpClient } from 'client';
import qs from 'qs'
import { flatten } from 'lodash';

const getUserCredits = (requestHandler) => async (type, resource, parameters) => {
    if (resource === 'tickets' && type === 'GET_LIST') {
        const { filter, pagination, sort } = parameters;
        const { charge_remote_plan_id, user_id } = filter
        const { perPage, page } = pagination
        if (charge_remote_plan_id) {
            const { charge_provider, is_chargeable } = filter;
            const { order } = sort
            const startIndex = (page - 1) * perPage;
            const endIndex = startIndex + perPage;
            const creditQuery = qs.stringify({ user_id, '$limit': perPage, remote_plan_id: charge_remote_plan_id })
            const userCredits = await httpClient(`${apiUrl}/payments/v2/credits?${creditQuery}`).then(({ json }) => json.data)
            const userTickets = await Promise.all(userCredits.map((credit) => {
                const ticketQuery = qs.stringify({
                    credit_id: credit.id
                })
                return httpClient(`${apiUrl}/tickets?${ticketQuery}`).then(({ json }) => json.data)
            }))
            const results = flatten(userTickets)
            const data = results.filter((ticket) => {
                if (charge_provider && is_chargeable) {
                    return ticket.payment?.charge?.provider === charge_provider && ticket.is_chargeable === is_chargeable;
                }
                if (charge_provider) {
                    return ticket.payment?.charge?.provider === charge_provider;
                }
                if (is_chargeable) {
                    return ticket.is_chargeable === is_chargeable;
                }
                return ticket
            }).sort((a, b) => {
                if (order === 'ASC') {
                    return new Date(a.event.date_begin) - new Date(b.event.date_begin)
                } else if (order === 'DESC') {
                    return new Date(b.event.date_begin) - new Date(a.event.date_begin)
                }
            }).map((ticket) => ({ ...ticket, remote_plan_id: charge_remote_plan_id })).slice(startIndex, endIndex);
            return { data: data, total: results.length }
        }
        return requestHandler(type, resource, parameters).then((response) => {
            const ticket_ids = response.data.map((ticket) => ticket.id)
            const query = qs.stringify({ ticket_id: ticket_ids, user_id: user_id, '$limit': ticket_ids.length })
            return httpClient(`${apiUrl}/payments/v2/credits?${query}`).then(({ json }) => {
                const userCredits = json.data.reduce((acc, credit) => {
                    acc[credit.id] = credit.remote_plan_id;
                    return acc;
                }, {});
                const data = [...response.data].map((ticket) => {
                    return { ...ticket, remote_plan_id: userCredits[ticket.credit_id] }
                });
                return { ...response, data }
            }).catch(() => response);
        })
    };
    return requestHandler(type, resource, parameters);
}

export default getUserCredits;
