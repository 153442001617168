import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Button } from '@material-ui/core';
import { CheckOutlined } from '@material-ui/icons';

import { useNotify, useRefresh } from 'react-admin';
import httpClient from '../../../client/httpClient';

import CancelOfferButton from './CancelOfferButton';
import OfferApplicationConfirmModal from './OfferApplicationConfirmModal';

const OfferActionsField = (props) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const { record } = props;
  const canBeCancelled = !['cancelled', 'lost'].includes(get(record, 'current_status.status_name'));
  const {
    type,
    id,
    current_status: { status_name },
  } = record;
  const notify = useNotify();
  const refresh = useRefresh();

  const apiUrl = process.env.REACT_APP_API_URL;

  const applyOffer = async () => {
    try {
      return await httpClient(`${apiUrl}/offers/${id}/actions/accept`, { method: 'POST' });
    } catch (error) {
      notify(`Error: ${error.message}`, 'warning');
    } finally {
      closeModal();
      refresh();
    }
  };

  const closeModal = () => setModalOpen(false);
  const openModal = () => setModalOpen(true);

  return (
    <>
      {type === 'application' && status_name === 'applied' && (
        <Button variant='contained' color='primary' onClick={openModal}>
          <CheckOutlined />
          Apply
        </Button>
      )}
      {canBeCancelled && <CancelOfferButton record={record} />}
      <OfferApplicationConfirmModal closeModal={closeModal} isModalOpen={isModalOpen} applyOffer={applyOffer} />
    </>
  );
};

OfferActionsField.propTypes = {
  record: PropTypes.object,
};

OfferActionsField.defaultProps = {
  record: {},
};

export default OfferActionsField;
