import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import ResultIcon from '@material-ui/icons/Assessment';
import { stringify } from 'query-string';
import React from 'react';
import { Link } from 'react-router-dom';

const styles = {
  link: {
    display: 'inline-flex',
    alignItems: 'center',
  },
};

const LinkToRelatedResults = ({ classes, record }) => {
  if (!record) return null;
  return (
    <Button
      color='primary'
      component={Link}
      to={{
        pathname: '/results',
        search: stringify({
          filter: JSON.stringify({ event_id: record.id }),
        }),
      }}
      className={classes.link}
      label='Results'
    >
      <ResultIcon className={classes.icon} />
    </Button>
  );
};

export default withStyles(styles)(LinkToRelatedResults);
