import {
  CREATE_EVENT_TYPE_STRUCTURE_FAILURE,
  CREATE_EVENT_TYPE_STRUCTURE_SUCCESS,
  UPDATE_EVENT_TYPE_STRUCTURE_FAILURE,
  UPDATE_EVENT_TYPE_STRUCTURE_SUCCESS,
  RESET_EVENT_TYPE_STRUCTURE_FORM_ERRORS,
} from './actions';

export default (previousState = [], { type, payload }) => {
  if ([CREATE_EVENT_TYPE_STRUCTURE_FAILURE, UPDATE_EVENT_TYPE_STRUCTURE_FAILURE].includes(type)) {
    return payload.errors || payload.details || previousState;
  }

  if ([CREATE_EVENT_TYPE_STRUCTURE_SUCCESS, UPDATE_EVENT_TYPE_STRUCTURE_SUCCESS].includes(type)) {
    return [];
  }

  if (type === RESET_EVENT_TYPE_STRUCTURE_FORM_ERRORS) {
    return [];
  }

  return previousState;
};
