import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import React from 'react';
import { Link } from 'react-admin';

import FullNameField from './FullNameField';

const styles = (theme) => ({
  link: {
    color: theme.palette.primary.main,
  },
});

const EmbeddedEventField = ({ record, className, classes = {}, isLoading, ...rest }) =>
  record.event ? (
    <Link to={{ pathname: `/events/${record.event_id}/show` }} className={className}>
      <FullNameField
        className={classnames(
          classes.link, // force color override for Typography components
        )}
        record={record.event}
        {...rest}
      />
    </Link>
  ) : null;

EmbeddedEventField.defaultProps = {
  source: 'event_id',
  addLabel: true,
  label: 'Event',
  link: 'show',
  allowEmpty: true,
  record: {},
};

export default withStyles(styles)(EmbeddedEventField);
