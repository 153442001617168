import map from 'lodash/map';
import React, { useEffect } from 'react';
import { DateField, NumberField, Show, Tab, TabbedShowLayout, TextField } from 'react-admin';
import { connect } from 'react-redux';
import EventTypeShowActions from 'reactAdmin/event-types/EventTypeShowActions';
import CapitalizedTextField from 'reactAdmin/fields/CapitalizedTextField';
import EventTypeStructureFormField from 'reactAdmin/fields/EvenTypeStructureFormField';
import BooleanField from 'reactAdmin/fields/VerbalBooleanField';

import { resetEventTypeStructureFormErrors } from '../event-type-structures/actions';
import { languages } from './constants';

const EventTypeShow = (props) => {
  useEffect(() => {
    return props.resetEventTypeStructureFormErrors;
  });

  return (
    <Show title='Event Type Detail View' actions={<EventTypeShowActions />} {...props}>
      <TabbedShowLayout>
        <Tab label='Base'>
          <TextField source='id' />
          <BooleanField source='booking_options.requires_paying_customer' label='Is Chargeable' />
          <TextField source='slug' />
          <TextField source='seo_slug' label='SEO Slug' />
          <TextField source='workout_category' />
          <CapitalizedTextField label='Difficulty' source='difficulty_level' {...props} />
          <NumberField label='Cardio Level' source='cardio_level' />
          <NumberField label='Strength Level' source='strength_level' />
          <DateField label='Created At' source='created_at' locales='de-DE' />
        </Tab>

        <Tab label='Structure'>
          <EventTypeStructureFormField />
        </Tab>

        {map(languages, (language) => (
          <Tab path={`translation_${language.code}`} label={`${language.name} Translation`} key={language.code}>
            <TextField label='Name' source={`name_${language.code}`} />
            <TextField label='Description' source={`description_${language.code}`} />
            <TextField label='Short Description' source={`seo_title_${language.code}`} />
            <TextField label='Address Description' source={`seo_description_${language.code}`} />
          </Tab>
        ))}
      </TabbedShowLayout>
    </Show>
  );
};

const mapDispatchToProps = {
  resetEventTypeStructureFormErrors,
};

export default connect(null, mapDispatchToProps)(EventTypeShow);
