import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import ContentAddIcon from '@material-ui/icons/Add';
import ContentCopyIcon from '@material-ui/icons/FileCopy';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { stringify } from 'query-string';
import React from 'react';
import { ListButton, EditButton } from 'react-admin';
import { Link } from 'react-router-dom';
import { EventCancelModal } from '../EventCancelModal';
import CancelButton from '../CancelButton';
import MeasureButton from '../MeasureButton';

const styles = {
  row: { display: 'flex', justifyContent: 'flex-end', flexFlow: 'row wrap' },
  cardAction: { zIndex: 2, display: 'inline-block', float: 'right', textAlign: 'right' },
};

const EventShowActions = ({ basePath, data, hasEdit, hasList }) => {
  const eventId = data && data.id ? data.id : null;

  const query = {};

  if (data) {
    const dateBeginNextWeek = moment(data.date_begin).add(1, 'weeks').format();

    if (dateBeginNextWeek) {
      query.date_begin = dateBeginNextWeek;
    }

    const params = ['location_id', 'coach_id', 'event_type_id', 'duration', 'max_participants'];

    params.forEach((p) => {
      if (data[p]) query[p] = data[p];
    });
  }

  return (
    <CardActions>
      <div style={styles.row}>
        {hasEdit && <EditButton basePath={basePath} record={data} />}
        {hasList && <ListButton basePath={basePath} />}
      </div>
      <div style={styles.row}>
        <Button color='primary' component={Link} to={`/tickets/create?event_id=${eventId}`}>
          <ContentAddIcon /> Add Ticket
        </Button>
      </div>

      {data && (
        <div style={styles.row}>
          <Button
            color='primary'
            component={Link}
            to={{
              pathname: '/events/create',
              state: { record: data },
            }}
          >
            <ContentCopyIcon /> Copy Event
          </Button>
        </div>
      )}
      <div style={styles.row}>
        {
          data?.current_status?.status_name === 'scheduled' &&
          <EventCancelModal event={data} />
        }
        <MeasureButton record={data} />
      </div>
    </CardActions>
  );
};

EventShowActions.propTypes = {
  basePath: PropTypes.string,
  data: PropTypes.object,
  hasEdit: PropTypes.bool,
  hasList: PropTypes.bool,
};

EventShowActions.defaultProps = { hasEdit: true, hasList: true };

export default EventShowActions;
