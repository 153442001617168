import React from 'react';
import { ReferenceField } from 'react-admin';

import FullNameField from './FullNameField';


const EventReferenceField = props => (
  <ReferenceField source='event_id' reference='events' {...props}>
    <FullNameField />
  </ReferenceField>
);

EventReferenceField.defaultProps = {
  source: 'event_id',
  addLabel: true,
  link: 'show',
  allowEmpty: true,
};

export default EventReferenceField;
