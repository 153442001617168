import addUploadCapabilities from 'reactAdmin/groups/utils/addUploadCapabilities';
import addAvatarUploadCapabilities from 'reactAdmin/users/utils/addAvatarUploadCapabilities';
import addProfileImageUploadCapabilities from 'reactAdmin/trainers/utils/addProfileImageUploadCapabilities';
import addMapImageUploadCapabilities from 'reactAdmin/locations/utils/addMapImageUploadCapabilities';
import addTranslationCapabilities from 'reactAdmin/locations/utils/addTranslationCapabilities';
import addCompanyFilterCapabilities from 'reactAdmin/events/utils/addCompanyFilterCapabilities';
import getUserCredits from 'reactAdmin/tickets/utils/getUserCredits';

import httpClient from './httpClient';
import customRestProvider from './customRestProvider';
import authProvider from './authProvider';

const apiUrl = process.env.REACT_APP_API_URL;


let restProvider = customRestProvider(apiUrl, httpClient);
restProvider = addUploadCapabilities(restProvider);
restProvider = addAvatarUploadCapabilities(restProvider);
restProvider = addProfileImageUploadCapabilities(restProvider);
restProvider = addMapImageUploadCapabilities(restProvider);
restProvider = addTranslationCapabilities(restProvider);
restProvider = addCompanyFilterCapabilities(restProvider);
restProvider = getUserCredits(restProvider);

const restClient = restProvider;

export {
  httpClient,
  customRestProvider,
  authProvider,
  restProvider,
  restClient, // TODO: Remove
};
