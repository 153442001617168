import React from 'react';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import ReportIcon from '@material-ui/icons/Assessment';


const styles = {
  card: { marginBottom: '1em' },
  icon: { float: 'right', width: 64, height: 64, padding: 16, color: '#4caf50' },
  empty: { padding: 16 },
};

const Reports = () => (
  <Card style={styles.card}>
    <CardHeader avatar={<ReportIcon />} title='Reports' />
    <CardContent>
      <div><Link href to={{ pathname: '/reports/birthdays' }}>Birthdays this month</Link></div>
    </CardContent>
  </Card>
);

export default Reports;
