import PropTypes from 'prop-types';
import { parse } from 'query-string';
import React from 'react';
import {
  BooleanInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  AutocompleteInput,
  required,
} from 'react-admin';

import { vendorNameOptions, modelNameOptions, replacementReasons } from './constants';

const SensorForm = ({ isExisting, ...props }) => {
  const { sensor_pool_id } = parse(props.location.search);

  const redirect = sensor_pool_id ? `/sensor_pools/${sensor_pool_id}/show/sensors` : isExisting ? 'show' : 'list';

  const validateSensorId = required();
  const validateHardwareId = required();
  const validateVendorId = required();
  const validateVendorName = required();
  const validateModelName = required();
  const validateSensorPool = required();

  return (
    <SimpleForm {...props} initialValues={{ sensor_pool_id }} redirect={redirect}>
      <TextInput source='slug' label='Sensor ID' validate={validateSensorId} />
      <TextInput source='hw_uid' label='Hardware ID' validate={validateHardwareId} />

      <TextInput source='serial_no' label='Vendor Serial No.' validate={validateVendorId} />
      <SelectInput
        source='vendor_name'
        choices={vendorNameOptions}
        validate={validateVendorName}
        initialValue={vendorNameOptions[1].id}
      />
      <SelectInput
        source='model_name'
        choices={modelNameOptions}
        validate={validateModelName}
        initialValue={modelNameOptions[1].id}
      />

      <ReferenceInput
        label='Sensor Pool'
        source='sensor_pool_id'
        reference='sensor_pools'
        perPage={100}
        allowEmpty
        validate={validateSensorPool}
      >
        <AutocompleteInput optionText={(record) => record && record.name} />
      </ReferenceInput>
      <SelectInput
        source='replacement_reason'
        choices={replacementReasons}
      />

      <BooleanInput source='is_allocatable' label='Allocatable' defaultValue />

      <NumberInput source='current_battery_level' />
      <TextInput multiline source='notes' />
    </SimpleForm>
  );
};

SensorForm.propTypes = {
  isExisting: PropTypes.bool,
  location: PropTypes.object,
};

SensorForm.defaultProps = {
  isExisting: false,
  location: {},
};

export default SensorForm;
