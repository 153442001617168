export const roles = [{ id: 'primary', name: 'Primary' }, { id: 'backup', name: 'Backup' }];

export const languages = [
  { id: 'en', name: 'English' },
  { id: 'de', name: 'German' },
  { id: 'es', name: 'Spanish' },
  { id: 'nl', name: 'Dutch' },
  { id: 'fr', name: 'French' },
  { id: 'sv', name: 'Swedish' },
];
