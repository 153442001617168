import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import moment from 'moment-timezone';
import { withStyles } from '@material-ui/core/styles';
import LocationIcon from '@material-ui/icons/Place';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';

import SensorFlags from './SensorFlags';
import BatteryLevelIcon from './BatteryLevelIcon';


const style = (theme) => ({
  rightListItemText: {
    marginRight: '1em',
  },
  secondaryContainer: {
    display: 'block',
  },
  secondaryFirstIcon: {
    marginBottom: '-2px',
  },
  secondaryRow: {
    display: 'block',
  },
  radio: {
    padding: 0,
  },
});

class SensorListItem extends React.Component {
  handleSelect = (sensor) => () => this.props.onSelected(sensor);

  render () {
    const { sensor, classes, isSelected, onSelected } = this.props;
    const isSelectable = !!onSelected;
    const placeName = get(sensor, 'place.name');
    const battLevel = sensor.current_battery_level;
    const battLevelUpdated = moment(sensor.current_battery_level_updated_at).format('DD.MM.YYYY');

    return (
      <ListItem
        button
        component={isSelectable ? 'div' : 'a'}
        href={isSelectable ? null : `#/sensors/${sensor.id}/show`}
        onClick={isSelectable ? this.handleSelect(sensor) : null}
      >
        {isSelectable && (
          <Radio
            className={classes.radio}
            checked={isSelected}
            tabIndex={-1}
            disableRipple
          />
        )}
        <ListItemText
          primary={(
            <span>
              {sensor.slug}
            </span>
          )}
          secondary={(
            <span className={classes.secondaryContainer}>
              <span className={classes.secondaryRow}>
                {placeName && <span><LocationIcon className={classes.secondaryFirstIcon} fontSize='inherit' />{placeName}&nbsp;|&nbsp;</span>}
              </span>
              <span className={classes.secondaryRow}>
                {battLevel && <span><BatteryLevelIcon className={classes.secondaryFirstIcon} level={battLevel} />{battLevel}% ({battLevelUpdated})</span>}
              </span>
              <span className={classes.secondaryRow}>
                <SensorFlags sensor={sensor} />
              </span>
            </span>
          )}
        />
      </ListItem>
    );
  }
}

SensorListItem.propTypes = {
  sensor: PropTypes.object.isRequired,
  onSelected: PropTypes.func,
  classes: PropTypes.object,
  isSelected: PropTypes.bool,
};

SensorListItem.defaultProps = {
  onSelected: () => null,
  classes: {},
  isSelected: null,
};

export default withStyles(style)(SensorListItem);
